import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import { BalanceOutput } from "../../../../../../../../../../components";

const BonusBalanceCellContent: React.FC<BonusBalanceCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => item.paymentAccounts, [item.paymentAccounts]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<BalanceOutput value={content} balanceType="bonus" />
		</CellContentRoot>
	);
};

declare namespace BonusBalanceCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default BonusBalanceCellContent;
