import React from "react";

import { CallSignCellContent as Content } from "../components";

import base from "./base";

import { Column, ColumnId } from ".";

function callSign({ width, onResize }: Column.Options) {
	return base(ColumnId.CallSign, (item) => <Content item={item} />, {
		width,

		onResize: onResize as (
			columnWidth?: number | undefined,
			dataKey?: string | undefined,
		) => void,
	});
}

export default callSign;
