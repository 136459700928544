/**
 * Converts an object of pixel values to column styles for different formats (PDF, Excel).
 *
 * @param options - An object containing width values and format type.
 * @param options.widths - An object where keys represent column identifiers and values represent pixel widths.
 * @param options.format - The target format ("pdf" or "excel") to determine the conversion ratio.
 * @returns An object mapping column identifiers to their respective styles in the chosen format.
 */
const convertPixelsToColumnWidthsFileHelper = ({
	widths,
	format,
}: {
	widths: Record<string, number>;
	format: "pdf" | "excel";
}): Record<string, Partial<{ cellWidth: number }>> => {
	if (!widths || typeof widths !== "object") {
		console.error(
			"Invalid widths: expected an object with numeric values.",
		);
		return {};
	}

	if (format !== "pdf" && format !== "excel") {
		console.error('Invalid format: expected "pdf" or "excel".');
		return {};
	}

	const [PDF_FORMAT, EXCEL_FORMAT] = [3, 6];
	const divisor = format === "pdf" ? PDF_FORMAT : EXCEL_FORMAT;

	const pixelsToPoints = (px: number): number => (px > 0 ? px / divisor : 0);

	const columnStyles: Record<string, Partial<{ cellWidth: number }>> = {};

	Object.entries(widths).forEach(([key, width]) => {
		if (typeof width !== "number" || Number.isNaN(width) || width < 0) {
			console.error(
				`Invalid column width for key ${key}: expected a positive number.`,
			);
			return;
		}
		columnStyles[key] = { cellWidth: pixelsToPoints(width) };
	});

	return columnStyles;
};

export default convertPixelsToColumnWidthsFileHelper;
