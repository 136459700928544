/* eslint-disable no-param-reassign */

import * as ModelEvent from "@node-elion/syncron";
import { compact, isBoolean, isNumber, isUndefined, set } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createRPCQuery from "../../utils/createRPCQuery.util";
import createLogger from "../../utils/logger.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import IResponseWithItems from "../../types/IResponse";
import { ITransaction } from "../../types/ITransaction";
import { SortingOrder } from "../../types/SortingOrder";
import Subscription from "../../types/Subscription";
import { PaymentAccount } from "../../types/PaymentAccount";
import {
	Schedule,
	File,
	WorkShift,
	ExecutorShiftJournal,
	Parking,
	Person,
	Language,
	Role,
	Dispatcher,
	ExecutorRate,
	Car,
	Service,
	Base,
	TaxiService,
} from "..";

export enum ExecutorStatus {
	AVAILABLE = "available",
	BUSY = "busy",
	DINNER = "dinner",
	HOME = "home",
	ON_ORDER = "on_order",
	CLOSED = "closed",
}

const logger = createLogger({ name: "Executor" });

class Executor extends Base {
	static getFullName = (item: any) =>
		`${item?.surname || ""} ${item?.name || ""} ${
			item?.fatherName || ""
		}`.trim();

	static fromResponse(data: any): Executor.Model | undefined {
		try {
			const paymentAccounts =
				data?.paymentAccounts?.filter?.((item) => {
					if (item?.type === "main" || item?.type === "bonus") {
						return true;
					}
					return false;
				}) || [];

			const roles =
				data?.executorToRoles?.map((executorToRole) =>
					Role.fromResponse(executorToRole?.role),
				) || [];

			const services =
				data?.executorToServices?.map((executorToService) =>
					Service.fromResponse(executorToService?.service),
				) || [];

			const cars =
				data?.executorToCrews?.map((executorToCrew) =>
					Car.fromResponse(executorToCrew?.crew?.car),
				) || [];

			const executorShiftJournals =
				data?.executorShiftJournals?.map((shiftJournal) =>
					ExecutorShiftJournal.fromResponse(shiftJournal),
				) || [];
			const taxiService = data?.taxiService
				? TaxiService.fromResponse(data.taxiService)
				: undefined;

			const person = data?.person
				? Person.fromResponse(data.person)
				: undefined;

			const responsibleDispatcher = data?.responsibleDispatcher
				? Dispatcher.fromResponse(data.responsibleDispatcher)
				: undefined;

			const workShift = data?.schedule
				? WorkShift.fromResponse(data.schedule)
				: undefined;

			const rate = data?.rate
				? ExecutorRate.fromResponse(data.rate)
				: undefined;

			const otherFiles =
				data?.otherFiles?.map((file) => File.fromResponse(file)) || [];
			const passportFiles =
				data?.passportFiles?.map((file) => File.fromResponse(file)) ||
				[];
			const drivingLicenseFiles =
				data?.driverLicenseFiles?.map((file) =>
					File.fromResponse(file),
				) || [];
			const IEFiles =
				data?.individualProprietorFiles?.map((file) =>
					File.fromResponse(file),
				) || [];
			const avatarFiles =
				data?.personalFiles?.map((file) => File.fromResponse(file)) ||
				[];
			const licenseFiles =
				data?.licenseFiles?.map((file) => File.fromResponse(file)) ||
				[];

			const payload: Executor.Model = {
				id: data?.id,

				roles,
				cars,
				services,
				executorShiftJournals,
				taxiService,
				person,
				responsibleDispatcher,
				workShift,
				rate,
				otherFiles,
				passportFiles,
				drivingLicenseFiles,
				IEFiles,

				avatarFiles,
				licenseFiles,

				paymentAccounts,

				// taxIdFiles: data.individualProprietorFiles?.map((file) =>
				// 	File.fromResponse(file),
				// ),

				group: data?.group,
				currentParking: data?.currentParking,

				debt: data?.debt,
				closedBy: data?.closedBy,
				online: data?.online,
				hasActiveOrder: data?.hasActiveOrder,
				schedule: data?.schedule,
				alias: data?.callSign,
				prevStatus: data?.status,
				status: data?.closedBy ? ExecutorStatus.CLOSED : data?.status,
				isWorking: data?.workingStatus,
				login: data?.login,
				priority: data?.executorPriority || 0,
				notes: data?.notes,
				active: data?.active,

				passwordAutoReset: data?.passwordAutoReset,
				isPriorityEnabled: data?.executorPriorityStatus,
				executorToCrews: data?.executorToCrews || [],
				executorToOrders: data?.executorToOrders || [],

				passportSeries: data?.additionalFields?.passport?.series ?? "",
				passportNumber: data?.additionalFields?.passport?.number ?? "",
				passportIssuedAt: data?.additionalFields?.passport?.issuedAt,
				passportIssuedBy:
					data?.additionalFields?.passport?.issuedBy ?? "",
				taxId: data?.additionalFields?.taxId ?? "",
				residenceAddress:
					data?.additionalFields?.passport?.birthPlace ?? "",
				livingAddress:
					data?.additionalFields?.passport?.residencePlace ?? "",

				drivingLicenseSeries:
					data?.additionalFields?.driverLicense?.series ?? "",
				drivingLicenseNumber:
					data?.additionalFields?.driverLicense?.number ?? "",
				drivingLicenseCategory:
					data?.additionalFields?.driverLicense?.category ?? "",
				drivingLicenseIssuedAt:
					data?.additionalFields?.driverLicense?.issuedAt,
				drivingExperience:
					data?.additionalFields?.driverLicense
						?.generalDrivingExperience,

				bankCardNumber: data?.additionalFields?.bankCard?.number ?? "",
				bankCardExpiresAt: data?.additionalFields?.bankCard?.expiresAt,
				bankCardNotes: data?.additionalFields?.bankCard?.notes ?? "",

				IESeries:
					data?.additionalFields?.individualProprietor?.series ?? "",
				IECertificateNumber:
					data?.additionalFields?.individualProprietor?.number ?? "",
				IERegisteredAt:
					data?.additionalFields?.individualProprietor?.issuedAt,
				IEUSREOU:
					data?.additionalFields?.individualProprietor
						?.identificationNumber ?? "",
				IEIBAN:
					data?.additionalFields?.individualProprietor?.iban ?? "",

				permits: {
					isPaymentCommissionsThroughPaymentSystem:
						data?.permits
							?.isPaymentCommissionsThroughPaymentSystem || false,
					isAcceptanceOfCustomerPaymentsToPersonalAccount:
						data?.permits
							?.isAcceptanceOfCustomerPaymentsToPersonalAccount ||
						false,
					isAllowToSelectCarClassesInApp:
						data?.permits?.isAllowToSelectCarClassesInApp || false,
				},

				createdAt: data?.createdAt,
				updatedAt: data?.updatedAt,
				deletedAt: data?.deletedAt,
			};

			return payload;
		} catch (error) {
			logger.error("[Executors] error ", {
				error,
			});
			return undefined;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static toRequest(model: Executor.Model.New | Executor.Model.Modified): any {
		const result: Record<string, any> = {
			otherFileIds: model.otherFileIds,
			passportFileIds: model.passportFileIds,
			driverLicenseFileIds: model.drivingLicenseFileIds,
			individualProprietorFileIds: model.IEFileIds,
			personalFileIds: model.avatarFileIds,
			licenseFileIds: model.licenseFileIds,

			roleIds: model.roleIds,

			carIds: model.carIds,

			serviceIds: model.serviceIds,
			taxiServiceId: model.taxiServiceId,
			responsibleDispatcherId: model.responsibleDispatcherId,
			scheduleId: model.workShiftId,
			executorRateId: model.rateId,

			groupId: model.groupId !== 0 ? model.groupId : null,

			callSign: model.alias,
			executorPriority: model.priority || 0,
			active: model.active,

			login: model.login,
			password: model.password,

			surname: model.lastName,
			name: model.firstName,
			fatherName: model.fatherName,
			phones: model.phones?.map((phone, index) => ({
				group: index === 0 ? 0 : 1,
				number: phone,
			})),

			birthday: model.birthAt,

			additionalFields: {},

			notes: model.notes,
			permits: model.permits,
		};

		if (model.status) result.status = model.status;
		if (isBoolean(model.debt)) result.debt = model.debt;
		if (isBoolean(model.closedBy)) result.closedBy = model.closedBy;

		set(
			result.additionalFields,
			"passport.series",
			model.passportSeries ?? "",
		);

		set(
			result.additionalFields,
			"passport.number",
			model.passportNumber ?? "",
		);

		if (!isUndefined(model.passportIssuedAt))
			set(
				result.additionalFields,
				"passport.issuedAt",
				model.passportIssuedAt,
			);

		set(
			result.additionalFields,
			"passport.issuedBy",
			model.passportIssuedBy ?? "",
		);

		set(
			result.additionalFields,
			"passport.birthPlace",
			model.residenceAddress ?? "",
		);

		set(
			result.additionalFields,
			"passport.residencePlace",
			model.livingAddress ?? "",
		);

		set(result.additionalFields, "taxId", model.taxId ?? "");

		set(
			result.additionalFields,
			"driverLicense.series",
			model.drivingLicenseSeries ?? "",
		);

		set(
			result.additionalFields,
			"driverLicense.number",
			model.drivingLicenseNumber ?? "",
		);

		set(
			result.additionalFields,
			"driverLicense.category",
			model.drivingLicenseCategory ?? "",
		);

		if (!isUndefined(model.drivingLicenseIssuedAt))
			set(
				result.additionalFields,
				"driverLicense.issuedAt",
				model.drivingLicenseIssuedAt,
			);

		set(
			result.additionalFields,
			"driverLicense.generalDrivingExperience",
			model.drivingExperience,
		);

		set(result.additionalFields, "bankCard.number", model.bankCardNumber);

		if (!isUndefined(model.bankCardExpiresAt))
			set(
				result.additionalFields,
				"bankCard.expiresAt",
				model.bankCardExpiresAt,
			);

		set(result.additionalFields, "bankCard.notes", model.bankCardNotes);

		set(
			result.additionalFields,
			"individualProprietor.series",
			model.IESeries,
		);

		set(
			result.additionalFields,
			"individualProprietor.number",
			model.IECertificateNumber,
		);

		if (!isUndefined(model.IERegisteredAt))
			set(
				result.additionalFields,
				"individualProprietor.issuedAt",
				model.IERegisteredAt,
			);

		set(
			result.additionalFields,
			"individualProprietor.identificationNumber",
			model.IEUSREOU,
		);

		set(result.additionalFields, "individualProprietor.iban", model.IEIBAN);

		if (model.paymentTransactions) {
			result.paymentTransactions = model.paymentTransactions;
		}

		logger.info("[Executor] toRequest", { model, result });

		return result;
	}

	public static async getById(
		id: number,
	): Promise<Executor.Model | undefined | null> {
		try {
			const res = await this.request(
				(prpc) => prpc.theirsModel.executor.getById(id),
				{ silent: false, error: true },
			);

			if (!res?.id) return null;

			logger.info("[Executor] getById", res);

			return this.fromResponse(res);
		} catch (error) {
			logger.error("[Executor] error getById", error);
			return null;
		}
	}

	static toCloseRequest(model): any {
		const result: Record<string, any> = {
			reason: model.reason,
			until: model.until,
		};

		return result;
	}

	public static async getHistory(id: number) {
		return this.request((prpc) =>
			prpc.theirsModel.customer.getHistory(id),
		) as unknown as Executor.History;
	}

	public static async store(object: Executor.Model.New) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.executor.create(
						Executor.toRequest(object),
					),
				{ silent: false, error: true },
			);

			return res;
		} catch (error) {
			logger.error("[Executor] error store", error);
			return null;
		}
	}

	public static async update(object: Executor.Model.Modified) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.executor.update(
						object.id,
						Executor.toRequest(object),
					),
				{ silent: false, error: true },
			);

			return res;
		} catch (error) {
			logger.error("[Executor] error update", { error });
			return null;
		}
	}

	public static async close(object: Executor.CloseExecutorRequestData) {
		return this.request((prpc) =>
			prpc.theirsModel.executor.close(
				object.id,
				Executor.toCloseRequest(object),
			),
		);
	}

	public static async getAll(
		object?: Executor.SubscribeOptions,
	): Promise<IResponseWithItems<Executor.Model>> {
		const response = await this.request((prpc) =>
			prpc.theirsModel.executor.getAll(object),
		);
		return {
			...response,
			items: response?.items?.map(this.fromResponse),
		};
	}

	public static async updateWorkingStatus(
		object: Executor.UpdateExecutorWorkingStatusRequestData,
		selectedCarId: number | null,
	) {
		return this.request((prpc) => {
			if (isNumber(selectedCarId)) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const onEvent = async (data) => {
					try {
						await prpc.theirsModel.executor.updateWorkingStatus(
							object.id,
							{
								carId: selectedCarId,
								workingStatus: object.workingStatus,
							},
						);
					} catch (error) {
						logger.error("[Executor] updateWorkingStatus:", error);
					}
				};

				return prpc.theirsModel.executor.updateWorkingStatus(
					object.id,
					{
						workingStatus: object.workingStatus,
						onEvent,
					},
				);
			}
			return prpc.theirsModel.executor.updateWorkingStatus(object.id, {
				workingStatus: object.workingStatus,
			});
		});
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async search(
		options: Executor.SearchOptions,
	): Promise<Executor.Model | void> {
		const response = await this.request((prpc) =>
			prpc.theirsModel.executor.search({
				query: options.query,
				callSign: options.alias,
			}),
		);

		const value = Executor.fromResponse(response);
		if (!value) return undefined;
		return value;
	}

	public static async getByPhone(phone: string) {
		if (!phone) return null;
		try {
			const res = await this.request(
				(prpc) => prpc.theirsModel.executor.getByPhone(phone),
				{ silent: false, error: true },
			);
			return res;
		} catch (error) {
			logger.error("[Executor] error getByPhone", { phone, error });
			return null;
		}
	}

	public static async checkAlias(
		options: Executor.CheckAliasOptions,
	): Promise<boolean> {
		const response = await this.request((prpc) =>
			prpc.theirsModel.executor.checkCallSign({
				taxiServiceId: options.taxiServiceId,
				callSign: options.alias,
			}),
		);

		return response.isFree;
	}

	static async reloadTaximeterFromExecutor(object: {
		executorId: Executor.Model["id"];
		params: { status: boolean };
	}): Promise<number | null> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.executor.reloadTaximeterFromExecutor(object),
			);
			return res;
		} catch (error) {
			logger.error(error);
			return null;
		}
	}

	static async rating(object: {
		id: Executor.Model["id"];
	}): Promise<number | null> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.feedback.executor.rating(object.id),
			);

			return res;
		} catch (error) {
			logger.error(error);
			return null;
		}
	}

	public static async subscribe(
		options: Executor.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Executor.Model>,
	): Promise<Subscription<Executor.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("[Executor] subscribe", { options, state });
				const items = compact(state.models);
				const models = compact(items.map(this.fromResponse));
				onUpdate({
					...state,
					models,
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				createRPCQuery(() =>
					prpc.theirsModel.executor.subscribe({
						params: this.optionsToRequest(options),
						ping: () => true,
						onEvent: (events) => {
							modelEventConstructor.onEvent(events);
						},
						onError: (error) => {
							logger.error(error);
						},
					}),
				),
			{ name: "Executor.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Executor.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Executor.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.executor.delete(id));
	}

	private static optionsToRequest(options: Executor.SubscribeOptions) {
		const request: Record<string, any> = {
			limit: options.limit,
			offset: options.offset,
			query: options.query,
			order: options.order,
			lang: options.language,
			taxiServiceIds: options.taxiServiceIds,
			statuses: options.statuses,
			active: options.active,
			isOnline: options.isOnline,
			workingStatus: options.workingStatus,
			subscribeType: options.subscribeType,
		};

		if (options.order) {
			request.order = {};

			if (options.order.fullName)
				request.order.name = options.order.fullName.toUpperCase();

			if (options.order.name)
				request.order.name = options.order.name.toUpperCase();

			if (options.order.alias)
				request.order.callSign = options.order.alias.toUpperCase();

			if (options.order.callSign)
				request.order.callSign = options.order.callSign.toUpperCase();

			if (options.order.phone)
				request.order.phone = options.order.phone.toUpperCase();

			if (options.order.phones)
				request.order.phone = options.order.phones.toUpperCase();

			if (options.order.parkNumber)
				request.order.parkNumber =
					options.order.parkNumber.toUpperCase();

			if (options.order.balance)
				request.order.balance = options.order.balance.toUpperCase();

			if (options.order.bonusBalance)
				request.order.bonusBalance =
					options.order.bonusBalance.toUpperCase();

			if (options.order.role)
				request.order.roles = options.order.role.toUpperCase();

			if (options.order.company)
				request.order.company = options.order.company.toUpperCase();

			if (options.order.taxiService)
				request.order.taxiService =
					options.order.taxiService.toUpperCase();

			if (options.order.registeredAt)
				request.order.registeredAt =
					options.order.registeredAt.toUpperCase();

			if (options.order.responsibleDispatcher)
				request.order.responsibleDispatcher =
					options.order.responsibleDispatcher.toUpperCase();

			if (options.order.executorRateId)
				request.order.executorRateId =
					options.order.executorRateId.toUpperCase();

			if (options.order.priority)
				request.order.priority = options.order.priority.toUpperCase();
		}

		return request;
	}
}

export enum ExecutorSubscribeTypeEnum {
	FULL = "full",
	EXECUTOR_PAGE = "executor_page",
	MAIN_ORDER_PAGE = "main_order_page",
	MAIN_ORDER_GET_ALL_PAGE = "main_order_get_all_page",
	MAIN_ORDER_PARKING = "main_order_page_parking",
	EXECUTOR_MODAL = "executor_modal",
	EXECUTOR_MULTISELECT_MODAL = "executor_multiselect_modal",
	EXECUTOR_PARKING = "executor_parking",
	EXECUTOR_PARKING_ORDER_PAGE_SAVE_MODAL = "executor_parking_order_page_save_modal",
	FRONT = "front",
}
export const ExecutorSubscribeType = {
	FULL: "full",
	EXECUTOR_PAGE: "executor_page",
	MAIN_ORDER_PAGE: "main_order_page",
	MAIN_ORDER_GET_ALL_PAGE: "main_order_get_all_page",
	MAIN_ORDER_PARKING: "main_order_page_parking",
	EXECUTOR_MODAL: "executor_modal",
	EXECUTOR_MULTISELECT_MODAL: "executor_multiselect_modal",
	EXECUTOR_PARKING: "executor_parking",
	EXECUTOR_PARKING_ORDER_PAGE_SAVE_MODAL:
		"executor_parking_order_page_save_modal",
	FRONT: "front",
} as const;
export type TypeExecutorSubscribeType = typeof ExecutorSubscribeType;
export type ValueExecutorSubscribeType =
	(typeof ExecutorSubscribeType)[keyof typeof ExecutorSubscribeType];

declare namespace Executor {
	interface Model {
		id: number;
		group:
			| (Record<string, any> & {
					name: Record<Language, string>;
					active: boolean;
					default: boolean;
					configuration: Record<string, unknown>;
					executorIds?: number[];
					taxiServiceIds?: number[];
					id: number;
			  })
			| null;
		debt?: boolean;
		roles?: Role.Model[]; // executorToRoles
		cars?: Car.Model[]; // executorToCars
		services?: Service.Model[]; // executorToServices
		executorShiftJournals?: ExecutorShiftJournal.Model[];
		taxiService?: TaxiService.Model;
		currentParking?: Parking.CurrentParking;
		person?: Person.Model;
		responsibleDispatcher?: Dispatcher.Model;
		workShift?: WorkShift.Model;
		rate?: ExecutorRate.Model;
		otherFiles?: File.Model[];
		passportFiles?: File.Model[];
		drivingLicenseFiles?: File.Model[];
		IEFiles?: File.Model[];
		taxIdFiles?: File.Model[];
		avatarFiles?: File.Model[];
		licenseFiles?: File.Model[];
		paymentAccounts: PaymentAccount[];

		closedBy: ClosedBy;
		online: boolean;
		hasActiveOrder: boolean;
		schedule: Schedule.Model;
		prevStatus?: Model.Status;
		status: Model.Status;
		isWorking: boolean; // workingStatus
		login: string;
		priority: number; // executorPriority
		alias: string; // callSign
		notes: string;
		active: boolean;
		passwordAutoReset: boolean;
		isPriorityEnabled: boolean; // executorPriorityStatus
		executorToCrews: ExecutorToCrews;
		executorToOrders: any[];

		passportSeries: string;
		passportNumber: string;
		passportIssuedAt?: Date;
		passportIssuedBy: string;
		taxId: string;
		residenceAddress: string;
		livingAddress: string;

		drivingLicenseSeries: string;
		drivingLicenseNumber: string;
		drivingLicenseCategory: string;
		drivingLicenseIssuedAt?: Date;
		drivingExperience?: number;

		bankCardNumber?: number;
		bankCardExpiresAt?: Date;
		bankCardNotes: string;

		IESeries: string;
		IECertificateNumber: string;
		IERegisteredAt?: Date;
		IEUSREOU: string;
		IEIBAN: string;

		permits: Permits;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface Permits {
		isPaymentCommissionsThroughPaymentSystem: boolean;
		isAcceptanceOfCustomerPaymentsToPersonalAccount: boolean;
		isAllowToSelectCarClassesInApp: boolean;
		isAllowToSelectCarClassesInAppGroup?: boolean;
		isAllowToSelectCarClassesInAppGlobal?: boolean;
	}

	interface CloseExecutorRequestData {
		id: number;
		reason: string;
		until?: number;
	}

	interface UpdateExecutorWorkingStatusRequestData {
		id: number;
		workingStatus: boolean;
	}

	interface SearchOptions {
		query?: string;
		alias?: string;
	}

	interface CheckAliasOptions {
		taxiServiceId: number;
		alias: string;
	}

	interface ClosedBy {
		id: number;
		reason: string;
		until: string;
		closedAt: string;
		dispatcher: Dispatcher.Model;
	}

	interface ExecutorToCrews {
		id: number;
		additionalFields: any | null; //
		crew: Crew;
		createdAt: string | null;
		updatedAt: string | null;
		deletedAt: string | null;
	}

	interface Crew {
		id: number;
		additionalFields: null;
		car: Car.Model;
		createdAt: string | null;
		updatedAt: string | null;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<Executor.Model>, "order"> {
		language?: Language;
		taxiServiceIds?: number[];
		statuses?: string[];
		active?: boolean;
		isOnline?: boolean;
		workingStatus?: boolean;
		subscribeType?: ValueExecutorSubscribeType;
		order?: Order;
	}

	interface Order {
		fullName?: SortingOrder;
		name?: SortingOrder;
		alias?: SortingOrder;
		callSign?: SortingOrder;
		phone?: SortingOrder;
		phones?: SortingOrder;
		parkNumber?: SortingOrder;
		balance?: SortingOrder;
		bonusBalance?: SortingOrder;

		role?: SortingOrder;

		company?: SortingOrder;
		taxiService?: SortingOrder;
		registeredAt?: SortingOrder;
		responsibleDispatcher?: SortingOrder;
		executorRateId?: SortingOrder;
		priority?: SortingOrder;
	}

	namespace Model {
		type Status = ExecutorStatus;

		interface New {
			otherFileIds?: number[];
			passportFileIds?: number[];
			drivingLicenseFileIds?: number[];
			IEFileIds?: number[];
			avatarFileIds?: number[];
			licenseFileIds?: number[];

			carIds?: number[];

			roleIds: number[];
			serviceIds?: number[];
			taxiServiceId: number;
			responsibleDispatcherId: number;
			workShiftId?: number;
			rateId?: number;
			groupId?: number;

			alias?: string; // callSign
			status?: Model.Status;
			priority?: number; // executorPriority
			active?: boolean;

			closedBy?: boolean | Record<string, any> | null;
			debt?: boolean | null;

			login: string;
			password: string;

			lastName?: string; // surname
			firstName?: string; // name
			fatherName?: string;
			phones?: (string | number)[];

			passportSeries?: string;
			passportNumber?: string;
			passportIssuedAt?: Date;
			passportIssuedBy?: string;
			taxId?: string;
			birthAt?: Date;
			residenceAddress?: string;
			livingAddress?: string;

			drivingLicenseSeries?: string;
			drivingLicenseNumber?: string;
			drivingLicenseCategory?: string;
			drivingLicenseIssuedAt?: Date;
			drivingExperience?: number;

			bankCardNumber?: number;
			bankCardExpiresAt?: Date;
			bankCardNotes?: string;

			IESeries?: string;
			IECertificateNumber?: string;
			IERegisteredAt?: Date;
			IEUSREOU?: string;
			IEIBAN?: string;

			notes?: string;

			paymentTransactions: {
				main: ITransaction;
				bonus: ITransaction;
			};
			permits: Permits;
		}

		type Modified = Partial<New> & Pick<Model, "id">;
	}

	type History = History.Item[];

	namespace History {
		interface Item {
			id: number;

			user: null;

			action: "create" | "update" | "delete";
			comment: string;
			changes: Entry[];

			timestamp: number;
			version: number;
		}

		namespace Entry {
			interface AnyChange<
				Field extends string,
				Value,
				Type extends "simple" | "array",
			> {
				previous?: Value;
				actual?: Value;
				field: Field;
				type: Type extends "simple"
					? "update"
					:
							| "array_item_add"
							| "array_item_remove"
							| "array_item_update";
			}

			type LoginChange = AnyChange<"login", string, "simple">;
			type StatusChange = AnyChange<"status", Model.Status, "simple">;
			type ActiveChange = AnyChange<"active", boolean, "simple">;
			type WorkingStatusChange = AnyChange<
				"workingStatus",
				boolean,
				"simple"
			>;
			type ExecutorPriorityChange = AnyChange<
				"executorPriority",
				number,
				"simple"
			>;
			type PasswordAutoResetChange = AnyChange<
				"passwordAutoReset",
				boolean,
				"simple"
			>;
			type ExecutorPriorityStatusChange = AnyChange<
				"executorPriorityStatus",
				boolean,
				"simple"
			>;
			type CallSignStatusChange = AnyChange<"callSign", string, "simple">;
			type NotesChange = AnyChange<"notes", string, "simple">;
			type BalanceChange = AnyChange<"balance", number, "simple">;
			type FirstNameChange = AnyChange<"name", string, "simple">;
			type LastNameChange = AnyChange<"surname", string, "simple">;
			type FatherNameChange = AnyChange<"fatherName", string, "simple">;
			type BirthdayChange = AnyChange<"birthday", Date, "simple">;
			type CountryChange = AnyChange<"country", string, "simple">;
			type CityChange = AnyChange<"city", string, "simple">;
			type AddressChange = AnyChange<"address", string, "simple">;

			type EmailsChange = AnyChange<
				"emails",
				Person.Model.Email[],
				"array"
			>;
			type PhonesChange = AnyChange<
				"phones",
				Person.Model.Phone[],
				"array"
			>;

			type PasswordChange = AnyChange<"password", never, "simple">;
			type PassportNumberChange = AnyChange<
				"passport.number",
				string,
				"simple"
			>;
			type PassportSeriesChange = AnyChange<
				"passport.series",
				string,
				"simple"
			>;
			type PassportIssuedByChange = AnyChange<
				"passport.issuedBy",
				string,
				"simple"
			>;
			type PassportIssuedAtChange = AnyChange<
				"passport.issuedAt",
				Date,
				"simple"
			>;
			type PassportBirthPlaceChange = AnyChange<
				"passport.birthPlace",
				string,
				"simple"
			>;
			type PassportResidencePlaceChange = AnyChange<
				"passport.residencePlace",
				string,
				"simple"
			>;

			type BankCardNumberChange = AnyChange<
				"bankCard.number",
				string,
				"simple"
			>;
			type BankCardIssuedAtChange = AnyChange<
				"bankCard.issuedAt",
				Date,
				"simple"
			>;
			type BankCardNotesChange = AnyChange<
				"bankCard.notes",
				string,
				"simple"
			>;

			type DriverLicenseNumberChange = AnyChange<
				"driverLicense.number",
				string,
				"simple"
			>;
			type DriverLicenseSeriesChange = AnyChange<
				"driverLicense.series",
				string,
				"simple"
			>;
			type DriverLicenseCategoryChange = AnyChange<
				"driverLicense.category",
				string,
				"simple"
			>;
			type DriverLicenseIssuedAtChange = AnyChange<
				"driverLicense.issuedAt",
				Date,
				"simple"
			>;
			type DriverLicenseDateRangeFromChange = AnyChange<
				"driverLicense.dateRange.from",
				Date,
				"simple"
			>;
			type DriverLicenseDateRangeToChange = AnyChange<
				"driverLicense.dateRange.to",
				Date,
				"simple"
			>;
			type DriverLicenseGeneralDrivingExperienceChange = AnyChange<
				"driverLicense.generalDrivingExperience",
				number,
				"simple"
			>;
			type DriverLicenseNotesChange = AnyChange<
				"driverLicense.notes",
				string,
				"simple"
			>;

			type LicenseNotesChange = AnyChange<
				"license.notes",
				string,
				"simple"
			>;

			type IndividualProprietorNumberChange = AnyChange<
				"individualProprietor.number",
				string,
				"simple"
			>;
			type IndividualProprietorSeriesChange = AnyChange<
				"individualProprietor.series",
				string,
				"simple"
			>;
			type IndividualProprietorIdentificationNumberChange = AnyChange<
				"individualProprietor.identificationNumber",
				string,
				"simple"
			>;
			type IndividualProprietorIBANChange = AnyChange<
				"individualProprietor.iban",
				string,
				"simple"
			>;
			type IndividualProprietorIssuedAtChange = AnyChange<
				"individualProprietor.issuedAt",
				Date,
				"simple"
			>;
			type IndividualProprietorNotesChange = AnyChange<
				"individualProprietor.notes",
				string,
				"simple"
			>;

			type TaxiServiceChange = AnyChange<"taxiService", string, "simple">;
			type ScheduleChange = AnyChange<"schedule", string, "simple">;
			type ResponsibleDispatcherChange = AnyChange<
				"responsibleDispatcher",
				string,
				"array"
			>;
			type ServicesChange = AnyChange<"services", string[], "array">;
			type CrewsChange = AnyChange<"crews", Crew[], "array">;
			type RolesChange = AnyChange<"roles", string[], "array">;

			type PersonalFilesChange = AnyChange<
				"personalFiles",
				File.Model[],
				"array"
			>;
			type PassportFilesChange = AnyChange<
				"passportFiles",
				File.Model[],
				"array"
			>;
			type DriverLicenseFilesChange = AnyChange<
				"driverLicenseFiles",
				File.Model[],
				"array"
			>;
			type LicenseFilesChange = AnyChange<
				"licenseFiles",
				File.Model[],
				"array"
			>;
			type IndividualProprietorFilesChange = AnyChange<
				"individualProprietorFiles",
				File.Model[],
				"array"
			>;
			type OtherFilesChange = AnyChange<
				"otherFiles",
				File.Model[],
				"array"
			>;
		}

		type Entry =
			| Entry.LoginChange
			| Entry.StatusChange
			| Entry.ActiveChange
			| Entry.WorkingStatusChange
			| Entry.ExecutorPriorityChange
			| Entry.PasswordAutoResetChange
			| Entry.ExecutorPriorityStatusChange
			| Entry.CallSignStatusChange
			| Entry.NotesChange
			| Entry.BalanceChange
			| Entry.FirstNameChange
			| Entry.LastNameChange
			| Entry.FatherNameChange
			| Entry.BirthdayChange
			| Entry.CountryChange
			| Entry.CityChange
			| Entry.AddressChange
			| Entry.EmailsChange
			| Entry.PhonesChange
			| Entry.PasswordChange
			| Entry.PassportNumberChange
			| Entry.PassportSeriesChange
			| Entry.PassportIssuedByChange
			| Entry.PassportIssuedAtChange
			| Entry.PassportBirthPlaceChange
			| Entry.PassportResidencePlaceChange
			| Entry.BankCardNumberChange
			| Entry.BankCardIssuedAtChange
			| Entry.BankCardNotesChange
			| Entry.DriverLicenseNumberChange
			| Entry.DriverLicenseSeriesChange
			| Entry.DriverLicenseCategoryChange
			| Entry.DriverLicenseIssuedAtChange
			| Entry.DriverLicenseDateRangeFromChange
			| Entry.DriverLicenseDateRangeToChange
			| Entry.DriverLicenseGeneralDrivingExperienceChange
			| Entry.DriverLicenseNotesChange
			| Entry.LicenseNotesChange
			| Entry.IndividualProprietorNumberChange
			| Entry.IndividualProprietorSeriesChange
			| Entry.IndividualProprietorIdentificationNumberChange
			| Entry.IndividualProprietorIBANChange
			| Entry.IndividualProprietorIssuedAtChange
			| Entry.IndividualProprietorNotesChange
			| Entry.TaxiServiceChange
			| Entry.ScheduleChange
			| Entry.ResponsibleDispatcherChange
			| Entry.ServicesChange
			| Entry.CrewsChange
			| Entry.RolesChange
			| Entry.PersonalFilesChange
			| Entry.PassportFilesChange
			| Entry.DriverLicenseFilesChange
			| Entry.LicenseFilesChange
			| Entry.IndividualProprietorFilesChange
			| Entry.OtherFilesChange;
	}
}

export default Executor;
