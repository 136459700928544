import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (
	item: PaymentFromExecutorCashCellContent.Props["item"],
) => {
	if (!isNumber(item?.manualReplenishmentAmount)) return formatNumber(0);
	return formatNumber(item?.manualReplenishmentAmount);
};

const PaymentFromExecutorCashCellContent: React.FC<
	PaymentFromExecutorCashCellContent.Props
> = ({ item }) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace PaymentFromExecutorCashCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default PaymentFromExecutorCashCellContent;
