import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ClickAwayListener } from "@mui/material";
import { Column, Select } from "uikit";
import styled from "styled-components";

import SIPToDispatcher from "../../../../../../../../services/SIPToDispatcher";
import sipToDispatcher from "../../../../../../../../redux/reducers/sipToDispatcher";
import Loader from "../../../../../../../Loader";
import SIP from "../../../../../../../../services/SIP";
import LabeledField from "../../../../../../../LabeledField";
import { useTypedSelector } from "../../../../../../../../redux/store";
import softphoneWidget from "../../../../../../../../redux/reducers/softphoneWidget";
import DeleteModal from "../../../../../../../DeleteModal";

import { checkSipForActivity } from "./components/Sip/helper";
import Sip from "./components/Sip";
import Root from "./components/Root";
import SipTable from "./components/SipTable";
import HR from "./components/HR";
import Message from "./components/Message";

const StyledLabeledField = styled(LabeledField)`
	margin: 0 0 0.75rem 0;
	color: rgba(0, 0, 0, 0.87);
	justify-items: center;
	text-align: center;
	font-weight: 700;
	font-size: 1.1rem;
	line-height: 1.3;
`;

interface Props {
	userId: number;
	onClose: () => void;
}

type LineType = "internal" | "external" | "none" | null;

const PopupContent: React.FC<Props> = ({ userId, onClose }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { uaConfig } = useTypedSelector((state) => state.sipToDispatcher);
	const { callsData } = useTypedSelector((state) => state.softphoneWidget);
	const { lineType, sipValue } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const [isLoading, setIsLoading] = useState(true);
	const [isHardPhoneSipsListEmpty, setIsHardPhoneSipsListEmpty] =
		useState(false);
	const [isSoftPhoneSipsListEmpty, setIsSoftPhoneSipsListEmpty] =
		useState(false);
	const [softPhoneSipsData, setSoftPhoneSipsData] = useState<
		SIP.Model.Available[]
	>([]);
	const [hardPhoneSipsData, setHardPhoneSipsData] = useState<
		SIP.Model.Available[]
	>([]);

	const [showModal, setShowModal] = useState(false);

	const closeModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const openModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const [selectedLineType, setSelectedLineType] = useState<LineType>(null);

	const internalLine = t("main_header.sip.internal_line") || "";
	const externalLine = t("main_header.sip.external_line") || "";
	const internal = t("main_header.sip.internal") || "";
	const external = t("main_header.sip.external") || "";
	const none = t("main_header.sip.none") || "";
	const lines = t("main_header.sip.lines") || "";
	const sipAlert = t("main_header.sip.alert") || "";
	const noAvailable = t("main_header.sip.no_available") || "";

	const onSubmitSip = useCallback(
		async (sipId: number) => {
			const data = await SIPToDispatcher.initSession({
				sipToDispatcherId: sipId,
			});

			if (data.success) {
				const selectedHardPhoneSip = hardPhoneSipsData.find(
					(sip) => sip.sipToDispatcherId === sipId,
				);
				const selectedSoftPhoneSip = softPhoneSipsData.find(
					(sip) => sip.sipToDispatcherId === sipId,
				);

				const sipToDispatcherId =
					selectedHardPhoneSip?.sipId ||
					selectedSoftPhoneSip?.sipId ||
					sipId;

				if (selectedHardPhoneSip) {
					dispatch(
						sipToDispatcher.actions.setSip({
							id: sipToDispatcherId,
							sipValue: selectedHardPhoneSip.sipValue,
						}),
					);
					dispatch(sipToDispatcher.actions.setLineType(externalLine));
				}

				if (selectedSoftPhoneSip) {
					dispatch(
						sipToDispatcher.actions.setSip({
							id: sipToDispatcherId,
							sipValue: selectedSoftPhoneSip.sipValue,
						}),
					);
					dispatch(sipToDispatcher.actions.setLineType(internalLine));
				}

				sessionStorage.setItem(
					"sipToDispatcherId",
					JSON.stringify(sipToDispatcherId || sipId),
				);
				sessionStorage.setItem("dispatcherId", JSON.stringify(userId));
				sessionStorage.setItem(
					"sipData",
					JSON.stringify({
						dispatcherId: userId,
						sipToDispatcherId: sipToDispatcherId || sipId,
						sipValue:
							selectedHardPhoneSip?.sipValue ||
							selectedSoftPhoneSip?.sipValue,
						lineType: selectedSoftPhoneSip
							? internalLine
							: externalLine,
					}),
				);
			}
			onClose();
		},
		[
			onClose,
			hardPhoneSipsData,
			softPhoneSipsData,
			userId,
			internalLine,
			externalLine,
			dispatch,
		],
	);

	const onSubmitSoftPhoneSip = useCallback(async () => {
		for (let i = 0; i < softPhoneSipsData.length; i++) {
			const sip = softPhoneSipsData[i];
			if (checkSipForActivity(sip.lastCheckMs)) {
				await onSubmitSip(sip.sipToDispatcherId);
				onClose();
				return null;
			}
		}
		setIsSoftPhoneSipsListEmpty(true);
		return null;
	}, [onClose, onSubmitSip, softPhoneSipsData]);

	const getSips = useCallback(async () => {
		const sips = await SIPToDispatcher.getAvailable({
			dispatcherId: userId,
			status: "ALL",
		});

		const filtredHardPhoneSips = sips.filter((sip) => !sip.isSoftPhone);
		if (!filtredHardPhoneSips.length) {
			setIsHardPhoneSipsListEmpty(true);
		}
		const filtresSoftPhoneSips = sips.filter((sip) => sip.isSoftPhone);
		if (!filtresSoftPhoneSips.length) {
			setIsSoftPhoneSipsListEmpty(true);
		}

		setHardPhoneSipsData(filtredHardPhoneSips);
		setSoftPhoneSipsData(filtresSoftPhoneSips);
		setIsLoading(false);
		return null;
	}, [userId]);

	const clearSipSession = useCallback(async () => {
		await SIPToDispatcher.initSession({
			sipToDispatcherId: -1,
		});
		uaConfig?.ua.stop();
		dispatch(softphoneWidget.actions.clearCalls());
		dispatch(softphoneWidget.actions.setSelectedCaller(null));
		dispatch(softphoneWidget.actions.setIsOpen(false));
		dispatch(sipToDispatcher.actions.clearSipToDispatcher());
		sessionStorage.removeItem("sipToDispatcherId");
		sessionStorage.removeItem("dispatcherId");
		sessionStorage.removeItem("sipData");
		onClose();
	}, [dispatch, onClose, uaConfig?.ua]);

	useEffect(() => {
		if (isLoading) getSips();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const variousSipsChanges = useCallback(
		(lineType: LineType) => {
			if (lineType === "internal") onSubmitSoftPhoneSip();
			if (lineType === "none") clearSipSession();
		},
		[clearSipSession, onSubmitSoftPhoneSip],
	);

	useEffect(() => {
		if (selectedLineType === "internal") {
			if (callsData.length) {
				openModal();
				return;
			}
			onSubmitSoftPhoneSip();
		}
	}, [callsData.length, onSubmitSoftPhoneSip, openModal, selectedLineType]);

	useEffect(() => {
		if (selectedLineType === "none") {
			if (callsData.length) {
				openModal();
				return;
			}
			clearSipSession();
		}
	}, [
		callsData.length,
		clearSipSession,
		dispatch,
		onClose,
		openModal,
		selectedLineType,
		uaConfig?.ua,
	]);

	const selectOptions = useMemo(() => {
		const internalLine = {
			key: 1,
			label: internal,
			value: "internal",
		};
		const externalLine = {
			key: 2,
			label: external,
			value: "external",
		};
		const noLine = {
			key: 3,
			label: none,
			value: "none",
		};
		return [internalLine, externalLine, noLine];
	}, [external, internal, none]);

	const onChangeSelect = useCallback((value) => {
		setSelectedLineType(value.value);
	}, []);

	const onConfirm = useCallback(() => {
		variousSipsChanges(selectedLineType);
	}, [selectedLineType, variousSipsChanges]);

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Root>
				<Column maxedHeight maxedWidth gaps="12px 10px">
					<StyledLabeledField label={lines}>
						{isLoading && <Loader active dimmed={false} />}
						{!isLoading && (
							<Select
								placeholder={lineType || sipValue || lines}
								options={selectOptions}
								onSelect={onChangeSelect}
							/>
						)}
					</StyledLabeledField>

					{selectedLineType === "external" && <HR />}
					{selectedLineType === "internal" &&
						!softPhoneSipsData.length && <HR />}
					{selectedLineType === "external" &&
						!!hardPhoneSipsData.length && (
							<SipTable>
								{hardPhoneSipsData.map((sip) => (
									<Sip
										key={sip?.sipToDispatcherId}
										data={sip}
										onSubmit={onSubmitSip}
									/>
								))}
							</SipTable>
						)}
				</Column>

				{selectedLineType === "external" &&
					isHardPhoneSipsListEmpty && (
						<Message>{noAvailable}</Message>
					)}
				{selectedLineType === "internal" &&
					isSoftPhoneSipsListEmpty && (
						<Message>{noAvailable}</Message>
					)}
				{showModal && (
					<DeleteModal
						label={sipAlert}
						onConfirm={onConfirm}
						onCancel={closeModal}
					/>
				)}
			</Root>
		</ClickAwayListener>
	);
};

export default PopupContent;
