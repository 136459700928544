/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { OrderSettings } from "../../../../../../services";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import { mapByKey } from "../../../../../../utils";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import { useSort } from "../../../../../../hooks/useTableSettings";
import {
	StyledGrid,
	StyledGridItem,
} from "../../../../../../components/common";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../constants/access";

import Content from "./components/Content";
import EditModal from "./components/EditModal";

const Executor: React.FC = () => {
	const lang = useTypedSelector((state) => state.session.language);

	const [settings, setSettings] = useState<OrderSettings.Model[]>([]);

	useEffect(() => {
		OrderSettings.getAll().then((res) => {
			setSettings(res);
		});
	}, []);

	const [editingItem, setEditingItem] = useState<any | null>(null);

	const [selected, setSelected] = useState<number[]>([]);

	const modelItemById = useMemo(() => mapByKey(settings, "id"), [settings]);

	const edit = useCallback((id: number) => {
		setEditingItem({});
	}, []);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const addHandler = useCallback(() => {
		setEditingItem({});
	}, []);

	const areas = useMemo(() => `'header' 'main'`, []);

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"setting.executorParameters",
		"executorParameters",
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalOnSave = useCallback(() => {
		setEditingItem(null);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);
	const contentOnLoadMore = useCallback(() => {}, []);
	return (
		<StyledGrid areas={areas} rows="50px 1fr" w="100%" h="100%">
			<StyledGridItem area="header">
				<DefaultPageHeader
					canAdd={true}
					canEdit={selected.length === 1}
					canDelete={false}
					onAdd={addHandler}
					onEdit={editHeaderHandler}
					accessName={ARR_ACCESS_PART_KEY[TabKeys.EXECUTOR]}
				/>
			</StyledGridItem>

			<StyledGridItem area="main">
				<Content
					selected={selected}
					sort={sort}
					loading={false}
					data={[]}
					language={lang}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</StyledGridItem>

			{editingItem && (
				<EditModal
					value={editingItem}
					language={lang}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
		</StyledGrid>
	);
};

export default Executor;
