import React, { Dispatch, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "uikit";
import { assign, clone } from "lodash";

import { Language } from "../../services";
import { getTaxiServiceLabel } from "../../utils";
import { useCompanyAndTaxiServiceIdsFilter } from "../../hooks";

const CompanyAndTaxiServiceSelect: React.FC<
	CompanyAndTaxiServiceSelect.Props
> = ({ value, language, allowedTaxiServiceIds, onChange }) => {
	const { t } = useTranslation();
	const currentValueRef = useRef(value);

	currentValueRef.current = value;

	const setField = useCallback(
		<Field extends keyof CompanyAndTaxiServiceSelect.Value>(
			field: Field,
			fieldValue: CompanyAndTaxiServiceSelect.Value[Field],
		) => {
			currentValueRef.current = assign(clone(currentValueRef.current), {
				[field]: fieldValue,
			});

			onChange(currentValueRef.current);
		},
		[onChange],
	);

	const onChangeTaxiServiceIds = useCallback(
		(taxiServiceIds: number[] | ["all"]) => {
			setTaxiServiceIdsError(false);

			setField("taxiServiceIds", taxiServiceIds);
		},
		[setField],
	);

	const onChangeCompanyIds = useCallback(
		(companyIds: number[] | ["all"]) => {
			setCompanyIdsError(false);

			setField("companyIds", companyIds);
		},
		[setField],
	);

	const [
		possibleCompanies,
		possibleTaxiServices,
		companyIds,
		taxiServiceIds,
		setCompanyIds,
		setTaxiServiceIds,
	] = useCompanyAndTaxiServiceIdsFilter(
		value.companyIds,
		value.taxiServiceIds,
		onChangeCompanyIds,
		onChangeTaxiServiceIds,
		{ allowedTaxiServiceIds },
	);

	const [companyIdsError, setCompanyIdsError] = useState(false);

	const possibleCompanyOptions = useMemo(
		() =>
			possibleCompanies.map((company) => ({
				key: company.id,
				label: company.name?.[language] ?? "",
				value: company.id,
			})),
		[language, possibleCompanies],
	);

	const companyIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setCompanyIds(newCompanyIds as number[]);
		},
		[setCompanyIds],
	);

	const [taxiServiceIdsError, setTaxiServiceIdsError] = useState(false);

	const possibleTaxiServiceOptions = useMemo(
		() =>
			possibleTaxiServices.map((taxiService) => ({
				key: taxiService.id,
				label: getTaxiServiceLabel(taxiService, language),
				value: taxiService.id,
			})),
		[language, possibleTaxiServices],
	);

	const taxiServiceIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setTaxiServiceIds(newCompanyIds as number[]);
		},
		[setTaxiServiceIds],
	);

	return (
		<>
			<MultiSelect
				value={companyIds}
				all
				placeholder={t("companyAndTaxiServiceSelect.str0") ?? ""}
				error={companyIdsError}
				options={possibleCompanyOptions}
				onChange={companyIdsOnChange}
			/>
			<MultiSelect
				value={taxiServiceIds}
				all
				placeholder={t("companyAndTaxiServiceSelect.str1") ?? ""}
				error={taxiServiceIdsError}
				options={possibleTaxiServiceOptions}
				onChange={taxiServiceIdsOnChange}
			/>
		</>
	);
};

declare namespace CompanyAndTaxiServiceSelect {
	interface Value {
		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];
	}

	interface Props {
		value: Value;

		language: Language;
		allowedTaxiServiceIds?: number[];

		onChange: Dispatch<Value>;
	}
}

export default CompanyAndTaxiServiceSelect;
