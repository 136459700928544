import React, {
	Dispatch,
	memo,
	useCallback,
	useMemo,
	useState,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Client } from "../../../../services";
import { useModelSubscribe } from "../../../../hooks";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";
import { StyledP, StyledRow, StyledColumn } from "../..";

const EqualWidthColumn = styled(StyledColumn)`
	flex: 1;
`;

const MultiSelectClientWithModal: React.FC<
	MultiSelectClientWithModal.Props
> = ({
	value = [],
	onChange,
	disabled,
	titleText,
	required,
	modalSetting,
	sort,
	subscribeOptions = {},
}) => {
	const { t } = useTranslation();

	const options = useMemo(() => subscribeOptions, [subscribeOptions]);

	const clientSubscribe = useModelSubscribe(options, Client);

	const [error, setError] = useState("");

	const texts = useMemo(
		() => ({
			title: t("clientChoise"),
			allText: t("all_client"),
			allActiveText: t("all_active_client"),
		}),
		[t],
	);

	const convertDataToFullname = (client: Client.Model): string => {
		const lastName = client.person?.lastName ? client.person?.lastName : "";
		const firstName = client.person?.firstName
			? client.person?.firstName
			: "";
		const fatherName = client.person?.fatherName
			? client.person?.fatherName
			: "";

		return `${lastName} ${firstName} ${fatherName}`.trim();
	};

	const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			clientSubscribe.models
				.map((item) => ({
					key: item.id,
					label: convertDataToFullname(item),
					value: item.id,
					name: convertDataToFullname(item),
					data: item,
				}))
				.filter((option) => option.label !== ""),
		[clientSubscribe.models],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) =>
				value.find((data) => data === item.value),
			),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const ids = newValue.map((item) => item.value);
			onChange(ids);
		},
		[onChange],
	);

	useEffect(() => {
		if (disabled) {
			onChange([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disabled]);

	return (
		<StyledRow gap="7px" alignItems="center">
			<EqualWidthColumn gap="7px">
				{titleText && <StyledP>{t(titleText) ?? ""}</StyledP>}
				<MultiSelectWithModal
					value={selected}
					onChange={selectOnChange}
					options={selectOptions}
					error={error}
					disabled={disabled}
					title={texts.title}
					allText={texts.allText}
					allActiveText={texts.allActiveText}
					required={required}
					pullUpItemInsideArray
					hiddenButton={false}
					showSelectAll
					modalSetting={modalSetting}
					sort={{
						show: true,
						active: true,
						...(sort || {}),
					}}
				/>
			</EqualWidthColumn>
		</StyledRow>
	);
};

declare namespace MultiSelectClientWithModal {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText?: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		subscribeOptions?: Client.SubscribeOptions;
	}

	type Value = number[];
}

export default memo(MultiSelectClientWithModal);
