import { TaxiService, Language } from "../services";

/**
 * Generates a label for a taxi service, primarily for use in selectors.
 *
 * This function constructs a label by combining the settlement name with the company name
 * in parentheses. Since company names are often named after cities, multiple companies
 * may have branches in the same city. To avoid confusion, the company name is appended
 * to the settlement name.
 *
 * @param {TaxiService.Model} taxiService - The taxi service object.
 * @param {Language} language - The current language for localization.
 * @param {boolean} [onlyTaxiService] - If true, returns only the branch name without the company.
 * @returns {string} - The formatted label including the settlement name and company affiliation.
 */
const getTaxiServiceLabel = (
	taxiService: TaxiService.Model,
	language: Language,
	onlyTaxiService?: boolean,
): string => {
	if (!taxiService || !language) return "";

	const taxiServiceName = taxiService.settlement?.[language] ?? "";
	if (onlyTaxiService) return taxiServiceName;

	const companyName = taxiService.company?.name?.[language];
	const _companyName = companyName ? ` (${companyName})` : "";

	return `${taxiServiceName}${_companyName}`;
};

export default getTaxiServiceLabel;
