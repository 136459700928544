import React, {
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { MultiSelect, react } from "uikit";
import { useTranslation } from "react-i18next";

import PaymentSystem from "../../../../../../services/PaymentSystem";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import getCompanies from "../../../../../../redux/services/Company/getCompanies";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../components/LabeledField";
import { useTaxiServiceFilterAccess } from "../../../../../../access";
import translationPath from "../../constants/translationPath";

import InternalController from "./Controller";

const CompaniesBase = react.withController<
	Companies.PropsBase,
	Companies.Controller
>(({ value, onChange, controller }) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const { subCompaniesOptions } = useTaxiServiceFilterAccess<any>();

	const { companies } = useTypedSelector((state) => state.ordersPageReducer);

	const valueEditor = useObjectEditor(value, onChange);

	const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
	const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");

	const selectedCompanies = valueEditor.useGetter("companyIds");
	const setSelectedCompanies = valueEditor.useSetter("companyIds");

	const [initialCompanyIds] = useState(selectedCompanies);
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);

	const companiesOptions = useMemo(
		() => subCompaniesOptions({ initialCompanyIds }),
		[subCompaniesOptions, initialCompanyIds],
	);

	const updateSelectedValues = useCallback(
		(newCompanies) => {
			setSelectedCompanies(newCompanies);
			const newTaxiServiceIds = taxiServiceIds.filter((serviceId) => {
				const serviceCompany = companies.items.find((company) =>
					company.taxiServices.some(
						(service) => service.id === serviceId,
					),
				);
				return (
					serviceCompany && newCompanies.includes(serviceCompany.id)
				);
			});
			setTaxiServiceIds(newTaxiServiceIds);
		},
		[
			setSelectedCompanies,
			taxiServiceIds,
			setTaxiServiceIds,
			companies.items,
		],
	);

	useEffect(() => {
		if (companiesOptions.length === 1)
			updateSelectedValues([companiesOptions[0].key]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companiesOptions]);

	controller.setContext({ value, setError });

	return (
		<>
			<LabeledField
				label={t(`${translationPath}.modal.company.title`) || ""}
			>
				<MultiSelect
					all={companiesOptions.length > 1}
					disabled={companiesOptions.length === 1}
					placeholder={
						t(`${translationPath}.modal.company.chooseCompany`) ||
						""
					}
					error={error}
					value={selectedCompanies}
					options={companiesOptions}
					onChange={updateSelectedValues}
				/>
			</LabeledField>
		</>
	);
}, InternalController);
const Companies = memo(CompaniesBase);

declare namespace Companies {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		taxiServices: PaymentSystem.Model["taxiServices"];
		taxiServiceIds: PaymentSystem.Model["taxiServiceIds"];
		companies: PaymentSystem.Model["companies"];
		companyIds: PaymentSystem.Model["companyIds"];
	}
}

export default Companies;
