import React, { useCallback, useMemo, useRef, useEffect } from "react";
import { uniqueId } from "lodash";
import { useTranslation } from "react-i18next";

import { useTypedDispatch, useTypedSelector } from "../../../redux/store";
import openOrderCard from "../../../redux/services/Order/card/openOrderCard";
import orderPage from "../../../redux/reducers/OrdersPage";
import { ORDERS_TYPES } from "../../../redux/constants/OrdersPage";
import {
	getOrderPassengerPoints,
	stringifyPoint,
} from "../../../redux/constants/OrdersPage/order";
import {
	useColumns,
	useSort,
	useVisibleColumns,
	useWidths,
} from "../../../hooks/useTableSettings";
import { isTextMatchingAnySegmentFilterHelper } from "../../../utils";

import { ColumnId } from "./components/ModelTable/columns";
import ModelTable from "./components/ModelTable";

const orderTableByType = {
	live: "orderLive",
	closed: "orderClosed",
	preliminary: "orderPreliminary",
	executable: "orderExecutable",
	all: "orderAll",
} as const;

const OrderTable: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const settingsLanguage = useTypedSelector(
		(state) => state.session.language,
	);

	const {
		ordersType,
		orders: ordersByType,
		activeOrder,
	} = useTypedSelector((state) => state.ordersPageReducer);
	const filters = useTypedSelector(
		(state) => state.ordersPageReducer.filters[ordersType],
	);

	const isNotClosedTab = useMemo(
		() => ordersType !== ORDERS_TYPES.CLOSED,
		[ordersType],
	);

	const orders = useMemo(
		() => ordersByType[ordersType],
		[ordersByType, ordersType],
	);

	const tableId = useMemo(
		() => `main-order-page-${ordersType}`,
		[ordersType],
	);
	const tableType = useMemo(() => orderTableByType[ordersType], [ordersType]);

	const columns = useColumns(tableType);
	const { columnIds, setColumnIds } = useVisibleColumns(tableId, tableType);
	const { sortMethod, setSortMethod } = useSort(tableId, tableType);
	const { widths, setWidths } = useWidths(tableId, tableType);

	const modelTableColumns = useMemo<ModelTable.Column[]>(
		() =>
			columns
				.map((column) => ({
					id: column.id as ColumnId,
					width: widths[column.id],
					visible: columnIds.includes(column.id as ColumnId),
				}))
				.sort((leftColumn, rightColumn) => {
					const leftIndex = columnIds.indexOf(leftColumn.id);
					const rightIndex = columnIds.indexOf(rightColumn.id);

					return leftIndex - rightIndex;
				}),
		[columnIds, columns, widths],
	);

	const context = useRef<any>({});

	context.current.activeOrder = activeOrder;

	const selectOrder = useCallback(
		(orderId: number) => {
			if (context.current.activeOrder?.id === orderId) return;

			const original = orders.items.find((item) => item.id === orderId);

			if (original) {
				dispatch(orderPage.actions.setFocusableOrder(null));

				dispatch(
					orderPage.actions.setActiveOrder({
						...original,
						hash: uniqueId("order"),
					}),
				);

				if (original.points.length === 1) {
					dispatch(orderPage.actions.setActivePoint(0));
				}
			}
		},
		[orders.items, dispatch],
	);

	const editOrder = useCallback(
		(orderId: number) => {
			dispatch(openOrderCard(orderId));
		},
		[dispatch],
	);

	const modelTableOnChangeColumns = useCallback(
		(columns: ModelTable.Column[]) => {
			setColumnIds(
				columns
					.filter((column) => column.visible)
					.map((column) => column.id),
			);

			setWidths(
				columns.reduce((accumulator, column) => {
					accumulator[column.id] = column.width || 100;

					return accumulator;
				}, {} as Record<ColumnId, number>),
			);
		},
		[setColumnIds, setWidths],
	);

	const modelTableOnLoadMore = useCallback(() => {
		dispatch(
			orderPage.actions.setFilters({
				type: ordersType,
				data: {
					...filters,
					limit: orders.items.length + 10,
				},
			}),
		);
	}, [dispatch, filters, orders.items.length, ordersType]);

	const items = useMemo(() => {
		const models = orders?.items;

		if (!Array.isArray(models) || models.length === 0) return [];

		if (isNotClosedTab) {
			const modelsFiltered = models.filter((model) => {
				let retval = true;
				const [
					isOnFilterByExecutor,
					isOnFilterByCar,
					isOnFilterByOrder,
					isOnFilterByMulty,
				] = [
					Boolean(
						filters.byExecutor?.number &&
							typeof filters.byExecutor.number === "number",
					),
					Boolean(
						filters.byCar?.number &&
							typeof filters.byCar.number === "string",
					),
					Boolean(
						filters.byOrder &&
							["phone", "from", "to"].some(
								(key) =>
									typeof filters.byOrder?.[key] === "string",
							),
					),
					Boolean(
						filters.byMulty &&
							[
								"companyIds",
								"taxiServices",
								"taxiServiceIds",
								"services",
								"classes",
							].some((key) =>
								Array.isArray(filters.byMulty?.[key]),
							),
					),
				];

				if (isOnFilterByExecutor && retval) {
					const aliasExecutorFind: number =
						filters.byExecutor?.number ?? 0;
					const arrAliasExecutorsFromModel: string[] =
						model.executorToOrder?.map(
							(executor) => executor?.executor?.callSign ?? 0,
						) || [];

					retval = arrAliasExecutorsFromModel.includes(
						`${aliasExecutorFind}`,
					);
				}

				if (isOnFilterByCar && retval) {
					const aliasCarFind: string = filters.byCar?.number ?? "";
					const typeCarFind: string = filters.byCar?.type ?? "garage";

					if (typeCarFind === "garage") {
						const callSignCarsFromOrder: string[] =
							model.carToOrder?.map(
								(c) => c.car?.callSign ?? "",
							) || [];

						retval = callSignCarsFromOrder.includes(aliasCarFind);
					}

					if (typeCarFind === "state") {
						const registrationNumberCarToOrder: string[] =
							model.carToOrder?.map(
								(c) =>
									c.car?.additionalFields
										?.registrationNumber ?? "",
							) || [];

						retval =
							registrationNumberCarToOrder.includes(aliasCarFind);
					}
				}

				if (isOnFilterByOrder && retval) {
					const phoneOrderFind: string | null =
						filters.byOrder?.phone ?? null;
					const fromOrderFind: string | null =
						filters.byOrder?.from ?? null;
					const toOrderFind: string | null =
						filters.byOrder?.to ?? null;

					const pointOnMap = t(["point_on_map"]);

					if (phoneOrderFind && retval) {
						const phonesFromOrder = model.phones.map(
							(phone) => phone.number ?? "",
						);

						retval = phonesFromOrder.some((phone) =>
							phone.includes(phoneOrderFind),
						);
					}

					if (fromOrderFind && retval) {
						const points = getOrderPassengerPoints(model);

						const pickupFromOrder = points?.[0]
							? stringifyPoint(points[0], "address", pointOnMap)
							: "";

						retval = isTextMatchingAnySegmentFilterHelper(
							pickupFromOrder,
							fromOrderFind,
						);
					}

					if (toOrderFind && retval) {
						const points = getOrderPassengerPoints(model);

						const destinationFromOrder = points?.at(-1)
							? stringifyPoint(
									points.at(-1),
									"address",
									pointOnMap,
							  )
							: "";

						retval = isTextMatchingAnySegmentFilterHelper(
							destinationFromOrder,
							toOrderFind,
						);
					}
				}

				if (isOnFilterByMulty && retval) {
					const getValidArray = (arr?: number[]): number[] | null =>
						Array.isArray(arr) && arr.length ? arr : null;

					const { byMulty } = filters;

					const [
						companyIdsOrderFind,
						taxiServiceIdsOrderFind,
						servicesOrderFind,
						classesOrderFind,
					] = [
						getValidArray(byMulty?.companyIds),
						getValidArray(byMulty?.taxiServiceIds),
						getValidArray(byMulty?.services),
						getValidArray(byMulty?.classes),
					];

					if (companyIdsOrderFind && retval) {
						const companyFromOrder =
							model.taxiService?.company?.id ?? -1;

						retval = companyIdsOrderFind.includes(companyFromOrder);
					}

					if (taxiServiceIdsOrderFind && retval) {
						const taxiServiceFromOrder =
							model.taxiService?.id ?? -1;

						retval =
							taxiServiceIdsOrderFind.includes(
								taxiServiceFromOrder,
							);
					}

					if (classesOrderFind && retval) {
						const carClassessFromOrder =
							model.orderToCarClasses?.map(
								(item) => item?.carClass?.id || -1,
							) || [];

						retval = carClassessFromOrder.some((num) =>
							classesOrderFind.includes(num),
						);
					}

					if (servicesOrderFind && retval) {
						const servicesFromOrder =
							model.orderToServices?.map(
								(item) => item?.service?.id || -1,
							) || [];

						retval = servicesFromOrder.some((num) =>
							servicesOrderFind.includes(num),
						);
					}
				}

				return retval;
			});

			return modelsFiltered;
		}

		return models;
	}, [filters, isNotClosedTab, orders?.items, t]);

	useEffect(() => {
		/**
		 * Manages the visibility and selection of the active order in the order information widget.
		 *
		 * - If no orders are available (due to filters or lack of data), resets the active order to the default state.
		 * - If an active order exists but lacks route or point details, automatically selects the first available order.
		 * - Ensures that the widget does not remain empty when filters exclude all orders.
		 */
		if (items.length === 0) {
			dispatch(orderPage.actions.setActiveOrderBeDefault());
		} else if (!activeOrder.points?.length && !activeOrder.route?.length) {
			selectOrder(items[0].id);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items.length]);

	return (
		<ModelTable
			selected={activeOrder.id}
			sort={sortMethod}
			columns={modelTableColumns}
			data={items}
			language={settingsLanguage}
			onChangeSelected={selectOrder}
			onChangeSort={setSortMethod}
			onChangeColumns={modelTableOnChangeColumns}
			onEdit={editOrder}
			onLoadMore={modelTableOnLoadMore}
		/>
	);
};

export default OrderTable;
