import React, { Suspense, useMemo, lazy } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import {
	StyledRow,
	SuspenseLoader,
	SideTab,
} from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyExecutorsGeneral = lazy<React.FC<AccountingPage.Props>>(async () => {
	const elem = await import(
		"./tabs/ExecutorsGeneral" /* webpackChunkName: "accounting-page-executors-general" */
	);
	return { default: elem.default };
});

const LazyExecutors = lazy<React.FC<AccountingPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Executors" /* webpackChunkName: "accounting-page-executors" */
	);
	return { default: elem.default };
});

const LazyBankAccounts = lazy<React.FC<AccountingPage.Props>>(async () => {
	const elem = await import(
		"./tabs/BankAccounts" /* webpackChunkName: "accounting-page-bank-accounts" */
	);
	return { default: elem.default };
});

const LazyAgents = lazy<React.FC<AccountingPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Agents" /* webpackChunkName: "accounting-page-agents" */
	);
	return { default: elem.default };
});

const LazyReplenishPerformersBalance = lazy<React.FC<AccountingPage.Props>>(
	async () => {
		const elem = await import(
			"./tabs/ReplenishPerformersBalance" /* webpackChunkName: "accounting-page-replenish-performers-balance" */
		);
		return { default: elem.default };
	},
);

const AccountingPage: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.EXECUTORS_GENERAL,
					label: t("mainPage.accounting.executorsGeneral.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyExecutorsGeneral
									headerTitle={t(
										"mainPage.accounting.executorsGeneral.title",
									)}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTORS_GENERAL],
				},
				{
					key: TabKeys.EXECUTORS,
					label: t("mainPage.accounting.executors2.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyExecutors
									headerTitle={t(
										"mainPage.accounting.executors2.title",
									)}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTORS],
				},

				{
					key: TabKeys.BANK_ACCOUNTS,
					label: t("mainPage.accounting.bankAccounts.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyBankAccounts
									headerTitle={t(
										"mainPage.accounting.bankAccounts.title",
									)}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.BANK_ACCOUNTS],
				},
				{
					key: TabKeys.AGENTS,
					label: t("mainPage.accounting.agents.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyAgents
									headerTitle={t(
										"mainPage.accounting.agents.title",
									)}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.AGENTS],
				},
				{
					key: TabKeys.REPLENISH_PERFORMERS_BALANCE,
					label: t(
						"mainPage.accounting.replenishPerformersBalance.title",
					),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyReplenishPerformersBalance
									headerTitle={t(
										"mainPage.accounting.replenishPerformersBalance.title",
									)}
								/>
							</Suspense>
						),
					},
					accessName:
						TabAccessNames[TabKeys.REPLENISH_PERFORMERS_BALANCE],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<Suspense
			fallback={
				<StyledRow position="absolute" top="50%" left="50%">
					<SuspenseLoader />
				</StyledRow>
			}
		>
			<BasicPageLayout
				navigation={
					<SideTab
						value={activeKey}
						onChange={setActiveKey}
						options={tabs}
						variant="vertical"
					/>
				}
				content={activeTab.value.render()}
			/>
		</Suspense>
	);
};

declare namespace AccountingPage {
	interface Props {
		headerTitle: string;
	}
}

export default AccountingPage;
