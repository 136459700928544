import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const ExecutorRateIdCellContent: React.FC<ExecutorRateIdCellContent.Props> = ({
	item,
}) => {
	const modelItems = useTypedSelector(
		(state) => state.accounts.executors.data.executorRate,
	);

	const content = useMemo(
		() => modelItems?.find((rate) => rate.id === item.rate?.id)?.name ?? "",

		[modelItems, item],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutorRateIdCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default ExecutorRateIdCellContent;
