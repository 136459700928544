/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { Executor } from "../../../../../services";
import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	executors: [],
};

const setExecutorsData: ReduxReducer<State, Executor.Model[]> = (
	state,
	action,
) => {
	state.executors = action.payload;
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const orderExecutorsData = createSlice({
	name: Orders.ConstantOrdersSlice.EXECUTORS_DATA,
	initialState,
	reducers: {
		reset,
		setExecutorsData,
	},
});

export const orderExecutorsDataAction = orderExecutorsData.actions;
export const orderExecutorsDataReducer = orderExecutorsData.reducer;
export type orderExecutorsDataState = State;

export default orderExecutorsData;
