/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { ReduxReducer, ValueToggle } from "../interface";

import { State, TrackData } from "./interface";

const initialState: State = {
	loading: false,
	filters: {
		dateRange: {
			from: moment().startOf("day").toDate(),
			to: moment().endOf("day").toDate(),
		},
	},

	player: {
		position: null,
		next: null,
		date: 0,
		toggle: false,
		max: 0,
		speed: 1,
		deg: 0,
		duration: 0,
		run: true,
	},
	history: {
		items: [],
		error: null,
		isLoading: false,
		positions: [],
		mapBounds: null,
		centroid: [50.455002, 30.511284],
		trackStartAndEnd: [],
		circleMarkerItems: [],
		radius: 0,
		distances: [],
	},
};

const setGPSHistoryRadius: ReduxReducer<State, State["history"]["radius"]> = (
	state,
	{ payload },
) => {
	state.history.radius = payload;
};

const setGPSHistoryTrackStartAndEnd: ReduxReducer<
	State,
	State["history"]["trackStartAndEnd"]
> = (state, { payload }) => {
	state.history.trackStartAndEnd = payload;
};

const setGPSHistoryCircleMarkerItems: ReduxReducer<
	State,
	State["history"]["circleMarkerItems"]
> = (state, { payload }) => {
	state.history.circleMarkerItems = payload;
};

const setGPSHistoryPositions: ReduxReducer<
	State,
	State["history"]["positions"]
> = (state, { payload }) => {
	state.history.positions = payload;
};

const setGPSHistoryMapBounds: ReduxReducer<
	State,
	State["history"]["mapBounds"]
> = (state, { payload }) => {
	state.history.mapBounds = payload;
};

const setGPSHistoryCentroid: ReduxReducer<
	State,
	State["history"]["centroid"]
> = (state, { payload }) => {
	state.history.centroid = payload || initialState.history.centroid;
};

const resetGPSHistory: ReduxReducer<State, any> = (state) => {
	state.history = initialState.history;
};

const setLoadingGPSHistory: ReduxReducer<
	State,
	State["history"]["isLoading"]
> = (state, { payload }) => {
	state.history.isLoading = payload;
};

const setErrorGPSHistory: ReduxReducer<State, State["history"]["error"]> = (
	state,
	{ payload },
) => {
	state.history.error = payload;
};

const setGPSHistoryDistances: ReduxReducer<
	State,
	State["history"]["distances"]
> = (state, { payload }) => {
	state.history.distances = payload;
};
const addGPSHistory: ReduxReducer<State, State["history"]["items"]> = (
	state,
	{ payload },
) => {
	state.history.items = payload;
};

const setLoading: ReduxReducer<State, State["loading"]> = (
	state,
	{ payload },
) => {
	state.loading = payload;
};

const setCallsignExists: ReduxReducer<State, State["callsignExists"]> = (
	state,
	{ payload },
) => {
	state.callsignExists = payload;
};

const setPlayerPosition: ReduxReducer<State, State["player"]["position"]> = (
	state,
	{ payload },
) => {
	state.player.position = payload;
};

const setPlayerNext: ReduxReducer<State, State["player"]["next"]> = (
	state,
	{ payload },
) => {
	state.player.next = payload;
};

const setPlayerSpeed: ReduxReducer<State, State["player"]["speed"]> = (
	state,
	{ payload },
) => {
	state.player.speed = payload;
};

const setPlayerDeg: ReduxReducer<State, State["player"]["deg"]> = (
	state,
	{ payload },
) => {
	state.player.deg = payload;
};

const setPlayerDuration: ReduxReducer<State, State["player"]["duration"]> = (
	state,
	{ payload },
) => {
	state.player.duration = payload;
};

const setPlayerRun: ReduxReducer<State, ValueToggle> = (state, { payload }) => {
	if (payload === "toggle") state.player.run = !state.player.run;
	else state.player.run = payload === "on";
};

const setPlayerDate: ReduxReducer<State, State["player"]["date"]> = (
	state,
	{ payload },
) => {
	state.player.date = payload;
};
const setPlayerMax: ReduxReducer<State, State["player"]["max"]> = (
	state,
	{ payload },
) => {
	state.player.max = payload;
};

const setPlayerToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.player.toggle = !state.player.toggle;
	else state.player.toggle = payload === "on";
};

// TODO: not is use
const setRoute: ReduxReducer<State, TrackData> = (
	state,
	{ payload: track },
) => {
	const start = track.segments.at(0)?.at(0)?.date;
	const end = track.segments.at(-1)?.at(-1)?.date;

	if (start == null || end == null) {
		state.track = {
			startTimestamp: -1,
			endTimestamp: -1,
			durationMS: -1,
			data: track,
		};
	} else {
		state.track = {
			startTimestamp: start,
			endTimestamp: end,
			durationMS: end - start,
			data: track,
		};
	}
};

const modifyFilters: ReduxReducer<State, Partial<State["filters"]>> = (
	state,
	{ payload },
) => {
	state.filters = {
		...state.filters,
		...payload,
	};
};

const resetGPSLog: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const archiveGPSLog = createSlice({
	name: "archiveGPSLog",
	initialState,
	reducers: {
		resetGPSLog,
		setRoute,
		modifyFilters,
		addGPSHistory,
		setLoadingGPSHistory,
		setErrorGPSHistory,
		setLoading,
		setCallsignExists,
		setGPSHistoryPositions,
		setGPSHistoryCentroid,
		setGPSHistoryMapBounds,
		setGPSHistoryCircleMarkerItems,
		setGPSHistoryTrackStartAndEnd,
		resetGPSHistory,
		setGPSHistoryRadius,
		setGPSHistoryDistances,
		setPlayerPosition,
		setPlayerNext,
		setPlayerDuration,
		setPlayerDate,
		setPlayerToggle,
		setPlayerMax,
		setPlayerSpeed,
		setPlayerDeg,
		setPlayerRun,
	},
});

export const archiveGPSLogAction = archiveGPSLog.actions;
export const archiveGPSLogReducer = archiveGPSLog.reducer;

export default archiveGPSLog;
