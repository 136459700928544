import React, { useLayoutEffect, useMemo, useCallback } from "react";

import { SMSTemplate, Order, Tariff, ExecutorLocations } from "../../services";
import { useTypedDispatch, useTypedSelector } from "../../redux/store";
import orderPage from "../../redux/reducers/OrdersPage";
import setRates from "../../redux/actions/Preferences/Rates/setRates";
import { ORDERS_TYPES, OrderType } from "../../redux/constants/OrdersPage";
import { globalState } from "../../redux/reducers";
import OrderStatus from "../../types/OrderStatus";
import { MessageTemplateActions } from "../../types/MessageTemplateEnums";
import { useModelSubscribe } from "../../hooks";
import { useTaxiServiceFilterAccess } from "../../access";
import { useSort } from "../../hooks/useTableSettings";

const ORDER_SMS_TEMPLATES = [
	MessageTemplateActions.WILL_BE_TIME,
	MessageTemplateActions.OUTPUT_PASSENGERS,
	MessageTemplateActions.LATE,
	MessageTemplateActions.CAR_NOT_FOUND,
	MessageTemplateActions.ORDER_SUCCESSFULLY_COMPLETED,
	MessageTemplateActions.WITH_PASSENGERS,
	MessageTemplateActions.DRIVER_SWITCHED,
];

const OrderPageModelsLoader: React.FC<OrderPageModelsLoader.Props> = () => {
	const dispatch = useTypedDispatch();
	const { subTaxiServiceFilterAccess } =
		useTaxiServiceFilterAccess<Order.Model>();

	const connected = useTypedSelector((state) => state.prpc.connected);
	const authorized = useTypedSelector((state) => state.prpc.authorized);

	const ratesSubscription = useModelSubscribe({}, Tariff);
	const smsTemplatesSubscription = useModelSubscribe(
		{
			actions: ORDER_SMS_TEMPLATES,
			order: { action: "ASC" },
		} as any,
		SMSTemplate,
	);
	const subscribeExecutorLocations = useModelSubscribe(
		{ revealExecutor: true },
		ExecutorLocations,
	);

	const canSubscribe: boolean = useMemo(
		() => connected && authorized,
		[connected, authorized],
	);

	const currentUser = useTypedSelector((state) => state.account.user);
	const { preliminaryOrderBecomesLive } = useTypedSelector(
		(state) => state.settings.order.preliminary,
	);
	const filtersBtns = useTypedSelector(
		(state) => state.ordersPageReducer.filtersBtns,
	);

	const timeToLive = preliminaryOrderBecomesLive.interval; // TODO add more defaults from the settings filia

	// const orderTableByType = {
	// 	live: "orderLive",
	// 	closed: "orderClosed",
	// 	preliminary: "orderPreliminary",
	// 	executable: "orderExecutable",
	// 	all: "orderAll",
	// } as const;

	// const { sortMethod: sortMethodAll } = useSort(
	// 	`main-order-page-all`,
	// 	orderTableByType.all,
	// );
	// const { sortMethod: sortMethodClosed } = useSort(
	// 	`main-order-page-closed`,
	// 	orderTableByType.closed,
	// );

	const getOrdersSubscribeOptions = useCallback(
		(
			type: OrderType,
			sortMethod?: { column?: string; type?: string },
		): Order.SubscribeOptions => {
			const options: Order.SubscribeOptions = {
				tab: type,
				// order:
				// 	typeof sortMethod?.column === "string" &&
				// 	typeof sortMethod?.type === "string"
				// 		? { [sortMethod.column]: sortMethod.type }
				// 		: undefined,
			};

			if (type === ORDERS_TYPES.CLOSED) {
				options.dateRange = {
					from: new Date(Date.now() - 24 * 60 * 60 * 1000),
					to: new Date(Date.now() + 24 * 60 * 60 * 1000),
				};
			}

			if (type === ORDERS_TYPES.ALL) {
				if (filtersBtns.showOnlyMyOrders && currentUser) {
					options.dispatcherIds = [currentUser.id];
				}
				if (filtersBtns.showOrdersWithoutExecutors) {
					options.hasExecutor = false;
				}
			}

			return options;
		},
		[
			currentUser,
			filtersBtns.showOnlyMyOrders,
			filtersBtns.showOrdersWithoutExecutors,
		],
	);

	const OrderClosedSubscribeOptions = useMemo(
		() => getOrdersSubscribeOptions(ORDERS_TYPES.CLOSED),
		[getOrdersSubscribeOptions],
	);
	const OrderAllSubscribeOptions = useMemo(
		() => getOrdersSubscribeOptions(ORDERS_TYPES.ALL),
		[getOrdersSubscribeOptions],
	);

	const orderClosedSubscription = useModelSubscribe(
		OrderClosedSubscribeOptions,
		Order,
	);
	const orderAllSubscription = useModelSubscribe(
		OrderAllSubscribeOptions,
		Order,
	);

	const subscriberOptions = useCallback(
		(data: useModelSubscribe.Data<Order.Model>, type: OrderType) => {
			const pagination = (data?.metadataState?.pagination || 0) as number;
			const dataAfterAccess = subTaxiServiceFilterAccess({
				models: data?.models || [],
				branchPath: "taxiService.id",
			});
			// const models = [...dataAfterAccess].sort((a, b) => b.id - a.id);
			const models = dataAfterAccess.sort((a, b) => b.id - a.id);

			const setOnRedux = (
				_type: OrderType,
				_models: Order.Model[],
				_pagination,
			) =>
				dispatch(
					orderPage.actions.setOrdersByTab({
						tab: _type,
						data: {
							items: _models,
							count: (_pagination?.count ?? 0) as number,
							pagination: _pagination,
							loaded: true,
						},
					}),
				);

			if (type === ORDERS_TYPES.CLOSED) {
				setOnRedux(type, models, {
					offset: models.length,
					count: models.length,
				});
			}

			if (type === ORDERS_TYPES.ALL) {
				const updatedModels = models.map((model) => {
					const {
						stage,
						orderDate,
						moveToProgress,
						isPreliminary,
						status,
					} = model;

					const parsedOrderDate = orderDate
						? new Date(orderDate).getTime()
						: NaN;
					const futureTime = timeToLive
						? Date.now() + timeToLive
						: null;

					const isLastTime =
						parsedOrderDate && futureTime
							? parsedOrderDate <= futureTime
							: true;
					const isMoreTime =
						parsedOrderDate && futureTime
							? parsedOrderDate > futureTime
							: true;

					const isValueMoveToProgress = moveToProgress?.value;
					const isNotValueMoveToProgress = !isValueMoveToProgress;

					let newStage = stage;

					if (!stage) {
						if (isNotValueMoveToProgress && isLastTime) {
							newStage = Order.ORDERS_STAGE.LIVE;
						} else if (isPreliminary && isMoreTime) {
							newStage = Order.ORDERS_STAGE.PRELIMINARY;
						} else if (
							status === OrderStatus.EXECUTING &&
							isValueMoveToProgress &&
							(isPreliminary ? isLastTime : true)
						) {
							newStage = Order.ORDERS_STAGE.EXECUTABLE;
						}
					}

					return newStage ? { ...model, stage: newStage } : model;
				});

				const [modelsLive, modelsPreliminary, modelsExecutable] =
					updatedModels.reduce(
						(acc, model) => {
							switch (model.stage) {
								case Order.ORDERS_STAGE.LIVE:
									acc[0].push(model);
									break;
								case Order.ORDERS_STAGE.PRELIMINARY:
									acc[1].push(model);
									break;
								case Order.ORDERS_STAGE.EXECUTABLE:
									acc[2].push(model);
									break;
								default:
									console.error(
										`[Orders] Warn: Unknown stage encountered while distributing orders between tabs on the Orders page. Id: ${model.id}, stage: ${model.stage}`,
										model,
									);
									break;
							}
							return acc;
						},
						Array.from({ length: 3 }, () => [] as typeof models),
					);

				setOnRedux(ORDERS_TYPES.ALL, updatedModels, {
					offset: updatedModels.length,
					count: updatedModels.length,
				});
				setOnRedux(ORDERS_TYPES.LIVE, modelsLive, {
					offset: modelsLive.length,
					count: modelsLive.length,
				});
				setOnRedux(ORDERS_TYPES.PRELIMINARY, modelsPreliminary, {
					offset: modelsPreliminary.length,
					count: modelsPreliminary.length,
				});
				setOnRedux(ORDERS_TYPES.EXECUTABLE, modelsExecutable, {
					offset: modelsExecutable.length,
					count: modelsExecutable.length,
				});

				const totalCount =
					modelsLive.length +
					modelsPreliminary.length +
					modelsExecutable.length;

				if (totalCount !== models.length) {
					console.error(
						`[Orders] Error: Mismatch in order distribution. Expected ${updatedModels.length}, but got ${totalCount}. Check sorting logic between tabs.`,
					);
				}
			}
		},
		[dispatch, subTaxiServiceFilterAccess, timeToLive],
	);

	useLayoutEffect(() => {
		if (canSubscribe)
			subscriberOptions(orderClosedSubscription, ORDERS_TYPES.CLOSED);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, orderClosedSubscription]);

	useLayoutEffect(() => {
		if (canSubscribe)
			subscriberOptions(orderAllSubscription, ORDERS_TYPES.ALL);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, orderAllSubscription]);

	useLayoutEffect(() => {
		const models = canSubscribe ? ratesSubscription.models : [];
		dispatch(setRates(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, ratesSubscription.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? smsTemplatesSubscription.models : [];
		dispatch(orderPage.actions.setSMSTemplates(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, smsTemplatesSubscription.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeExecutorLocations.models : [];
		dispatch(
			globalState.executorLocations.data.setSubscriptionBasicData(models),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeExecutorLocations.models]);

	return <></>;
};

declare namespace OrderPageModelsLoader {
	interface Props {}
}

export default OrderPageModelsLoader;
