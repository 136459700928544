import styled from "styled-components";

import { Colors } from "../../../../../../common";

const Root = styled.div`
	grid-area: points;

	min-height: 134px;

	overflow: hidden;

	background: ${Colors.BACKGROUND_BLUE_1};
`;

export default Root;
