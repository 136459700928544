import React, {
	Dispatch,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Stepper, TextBox, theme } from "uikit";
import { isNumber, isUndefined } from "lodash";
import moment from "moment";

import {
	Executor,
	CarClass,
	Car,
	Service,
} from "../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../redux/store";
import {
	useExecutorsSubHandler,
	useObjectEditor,
	useFormatDate,
	useTime,
	useModelSubscribe,
} from "../../../../../../../../hooks";
import { getExecutorStatuses } from "../../../../../../../../components/OrderPageWidgets/Executors/constants";
import CarModal from "../../../../../../../../components/OrderPageWidgets/Executors/components/ExecutorModal/components/CarModal";
import {
	StyledP,
	StyledRow,
	StyledGrid,
	StyledColumn,
} from "../../../../../../../../components/common";

import ExecutorCard from "./components/ExecutorCard";

const Content: React.FC<Content.Props> = ({
	value,
	carClasses,
	services,
	onChange,
	ordertaxiServiceId,
}) => {
	const { t } = useTranslation();
	const { getAllExecutorsSubHandler } = useExecutorsSubHandler();

	const user = useTypedSelector((state) => state.account.user);
	const lang = useTypedSelector((state) => state.session.language);

	const [isCarClasses, setIsCarClasses] = useState<boolean>(true);
	const [isCarService, setIsCarService] = useState<boolean>(true);
	const [isExecutorService, setIsExecutorService] = useState<boolean>(true);

	const EXECUTOR_STATUSES = useMemo(() => getExecutorStatuses(t), [t]);

	const [executorAlias, setExecutorAlias] = useState("");
	const [showCarModal, setShowCarModal] = useState(false);
	const [carIds, setCarIds] = useState<number[]>([]);

	const subscribeCarOptions = useMemo<Car.SubscribeOptions>(
		() => ({ ids: carIds }),
		[carIds],
	);

	const carModelData = useModelSubscribe(subscribeCarOptions, Car);

	const executorData = useMemo(() => {
		const options: Executor.SubscribeOptions = {
			query: executorAlias,
			...(typeof ordertaxiServiceId === "number"
				? { taxiServiceIds: [ordertaxiServiceId] }
				: {}),
		};

		return getAllExecutorsSubHandler(options);
	}, [executorAlias, getAllExecutorsSubHandler, ordertaxiServiceId]);

	const valueEditor = useObjectEditor(value, onChange);

	const setExecutorValue = valueEditor.useSetter("executor");

	const timeValue = valueEditor.useGetter("arrivalTime");
	const setTimeValue = valueEditor.useSetter("arrivalTime");

	const openShiftValue = valueEditor.useGetter("openShift");
	const setOpenShiftValue = valueEditor.useSetter("openShift");

	const carIdValue = valueEditor.useGetter("carId");
	const setCarIdValue = valueEditor.useSetter("carId");

	const carModels = useMemo(() => carModelData.models, [carModelData]);

	const timeOnChange = useCallback(
		(value: Stepper.Value) => {
			if (!isUndefined(value)) setTimeValue(value);
		},
		[setTimeValue],
	);

	const currentExecutor = useMemo(
		() =>
			executorAlias.length
				? executorData.find(
						(executor) => executor.alias === executorAlias,
				  )
				: undefined,
		[executorAlias, executorData],
	);

	useLayoutEffect(() => {
		setCarIdValue(undefined);
	}, [currentExecutor, setCarIdValue]);

	useLayoutEffect(() => {
		if (!currentExecutor) {
			setOpenShiftValue(undefined);
		}

		if (!currentExecutor) {
			setExecutorValue(undefined);
		} else {
			setExecutorValue(currentExecutor);
		}
	}, [
		currentExecutor,
		currentExecutor?.id,
		setExecutorValue,
		setOpenShiftValue,
	]);

	const carModalSelectedCar = useMemo(
		() =>
			currentExecutor?.cars?.find((car) => car.id === carIdValue) ?? null,
		[carIdValue, currentExecutor?.cars],
	);

	const translations = useMemo(() => {
		const keys = [100, 101, 102, 150, 200, 201, 202, 205, 206, 207, 208];

		const linkTranslations =
			"pages.mainPage.pages.orders.setOrderExecutorModal.content";

		const baseTranslations = keys.reduce(
			(acc, key) => ({
				...acc,
				[`str${key}`]: t(`${linkTranslations}.str${key}`) ?? "",
			}),
			{} as Record<string, string>,
		);

		const retval = {
			isCarClasses: t(`${linkTranslations}.isCarClasses`) ?? "",
			isCarService: t(`${linkTranslations}.isCarService`) ?? "",
			isExecutorService: t(`${linkTranslations}.isExecutorService`) ?? "",
			...baseTranslations,
		} as Record<string, string>;

		return retval;
	}, [t]);

	const executorFullName = useMemo(() => {
		const person = currentExecutor?.person;
		if (!person) return translations.str200;

		return `${person.lastName || ""} ${person.firstName || ""} ${
			person.fatherName || ""
		}`.trim();
	}, [currentExecutor?.person, translations.str200]);

	const existCar = useMemo(
		() => carModels?.find((item) => carIds.includes(item.id)),
		[carIds, carModels],
	);

	useLayoutEffect(() => {
		if (isUndefined(currentExecutor)) return;
		if (!existCar) return;
		if (!services.length) {
			setIsCarService(true);
			return;
		}

		const executorServices =
			currentExecutor.services?.map((item) => item.id) || [];
		const executorCarServices =
			existCar.services?.map((item) => item.id) || [];

		const isExistCar = [...services]
			.filter((item) => item.availableForCar)
			.every((item) => executorCarServices.includes(item.id));

		const isExistExecutor = [...services]
			.filter((item) => item.availableForExecutor)
			.every((item) => executorServices.includes(item.id));

		setIsCarService(isExistCar);
		setIsExecutorService(isExistExecutor);
	}, [
		carModels.length,
		currentExecutor,
		existCar,
		setIsCarService,
		setIsExecutorService,
		services,
	]);

	useLayoutEffect(() => {
		if (!carClasses.length) {
			setIsCarClasses(false);
			return;
		}
		if (isUndefined(currentExecutor)) return;
		if (!existCar) return;

		const {
			distributableCarClass,
			broadcastableCarClass,
			class: existCarClass,
		} = existCar;

		const distributionCompatibleMode: CarClass.CompatibleMode | undefined =
			existCarClass?.distributionCompatibleMode;

		if (!distributionCompatibleMode) {
			setIsCarClasses(false);
			return;
		}

		const existCarClassId =
			existCarClass?.active && existCarClass?.deletedAt === null
				? existCarClass?.id
				: undefined;

		if (distributionCompatibleMode === CarClass.CompatibleMode.WITH_ALL) {
			setIsCarClasses(true);
			return;
		}

		if (
			distributionCompatibleMode === CarClass.CompatibleMode.WITH_ITSELF
		) {
			const exist = carClasses.some(
				(item) => item.id === existCarClassId,
			);
			setIsCarClasses(exist);
			return;
		}

		if (distributionCompatibleMode !== CarClass.CompatibleMode.AUTO) {
			setIsCarClasses(false);
			return;
		}

		const distributableCarClassIds = distributableCarClass
			? distributableCarClass
					.filter((item) => item.active && !item.deletedAt)
					.map((item) => item.id)
			: [];

		const broadcastableCarClassIds = broadcastableCarClass
			? broadcastableCarClass
					.filter((item) => item.active && !item.deletedAt)
					.map((item) => item.id)
			: [];

		const exist = carClasses.find((item) => {
			if (item.id === existCarClassId) return true;
			return (
				distributableCarClassIds.includes(item.id) ||
				broadcastableCarClassIds.includes(item.id)
			);
		});

		setIsCarClasses(!!exist);
	}, [
		carClasses.length,
		currentExecutor,
		carIds,
		setIsCarClasses,
		carClasses,
		existCar,
	]);

	const executorCar = useMemo(() => {
		if (!currentExecutor) return translations.str201;
		const carShift = currentExecutor?.executorShiftJournals?.at(0);
		const carShiftId = carShift ? carShift.car?.id : undefined;

		if (
			currentExecutor.cars?.length !== 1 &&
			!carModalSelectedCar &&
			!carShiftId
		)
			return translations.str202;

		if (!carIdValue && carShiftId) setCarIdValue(carShiftId);

		const car =
			carModalSelectedCar ||
			currentExecutor?.cars?.find(
				(executorCar) => executorCar.id === carShiftId,
			) ||
			currentExecutor?.cars?.at(0);

		if (!car) return translations.str201;

		setCarIds([car.id]);

		return `${car.model?.carBaseTypeToCarBrand?.carBrand?.name ?? ""} ${
			car.model?.name ?? ""
		}, ${car.color?.name?.[lang] ?? ""} (${car.parkNumber}, ${
			car.registrationNumber
		})`;
	}, [
		currentExecutor,
		carModalSelectedCar,
		translations.str202,
		translations.str201,
		carIdValue,
		setCarIdValue,
		lang,
	]);

	const getExecutorStatusData = useCallback(
		(executor: Executor.Model | undefined) => {
			const isWorking = executor?.isWorking ?? false;

			const onOwnOrder =
				executor?.executorToOrders.find(
					(order) => order.source === "executor" && !order.closedAt,
				) ?? false;

			const hasDebt = executor?.debt ?? false;

			let status: keyof typeof EXECUTOR_STATUSES | undefined =
				executor?.status;

			if (isWorking) {
				if (hasDebt) {
					if (onOwnOrder) {
						status = "ownOrderDebt";
					} else {
						status = "availableDebt";
					}
				} else if (onOwnOrder) {
					status = "ownOrder";
				}
			} else {
				status = "notWorking";
			}

			if (!status) return undefined;

			const statusData = EXECUTOR_STATUSES[status];

			return statusData;
		},
		[EXECUTOR_STATUSES],
	);

	const executorStatus = useMemo(() => {
		if (!currentExecutor) return translations.str202;

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const statusData = getExecutorStatusData(currentExecutor)!;

		const workingStatus = currentExecutor.isWorking
			? translations.str205
			: translations.str206;

		return `${statusData.label} (${workingStatus})`;
	}, [
		currentExecutor,
		getExecutorStatusData,
		translations.str202,
		translations.str205,
		translations.str206,
	]);

	const executorStatusColor = useMemo(() => {
		if (!currentExecutor || !currentExecutor.isWorking)
			return theme.colors.primary;

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const statusData = getExecutorStatusData(currentExecutor)!;

		return statusData.color;
	}, [currentExecutor, getExecutorStatusData]);

	const executorAssignedBy = useMemo(
		() =>
			user?.person
				? `${Executor.getFullName(user.person)} (${user.callSign})`
				: "",
		[user?.callSign, user?.person],
	);

	const executorError = useMemo(() => {
		if (!currentExecutor?.status) return undefined;

		if (!currentExecutor?.isWorking && !openShiftValue)
			return translations.str207;

		return undefined;
	}, [
		currentExecutor?.isWorking,
		currentExecutor?.status,
		openShiftValue,
		translations.str207,
	]);

	const now = useTime(1e3);
	const formatDate = useFormatDate();

	const arriveTime = useMemo(
		() =>
			formatDate(
				moment(now)
					.set({ second: 0, millisecond: 0 })
					.add(timeValue, "minutes"),
				"(DD.MM.YYYY HH:mm)",
			),
		[formatDate, now, timeValue],
	);

	const openShiftOnChange = useCallback(
		(value: boolean) => {
			setOpenShiftValue(value);
		},
		[setOpenShiftValue],
	);

	const openCarModal = useCallback(() => {
		setShowCarModal(true);
	}, []);

	const onChangeAlias = useCallback(
		(str: string) => {
			setExecutorAlias(str);
			if (!isCarClasses) setIsCarClasses(true);
			if (!isCarService) setIsCarService(true);
			if (!isExecutorService) setIsExecutorService(true);
			if (carIdValue) setCarIdValue(undefined);
			if (carIds.length) setCarIds([]);
		},
		[
			isCarClasses,
			isCarService,
			isExecutorService,
			carIdValue,
			setCarIdValue,
			carIds.length,
		],
	);

	const closeCarModal = useCallback(() => {
		setShowCarModal(false);
	}, []);

	const carModalOnSave = useCallback(
		(car) => {
			closeCarModal();
			setCarIdValue(car.id);
		},
		[closeCarModal, setCarIdValue],
	);

	return (
		<>
			<StyledColumn pInline="24px" minW="470px" gap="16px">
				<StyledGrid
					alignItems="center"
					justify="space-between"
					columns="auto 286px"
					gap="12px"
					areas=""
				>
					<div>{translations.str100}</div>
					<TextBox.TextBox
						value={executorAlias}
						style={{ height: 31 }}
						error={executorError}
						autoFocus
						placeholder={`${translations.str208}...`}
						onChange={onChangeAlias}
					/>
				</StyledGrid>
				<StyledGrid
					alignItems="center"
					justify="space-between"
					columns="auto 286px"
					gap="12px"
					areas=""
				>
					<div>{translations.str101}</div>
					<StyledGrid
						alignItems="center"
						columns="auto 1fr auto"
						gap="12px"
						areas=""
					>
						<Stepper value={timeValue} onChange={timeOnChange} />
						<div>{translations.str102}</div>
						<div>{arriveTime}</div>
					</StyledGrid>
				</StyledGrid>
				<StyledRow h="32px">
					<CheckBoxWithContent
						value={openShiftValue ?? false}
						disabled={currentExecutor?.isWorking ?? true}
						gap="10px"
						onChange={openShiftOnChange}
					>
						{translations.str150}
					</CheckBoxWithContent>
				</StyledRow>
				{!isCarClasses && (
					<StyledP colors="red">{translations.isCarClasses}</StyledP>
				)}
				{!isCarService && (
					<StyledP colors="red">{translations.isCarService}</StyledP>
				)}
				{!isExecutorService && (
					<StyledP colors="red">
						{translations.isExecutorService}
					</StyledP>
				)}
				<ExecutorCard
					fullName={executorFullName}
					car={executorCar}
					status={executorStatus}
					statusColor={executorStatusColor}
					online={currentExecutor?.online ?? false}
					needToSelectCar={
						isNumber(currentExecutor?.cars?.length) &&
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						currentExecutor!.cars!.length > 1
					}
					assignedBy={executorAssignedBy}
					onSelectCar={openCarModal}
				/>
			</StyledColumn>
			{showCarModal && currentExecutor && (
				<CarModal
					executor={currentExecutor}
					language={lang}
					selectedCar={carModalSelectedCar}
					onClose={closeCarModal}
					onSave={carModalOnSave}
				/>
			)}
		</>
	);
};

declare namespace Content {
	interface Value {
		executor?: Executor.Model;
		arrivalTime: number;
		openShift?: boolean;
		carId?: number;
	}

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
		ordertaxiServiceId?: number;
		carClasses: CarClass.Model[];
		services: Service.Model[];
	}
}

export default Content;
