import React, { useMemo } from "react";

import { Order, Language } from "../../../../../../../services";
import CellContentRoot from "../CellContentRoot";

const CarClassCellContent: React.FC<CarClassCellContent.Props> = ({
	item,
	language,
}) => {
	const content = useMemo(
		() =>
			item.orderToCarClasses
				?.map((item) => item?.carClass?.name?.[language])
				.join(", ") ?? "",
		[item.orderToCarClasses, language],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CarClassCellContent {
	interface Props {
		item: Order.Model;

		language: Language;
	}
}

export default CarClassCellContent;
