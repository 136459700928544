import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (
	item: OrdersCountToCardExecutorCellContent.Props["item"],
) => {
	if (!isNumber(item?.ordersCounters?.cardToExecutor?.count)) return "";
	return item.ordersCounters.cardToExecutor.count;
};

const OrdersCountToCardExecutorCellContent: React.FC<
	OrdersCountToCardExecutorCellContent.Props
> = ({ item }) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace OrdersCountToCardExecutorCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default OrdersCountToCardExecutorCellContent;
