import React, { useMemo } from "react";

import { Dispatcher } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const CallSignCellContent: React.FC<CallSignCellContent.Props> = ({ item }) => {
	const content = useMemo(() => item.alias ?? "", [item?.alias]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace CallSignCellContent {
	interface Props {
		item: Dispatcher.Model;
	}
}

export default CallSignCellContent;
