import React, { memo, useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useTypedSelector } from "../../../../../../redux/store";
import { Popup, StyledColumn } from "../../../../../common";
import { MultiSelectWithModalBase } from "../../../../../common/Modal";
import { ListSelectMemo } from "../../../../../Orders";
import { LabeledContent } from "..";

import { PopupContent } from "./components";

const Content = styled.div`
	cursor: pointer;
	user-select: none;
`;

const popupOffset = { x: -27, y: 38 };

const SIP2 = memo(() => {
	const { t } = useTranslation();

	const user = useTypedSelector((state) => state.account.user);
	const { sipValue, lineType } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const [showPopup, setShowPopup] = useState(false);
	const [selectOptionsExternal, setSelectOptionsExternal] =
		useState<MultiSelectWithModalBase.Value>([]);
	const [choiseSIPidExternal, setChoiseSIPidExternal] = useState<
		number | null
	>(null);

	const [onShowModalExternal, setShowModalExternal] =
		useState<boolean>(false);

	const closePopup = useCallback(() => {
		setShowPopup(false);
	}, []);

	const textTranslate = useMemo(
		() => ({
			externalLine: t("main_header.sip.external_line") ?? "",
			notSelected: t("main_header.notSelected") ?? "",
			line: t("main_header.line") ?? "",
		}),
		[t],
	);

	return (
		<Popup
			useHoverControl
			open={showPopup}
			offset={popupOffset}
			tracker={
				<LabeledContent
					width={180}
					label={
						lineType === "Отсутствуют"
							? textTranslate.line
							: lineType
					}
				>
					<Content>{sipValue || textTranslate.notSelected}</Content>

					{onShowModalExternal && (
						<ListSelectMemo
							sort={{ show: true, active: true, sortType: "asc" }}
							hiddenButton={false}
							headerDivider
							required
							footerDivider
							selectOnlyOne
							value={undefined}
							title={textTranslate.externalLine}
							options={selectOptionsExternal}
							onClose={() => {
								setShowModalExternal(false);
								closePopup();
							}}
							onSubmit={(value) => {
								if (
									Array.isArray(value) &&
									value.length === 1
								) {
									setChoiseSIPidExternal(value[0]?.value);
								}

								setTimeout(() => {
									setShowModalExternal(false);
									closePopup();
								}, 0);
							}}
						/>
					)}
				</LabeledContent>
			}
			onChangeOpen={(value) => {
				setTimeout(() => {
					if (!(onShowModalExternal && !value)) setShowPopup(value);
				}, 0);
			}}
		>
			<StyledColumn
				style={
					onShowModalExternal
						? {
								opacity: 0,
								position: "absolute",
								bottom: 0,
								right: 0,
						  }
						: {}
				}
				gap="0.5rem"
				justify="space-between"
				alignItems="center"
				bgC="#ffffff"
				p="1rem 1rem 1.75rem 1rem"
				b="1px solid #c5c6c7"
				br="5px"
				w={{ min: "100px" }}
			>
				<PopupContent
					onClose={() => {
						setTimeout(() => {
							if (!onShowModalExternal) closePopup();
						}, 0);
					}}
					setShowModalExternal={setShowModalExternal}
					userId={user?.id || -1}
					setSelectOptionsExternal={setSelectOptionsExternal}
					choiseSIPidExternal={choiseSIPidExternal}
					setChoiseSIPidExternal={setChoiseSIPidExternal}
				/>
			</StyledColumn>
		</Popup>
	);
});

export default SIP2;
