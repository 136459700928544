const getFullName = (person?: {
	lastName?: string;
	firstName?: string;
	fatherName?: string;
}) => {
	if (!person) return "";
	const { lastName = "", firstName = "", fatherName = "" } = person;
	return `${lastName} ${firstName} ${fatherName}`.trim();
};

const getPhone = (person?: { phones?: { number?: string }[] }) => {
	if (!person || !person.phones) return "";
	const phoneFirst = person.phones?.[0]?.number ?? "";
	return `${phoneFirst}`.trim();
};

export { getFullName, getPhone };
