import React, { memo } from "react";
import styled, { keyframes } from "styled-components";
import { Property } from "csstype";

import { Style, StyledRow } from "../styles";

const StyleLoading = styled.span`
	min-width: 60px;
	height: 40px;
	position: relative;
	display: inline-block;
	color: var(--rs-primary-500);

	&::before {
		content: "";
		left: 0;
		top: 0;
		position: absolute;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		background-color: var(--rs-primary-500);
		background-image: radial-gradient(
				circle 8px at 18px 18px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 18px 0px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 0px 18px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 36px 18px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 18px 36px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 30px 5px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 30px 5px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 30px 30px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 5px 30px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 5px 5px,
				var(--rs-gray-50) 100%,
				transparent 0
			);
		background-repeat: no-repeat;
		box-sizing: border-box;
		animation: rotationBack 3s linear infinite;
	}
	&::after {
		content: "";
		left: 35px;
		top: 15px;
		position: absolute;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: var(--rs-primary-500);
		background-image: radial-gradient(
				circle 5px at 12px 12px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 12px 0px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 0px 12px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 24px 12px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 12px 24px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 20px 3px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 20px 3px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 20px 20px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 3px 20px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 3px 3px,
				var(--rs-gray-50) 100%,
				transparent 0
			);
		background-repeat: no-repeat;
		box-sizing: border-box;
		animation: rotationBack 4s linear infinite reverse;
	}

	@keyframes rotationBack {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(-360deg);
		}
	}
`;

const StyleLoading2 = styled.span`
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	animation-fill-mode: both;
	animation: bblFadInOut 1.8s infinite ease-in-out;

	&:before,
	&:after {
		border-radius: 50%;
		width: 2.5em;
		height: 2.5em;
		animation-fill-mode: both;
		animation: bblFadInOut 1.8s infinite ease-in-out;
	}
	& {
		color: #fff;
		font-size: 7px;
		position: relative;
		text-indent: -9999em;
		transform: translateZ(0);
		animation-delay: -0.16s;
	}
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
	}
	&:before {
		left: -3.5em;
		animation-delay: -0.32s;
	}
	&:after {
		left: 3.5em;
	}

	@keyframes bblFadInOut {
		0%,
		80%,
		100% {
			box-shadow: 0 2.5em 0 -1.3em;
		}
		40% {
			box-shadow: 0 2.5em 0 0;
		}
	}
`;

const StyleLoading3 = styled.span`
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 32px 0 #fff, -32px 0 #fff;
	position: relative;
	animation: flash 0.5s ease-out infinite alternate;

	@keyframes flash {
		0% {
			background-color: #fff2;
			box-shadow: 32px 0 #fff2, -32px 0 #fff;
		}
		50% {
			background-color: #fff;
			box-shadow: 32px 0 #fff2, -32px 0 #fff2;
		}
		100% {
			background-color: #fff2;
			box-shadow: 32px 0 #fff, -32px 0 #fff2;
		}
	}
`;

const StyleLoading4 = styled.span`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: block;
	margin: 15px auto;
	position: relative;
	color: #fff;
	left: -100px;
	box-sizing: border-box;
	animation: shadowRolling 2s linear infinite;

	@keyframes shadowRolling {
		0% {
			box-shadow: 0px 0 rgba(255, 255, 255, 0),
				0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
				0px 0 rgba(255, 255, 255, 0);
		}
		12% {
			box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
				0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
		}
		25% {
			box-shadow: 110px 0 white, 100px 0 white,
				0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
		}
		36% {
			box-shadow: 120px 0 white, 110px 0 white, 100px 0 white,
				0px 0 rgba(255, 255, 255, 0);
		}
		50% {
			box-shadow: 130px 0 white, 120px 0 white, 110px 0 white,
				100px 0 white;
		}
		62% {
			box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white,
				120px 0 white, 110px 0 white;
		}
		75% {
			box-shadow: 200px 0 rgba(255, 255, 255, 0),
				200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
		}
		87% {
			box-shadow: 200px 0 rgba(255, 255, 255, 0),
				200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
				130px 0 white;
		}
		100% {
			box-shadow: 200px 0 rgba(255, 255, 255, 0),
				200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
				200px 0 rgba(255, 255, 255, 0);
		}
	}
`;

const StyleLoading5 = styled.span`
	text-align: center;
	font-size: 24px;
	color: transparent;
	white-space: pre-wrap;
	overflow: hidden;
	width: 315px;
	background: linear-gradient(0.25turn, transparent, #fff, transparent),
		linear-gradient(#ddd, #ddd),
		radial-gradient(38px circle at 19px 19px, #ddd 50%, transparent 51%),
		linear-gradient(#ddd, #ddd);
	background-color: #fff;
	background-repeat: no-repeat;
	background-size: 315px 200px;
	background-position: -315px 0, 0 0;
	background-clip: text;

	animation: loading 1.5s infinite;

	@keyframes loading {
		to {
			background-position: 315px 0, 0 0;
		}
	}
`;

const dotAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0);
  }
`;

const Dot = styled.span`
	width: 4px;
	height: 4px;
	background-color: #fff;
	border-radius: 50%;
	margin: 0 2px;
	animation: ${dotAnimation} 0.8s infinite ease-in-out;

	&:nth-child(1) {
		animation-delay: 0s;
	}
	&:nth-child(2) {
		animation-delay: 0.1s;
	}
	&:nth-child(3) {
		animation-delay: 0.2s;
	}
	&:nth-child(4) {
		animation-delay: 0.3s;
	}
`;

const StyleLoading6 = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
// https://cssloaders.github.io/
const SuspenseLoaderBase: React.FC<SuspenseLoaderBase.Props> = ({
	loader = "1",
	text = "Loading...",
	position = "absolute",
	...props
}): JSX.Element => (
	<StyledRow
		position={position}
		justify="center"
		alignItems="center"
		{...props}
	>
		{/* Loader 1: Rotating circle with radial gradients */}
		{loader === "1" && <StyleLoading />}
		{/* Loader 2: Circle with "bubbling" animation on both sides */}
		{loader === "2" && <StyleLoading2 />}
		{/* Loader 3: Small white dot with a blinking (pulsing) effect */}
		{loader === "3" && <StyleLoading3 />}
		{/* Loader 4: Small dot with movement animation using box-shadow */}
		{loader === "4" && <StyleLoading4 />}
		{/* Loader 5: Animated text with a moving gradient, simulating loading */}
		{loader === "5" && <StyleLoading5>{text}</StyleLoading5>}
		{/* Loader 6: A set of four dots moving sequentially in a horizontal line */}
		{loader === "6" && (
			<StyleLoading6>
				<Dot />
				<Dot />
				<Dot />
				<Dot />
			</StyleLoading6>
		)}
	</StyledRow>
);

declare namespace SuspenseLoaderBase {
	interface Props extends Style.FlexType {
		loader?: "1" | "2" | "3" | "4" | "5" | "6";
		text?: string;
		position?: Property.Position;
	}
}

export const SuspenseLoader = memo(SuspenseLoaderBase);
export default SuspenseLoaderBase;
