/* eslint-disable no-shadow */

import React, { useEffect } from "react";
import styled from "styled-components";
import { InputBorders, theme } from "uikit";

import { useTypedDispatch } from "../../../../../../redux/store";
import getCompanies from "../../../../../../redux/services/Company/getCompanies";

import Root from "./components/Root";
import FirstRow from "./components/FirstRow";
import SecondRow from "./components/SecondRow";

export const StyledInput = styled(InputBorders.InputBorders)`
	height: 32px;
	width: 230px;
	border-radius: 5px;

	.inputGroup {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	.input-textfield {
		width: 100%;
		height: 100%;
		font-family: ${theme.font.n};
		font-weight: 400;
		line-height: 18px;
		font-size: 14px;
	}
`;

export default function HeaderOrder({
	toggleModalStatus,
	onOpenEditOrderModal,

	onCloseOrderClick,
	onCloneOrderClick,
	onSetOrderExecutorClick,
	onApproveOrderClick,
	onRecalculateOrderClick,
	onRecalculateOrderWithActualRatesClick,
	onRefuseOrderClick,
	onSendOrderToAirClick,
	onSendOrderToExecuting,
	onToggleOrderAirClick,
	onSetCallToClientBeingRequiredClick,
	onSetCallToClientSuccessClick,
	onSetCallToExecutorBeingRequiredClick,
	onSetCallToExecutorSuccessClick,
	onShowClientNumberToExecutorClick,
	onRevertOrderClick,
}) {
	const dispatch = useTypedDispatch();

	useEffect(() => {
		dispatch(getCompanies());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Root>
			<FirstRow />
			<SecondRow
				onRevertOrder={onRevertOrderClick}
				onOpenEditOrderModal={onOpenEditOrderModal}
				toggleModalStatus={toggleModalStatus}
				onCloseOrderClick={onCloseOrderClick}
				onCloneOrderClick={onCloneOrderClick}
				onSetOrderExecutorClick={onSetOrderExecutorClick}
				onApproveOrder={onApproveOrderClick}
				onRecalculateOrder={onRecalculateOrderClick}
				onRecalculateOrderWithActualRates={
					onRecalculateOrderWithActualRatesClick
				}
				onRefuseOrder={onRefuseOrderClick}
				onSendOrderToAir={onSendOrderToAirClick}
				onSendOrderToExecuting={onSendOrderToExecuting}
				onToggleOrderAir={onToggleOrderAirClick}
				onSetCallToClientBeingRequired={
					onSetCallToClientBeingRequiredClick
				}
				onSetCallToClientSuccess={onSetCallToClientSuccessClick}
				onSetCallToExecutorBeingRequired={
					onSetCallToExecutorBeingRequiredClick
				}
				onSetCallToExecutorSuccess={onSetCallToExecutorSuccessClick}
				onShowClientNumberToExecutor={onShowClientNumberToExecutorClick}
			/>
		</Root>
	);
}
