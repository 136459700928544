import React, {
	memo,
	useMemo,
	useRef,
	useEffect,
	useState,
	useCallback,
	Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { ChatMessage as ChatMessageServices } from "../../../../../services";
import { useTypedSelector } from "../../../../../redux/store";
import MomentFnc from "../../../../../utils/MomentFnc";
import { orange, blue } from "../../../../../constants/styles/colors";
import { StyledColumn, StyledRow, Colors } from "../../../../common";
import Loader from "../../../../Loader";
import { composeFullName, getUserData, getFullTitle } from "../../tools";
import { useChatActions } from "../../hooks";
import { MarkedUserChatVersion, ScrollButton, Budge } from "..";

import { MessageBlock, DatePop, NewMessagesBar } from "./components";

const List = styled(StyledColumn)`
	height: 100%;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
	background: ${Colors.BACKGROUND_BLUE_1};
`;

const MessageItem = styled.div<ChatList.MessageItemProps>`
	position: relative;
	margin-top: 12px;
	margin-bottom: 12px;
	display: flex;
	flex-direction: column;
	align-items: ${(props) =>
		props.isCurrentUser ? "flex-end" : "flex-start"};

	& > div:last-child {
		margin-bottom: 0px;
	}

	${(props) =>
		props.highlighted &&
		css`
			background-color: ${blue[1]};
			box-shadow: 0px 4px 8px ${blue[0]};
			transition: background-color 0.3s ease, box-shadow 0.3s ease;
		`};
`;

const TriggerDate = styled(StyledRow)`
	margin: 0px 12px 6px 54px;
	max-width: 80%;
	color: ${orange.primary};
`;

const TriggerTextNotFindParent = styled(StyledRow)`
	margin: 0px 12px 6px 54px;
	max-width: 80%;
	color: ${orange.primary};
`;

const GroupMarkedUser = styled(StyledRow)`
	margin: 6px 12px 0px 66px;
	max-width: 80%;
`;

const Item = styled(StyledColumn)``;

const ChatList: React.FC<ChatList.Props> = (props) => {
	const { t } = useTranslation();
	const { listServices, isLoadingList, userId } = props;

	const momentFnc = useMemo(() => new MomentFnc(), []);

	const chatListRef = useRef<HTMLDivElement | null>(null);

	const { isShowScrollButton, isSendingMessage, lastRead } = useTypedSelector(
		(state) => state.orders.chats.chat,
	);

	const [limitLastRead, setLimitLastRead] = useState<number>(
		lastRead?.oldId ?? 0,
	);
	const [normalWorkViewScroll, setNormalWorkViewScroll] =
		useState<boolean>(false); // * An important flag for scrolling to focus on the unread group worked only 1 time per chat launch.
	const [highlightedMessageId, setHighlightedMessageId] = useState<
		number | null
	>(null);
	const [hiddenMessages, setHiddenMessages] = useState<number[]>([]);

	const {
		onSaveLastReadNew,
		onSaveLastReadRemaining,
		onSaveLastReadWhenScrollDown,
		hideScrollButton,
		showScrollButton,
		onSendMessage,
	} = useChatActions();

	const getRelativeDate = useMemo(
		() =>
			(
				array: ChatMessageServices.OutputGrouped.ChatMessage[],
				index: number,
			): string | null => {
				const getDatePreviousMessage =
					index > 0
						? momentFnc.toDateNavigator({
								t,
								dateToFormat: array[index - 1]?.createdAt,
								formatVersion: "format1",
						  })
						: null;
				const getDateActiveMessage = momentFnc.toDateNavigator({
					t,
					dateToFormat: array[index]?.createdAt ?? null,
					formatVersion: "format1",
				});

				const retval =
					getDateActiveMessage !== getDatePreviousMessage
						? getDateActiveMessage
						: null;

				return retval;
			},
		[momentFnc, t],
	);

	const getIsStatusView = useMemo(
		() =>
			(
				array: ChatMessageServices.OutputGrouped.ChatMessage[],
				index: number,
			): { isUnreadFirst: boolean; isReadLast: boolean } => {
				const firstIndexWhenUnread = array.findIndex(
					(item) => item.id > lastRead.oldId,
				);

				const isUnreadFirst = firstIndexWhenUnread === index;
				const lastIndexRead = array
					.slice()
					.reverse()
					.findIndex((item) => item.id <= lastRead.oldId);
				const isReadLast =
					lastIndexRead !== -1 &&
					index === array.length - 1 - lastIndexRead;

				return { isUnreadFirst, isReadLast };
			},
		[lastRead.oldId],
	);

	const getTriggerDate = (date: string | null | undefined) => {
		const getClockFaceTime = momentFnc.toClockFaceTime(date) ?? "";
		const getDateNavigator = momentFnc.toDateNavigator({
			t,
			dateToFormat: date,
			formatVersion: "format2",
		});

		const retval = `${getClockFaceTime}${
			getDateNavigator === "" ? "" : ` (${getDateNavigator})`
		}`;

		return retval;
	};

	// const getIsCurrentUser = useCallback(
	// 	(_message: ChatMessageServices.OutputGrouped.ChatMessage): boolean => {
	// 		if (!_message || !_message?.dispatcher?.person?.id) return false;
	// 		const retval = _message?.dispatcher?.person?.id === userId;
	// 		return retval;
	// 	},
	// 	[userId],
	// );

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getIsCurrentUser = (
		_message: ChatMessageServices.OutputGrouped.ChatMessage,
	): boolean => {
		if (!_message || !_message?.dispatcher?.person?.id) return false;
		const retval = _message?.dispatcher?.person?.id === userId;
		return retval;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setLastReadOnServer = async (id: number) => {
		// console.group("[Chat]: List");

		// console.table({
		// 	id,
		// 	isSendingMessage,
		// 	limitLastRead,
		// 	new: lastRead.newId,
		// 	old: lastRead.oldId,
		// });

		// console.groupEnd();

		if (lastRead.oldId >= id || limitLastRead >= id) return;

		try {
			const res = await ChatMessageServices.setLastRead(id);

			if (res && res.error) {
				console.error("[Chat]: Error setLastRead id message", res);
			} else {
				setLimitLastRead(id);
				onSaveLastReadNew(id);
			}
		} catch (error) {
			console.error("[Chat]: Error setLastRead id message", error);
		}
	};

	const handleFindParent = ({
		parentId,
		messageId,
	}: ChatList.HandleFindParendMessage): (() => void) | undefined => {
		if (chatListRef.current) {
			const messageElement = chatListRef.current.querySelector(
				`[data-id="${parentId}"]`,
			) as HTMLElement | null;

			if (messageElement) {
				messageElement.scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
				messageElement.focus();
				setHighlightedMessageId(parentId);

				const timer = setTimeout(() => {
					setHighlightedMessageId(null);
				}, 5000);

				return () => clearTimeout(timer);
			}

			setHiddenMessages((prevState) => [...prevState, messageId]);
		}

		return undefined;
	};

	useEffect(() => {
		// * Dynamic recording of unread messages
		const unreadMessages =
			listServices?.filter((message) => message.id > lastRead.newId) ||
			[];

		onSaveLastReadRemaining(unreadMessages.length);
	}, [listServices, lastRead.newId, onSaveLastReadRemaining]);

	/* // ? Scroll Part */

	const onClickScrollButton = useCallback(() => {
		/* Scrolling the list by pressing the scroll button */
		if (chatListRef.current) {
			chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
		}
	}, []);

	const observer = useRef<IntersectionObserver | null>(null);

	useEffect(() => {
		// * Scroll watcher. Alert visible element when scrolling

		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const messageId: string | null =
							entry.target.getAttribute("data-id");

						const id = messageId ? parseInt(messageId, 10) : 0;

						if (id === lastRead.oldId) {
							setNormalWorkViewScroll(true);
						} else if (
							normalWorkViewScroll &&
							id >= lastRead.oldId
						) {
							setLastReadOnServer(
								messageId ? parseInt(messageId, 10) : 0,
							);
						}
					}
				});
			},
			{
				root: chatListRef.current,
				threshold: 0.1,
			},
		);

		const currentObserver = observer.current;

		if (chatListRef.current) {
			const messageElements =
				chatListRef.current.querySelectorAll("[data-id]");
			messageElements.forEach((element) => {
				currentObserver.observe(element);
			});
		}

		return () => {
			if (currentObserver) currentObserver.disconnect();
		};
	}, [setLastReadOnServer, lastRead.oldId, normalWorkViewScroll]);

	useEffect(() => {
		// * Logic scroll for unread list messages and scroll then current user send new message

		if (listServices.length > 0 && chatListRef.current) {
			const lastReadElement = chatListRef.current.querySelector(
				'[data-is-read="true"]',
			);

			if (isSendingMessage) {
				if (listServices.length > 0) {
					// * Block automatic scrolling if the new message was not from the current user
					const lastMessage = listServices.at(-1);
					if (lastMessage) {
						const isNowSenderMessageFromNotCurrentUser =
							!getIsCurrentUser(lastMessage);

						if (isNowSenderMessageFromNotCurrentUser) return;
					}
				}

				// * Automatic scrolling if the new message was from the current user
				chatListRef.current.scrollTop =
					chatListRef.current.scrollHeight;

				onSendMessage();
			} else if (lastReadElement && !normalWorkViewScroll) {
				// * Auto scrolled to the unread mark. Blocked after receiving the full list, for normal scrolling of the send button
				lastReadElement.scrollIntoView({
					behavior: "smooth",
					block: "end",
				});
			}
		}
	}, [
		listServices,
		isSendingMessage,
		getIsCurrentUser,
		normalWorkViewScroll,
		onSendMessage,
	]);

	const SCROLL_BOTTOM_OFFSET_PERCENTAGE = 0.1;

	useEffect(() => {
		const handleScroll = () => {
			const currentRef = chatListRef.current;
			if (currentRef) {
				const { scrollTop, clientHeight, scrollHeight } = currentRef;
				const bottomScrollPosition = scrollTop + clientHeight;

				const visibleHeight = Math.min(clientHeight, scrollHeight);

				const offset = visibleHeight * SCROLL_BOTTOM_OFFSET_PERCENTAGE;

				if (bottomScrollPosition >= scrollHeight - offset) {
					if (listServices.length > 0) {
						const lastMessage = listServices.at(-1);
						if (lastMessage) {
							onSaveLastReadWhenScrollDown(lastMessage.id);
						}
					}
				}
			}
		};

		const currentRef = chatListRef.current;
		if (currentRef) {
			currentRef.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (currentRef) {
				currentRef.removeEventListener("scroll", handleScroll);
			}
		};
	}, [listServices, onSaveLastReadWhenScrollDown]);

	const [chatlist, setChatlist] = useState<HTMLElement | null>(null);

	const onScroll = useCallback(() => {
		if (chatlist) {
			const div = chatlist;
			const scrollRemaining =
				div.scrollHeight - div.scrollTop - div.clientHeight;
			const isCloseToBottom = scrollRemaining <= div.clientHeight;

			(isCloseToBottom ? hideScrollButton : showScrollButton)();
		}
	}, [chatlist, hideScrollButton, showScrollButton]);

	useEffect(() => {
		const chatElement = document.querySelector(
			'[itemId="chat-chatlist"]',
		) as HTMLElement | null;
		setChatlist(chatElement);

		if (chatElement) {
			chatElement.addEventListener("scroll", onScroll);
		}

		return () => {
			if (chatElement) {
				chatElement.removeEventListener("scroll", onScroll);
			}
			hideScrollButton();
		};
	}, [onScroll, hideScrollButton]);

	return (
		<>
			<List
				ref={chatListRef}
				itemID="chat-chatlist"
				onContextMenu={(e) => e.preventDefault()}
			>
				<Loader active={isLoadingList} dimmed={false}>
					{listServices.map((message, index, array) => {
						const userData = getUserData(message);

						if (!userData) return null;

						const newDateGroup = getRelativeDate(array, index);

						const getFullName = userData.person
							? composeFullName(userData.person)
							: "";

						const fullTitle = getFullTitle(userData);

						const isHasAddressee = !!(
							message.chatMessageToAllowedDispatchers?.length ||
							message.chatMessageToAllowedExecutors?.length
						);

						const isCurrentUser = getIsCurrentUser(message);

						const isThisTheLastElement: boolean =
							index === array.length - 1;

						const isNotLastSenderCurrentUser = !(
							isThisTheLastElement && isCurrentUser
						);

						const { isUnreadFirst, isReadLast } = getIsStatusView(
							array,
							index,
						);

						const isNotFindParent = hiddenMessages.includes(
							message.id,
						);

						return (
							<Item key={message.id ?? uuidv4()}>
								{newDateGroup && (
									<DatePop text={newDateGroup ?? ""} />
								)}
								<MessageItem
									isCurrentUser={isCurrentUser}
									data-id={message.id}
									data-is-unread={
										isUnreadFirst ? "true" : "false"
									}
									data-is-read={isReadLast ? "true" : "false"}
									className={
										highlightedMessageId === message.id
											? "highlighted"
											: ""
									}
									highlighted={
										highlightedMessageId === message.id
									}
								>
									{message.expirationDate && (
										<TriggerDate>
											{`${
												t(
													"orderPageWidgets.chat.chatList.str200",
												) ?? ""
											} ${getTriggerDate(
												message.expirationDate,
											)}`}
										</TriggerDate>
									)}
									{isNotFindParent && (
										<TriggerTextNotFindParent>
											{t(
												"orderPageWidgets.chat.chatList.str150",
											) ?? ""}
										</TriggerTextNotFindParent>
									)}

									{isUnreadFirst &&
										isNotLastSenderCurrentUser && (
											<NewMessagesBar />
										)}
									{
										<MessageBlock
											data={{
												id: message.id,
												isCurrentUser,
												type: "default", // TODO mock, then attach it to the message type functionality
												title: fullTitle,
												avatar: getFullName,
												content: message.content,
												createdAt: message.createdAt,
												chatMessageToAllowedDispatchers:
													message.chatMessageToAllowedDispatchers,
												chatMessageToAllowedExecutors:
													message.chatMessageToAllowedExecutors,
												expirationDate:
													message.expirationDate ??
													undefined,
												parent: message.parent,
											}}
											onClickFindParent={(
												value: number,
											) =>
												handleFindParent({
													parentId: value,
													messageId: message.id,
												})
											}
										/>
									}
									{isHasAddressee && (
										<GroupMarkedUser>
											<MarkedUserChatVersion
												data={{
													chatMessageToAllowedDispatchers:
														message.chatMessageToAllowedDispatchers ||
														[],
													chatMessageToAllowedExecutors:
														message.chatMessageToAllowedExecutors ||
														[],
												}}
											/>
										</GroupMarkedUser>
									)}
									{isThisTheLastElement && <br />}
								</MessageItem>
							</Item>
						);
					})}
				</Loader>
			</List>

			{isShowScrollButton && (
				<Fragment>
					<ScrollButton onClick={onClickScrollButton} />
					<Budge x={12} y={148} />
				</Fragment>
			)}
		</>
	);
};

declare namespace ChatList {
	interface Props {
		listServices: ChatMessageServices.OutputGrouped.ChatMessage[];
		isLoadingList: boolean;
		userId?: number;
	}

	interface MessageItemProps {
		isCurrentUser: boolean;
		highlighted: boolean;
	}

	interface HandleFindParendMessage {
		parentId: number;
		messageId: number;
	}
}

export default memo(ChatList);
