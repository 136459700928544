// import { useEffect, useMemo } from "react";
// import Order from "../../../../../services/Order";
// import useArchiveOrders from "./useArchiveOrders";

// import useModelSubscribe from "../../../../../hooks/useModelSubscribe2";
// import useArchiveSwitcher from "./useArchiveSwitcher";

// interface UseOrdersSubscribe {
// 	metadataState: ReturnType<typeof useModelSubscribe>["metadataState"];
// }

// /**
//  *  `useOrdersSubscribe`
//  */
// export const useOrdersSubscribe = (
// 	subscribeOptions: Order.SubscribeOptions,
// ): UseOrdersSubscribe => {
// 	const { setOrdersLoading } = useArchiveSwitcher();
// 	const {
// 		setOrders,
// 		setOrder,
// 		orders: { orders, order },
// 	} = useArchiveOrders();

// 	const { models, metadataState, loading } = useModelSubscribe(
// 		subscribeOptions,
// 		Order,
// 	);

// 	console.group("Console_Group_DP");
// 	console.log(`models`, models);
// 	console.log(`metadataState`, metadataState);
// 	console.log(`loading`, loading);
// 	console.log(`orders`, orders);
// 	console.log(`order`, order);
// 	//  console.table(activeTab);
// 	console.groupEnd();

// 	const data = useMemo<{
// 		items: Order.Model[];
// 		orderMap: Map<number, Order.Model>;
// 	}>(() => {
// 		if (!models.length) {
// 			return { items: orders, orderMap: new Map() };
// 		}
// 		const orderMap: Map<number, Order.Model> = new Map();
// 		const oldNewOrders = [...orders, ...models];
// 		const items: Order.Model[] = [];
// 		oldNewOrders.forEach((item) => orderMap.set(item.id, item));
// 		orderMap.forEach((item) => {
// 			items.push(item);
// 		});
// 		return { items, orderMap };
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [models]);

// 	useEffect(() => {
// 		if (!order) return;
// 		const exist = data.orderMap.get(order.id);
// 		if (exist) setOrder(exist);
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [data.orderMap]);

// 	useEffect(() => {
// 		setOrdersLoading(loading);
// 	}, [loading, setOrdersLoading]);

// 	useEffect(() => {
// 		if (!orders.length) setOrders(models);
// 	}, [setOrders, orders, models]);

// 	useEffect(() => {
// 		if (data.items?.length) setOrders(data.items);
// 	}, [data, setOrders]);

// 	return useMemo<UseOrdersSubscribe>(
// 		() => ({ metadataState }),
// 		[metadataState],
// 	);
// };

// export default useOrdersSubscribe;

// import { useEffect, useMemo, useRef } from "react";

// import Order from "../../../../../services/Order";
// import useModelSubscribe from "../../../../../hooks/useModelSubscribe2";

// import useArchiveOrders from "./useArchiveOrders";
// import useArchiveSwitcher from "./useArchiveSwitcher";

// interface UseOrdersSubscribe {
// 	metadataState: ReturnType<typeof useModelSubscribe>["metadataState"];
// }

// /**
//  *  `useOrdersSubscribe`
//  */
// export const useOrdersSubscribe = (
// 	subscribeOptions: Order.SubscribeOptions,
// ): UseOrdersSubscribe => {
// 	const { setOrdersLoading } = useArchiveSwitcher();
// 	const {
// 		setOrders,
// 		setOrder,
// 		orders: { orders, order },
// 	} = useArchiveOrders();

// 	const { models, metadataState, loading } = useModelSubscribe(
// 		subscribeOptions,
// 		Order,
// 	);

// 	const prevLoadingRef = useRef<boolean | null>(null);
// 	const prevModelsRef = useRef<Order.Model[]>([]);

// 	console.group("Console_Group_DP");
// 	console.log(`models`, models);
// 	console.log(`metadataState`, metadataState);
// 	console.log(`loading`, loading);
// 	console.log(`orders`, orders);
// 	console.log(`order`, order);
// 	console.groupEnd();

// 	useEffect(() => {
// 		setOrdersLoading(loading);

// 		const modelsChanged =
// 			models.length !== prevModelsRef.current.length ||
// 			models.some(
// 				(model, index) => model.id !== prevModelsRef.current[index]?.id,
// 			);

// 		if (loading !== prevLoadingRef.current || modelsChanged) {
// 			setOrders(models);
// 			prevLoadingRef.current = loading;
// 			prevModelsRef.current = models;
// 		}
// 	}, [loading, models, setOrders, setOrdersLoading]);

// 	useEffect(() => {
// 		if (!order) return;
// 		const exist = [...models, ...orders].find(
// 			(item) => item.id === order.id,
// 		);
// 		if (exist) setOrder(exist);
// 	}, [models, orders, order, setOrder]);

// 	return useMemo<UseOrdersSubscribe>(
// 		() => ({ metadataState }),
// 		[metadataState],
// 	);
// };

// export default useOrdersSubscribe;

import { useEffect, useMemo, useRef } from "react";

import Order from "../../../../../services/Order";
import useModelSubscribe from "../../../../../hooks/useModelSubscribe2";

import useArchiveOrders from "./useArchiveOrders";
import useArchiveSwitcher from "./useArchiveSwitcher";

interface UseOrdersSubscribe {
	metadataState: ReturnType<typeof useModelSubscribe>["metadataState"];
}

/**
 *  `useOrdersSubscribe`
 */
export const useOrdersSubscribe = (
	subscribeOptions: Order.SubscribeOptions,
): UseOrdersSubscribe => {
	const { setOrdersLoading } = useArchiveSwitcher();
	const {
		setOrders,
		setOrder,
		orders: { orders, order },
	} = useArchiveOrders();

	const { models, metadataState, loading } = useModelSubscribe(
		subscribeOptions,
		Order,
	);

	const prevLoadingRef = useRef<boolean | null>(null);
	const prevModelsRef = useRef<Order.Model[]>([]);
	const hasReceivedDataRef = useRef<boolean>(false);

	useEffect(() => {
		setOrdersLoading(loading);

		// Проверяем, были ли уже получены данные
		if (!hasReceivedDataRef.current && models.length > 0) {
			hasReceivedDataRef.current = true;
		}

		// Проверяем, изменились ли данные
		const modelsChanged =
			models.length !== prevModelsRef.current.length ||
			models.some((model, index) => {
				const prevModel = prevModelsRef.current[index];
				return (
					!prevModel ||
					model.id !== prevModel.id ||
					JSON.stringify(model) !== JSON.stringify(prevModel)
				);
			});

		if (loading !== prevLoadingRef.current || modelsChanged) {
			setOrders(models);
			prevLoadingRef.current = loading;
			prevModelsRef.current = models;
		}
	}, [loading, models, setOrders, setOrdersLoading]);

	useEffect(() => {
		if (!order) return;
		const exist = [...models, ...orders].find(
			(item) => item.id === order.id,
		);
		if (exist) setOrder(exist);
	}, [models, orders, order, setOrder]);

	return useMemo<UseOrdersSubscribe>(
		() => ({ metadataState }),
		[metadataState],
	);
};

export default useOrdersSubscribe;
