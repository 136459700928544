import React, { useMemo } from "react";
// import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = () => formatNumber(0);

const PenaltyAmountCellContent: React.FC<
	PenaltyAmountCellContent.Props
> = () => {
	const content = useMemo(() => cellProcessing(), []);
	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace PenaltyAmountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default PenaltyAmountCellContent;
