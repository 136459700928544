import React, { Dispatch, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";
import moment from "moment";

import { Role } from "../../../../../../../../../../services";
import { getTabsModeOne } from "../../../../../../../../../../utils";
import { OPERATIONAL_ROLES } from "../../../../../../../../../../constants/business";
import { StyledColumn } from "../../../../../../../../../../components/common";

import Root from "./components/Root";
import Title from "./components/Title";
import InformationContainer from "./components/InformationContainer";
import SubTitle from "./components/SubTitle";
import LockedBy from "./components/LockedBy";

const Header = memo<Header.Props>(
	({ tab, assignableFor, title, lockedBy, createdAt, onChangeTab }) => {
		const { t } = useTranslation();

		const tabOptions = useMemo(() => {
			const tabsForUser: Parameters<typeof getTabsModeOne>[1] = ["main"];
			const tabsForExecutor: Parameters<typeof getTabsModeOne>[1] = [
				"main",
			];
			const tabsBase: Parameters<typeof getTabsModeOne>[1] = ["main"];

			if (assignableFor === OPERATIONAL_ROLES.DISPATCHER)
				return getTabsModeOne(t, tabsForUser);

			if (assignableFor === OPERATIONAL_ROLES.EXECUTOR)
				return getTabsModeOne(t, tabsForExecutor);

			return getTabsModeOne(t, tabsBase);
		}, [assignableFor, t]);

		return (
			<Root sizes="auto!*">
				<InformationContainer
					align="center"
					justify="space-between"
					gaps="10px"
				>
					<Title>{title}</Title>
					<StyledColumn alignItems="end" gap="15px">
						{createdAt ? (
							<SubTitle>
								{`${
									t(
										"pages.mainPage.pages.accounts.tabs.roles.editModal.header.str201",
									) ?? ""
								}: ${moment(createdAt).format("DD.MM.YYYY")}`}
							</SubTitle>
						) : (
							<></>
						)}
					</StyledColumn>
				</InformationContainer>
				<Tabs
					value={tab}
					options={tabOptions}
					variant="panels"
					onChange={(newTab) =>
						onChangeTab(newTab.toString() as Header.Tab)
					}
				/>
			</Root>
		);
	},
);

declare namespace Header {
	type Tab = "main";

	interface Props {
		tab: Tab;
		assignableFor?: Role.AssignableTo;
		title: string;
		lockedBy?: string;
		createdAt?: string;

		onChangeTab: Dispatch<Tab>;
	}
}

export default Header;
