import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ExecutorRate } from "../../services";
import { useTypedSelector } from "../../redux/store";
import { sortByNumberOrString } from "../../utils";

const useExecutorRateSubHandler = (): useExecutorRateSubHandler.Props => {
	const { t } = useTranslation();

	enum ColumnIdAdditional {
		Name = "name",
		Companies = "companies",
		TaxiServices = "taxiServices",
	}

	const executorRateSubscribe = useTypedSelector(
		(state) => state.globalState.executorRate.data.subscription.basic,
	);
	const language = useTypedSelector((state) => state.session.language);
	const taxiServices = useTypedSelector((state) => state.taxiServices.models);

	const getNameTaxiServiceMax = useCallback(
		(items: ExecutorRate.Model, isCompany: boolean) => {
			const DEFAULT_TEXT = "Zero";

			if (
				!(
					Array.isArray(items.taxiServices) &&
					items.taxiServices?.length
				)
			)
				return DEFAULT_TEXT;

			if (taxiServices.length === items.taxiServices.length)
				return isCompany
					? t(["all_company", "All companies"])
					: t(["all_taxi_service", "All branches"]);

			const arr =
				items.taxiServices
					?.map((service) =>
						isCompany
							? service?.company?.name?.[language] ?? ""
							: service?.settlement?.[language] ?? "",
					)
					.sort((a, b) => a.localeCompare(b)) || [];

			const lastElement = arr.at(-1) ?? DEFAULT_TEXT;
			return lastElement;
		},
		[language, t, taxiServices.length],
	);

	const getAllExecutorRateSubHandler = useCallback(
		({
			limit,
			taxiServiceIds,
			order,
		}: useExecutorRateSubHandler.SmallSubscribeOptions): ExecutorRate.Model[] => {
			if (!Array.isArray(executorRateSubscribe)) {
				return [];
			}

			let retval = [...(executorRateSubscribe || [])];

			const [isTaxiServiceIds, isOrder, isLimit] = [
				Array.isArray(taxiServiceIds) && taxiServiceIds?.length,
				typeof order === "object" &&
					order !== null &&
					!Array.isArray(order),
				typeof limit === "number",
			];

			if (isTaxiServiceIds) {
				retval = retval.filter(
					(item) =>
						Array.isArray(item.taxiServiceIds) &&
						Array.isArray(taxiServiceIds) &&
						item.taxiServiceIds.some((id) =>
							taxiServiceIds.includes(id),
						),
				);
			}

			if (isOrder) {
				const [[dataKey, sortType] = []] = Object.entries(order || {});
				const isSort =
					typeof sortType === "string" &&
					typeof dataKey === "string" &&
					["asc", "desc"].includes(sortType.toLowerCase());

				if (isSort) {
					const isAscending = sortType.toLowerCase() === "asc";
					const sortOrder = isAscending ? 1 : -1;

					if (dataKey === ColumnIdAdditional.Name) {
						retval = retval.sort((prev, next) => {
							const prevValue = prev.name;
							const nextValue = next.name;

							return (
								sortByNumberOrString(
									prevValue,
									nextValue,
									true,
								) * sortOrder
							);
						});
					}

					if (
						dataKey === ColumnIdAdditional.Companies ||
						dataKey === ColumnIdAdditional.TaxiServices
					) {
						const isCompany =
							dataKey === ColumnIdAdditional.Companies;
						const sort = retval.sort((prev, next) => {
							const prevValue = getNameTaxiServiceMax(
								prev,
								isCompany,
							);
							const nextValue = getNameTaxiServiceMax(
								next,
								isCompany,
							);
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}
				}
			}

			if (isLimit) {
				retval = retval.slice(0, limit);
			}

			return retval;
		},
		[
			ColumnIdAdditional.Companies,
			ColumnIdAdditional.Name,
			ColumnIdAdditional.TaxiServices,
			executorRateSubscribe,
			getNameTaxiServiceMax,
		],
	);

	return useMemo(
		() => ({
			getAllExecutorRateSubHandler,
		}),
		[getAllExecutorRateSubHandler],
	);
};

declare namespace useExecutorRateSubHandler {
	interface Props {
		getAllExecutorRateSubHandler: (
			data: SmallSubscribeOptions,
		) => ExecutorRate.Model[];
	}

	interface SmallSubscribeOptions {
		taxiServiceIds?: number[];
		limit?: number;
		order?: ExecutorRate.Order;
	}
}

export default useExecutorRateSubHandler;
