import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { SideTab } from "../../../../components/common";

import { Programs, Executor } from "./tabs";
import { TabKeys, TabAccessNames } from "./constants/access";

const Parameters: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.APPLICATION,
					label: t("settings.parameters.tabs.programs.title"),
					value: {
						render() {
							return <Programs />;
						},
					},
					accessName: TabAccessNames[TabKeys.APPLICATION],
				},
				{
					key: TabKeys.EXECUTOR,
					label: t("settings.parameters.tabs.executor.title"),
					value: {
						render() {
							return <Executor />;
						},
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Parameters;
