import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { formatNumber } from "../../../../../../../../../../utils";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import { StyledP } from "../../../../../../../../../../components/common";

const BalanceCellContent: React.FC<BalanceCellContent.Props> = ({ item }) => {
	const content =
		item.paymentAccounts?.find((a) => a.type === "main")?.amount ?? 0;

	const colorMainBalance = useMemo(() => {
		if (
			!item?.rate ||
			!item?.rate?.active ||
			!item?.rate?.amountOfMoneyToBlock?.active
		) {
			return "";
		}
		const { amountOfMoneyToBlock } = item.rate;

		if (amountOfMoneyToBlock.value >= content) return "red";

		return "";
	}, [item.rate, content]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<StyledP colors={colorMainBalance}>{formatNumber(content)}</StyledP>
		</CellContentRoot>
	);
};

declare namespace BalanceCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default BalanceCellContent;
