import { CSSProperties } from "react";
import styled, { css, createGlobalStyle } from "styled-components";

/**
 ** `color-schema` - {@link https://developer.mozilla.org/en-US/docs/Web/CSS/color-scheme|link}, base `normal`
 */
export const GlobalStyle = createGlobalStyle`

	:root {
		color-scheme: light dark;
	};
`;

export const baseStyles = css<Style.BaseType>`
	${({ dis }) => dis && `display:${dis};`}
	${({ float }) => float && `float:${float};`} 
  ${({ p = "0px" }) => {
		if (typeof p === "string") return `padding: ${p};`;
		if (typeof p === "object") {
			return `
			${p?.bottom ? `padding-bottom: ${p.bottom};` : ""}
			${p?.left ? `padding-left: ${p.left};` : ""}
			${p?.right ? `padding-right: ${p.right};` : ""}
			${p?.top ? `padding-top: ${p.top};` : ""}
		`;
		}
		return undefined;
	}}
	${({ pInline }) => pInline && `padding-inline: ${pInline};`}
	margin: ${({ m = "0px" }) => m};
	gap: ${({ gap = "0px" }) => gap};

	${({ w }) => {
		if (!w) return w;
		if (typeof w === "string") return `width: ${w};`;
		return `
			${w.max ? `max-width: ${w.max};` : ""}
			${w.min ? `min-width: ${w.min};` : ""}
			${w.width ? `width: ${w.width};` : ""}
		`;
	}}
	${({ h }) => {
		if (!h) return h;
		if (typeof h === "string") return `height: ${h};`;
		return `
			${h.max ? `max-height: ${h.max};` : ""}
			${h.min ? `min-height: ${h.min};` : ""}
			${h.height ? `height: ${h.height};` : ""}
		`;
	}}
	${({ minW }) => minW && `min-width: ${minW};`}
	${({ maxW }) => maxW && `max-width: ${maxW};`}
	${({ minH }) => minH && `min-height: ${minH};`}
	${({ maxH }) => maxH && `max-height: ${maxH};`}

	${({ bgC }) => {
		if (typeof bgC === "string") return `background-color: ${bgC};`;
		if (typeof bgC === "object") {
			return `
			background-color: ${bgC?.light};
			@media (prefers-color-scheme: dark) {
				& {
					background-color: ${bgC?.dark};
				}
			};
			`;
		}
		return undefined;
	}}
	${({ bg }) => bg && `background:${bg};`}

	${({ over }) => over && `overflow:${over};`}
	${({ overX }) => overX && `overflow-x:${overX};`}
	${({ overY }) => overY && `overflow-y:${overY};`}

	${({ b }) => {
		if (!b) return b;
		if (typeof b === "string") return `border: ${b};`;
		if (typeof b === "object") {
			return `
			${b?.bottom ? `border-bottom: ${b.bottom};` : ""}
			${b?.left ? `border-left: ${b.left};` : ""}
			${b?.right ? `border-right: ${b.right};` : ""}
			${b?.top ? `border-top: ${b.top};` : ""}
		`;
		}
		return undefined;
	}}
	${({ br }) => br && `border-radius:${br};`}
	${({ bc }) => {
		if (typeof bc === "string") return `border-color: ${bc};`;
		if (typeof bc === "object") {
			return `
			${bc?.color ? `border-color: ${bc.color};` : ""}
			${bc?.bottom ? `border-bottom-color: ${bc.bottom};` : ""}
			${bc?.left ? `border-left-color: ${bc.left};` : ""}
			${bc?.right ? `border-right-color: ${bc.right};` : ""}
			${bc?.top ? `border-top-color: ${bc.top};` : ""}
			@media (prefers-color-scheme: dark) {
				& {
				${bc?.dark?.color ? `border-color: ${bc.dark.color};` : ""}
				${bc?.dark?.bottom ? `border-bottom-color: ${bc.dark.bottom};` : ""}
				${bc?.dark?.left ? `border-left-color: ${bc.dark.left};` : ""}
				${bc?.dark?.right ? `border-right-color: ${bc.dark.right};` : ""}
				${bc?.dark?.top ? `border-top-color: ${bc.dark.top};` : ""}
				}
			};
			`;
		}
		return undefined;
	}}
	${({ colors }) => {
		if (typeof colors === "string") return `color:${colors};`;
		if (typeof colors === "object") {
			return `
			color: ${colors?.light};

			@media (prefers-color-scheme: dark) {
				& {
				  color: ${colors?.dark};
				}
			};
			`;
		}
		return undefined;
	}}

	${({ baseBorder }) =>
		baseBorder &&
		`border: 1px solid;
		 border-radius: 5px;
		 border-color: #dee0e2;
		`}

	${({ css }) => css && css}
`;

export const visible = css<Pick<Style.BaseType, "visible">>`
	${({ visible = true }) =>
		!visible &&
		`display: none;
		visibility: hidden;
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: circle(0 at 50% 50%);
		color: transparent;
		font-size: 0;
		opacity: 0;
		width:0;
		height:0;
`}
`;

export const scrollbar = css<Pick<Style.BaseType, "scrollbar">>`
	${({ scrollbar = true }) =>
		scrollbar &&
		css`
			/* Only Chrome */
			&::-webkit-scrollbar {
				width: 7px;
				height: 7px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: #c3c5c7;
				background-clip: padding-box;
			}
			&::-webkit-scrollbar-track {
				background-color: #f3f6f9;
			}

			/* Only IE */
			@media screen and (-ms-high-contrast: active),
				(-ms-high-contrast: none) {
				scrollbar-face-color: #c3c5c7;
				scrollbar-shadow-color: #f3f6f9;
				scrollbar-highlight-color: #f3f6f9;
			}
			/* Only FireFox */
			@-moz-document url-prefix() {
				scrollbar-width: thin;
				scrollbar-color: #c3c5c7 #f3f6f9;
			}
		`}
`;

export const distributeStyles = css<Style.DistributeType>`
	${({ justify }) => justify && `justify-content:${justify};`}
	${({ alignItems }) => alignItems && `align-items:${alignItems};`}
	${({ alignContent }) => alignContent && `align-content:${alignContent};`}
	${({ textAlign }) => textAlign && `text-align:${textAlign};`}

	${({ cursor }) => cursor && `cursor:${cursor};`}
	${({ shadow }) => shadow && `box-shadow:${shadow};`}

	${({ position }) => position && `position:${position};`}
	${({ zi }) => zi && `z-index:${zi};`}
	${({ top }) => top && `top:${top};`}
	${({ bottom }) => bottom && `bottom:${bottom};`}
	${({ left }) => left && `left:${left};`}
	${({ right }) => right && `right:${right};`}

	${({ transform }) => transform && `transform:${transform};`}
	${({ transition }) => transition && `transition:${transition};`}
	${({ animation }) => animation && `animation:${animation};`}
	${({ resize }) => resize && `resize:${resize};`}
	${({ cp }) => cp && `clip-path:${cp};`}
`;

export const typographyStyles = css<Style.TypographyType>`
	${({ text }) => text?.ta && `text-align:${text.ta};`}
	${({ text }) => text?.tt && `text-transform:${text.tt};`}
	${({ text }) => text?.ts && `text-shadow:${text.ts};`}
	${({ text }) => text?.td && `text-decoration:${text.td};`}
	${({ text }) => text?.ti && `text-indent:${text.ti};`}
	${({ text }) => text?.over && `text-overflow:${text.over};`}
	${({ text }) => text?.ws && `white-space:${text.ws};`}
	${({ text }) => text?.hyphens && `hyphens:${text.hyphens};`}
	${({ text }) => text?.us && `user-select:${text.us};`}
	${({ text }) => text?.ww && `word-wrap:${text.ww};`}
	${({ text }) => text?.wb && `word-break:${text.wb};`}
	${({ text }) => text?.ls && `letter-spacing:${text.ls};`}

	${({ font }) => font?.ff && `font-family:${font.ff};`}
	${({ font }) => font?.fs && `font-style:${font.fs};`}
	${({ font }) => font?.fw && `font-weight:${font.fw};`}
	${({ font }) => font?.size && `font-size:${font.size};`}
	${({ font }) => font?.line && `line-height:${font.line};`}
`;

/** Add inside component `display: flex;` */
export const flexStyles = css<Style.FlexType>`
	${({ flex }) => flex?.flex && `flex:${flex.flex};`}
	${({ flex }) => flex?.flow && `flex-flow:${flex.flow};`}

	${({ flex }) => flex?.basis && `flex-basis:${flex.basis};`}
	${({ flex }) => flex?.grow && `flex-grow:${flex.grow};`}
	${({ flex }) => flex?.shrink && `flex-shrink:${flex.shrink};`}

	${({ flex }) => flex?.direction && `flex-direction:${flex.direction};`}
	${({ flex }) => flex?.wrap && `flex-wrap:${flex.wrap};`}
	${({ flex }) => flex?.order && `order:${flex.order};`}
`;

/** Add inside component `display: grid;` */
export const gridStyles = css<Partial<Style.GridType>>`
	${({ area }) => area && `grid-area:${area};`}
	${({ areas }) => areas && `grid-template-areas:${areas};`}

	${({ columns }) => {
		if (!columns) return "";
		if (typeof columns === "string") {
			return `grid-template-columns: ${columns};`;
		}
		return `
		${columns.auto ? `grid-auto-columns: ${columns.auto};` : ""}
		${columns.columns ? `grid-template-columns: ${columns.columns};` : ""}
		${columns.column ? `grid-column: ${columns.column};` : ""}
		${columns.end ? `grid-column-end: ${columns.end};` : ""}
		${columns.start ? `grid-column-start: ${columns.start};` : ""}

	`;
	}};

	${({ rows }) => {
		if (!rows) return "";
		if (typeof rows === "string") {
			return `grid-template-rows: ${rows};`;
		}
		return `
		${rows.auto ? `grid-auto-rows: ${rows.auto};` : ""}
		${rows.rows ? `grid-template-rows: ${rows.rows};` : ""}
		${rows.row ? `grid-row: ${rows.row};` : ""}
		${rows.end ? `grid-row-end: ${rows.end};` : ""}
		${rows.start ? `grid-row-start: ${rows.start};` : ""}

	`;
	}};

	${({ column }) => column && `grid-auto-columns:${column};`}
	${({ row }) => row && `grid-auto-rows:${row};`}

	${({ justifySelf }) => justifySelf && `justify-self:${justifySelf};`}
	${({ alignSelf }) => alignSelf && `align-self:${alignSelf};`}
`;

export const StyledRow = styled.div<Style.Row>`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	${baseStyles}
	${flexStyles}
	${distributeStyles}
	${visible}
    ${scrollbar}
`;

export const StyledColumn = styled.div<Style.Column>`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	${baseStyles}
	${flexStyles}
	${distributeStyles}
	${visible}
    ${scrollbar}
`;

export const StyledWrapIcon = styled(StyledRow)<{
	offset?: { x: string; y: string };
}>`
	justify-content: center;
	align-items: center;
	text-align: center;

	& svg {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		${distributeStyles}
		${flexStyles}
		${({ colors }) => {
			if (typeof colors === "string") return `fill:${colors};`;
			if (typeof colors === "object") {
				return `
				fill: ${colors?.light};

				@media (prefers-color-scheme: dark) {
					& {
						fill: ${colors?.dark};
					}
				};
				`;
			}
			return undefined;
		}};

		path {
			${({ colors }) => {
				if (typeof colors === "string") return `fill:${colors};`;
				if (typeof colors === "object") {
					return `
					fill: ${colors?.light};

					@media (prefers-color-scheme: dark) {
						& {
							fill: ${colors?.dark};
						}
					};
					`;
				}
				return undefined;
			}};
		}

		use {
			${({ offset }) => offset?.x && `x:${offset.x};`}
			${({ offset }) => offset?.y && `y:${offset.y};`}
		}
	}

	svg:focus,
	use:focus,
	path:focus {
		outline: none;
		box-shadow: none;
	}
	${visible}
`;

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/counters|counters}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/counter|counter}
 */
export const StyledList = styled.ul<
	Style.FlexType & Style.DistributeType & Style.BaseType
>`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	${distributeStyles}
	${baseStyles}
	${flexStyles}

	${visible}
	${scrollbar}
`;

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/counters|counters}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/counter|counter}
 */
export const StyledListItem = styled.li<
	Style.FlexType & Style.DistributeType & Style.BaseType
>`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	${distributeStyles}
	${baseStyles}
	${flexStyles}
	${visible}
	${scrollbar}
`;
/**
 * `grid`
 *  @see {@link https://cssgrid-generator.netlify.app/|cssgrid}
 *  @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/grid|grid}
 *  */
export const StyledGrid = styled.div<Style.Grid>`
	display: grid;
	grid-template-areas: ${({ areas = "main" }) => areas};

	${({ columns = "1fr" }) => {
		if (typeof columns === "string") {
			return `grid-template-columns: ${columns};`;
		}
		return `
		${columns.auto ? `grid-auto-columns: ${columns.auto};` : ""}
		${columns.columns ? `grid-template-columns: ${columns.columns};` : ""}
		${columns.column ? `grid-column: ${columns.column};` : ""}
		${columns.end ? `grid-column-end: ${columns.end};` : ""}
		${columns.start ? `grid-column-start: ${columns.start};` : ""}

	`;
	}};

	${({ rows = "1fr" }) => {
		if (typeof rows === "string") {
			return `grid-template-rows: ${rows};`;
		}
		return `
		${rows.auto ? `grid-auto-rows: ${rows.auto};` : ""}
		${rows.rows ? `grid-template-rows: ${rows.rows};` : ""}
		${rows.row ? `grid-row: ${rows.row};` : ""}
		${rows.end ? `grid-row-end: ${rows.end};` : ""}
		${rows.start ? `grid-row-start: ${rows.start};` : ""}

	`;
	}};

	${({ column }) => column && `grid-auto-columns:${column};`}
	${({ row }) => row && `grid-auto-rows:${row};`}


	${distributeStyles}
	${baseStyles}

	${visible}
	${scrollbar}
`;

/**
 * `grid`
 *  @see {@link https://cssgrid-generator.netlify.app/|cssgrid}
 *  */
export const StyledGridItem = styled.div<Style.GridItem>`
	display: grid;

	${gridStyles}

	grid-area: ${({ area = "main" }) => area};
	justify-self: ${({ justifySelf = "stretch" }) => justifySelf};
	align-self: ${({ alignSelf = "stretch" }) => alignSelf};

	${distributeStyles}
	${baseStyles}

	${visible}
	${scrollbar}
`;

export const StyledP = styled.p<Style.TextType>`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-align: start;
	white-space: nowrap;

	text-transform: none;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #21333f;

	box-shadow: none;
	${baseStyles}
	${distributeStyles}
	${flexStyles}
	${typographyStyles}

	${visible}
	${scrollbar}
`;

export const StyledSpan = styled.span<Style.TextType>`
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;
	text-align: start;
	white-space: nowrap;

	text-transform: none;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #21333f;

	box-shadow: none;
	${baseStyles}
	${distributeStyles}
	${flexStyles}
	${typographyStyles}

	${visible}
	${scrollbar}
`;

export declare namespace Style {
	/**
	 * Add base functions
	 * ```ts
	 * import Style, { baseStyles } from "./common/styles";
	 *
	 * const StyledHeader = styled.header<Style.FlexType>`
	 * 	${baseStyles}
	 * `
	 * ```
	 */
	export interface BaseType {
		/**
		 * ```scss
		 * {
		 *  display: flex;
		 *  justify-content: flex-start;
		 *  flex-direction: row;
		 *  :hover {}
		 * }
		 * ```
		 */
		css?: string;
		/** Show a custom scroll bar */
		scrollbar?: boolean;
		/** Adding a base border with default settings */
		baseBorder?: boolean;
		/** hidden element */
		visible?: boolean;
		/** display */
		dis?: CSSProperties["display"];
		/**
		 * padding - `top right bottom left`
		 *
		 * ```ts
		 * p="0 0 0 10px"
		 * p={{
		 *  right: "0",
		 *  left: "0",
		 *  bottom: "0",
		 *  top: "10px",
		 * }}
		 * ```
		 * */
		p?:
			| CSSProperties["padding"]
			| {
					right?: CSSProperties["paddingTop"];
					left?: CSSProperties["paddingLeft"];
					bottom?: CSSProperties["paddingBottom"];
					top?: CSSProperties["paddingRight"];
			  };
		pInline?: string;
		/**
		 *  margin - `top right bottom left`
		 * ```ts
		 * m="0 0 0 10px"
		 * ```
		 * */
		m?: string;
		/** `gap` */
		gap?: string;
		/**
		 * `width`
		 * ```ts
		 * w={"var(--baseWidth)"}
		 * w={"calc(10px + 100px)"}
		 * w={"clamp(10px, 4em, 80px)"}
		 * w={"max(20vw, 400px)"}
		 * w={"min(50vw, 200px)"}
		 * w="max-content"
		 * ```
		 * */
		w?:
			| string
			| {
					/** min-width */
					min?: string;
					/** max-width */
					max?: string;
					/** width */
					width?: string;
			  };
		/**
		 * `height`
		 * ```ts
		 * h={"var(--baseHeight)"}
		 * h={"calc(10px + 100px)"}
		 * h={"clamp(10px, 4em, 80px)"}
		 * h={"max(20vh, 400px)"}
		 * h={"min(50vh, 200px)"}
		 * h="max-content"
		 * ```
		 * */
		h?:
			| string
			| {
					/** min-height */
					min?: string;
					/** max-height */
					max?: string;
					/** height */
					height?: string;
			  };
		/** min-width */
		minW?: string;
		/** min-height */
		minH?: string;
		/** max-width */
		maxW?: string;
		/** max-height */
		maxH?: string;

		/** color */
		colors?:
			| CSSProperties["color"]
			| {
					light?: CSSProperties["color"];
					dark?: CSSProperties["color"];
			  };

		/** background */
		bg?: CSSProperties["background"];
		/** background-color */
		bgC?:
			| CSSProperties["backgroundColor"]
			| {
					light: CSSProperties["backgroundColor"];
					dark: CSSProperties["backgroundColor"];
			  };

		/** overflow */
		over?: CSSProperties["overflow"];
		/** overflow-y */
		overY?: CSSProperties["overflowY"];
		/** overflow-x */
		overX?: CSSProperties["overflowX"];

		/** border */
		b?:
			| CSSProperties["border"]
			| {
					right?: CSSProperties["borderTop"];
					left?: CSSProperties["borderLeft"];
					bottom?: CSSProperties["borderBottom"];
					top?: CSSProperties["borderRight"];
			  };
		/**
		 * `border-radius`
		 * @see {@link https://9elements.github.io/fancy-border-radius/|border-radius}
		 * */
		br?: CSSProperties["borderRadius"];
		/** border-color */
		bc?:
			| CSSProperties["borderColor"]
			| {
					color?: CSSProperties["borderColor"];
					right?: CSSProperties["borderTopColor"];
					left?: CSSProperties["borderLeftColor"];
					bottom?: CSSProperties["borderBottomColor"];
					top?: CSSProperties["borderRightColor"];
					dark?: {
						color?: CSSProperties["borderColor"];
						right?: CSSProperties["borderTopColor"];
						left?: CSSProperties["borderLeftColor"];
						bottom?: CSSProperties["borderBottomColor"];
						top?: CSSProperties["borderRightColor"];
					};
			  };

		float?: CSSProperties["float"];
	}

	/**
	 * Add base functions
	 * ```ts
	 * import Style, { distributeStyles } from "./common/styles";
	 *
	 * const StyledHeader = styled.header<Style.FlexType>`
	 * 	${distributeStyles}
	 * `
	 * ```
	 */
	export interface DistributeType {
		/**
		 * position
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/position}
		 */
		position?: CSSProperties["position"];
		top?: string;
		bottom?: string;
		left?: string;
		right?: string;

		justify?: CSSProperties["justifyContent"];
		alignItems?: CSSProperties["alignItems"];
		alignContent?: CSSProperties["alignContent"];
		textAlign?: CSSProperties["textAlign"];

		/** z-index */
		zi?: CSSProperties["zIndex"];
		/**
		 * `transition`
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/transition}
		 * ```ts
		 * transition={`all 1s ease-out`}
		 * ```
		 */
		transition?: CSSProperties["transition"];
		/**
		 * `transform`
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/transform}
		 * ```ts
		 * transform={`
		 * translateX(10px)
		 * rotate(10deg)
		 * translateY(5px)
		 * translate(12px, 50%)
		 * translate3d(12px, 50%, 3em)
		 * scale(2, 0.5)
		 * matrix(1, 2, 3, 4, 5, 6)
		 * skew(30deg, 20deg)
		 * `}
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/translate|translate}
		 * ```
		 */
		transform?: CSSProperties["transform"];

		/**
		 * animation
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/animation}
		 * @see {@link https://animista.net/play/basic/flip-scale/flip-scale-up-hor|animista}
		 * ```ts
		 * animation={`3s ease-in 1s infinite reverse both running slidein`}
		 * ```
		 */
		animation?: CSSProperties["animation"];
		/**
		 * `resize`
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/resize}
		 * ```ts
		 * resize={`both`}
		 * ```
		 */
		resize?: CSSProperties["resize"];
		/**
		 * `clip-path`
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path}
		 * @see {@link https://bennettfeely.com/clippy/|clippy}
		 */
		cp?: CSSProperties["clipPath"];
		/** box-shadow */
		shadow?: CSSProperties["boxShadow"];
		/** cursor */
		cursor?: CSSProperties["cursor"];
	}

	/**
	 * Add base functions
	 * ```ts
	 * import Style, { typographyStyles, baseStyles } from "./common/styles";
	 *
	 * const StyledHeader = styled.header<Style.FlexType>`
	 * 	${baseStyles}
	 * 	${typographyStyles}
	 * `
	 * ```
	 */
	export interface TypographyType extends BaseType {
		/**
		 ** The font CSS shorthand property sets all the different properties of an element's font.
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/font|font}
		 */
		font?: {
			/** `font-style` */
			fs?: CSSProperties["fontStyle"];
			/** `font-weight` */
			fw?: CSSProperties["fontWeight"];
			/** `font-family` */
			ff?: CSSProperties["fontFamily"];
			/** `font-size` */
			size?: CSSProperties["fontSize"];
			/** `line-height` */
			line?: CSSProperties["lineHeight"];
		};
		text?: {
			/** `letter-spacing`
			 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/letter-spacing|letter-spacing}
			 */
			ls?: CSSProperties["letterSpacing"];
			/**
			 ** `text-align`
			 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/text-align}
			 */
			ta?: CSSProperties["textAlign"];
			/**
			 * `text-transform`
			 *  @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/text-transform}
			 */
			tt?: CSSProperties["textTransform"];
			/**
			 * `text-shadow`
			 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/text-shadow}
			 * @see {@link https://html-css-js.com/css/generator/text-shadow/|text-shadow}
			 */
			ts?: CSSProperties["textShadow"];
			/**
			 *  `text-decoration`
			 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration} */
			td?: CSSProperties["textDecoration"];
			/**
			 * `text-indent`
			 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/text-indent}
			 */
			ti?: CSSProperties["textIndent"];
			/** `text-overflow` */
			over?: CSSProperties["textOverflow"];
			/**
			 * `white-space` - The white-space property sets how to display spaces between words.
			 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/white-space}
			 * */
			ws?: CSSProperties["whiteSpace"];
			hyphens?: CSSProperties["hyphens"];
			/** user-select */
			us?: CSSProperties["userSelect"];
			/** word-wrap */
			ww?: CSSProperties["wordWrap"];
			/** word-break */
			wb?: CSSProperties["wordBreak"];
		};
	}

	/**
	 * Add base functions
	 * ```ts
	 * import Style, { gridStyles, baseStyles  } from "./common/styles";
	 *
	 * const StyledHeader = styled.header<Style.FlexType>`
	 * 	display: grid;
	 * 	${baseStyles}
	 * 	${gridStyles}
	 * `
	 *
	 * ```
	 */
	export interface GridType extends BaseType {
		/**
		 * `grid-auto-columns`
		 * ```ts
		 * column="1fr"
		 * column="32px"
		 * column="auto"
		 * column="min-content"
		 * column="minmax(30px, auto)";
		 * ```
		 * */
		column?: string;
		/**
		 * `grid-template-columns`
		 * ```ts
		 *  columns={"repeat(3,1fr)"}
		 *  columns={"1fr 200px 90% 30rem 100vh"}
		 *  columns={"minmax(20px, auto) 1fr 1fr"}
		 *  columns="repeat(auto-fill, minmax(100px, 1fr))"
		 * ```
		 *  */
		columns?:
			| string
			| {
					/** `grid-auto-columns` */
					auto?: string;
					/** `grid-template-columns` */
					columns?: string;
					/** `grid-column`
					 * ```ts
					 *  columns={{column:"1"}}
					 *  columns={{column:"1 / 3"}}
					 *  columns={{column:"2 / -1"}}
					 *  columns={{column:"1 / span 2"}}
					 * ```
					 */
					column?: string;
					/** `grid-column-end`
					 * ```ts
					 *  columns={{end:"auto"}}
					 *  columns={{end:"1"}}
					 *  columns={{end:"-1"}}
					 *  columns={{end:"span 2"}}
					 * ```
					 */
					end?: string;
					/** `grid-column-start`
					 * ```ts
					 *  columns={{start:"auto"}}
					 *  columns={{start:"1"}}
					 *  columns={{start:"-1"}}
					 *  columns={{start:"span 2"}}
					 * ```
					 */
					start?: string;
			  };
		/**
		 * `grid-auto-rows`
		 * ```ts
		 * row="1fr"
		 * row="32px"
		 * row="auto"
		 * row="min-content"
		 * row="minmax(30px, auto)";
		 * ```
		 * */
		row?: string;
		/**
		 * `grid-template-rows`
		 * ```ts
		 * rows={"repeat(3,1fr)"}
		 * rows={"1fr 200px 90% 30rem 100vh"}
		 * rows={"minmax(20px, auto) 1fr 1fr"}
		 * rows="repeat(auto-fill, minmax(100px, 1fr))"
		 * ```
		 * */
		rows?:
			| string
			| {
					/** `grid-auto-rows` */
					auto?: string;
					/** `grid-template-rows` */
					rows?: string;
					/** `grid-row`
					 * ```ts
					 *  rows={{row:"1"}}
					 *  rows={{row:"1 / 3"}}
					 *  rows={{row:"2 / -1"}}
					 *  rows={{row:"1 / span 2"}}
					 * ```
					 */
					row?: string;
					/** `grid-row-end`
					 * ```ts
					 *  rows={{end:"auto"}}
					 *  rows={{end:"1"}}
					 *  rows={{end:"-1"}}
					 *  rows={{end:"span 2"}}
					 * ```
					 */
					end?: string;
					/** `grid-row-start`
					 * ```ts
					 *  rows={{start:"auto"}}
					 *  rows={{start:"1"}}
					 *  rows={{start:"-1"}}
					 *  rows={{start:"span 2"}}
					 * ```
					 */
					start?: string;
			  };
		/**
		 * `grid-template-areas`
		 * ```ts
		 * areas={`'header header header'
				   'sidebar main main'
				   'sidebar main main'
				   '. footer footer'`}
		 * ```
		 */
		areas: string;

		/** `grid-area`
		 * ```ts
		 * area={"header"}
		 * ```
		 */
		area?: string;
		justifySelf?: CSSProperties["justifySelf"];
		alignSelf?: CSSProperties["alignSelf"];
	}

	/**
	 * Add base functions
	 * ```ts
	 * import Style, { gridStyles, baseStyles  } from "./common/styles";
	 *
	 * const StyledHeader = styled.header<Style.FlexType>`
	 * 	display: grid;
	 * 	${baseStyles}
	 * 	${gridStyles}
	 * `
	 * ```
	 */
	export interface GridItemType extends Omit<GridType, "areas"> {
		/**
		 * `grid-template-areas`
		 * ```ts
		 * areas={`'header header header'
				   'sidebar main main'
				   'sidebar main main'
				   '. footer footer'`}
		 * ```
		 */
		areas?: string;
		/** `grid-area`
		 * ```ts
		 * area={"header"}
		 * ```
		 */
		area: string;
	}

	/**
	 * Add base functions
	 * ```ts
	 * import Style, { flexStyles, baseStyles  } from "./common/styles";
	 *
	 * const StyledHeader = styled.header<Style.FlexType>`
	 * 	display: flex;
	 * 	${baseStyles}
	 * 	${flexStyles}
	 * `
	 * ```
	 */
	export interface FlexType extends BaseType {
		flex?: {
			/**
			 * `flex-grow` | `flex-shrink` | `flex-basis`
			 * ```ts
			 * flex: `2 2 10%`;
			 * ```
			 *
			 */
			flex?: CSSProperties["flex"];
			/**
			 * `flex-direction` | `flex-wrap`
			 * ```ts
			 * flow:`column wrap`;
			 * ```
			 */
			flow?: CSSProperties["flexFlow"];
			order?: CSSProperties["order"];
			wrap?: CSSProperties["flexWrap"];
			direction?: CSSProperties["flexDirection"];
			basis?: CSSProperties["flexBasis"];
			grow?: CSSProperties["flexGrow"];
			shrink?: CSSProperties["flexShrink"];
		};
	}
	export type ButtonType = Style.BaseType &
		Style.FlexType &
		Style.DistributeType &
		Style.TypographyType;
	export type InputType = Style.BaseType &
		Style.FlexType &
		Style.DistributeType &
		Style.TypographyType;
	export type TextType = Style.BaseType &
		Style.FlexType &
		Style.DistributeType &
		Style.TypographyType;
	export type Column = Style.FlexType & Style.DistributeType & Style.BaseType;
	export type Row = Style.FlexType & Style.DistributeType & Style.BaseType;
	export type Grid = Style.GridType & Style.DistributeType & Style.BaseType;
	export type GridItem = Style.GridItemType &
		Style.DistributeType &
		Style.BaseType;
}
export default Style;
