import { useCallback, useMemo } from "react";

import { ExecutorLocations } from "../../services";
import { ExecutorStatus } from "../../services/Executor";
import { useTypedSelector } from "../../redux/store";

const useExecutorLocationsSubHandler =
	(): useExecutorLocationsSubHandler.Props => {
		const executorLocationsSubscribe = useTypedSelector(
			(state) =>
				state.globalState.executorLocations.data.subscription.basic,
		);

		const getAllExecutorLocationsSubHandler = useCallback(
			({
				limit,
				taxiServiceIds,
				orderIds,
				executorWorkingStatus,
				executorStatuses,
			}: useExecutorLocationsSubHandler.SmallSubscribeOptions): ExecutorLocations.Model[] => {
				if (!Array.isArray(executorLocationsSubscribe)) {
					return [];
				}

				let retval = [...(executorLocationsSubscribe || [])];

				const [
					isTaxiServiceIds,
					isLimit,
					isOrderIds,
					isExecutorWorkingStatus,
					isExecutorStatuses,
				] = [
					Array.isArray(taxiServiceIds) && taxiServiceIds?.length,
					typeof limit === "number",
					Array.isArray(orderIds) && orderIds?.length,
					typeof executorWorkingStatus === "boolean",
					Array.isArray(executorStatuses) && executorStatuses?.length,
				];

				if (isTaxiServiceIds) {
					retval = retval.filter(
						(item) =>
							item.taxiServiceId &&
							taxiServiceIds &&
							taxiServiceIds.includes(item.taxiServiceId),
					);
				}

				if (isOrderIds) {
					retval = retval.filter(
						(item) =>
							orderIds &&
							item.order?.id &&
							orderIds.includes(item.order?.id),
					);
				}

				if (isExecutorWorkingStatus) {
					retval = retval.filter(
						(item) =>
							item.executor?.isWorking === executorWorkingStatus,
					);
				}

				if (isExecutorStatuses) {
					retval = retval.filter(
						(item) =>
							executorStatuses &&
							item.executor?.status &&
							executorStatuses.includes(item.executor?.status),
					);
				}

				if (isLimit) {
					retval = retval.slice(0, limit);
				}

				return retval;
			},
			[executorLocationsSubscribe],
		);

		return useMemo(
			() => ({
				getAllExecutorLocationsSubHandler,
			}),
			[getAllExecutorLocationsSubHandler],
		);
	};

declare namespace useExecutorLocationsSubHandler {
	interface Props {
		getAllExecutorLocationsSubHandler: (
			data: SmallSubscribeOptions,
		) => ExecutorLocations.Model[];
	}

	interface SmallSubscribeOptions {
		taxiServiceIds?: number[];
		limit?: number;
		//
		orderIds?: number[];
		executorWorkingStatus?: boolean;
		executorStatuses?: ExecutorStatus[];
	}
}

export default useExecutorLocationsSubHandler;
