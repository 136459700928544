import React, { useMemo } from "react";
import { ToggleButton } from "uikit";

import { Role } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const ActiveCellContent: React.FC<ActiveCellContent.Props> = ({ item }) => {
	const content = useMemo(() => !!item.active, [item.active]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<div onClick={(event) => event.stopPropagation()}>
				<ToggleButton.ToggleButton
					value={content}
					onChange={(value) => {
						Role.update({
							...(item as Role.Model),

							active: value,
						});
					}}
				/>
			</div>
		</CellContentRoot>
	);
};

declare namespace ActiveCellContent {
	interface Props {
		item: Role.Model;
	}
}

export default ActiveCellContent;
