import { State as _DataExecuters } from "./Executors/data/interface";
import { State as _DataExecutorLocations } from "./ExecutorLocations/data/interface";
import { State as _DataExecutorGroup } from "./ExecutorGroup/data/interface";
import { State as _DataExecutorRate } from "./ExecutorRate/data/interface";
import { State as _DataRole } from "./Role/data/interface";

export namespace GlobalState {
	export namespace Executors {
		export interface DataExecuters extends _DataExecuters {}
	}

	export namespace ExecutorLocations {
		export interface DataExecutorLocations extends _DataExecutorLocations {}
	}

	export namespace ExecutorGroup {
		export interface DataExecutorGroupextends extends _DataExecutorGroup {}
	}

	export namespace ExecutorRate {
		export interface DataExecutorRate extends _DataExecutorRate {}
	}

	export namespace Role {
		export interface DataRole extends _DataRole {}
	}

	export const ConstantGlobalStateSlice = {
		EXECUTORS_DATA: "globalState/executors/data",
		EXECUTOR_LOCATIONS_DATA: "globalState/executorLocations/data",
		EXECUTOR_GROUP_DATA: "globalState/executorGroup/data",
		EXECUTOR_RATE_DATA: "globalState/executorRate/data",
		ROLE_DATA: "globalState/role/data",
	};
}
