import React, {
	PropsWithChildren,
	createContext,
	memo,
	useCallback,
	useContext,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { uniq } from "lodash";
import { jsPDF } from "jspdf";

import { Language } from "../../services";
import { useTypedSelector } from "../../redux/store";
import { callAddFont } from "../../utils";
import {
	createObjectFromConstant,
	createObjectLanguageNamesFromObject,
} from "../../assets/languages/langs";
import { BaseModelsLoader } from "../../components";

jsPDF.API.events.push(["addFonts", callAddFont]);

declare module "jspdf" {
	interface jsPDF {
		lastAutoTable: {
			finalY: number;
		};
	}
}

export const AppContext = createContext<AppProvider.Context | null>(null);

export const useAppContext = (): AppProvider.Context => {
	const store = useContext<AppProvider.Context | null>(AppContext);
	if (!store) {
		throw new Error("Missing AppContext.Provider in the tree");
	}
	return store;
};

export const AppProvider: React.FC<AppProvider.Props> = ({
	children,
}): JSX.Element => {
	const connected = useTypedSelector((state) => state.prpc.connected);
	const authorized = useTypedSelector((state) => state.prpc.authorized);
	const taxiServices = useTypedSelector((state) => state.taxiServices.models);

	const [taxiServiceIdsByCompanyId, setTaxiServiceIdsByCompanyId] = useState<
		Map<number, number[]>
	>(new Map());
	const [companyIdByTaxiServiceId, setCompanyIdByTaxiServiceId] = useState<
		Map<number, number>
	>(new Map());
	const [companyNameById, setCompanyNameById] = useState<
		Map<number, Record<Language, string>>
	>(new Map());
	const [taxiServiceNameById, setTaxiServiceNameById] = useState<
		Map<number, Record<Language, string>>
	>(new Map());
	const [taxiServiceLanguagesById, setTaxiServiceLanguagesById] = useState<
		Map<number, Language[]>
	>(new Map());

	useLayoutEffect(() => {
		if (taxiServices.length) {
			taxiServices.forEach(({ id, company, settlement, settings }) => {
				if (company?.id) {
					companyIdByTaxiServiceId.set(id, company.id);
					taxiServiceNameById.set(
						id,
						createObjectLanguageNamesFromObject(settlement),
					);
					companyNameById.set(
						company?.id,
						createObjectLanguageNamesFromObject(company?.name),
					);
					taxiServiceLanguagesById.set(id, settings?.languages || []);

					const exist = taxiServiceIdsByCompanyId.get(company?.id);
					if (exist) {
						taxiServiceIdsByCompanyId.set(
							company?.id,
							uniq([...exist, id]),
						);
					} else {
						taxiServiceIdsByCompanyId.set(company?.id, [id]);
					}
				}
			});
		} else {
			setTaxiServiceNameById(new Map());
			setCompanyNameById(new Map());
			setTaxiServiceIdsByCompanyId(new Map());
			setCompanyIdByTaxiServiceId(new Map());
			setTaxiServiceLanguagesById(new Map());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServices]);

	const getCompanyName = useCallback(
		(id?: number) => {
			if (!id) return createObjectFromConstant();
			const payload = companyNameById?.get(id);
			return payload || createObjectFromConstant();
		},
		[companyNameById],
	);
	const getTaxiServiceName = useCallback(
		(id?: number) => {
			if (!id) return createObjectFromConstant();
			const payload = taxiServiceNameById?.get(id);
			return payload || createObjectFromConstant();
		},
		[taxiServiceNameById],
	);
	const getTaxiServiceLanguages = useCallback(
		(id?: number) => {
			if (!id) return [];
			const payload = taxiServiceLanguagesById?.get(id);
			return payload || [];
		},
		[taxiServiceLanguagesById],
	);
	const getTaxiServiceIds = useCallback(
		(companyId?: number) => {
			if (!companyId) return [];
			const payload = taxiServiceIdsByCompanyId?.get(companyId);
			return payload || [];
		},
		[taxiServiceIdsByCompanyId],
	);
	const getCompanyIdByTaxiServiceId = useCallback(
		(taxiServiceId?: number) => {
			if (!taxiServiceId) return undefined;
			const payload = companyIdByTaxiServiceId?.get(taxiServiceId);
			return payload;
		},
		[companyIdByTaxiServiceId],
	);

	const provideValue = useMemo(
		() => ({
			getCompanyName,
			getTaxiServiceName,
			getTaxiServiceIds,
			getCompanyIdByTaxiServiceId,
			getTaxiServiceLanguages,
		}),
		[
			getCompanyName,
			getTaxiServiceIds,
			getTaxiServiceName,
			getTaxiServiceLanguages,
			getCompanyIdByTaxiServiceId,
		],
	);

	return (
		<AppContext.Provider value={provideValue}>
			{connected && authorized && <BaseModelsLoader />}
			{children}
		</AppContext.Provider>
	);
};

declare namespace AppProvider {
	export interface Props extends PropsWithChildren {}

	interface Context {
		/** get company name by id */
		getCompanyName: (id?: number) => Record<Language, string>;
		/** get taxi service name by id */
		getTaxiServiceName: (id?: number) => Record<Language, string>;
		/** get taxi service languages by id */
		getTaxiServiceLanguages: (id?: number) => Language[];
		/** get taxi service ids by company id */
		getTaxiServiceIds: (companyId?: number) => number[];
		/** get company id by taxi service id */
		getCompanyIdByTaxiServiceId: (companyId?: number) => number | undefined;
	}

	namespace Context {}
}

export const AppProviderMemo = memo(AppProvider);
