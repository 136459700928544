import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const ParkNumberCellContent: React.FC<ParkNumberCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() =>
			item.cars
				?.filter((c) => !!c.parkNumber)
				?.map((c) => c.parkNumber)
				?.join(", ") ?? "",
		[item.cars],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace ParkNumberCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default ParkNumberCellContent;
