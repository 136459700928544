import React, { forwardRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CarService from "../../../../../../../../../services/Car";
import { ValueLanguage } from "../../../../../../../../../assets/languages/langs";

import Root from "./components/Root";

const CarModel = styled.div`
	font-weight: 700;
	line-height: 20px;
`;

const CarData = styled.div`
	font-weight: 500;
	line-height: 16px;
`;

interface Props {
	car: CarService.Model;
	language: ValueLanguage;
	active: boolean;
	onSelect: (car: CarService.Model) => void;
}

const Car = forwardRef<HTMLDivElement, Props>(
	({ car, language, active, onSelect }, ref) => {
		const { t } = useTranslation();

		const CarModelInformation = useMemo(
			() =>
				`${car?.model?.carBaseTypeToCarBrand?.carBrand?.name} ${car?.model?.name}`,
			[
				car?.model?.carBaseTypeToCarBrand?.carBrand?.name,
				car?.model?.name,
			],
		);
		const CarAdditionalInformation = useMemo(() => {
			const color = car?.color?.name?.[language] || "";

			const registrationNumber = car?.registrationNumber || "";

			const parkingNumber = car?.parkNumber
				? `${
						t(
							"orderPageWidgets.executors.executorModal.carModal.car.str0",
						) ?? ""
				  } ${car.parkNumber}`
				: "";

			return [color, registrationNumber, parkingNumber].join(", ");
		}, [
			car?.color?.name,
			car.parkNumber,
			car?.registrationNumber,
			language,
			t,
		]);

		const selectCar = useCallback(() => {
			onSelect(car);
		}, [car, onSelect]);

		return (
			<Root ref={ref} active={active} onClick={selectCar}>
				<div style={{ padding: "0px 15px" }}>
					<CarModel>{CarModelInformation}</CarModel>
					<CarData>{CarAdditionalInformation}</CarData>
				</div>
			</Root>
		);
	},
);

export default Car;
