import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";
import { isBoolean } from "lodash";

import Order, {
	OrderTransferType,
} from "../../../../../../../../../../services/Order";
import OrderDistributionSettings from "../../../../../../../../../../services/OrderDistributionSettings";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import {
	Button,
	StyledRow,
} from "../../../../../../../../../../components/common";

// import {
// 	generateAccessName,
// 	AccessKey,
// 	hasAccess,
// } from "../../../../../../../../../../access";

const OrderTransferButtonsBase: React.FC<OrderTransferButtonsBase.Props> = ({
	disabled,
	orderId,
	order,
}) => {
	// const ACCESS_SECTION = AccessKey.ORDERS;
	// const ACCESS_NAME = AccessKey.CALL_ORDER_CLIENT;
	const { t } = useTranslation();

	const options = useMemo(() => {
		const payload: OrderDistributionSettings.SubscribeOptions = {
			limit: 100,
			includeTaxiServices: true,
		};
		return payload;
	}, []);

	const { models } = useModelSubscribe(options, OrderDistributionSettings);

	const transfer = useMemo(() => order?.additionalFields?.transfer, [order]);

	const [loadingSendToTransferLocal, setLoadingSendToTransferLocal] =
		useState<boolean>(false);
	const [loadingSendToTransferGlobal, setLoadingSendToTransferGlobal] =
		useState<boolean>(false);
	const [loadingSendToTransferOutside, setLoadingSendToTransferOutside] =
		useState<boolean>(false);

	const lastReceivedOrder = useMemo(
		() => transfer?.lastReceivedOrder,
		[transfer?.lastReceivedOrder],
	);

	const isNotEnabled = useMemo(() => {
		const taxiServiceId = order?.taxiService?.id;

		if (!taxiServiceId) return true;

		let value: OrderDistributionSettings.Model | undefined;

		if (!value) {
			value = models.find(
				(item) =>
					!item.root &&
					item?.taxiServiceIds?.includes(taxiServiceId) &&
					item.active,
			);
		}

		if (!value) {
			value = models.find((item) => item.root);
		}

		if (!value) return true;

		return !value?.settings?.externalOrderDistribution?.send?.enabled;
	}, [models, order]);

	const currentTransferStage = useMemo(() => {
		const value = transfer?.sendProcess?.currentTransferStage;
		return value;
	}, [transfer]);

	const isNotOwn = useMemo(() => {
		const value = order?.isOwn;
		return isBoolean(value) ? !value : false;
	}, [order?.isOwn]);

	const hasExecutor = useMemo(() => {
		if (!order?.id) return true;
		const value =
			order?.executorToOrder?.length ||
			lastReceivedOrder?.executorToOrder?.length;
		return !!value;
	}, [
		lastReceivedOrder?.executorToOrder?.length,
		order?.executorToOrder?.length,
		order?.id,
	]);

	const isCurrentTransferStage = useMemo(() => {
		if (hasExecutor) return true;
		return !currentTransferStage;
	}, [currentTransferStage, hasExecutor]);

	const disabledOrder = useMemo(() => {
		if (!orderId) return true;
		return false;
	}, [orderId]);

	const returnFromTransfer = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;
		if (hasExecutor) return;
		Order.returnFromTransfer(orderId);
	}, [orderId, isNotOwn, hasExecutor]);

	const sendToTransfer = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;
		if (hasExecutor) return;
		Order.sendToTransfer(orderId);
	}, [hasExecutor, isNotOwn, orderId]);

	const sendToTransferLocal = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;
		if (hasExecutor) return;
		if (loadingSendToTransferLocal) return;
		setLoadingSendToTransferLocal(true);
		await Order.sendToTransfer(orderId, { type: OrderTransferType.LOCAL });
		setLoadingSendToTransferLocal(false);
	}, [hasExecutor, isNotOwn, loadingSendToTransferLocal, orderId]);

	const sendToTransferGlobal = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;
		if (hasExecutor) return;
		if (loadingSendToTransferGlobal) return;
		setLoadingSendToTransferGlobal(true);
		await Order.sendToTransfer(orderId, { type: OrderTransferType.GLOBAL });
		setLoadingSendToTransferGlobal(false);
	}, [hasExecutor, isNotOwn, loadingSendToTransferGlobal, orderId]);

	const sendToTransferOutside = useCallback(async () => {
		if (!orderId) return;
		if (isNotOwn) return;
		if (hasExecutor) return;
		if (loadingSendToTransferOutside) return;
		setLoadingSendToTransferOutside(true);
		await Order.sendToTransfer(orderId, {
			type: OrderTransferType.OUTSIDE,
		});
		setLoadingSendToTransferOutside(false);
	}, [hasExecutor, isNotOwn, loadingSendToTransferOutside, orderId]);

	// const isNotAccess = useMemo(
	// 	() =>
	// 		!hasAccess(
	// 			generateAccessName(ACCESS_SECTION, ACCESS_NAME),
	// 			personalRole,
	// 		),
	// 	[ACCESS_SECTION, ACCESS_NAME, personalRole],
	// );

	// if (isNotAccess) return null;

	const textBtnGroup = useMemo(() => {
		const linkLang = "pages.mainPage.pages.orders.orderHeader.secondRow.";

		return {
			orderReturnFromTransfer:
				t(`${linkLang}orderReturnFromTransfer`) ?? "",
			orderSendToTransfer: t(`${linkLang}orderSendToTransfer`) ?? "",
			orderSendToTransferLocal:
				t(`${linkLang}orderSendToTransferLocal`) ?? "",
			orderSendToTransferGlobal:
				t(`${linkLang}orderSendToTransferGlobal`) ?? "",
			orderSendToTransferOutside:
				t(`${linkLang}orderSendToTransferOutside`) ?? "",
		};
	}, [t]);

	return (
		<StyledRow gap="5px">
			<Button
				variant="secondary"
				// disabled={
				// 	disabled ||
				// 	disabledOrder ||
				// 	isCurrentTransferStage ||
				// 	hasExecutor ||
				// 	isNotOwn
				// }
				disabled={true}
				title={textBtnGroup.orderReturnFromTransfer}
				tabIndex={-1}
				onClick={returnFromTransfer}
			>
				<Icon id="return-back" size={18} />
			</Button>

			<Button
				variant="secondary"
				// disabled={
				// 	disabled ||
				// 	disabledOrder ||
				// 	hasExecutor ||
				// 	!!currentTransferStage ||
				// 	isNotOwn ||
				// 	isNotEnabled
				// }
				disabled={true}
				title={textBtnGroup.orderSendToTransfer}
				tabIndex={-1}
				onClick={sendToTransfer}
			>
				<Icon id="sendTransferSend" size={22} />
			</Button>

			<Button
				variant="secondary"
				disabled={
					disabled ||
					disabledOrder ||
					hasExecutor ||
					currentTransferStage === OrderTransferType.LOCAL ||
					currentTransferStage === OrderTransferType.GLOBAL ||
					currentTransferStage === OrderTransferType.OUTSIDE ||
					isNotOwn ||
					isNotEnabled
				}
				title={textBtnGroup.orderSendToTransferLocal}
				tabIndex={-1}
				onClick={sendToTransferLocal}
			>
				<Icon id="sendTransferLocal" size={22} />
			</Button>

			<Button
				variant="secondary"
				disabled={
					disabled ||
					disabledOrder ||
					hasExecutor ||
					currentTransferStage === OrderTransferType.GLOBAL ||
					currentTransferStage === OrderTransferType.OUTSIDE ||
					isNotOwn ||
					isNotEnabled
				}
				title={textBtnGroup.orderSendToTransferGlobal}
				tabIndex={-1}
				onClick={sendToTransferGlobal}
			>
				<Icon id="sendTransferGlobal" size={22} />
			</Button>

			<Button
				variant="secondary"
				disabled={
					disabled ||
					disabledOrder ||
					hasExecutor ||
					currentTransferStage === OrderTransferType.OUTSIDE ||
					isNotOwn ||
					isNotEnabled
				}
				title={textBtnGroup.orderSendToTransferOutside}
				tabIndex={-1}
				onClick={sendToTransferOutside}
			>
				<Icon id="sendTransferOutside" size={22} />
			</Button>
		</StyledRow>
	);
};

export const OrderTransferButtons = memo(OrderTransferButtonsBase);

declare namespace OrderTransferButtonsBase {
	interface Props {
		orderId?: number;
		disabled?: boolean;
		order?: Order.Model;
	}
}

export default OrderTransferButtonsBase;
