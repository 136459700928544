import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { CarClass } from "../../../services";
import { useTypedSelector } from "../../../redux/store";
import { useModelSubscribe } from "../../../hooks";
import { MultiSelectWithModal, MultiSelectWithModalBase } from "../Modal";

/** @description use `MultiSelectCarClassWithModal` */
const AutoClassesBase: React.FC<AutoClassesBase.Props> = ({
	value,
	onChange,
	disabled,
	titleText,
	required,
}) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const autoClasses = useModelSubscribe({}, CarClass);

	const items = useMemo(() => autoClasses.models, [autoClasses.models]);

	const [error, setError] = useState("");

	const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.name[lang],
				value: item.id,
				name: item.name[lang],
				data: item,
			})),
		[items, lang],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) =>
				value.find((data) => data === item.value),
			),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const autoClassesIds = newValue.map((item) => item.value);
			onChange(autoClassesIds);
		},
		[onChange],
	);

	const texts = useMemo(
		() => ({
			title: t(titleText),
			allText: t("common.autoClasses.str200") ?? "",
		}),
		[t, titleText],
	);

	return (
		<MultiSelectWithModal
			error={error}
			disabled={disabled}
			value={selected}
			title={texts.title}
			allText={texts.allText}
			options={selectOptions}
			onChange={selectOnChange}
			required={required}
			pullUpItemInsideArray
			hiddenButton={false}
			showSelectAll
		/>
	);
};

/** @description use `MultiSelectCarClassWithModal` */
export const AutoClasses = memo(AutoClassesBase);

/** @description use `MultiSelectCarClassWithModal` */
declare namespace AutoClassesBase {
	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
		disabled?: boolean;
		titleText: string | string[];
		required?: boolean;
	}

	type Value = number[];
}

/** @description use `MultiSelectCarClassWithModal` */
export default AutoClassesBase;
