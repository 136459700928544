import React, { useCallback, useMemo, useState } from "react";
import { Button, Column, HR, Icon, Row } from "uikit";
import styled from "styled-components";

import { ModalTable } from "../../../../../../../ParkingsModal/components/Table";

interface Props {
	data: {
		id: number | undefined;
		name: string;
	}[];
	taxiServiceIdsTablePosition: number[];
	onChangeTaxiServiceTablePosition: React.Dispatch<
		React.SetStateAction<number[]>
	>;
}

const TableItem = styled(Row)`
	cursor: default;
	width: 100%;
	height: 100%;
`;
const TaxiService = styled(Row)`
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 32px;
`;

const TablePosition: React.FC<Props> = ({
	data,
	taxiServiceIdsTablePosition,
	onChangeTaxiServiceTablePosition,
}) => {
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (rowData?.id === selectedId) return "selected";

			if (!rowData?.active) return "not-active";

			return "";
		},
		[selectedId],
	);

	const onRowSelect = useCallback(
		(rowData) => {
			if (rowData.id === selectedId) {
				setSelectedId(null);
			} else {
				setSelectedId(rowData.id);
			}
		},
		[setSelectedId, selectedId],
	);

	const selectedExecutorIndex = useMemo(
		() => taxiServiceIdsTablePosition?.findIndex((id) => id === selectedId),
		[selectedId, taxiServiceIdsTablePosition],
	);

	const upExecutorToOnePosition = useCallback(() => {
		if (selectedExecutorIndex !== 0) {
			const copyTableData = [...taxiServiceIdsTablePosition];
			const prevEl = copyTableData[selectedExecutorIndex - 1];
			copyTableData[selectedExecutorIndex - 1] =
				copyTableData[selectedExecutorIndex];
			copyTableData[selectedExecutorIndex] = prevEl;

			onChangeTaxiServiceTablePosition(copyTableData);
		}
	}, [
		onChangeTaxiServiceTablePosition,
		selectedExecutorIndex,
		taxiServiceIdsTablePosition,
	]);

	const downExecutorToOnePosition = useCallback(() => {
		if (selectedExecutorIndex !== taxiServiceIdsTablePosition.length - 1) {
			const copyTableData = [...taxiServiceIdsTablePosition];
			const prevEl = copyTableData[selectedExecutorIndex + 1];
			copyTableData[selectedExecutorIndex + 1] =
				copyTableData[selectedExecutorIndex];
			copyTableData[selectedExecutorIndex] = prevEl;

			onChangeTaxiServiceTablePosition(copyTableData);
		}
	}, [
		onChangeTaxiServiceTablePosition,
		selectedExecutorIndex,
		taxiServiceIdsTablePosition,
	]);

	const upExecutorToFirstPosition = useCallback(() => {
		if (selectedExecutorIndex !== 0) {
			const copyTableData = [...taxiServiceIdsTablePosition];
			const firstEl = copyTableData[selectedExecutorIndex];
			const newTableData = copyTableData.filter((id) => id !== firstEl);
			newTableData.unshift(firstEl);

			onChangeTaxiServiceTablePosition(newTableData);
		}
	}, [
		onChangeTaxiServiceTablePosition,
		selectedExecutorIndex,
		taxiServiceIdsTablePosition,
	]);

	const downExecutorToLastPosition = useCallback(() => {
		if (selectedExecutorIndex !== taxiServiceIdsTablePosition.length - 1) {
			const copyTableData = [...taxiServiceIdsTablePosition];
			const lastEl = copyTableData[selectedExecutorIndex];
			const newTableData = copyTableData.filter((id) => id !== lastEl);
			newTableData.push(lastEl);

			onChangeTaxiServiceTablePosition(newTableData);
		}
	}, [
		onChangeTaxiServiceTablePosition,
		selectedExecutorIndex,
		taxiServiceIdsTablePosition,
	]);

	const disableUpButton = useMemo(
		() => !selectedId || selectedExecutorIndex === 0,
		[selectedExecutorIndex, selectedId],
	);
	const disableDownButton = useMemo(
		() =>
			!selectedId ||
			selectedExecutorIndex === taxiServiceIdsTablePosition.length - 1,
		[selectedExecutorIndex, selectedId, taxiServiceIdsTablePosition.length],
	);

	const [hoveredTaxiServiceId, setHoveredTaxiServicId] = useState<
		number | null
	>(null);

	const findTaxiServiceIndex = useCallback(
		(taxiServiceId: number) =>
			taxiServiceIdsTablePosition?.findIndex(
				(id) => id === taxiServiceId,
			),
		[taxiServiceIdsTablePosition],
	);

	const mouseEnter = useCallback((taxiServiceId: number) => {
		setHoveredTaxiServicId(taxiServiceId);
	}, []);

	const mouseLeave = useCallback(() => {
		setHoveredTaxiServicId(null);
	}, []);

	const upTaxiServiceToOnePosition = useCallback(
		(taxiServiceId: number) => {
			const taxiServiceIndex = findTaxiServiceIndex(taxiServiceId);
			if (taxiServiceIndex !== 0) {
				const copyTableData = [...taxiServiceIdsTablePosition];
				const prevEl = copyTableData[taxiServiceIndex - 1];
				copyTableData[taxiServiceIndex - 1] =
					copyTableData[taxiServiceIndex];
				copyTableData[taxiServiceIndex] = prevEl;

				onChangeTaxiServiceTablePosition(copyTableData);
				setImmediate(() => mouseLeave());
			}
		},
		[
			findTaxiServiceIndex,
			mouseLeave,
			onChangeTaxiServiceTablePosition,
			taxiServiceIdsTablePosition,
		],
	);

	const downTaxiServiceToOnePosition = useCallback(
		(taxiServiceId: number) => {
			const taxiServiceIndex = findTaxiServiceIndex(taxiServiceId);
			if (taxiServiceIndex !== taxiServiceIdsTablePosition.length - 1) {
				const copyTableData = [...taxiServiceIdsTablePosition];
				const prevEl = copyTableData[taxiServiceIndex + 1];
				copyTableData[taxiServiceIndex + 1] =
					copyTableData[taxiServiceIndex];
				copyTableData[taxiServiceIndex] = prevEl;

				onChangeTaxiServiceTablePosition(copyTableData);
				setImmediate(() => mouseLeave());
			}
		},
		[
			findTaxiServiceIndex,
			mouseLeave,
			onChangeTaxiServiceTablePosition,
			taxiServiceIdsTablePosition,
		],
	);

	const upTaxiServiceToFirstPosition = useCallback(
		(taxiServiceId: number) => {
			const taxiServiceIndex = findTaxiServiceIndex(taxiServiceId);
			if (taxiServiceIndex !== 0) {
				const copyTableData = [...taxiServiceIdsTablePosition];
				const firstEl = copyTableData[taxiServiceIndex];
				const newTableData = copyTableData.filter(
					(id) => id !== firstEl,
				);
				newTableData.unshift(firstEl);

				onChangeTaxiServiceTablePosition(newTableData);
				setImmediate(() => mouseLeave());
			}
		},
		[
			findTaxiServiceIndex,
			mouseLeave,
			onChangeTaxiServiceTablePosition,
			taxiServiceIdsTablePosition,
		],
	);

	const downTaxiServiceToLastPosition = useCallback(
		(taxiServiceId: number) => {
			const taxiServiceIndex = findTaxiServiceIndex(taxiServiceId);
			if (taxiServiceIndex !== taxiServiceIdsTablePosition.length - 1) {
				const copyTableData = [...taxiServiceIdsTablePosition];
				const lastEl = copyTableData[taxiServiceIndex];
				const newTableData = copyTableData.filter(
					(id) => id !== lastEl,
				);
				newTableData.push(lastEl);

				onChangeTaxiServiceTablePosition(newTableData);
				setImmediate(() => mouseLeave());
			}
		},
		[
			findTaxiServiceIndex,
			mouseLeave,
			onChangeTaxiServiceTablePosition,
			taxiServiceIdsTablePosition,
		],
	);

	const isButtonDisabled = useCallback(
		(executorId: number, position: "upDisable" | "downDisable") => {
			const taxiServiceIndex = findTaxiServiceIndex(executorId);
			if (taxiServiceIndex === 0 && position === "upDisable") return true;
			if (
				taxiServiceIndex === taxiServiceIdsTablePosition.length - 1 &&
				position === "downDisable"
			)
				return true;
			return false;
		},
		[findTaxiServiceIndex, taxiServiceIdsTablePosition.length],
	);

	const isShowButtons = useCallback(
		(taxiServiceId: number) => {
			if (selectedId === taxiServiceId) return true;
			if (hoveredTaxiServiceId === taxiServiceId) return true;
			return false;
		},
		[hoveredTaxiServiceId, selectedId],
	);

	return (
		<Column gaps="14px" maxedHeight>
			<Row gaps="5px*" style={{ padding: "0 10px" }}>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-up" size={23} />}
					onClick={upExecutorToOnePosition}
					disabled={disableUpButton}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-down" size={23} />}
					onClick={downExecutorToOnePosition}
					disabled={disableDownButton}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-top" size={23} />}
					onClick={upExecutorToFirstPosition}
					disabled={disableUpButton}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-bottom" size={23} />}
					onClick={downExecutorToLastPosition}
					disabled={disableDownButton}
				/>
			</Row>
			<HR />
			<ModalTable
				rowClassName={tableRowClassName}
				data={data}
				headerHeight={0}
				rowHeight={32}
				width={306}
				height={240}
			>
				<ModalTable.Column width={306}>
					<ModalTable.HeaderCell>{""}</ModalTable.HeaderCell>
					<ModalTable.Cell
						verticalAlign="middle"
						style={{ cursor: "default" }}
						onMouseLeave={mouseLeave}
					>
						{(rowData) => (
							<TableItem
								align="center"
								justify="space-between"
								onMouseEnter={() => mouseEnter(rowData.id)}
								onMouseMove={() => mouseEnter(rowData.id)}
								sizes="1fr auto"
							>
								<TaxiService
									maxedHeight
									align="center"
									onClick={() => onRowSelect(rowData)}
								>
									{rowData.name}
								</TaxiService>
								<Row
									style={{
										display: isShowButtons(rowData.id)
											? "block"
											: "none",
									}}
								>
									<Row gaps="3px*">
										<Button.Button
											variant="secondary"
											transparent
											icon={
												<Icon id="arrow-up" size={23} />
											}
											onClick={() =>
												upTaxiServiceToOnePosition(
													rowData.id,
												)
											}
											disabled={isButtonDisabled(
												rowData.id,
												"upDisable",
											)}
										/>
										<Button.Button
											variant="secondary"
											transparent
											icon={
												<Icon
													id="arrow-down"
													size={23}
												/>
											}
											onClick={() =>
												downTaxiServiceToOnePosition(
													rowData.id,
												)
											}
											disabled={isButtonDisabled(
												rowData.id,
												"downDisable",
											)}
										/>
										<Button.Button
											variant="secondary"
											transparent
											icon={
												<Icon
													id="arrow-top"
													size={23}
												/>
											}
											onClick={() =>
												upTaxiServiceToFirstPosition(
													rowData.id,
												)
											}
											disabled={isButtonDisabled(
												rowData.id,
												"upDisable",
											)}
										/>
										<Button.Button
											variant="secondary"
											transparent
											icon={
												<Icon
													id="arrow-bottom"
													size={23}
												/>
											}
											onClick={() =>
												downTaxiServiceToLastPosition(
													rowData.id,
												)
											}
											disabled={isButtonDisabled(
												rowData.id,
												"downDisable",
											)}
										/>
									</Row>
								</Row>
							</TableItem>
						)}
					</ModalTable.Cell>
				</ModalTable.Column>
			</ModalTable>
		</Column>
	);
};

export default TablePosition;
