/* eslint-disable no-shadow */
import { useChanged, useDebounce, useRender } from "uikit";
import { useCallback, useEffect, useRef } from "react";
import { NotUndefined, sha1 } from "object-hash";

import { RootState, store, useTypedSelector } from "../redux/store";
import StoreList from "../types/StoreList";
import StoreQueryMap from "../types/StoreQueryMap";

interface Service<Options, Model> {
	subscribe: (options: Options) => string;
	unsubscribe: (hash: string) => void;
	selector: Selector<Model>;
}

type Selector<Model> = (state: RootState) => StoreQueryMap<StoreList<Model>>;

interface Options {
	debounce?: number;
}

/**
 * @deprecated use hooks/useModelSubscribe2 instead
 */
export default function useModelSubscribe<
	SubscribeOptions extends NotUndefined,
	Model,
>(
	subscribeOptions: SubscribeOptions,
	Service: Service<SubscribeOptions, Model>,
	options: Options = { debounce: 300 },
): StoreList<Model> | undefined {
	const render = useRender();

	const firstTimeRef = useRef(true);
	const firstTime = firstTimeRef.current;

	const ServiceRef = useRef(Service);

	ServiceRef.current = Service;

	const hashRef = useRef("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const hash = hashRef.current;

	const resubscribe = useCallback(() => {
		if (hashRef.current) Service.unsubscribe(hashRef.current);

		const hash = Service.subscribe(subscribeOptions);

		hashRef.current = hash;

		render();

		return hash;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Service, sha1(subscribeOptions)]);

	const debouncedResubscribe = useDebounce(resubscribe, options.debounce);

	useEffect(() => {
		if (!firstTime) debouncedResubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedResubscribe]);

	// TODO: Problem in Service.subscribe id result, instead of sha1 it should be randomized.
	// useEffect(
	// 	() => () => {
	// 		if (hashRef.current && ServiceRef.current)
	// 			ServiceRef.current.unsubscribe(hashRef.current);
	// 	},
	// 	[],
	// );

	useChanged((PrevService) => {
		if (PrevService && hashRef.current)
			PrevService.unsubscribe(hashRef.current);
	}, Service);

	const internalSelector = useCallback(
		(state: RootState) => Service.selector(state)[hashRef.current],
		[Service],
	);

	if (firstTimeRef.current) {
		resubscribe();

		firstTimeRef.current = false;
	}
	return (
		useTypedSelector(internalSelector) ??
		Service.selector(store.getState())[hashRef.current]
	);
}
