/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import React, { useMemo, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { react, Row, Select, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Map from "../../redux/services/Map";
import { useTypedSelector } from "../../redux/store";
import { useTaxiServiceFilterAccess } from "../../access";

import InternalController from "./Controller";

const TaxiServiceForm = react.withController<
	TaxiServiceForm.InputifiedPropsBase,
	InternalController
>(
	react.inputify<TaxiServiceForm.InternalPropsBase, TaxiServiceForm.Value>(
		({ value, controller, onChange }) => {
			const { t } = useTranslation();
			const { subCompaniesOptions, subTaxiServiceOptions } =
				useTaxiServiceFilterAccess<any>();

			const rootRef = useRef<HTMLDivElement | null>(null);

			const taxiServices = useTypedSelector(
				(state) => state.taxiServices.models,
			);

			const [companyIdFiledError, setCompanyIdFieldError] =
				useState(false);
			const [taxiServiceIdFiledError, setTaxiServiceIdFieldError] =
				useState(false);

			const taxiService = useMemo(
				() =>
					taxiServices?.find(
						(taxiService) => taxiService.id === value,
					),
				[taxiServices, value],
			);

			const companyIdRef = useRef<number | undefined>(undefined);

			const [companyId, setCompanyId] = useInternal(
				taxiService?.company?.id ?? companyIdRef.current,
			);

			companyIdRef.current = companyId;

			const [initialCompanyIds] = useState(
				companyId != null ? [companyId] : undefined,
			);
			const [initialTaxiServiceIds] = useState(
				value ? [value] : undefined,
			);
			const selectedCompanies = useMemo(
				() => (companyId != null ? [companyId] : undefined),
				[companyId],
			);

			const companiesOptions = useMemo(
				() => subCompaniesOptions({}),
				[subCompaniesOptions],
			);

			const taxiServiceOptions = useMemo(
				() =>
					subTaxiServiceOptions({
						initialTaxiServiceIds,
						initialCompanyIds,
						selectedCompanies,
					}),
				[
					subTaxiServiceOptions,
					initialTaxiServiceIds,
					initialCompanyIds,
					selectedCompanies,
				],
			);

			useEffect(() => {
				if (companiesOptions.length === 1)
					setCompanyId(companiesOptions[0].key);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [companiesOptions]);

			useEffect(() => {
				if (taxiServiceOptions.length === 1)
					onChange(taxiServiceOptions[0].key);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [taxiServiceOptions]);

			controller.setContext({
				rootRef,

				value,
				companyId,
				taxiServices,

				setCompanyIdFieldError,
				setTaxiServiceIdFieldError,
			});

			return (
				<Row ref={rootRef} sizes="1fr*" gaps="8px*" maxedWidth>
					<Select
						placeholder={t("taxiServiceForm.str0") ?? ""}
						disabled={companiesOptions.length === 1}
						options={companiesOptions}
						value={companyId}
						error={companyIdFiledError}
						onSelect={(option) => {
							setCompanyIdFieldError(false);
							setCompanyId(option.value);
							onChange(undefined);
						}}
					/>
					<Select
						placeholder={t("taxiServiceForm.str1") ?? ""}
						disabled={taxiServiceOptions.length <= 1}
						options={taxiServiceOptions}
						value={value}
						error={taxiServiceIdFiledError}
						onSelect={(option) => {
							setCompanyIdFieldError(false);
							setTaxiServiceIdFieldError(false);
							onChange(option.value);
						}}
					/>
				</Row>
			);
		},
	),
	InternalController,
);

declare namespace TaxiServiceForm {
	type Value = number | undefined;

	interface PropsBase {
		language: Map.Language;
	}

	interface InternalPropsBase extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = InputifiedPropsBase;

	type Controller = InternalController;
}

export default TaxiServiceForm;
