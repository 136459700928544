import { isNumber, isString, isNaN } from "lodash";

/**
 * Sorts two values (strings or numbers) in a manner that handles both numeric and lexicographic sorting.
 * If `sortAsStrings` is true, the function treats numbers as strings to prevent numerical sorting based on their value,
 * and instead sorts them lexicographically, maintaining the string order (i.e., '10' comes after '2').
 *
 * @param _a - First value to compare, can be either a string or a number.
 * @param _b - Second value to compare, can be either a string or a number.
 * @param sortAsStrings - Flag indicating whether to treat numbers as strings for sorting.
 * If true, numbers are compared lexicographically as strings.
 * @returns A negative number if `_a` should come before `_b`, a positive number if `_b` should come before `_a`,
 * or 0 if both are equal.
 */
export const sortByNumberOrString = (
	_a: string | number,
	_b: string | number,
	sortAsStrings?: boolean,
): number => {
	// Helper function to check if the value is either a number or a string
	const isNumberOrString = (value: unknown): boolean =>
		isNumber(value) || isString(value);

	let [a, b] = [_a, _b];

	// If both values are not of type string or number, return 0 (no sorting)
	if (![a, b].every((value) => isNumberOrString(value))) {
		if (!isNumberOrString(a) && !isNumberOrString(b)) return 0;
		if (!isNumberOrString(a)) return 1;
		if (!isNumberOrString(b)) return -1;
	}

	// Function to check if a value is numeric
	const isNumeric = (value: string | number) =>
		isNumber(value) ||
		(/^-?\d+$/.test(String(value)) && !isNaN(Number(value)));

	// Convert the values to numbers if they are numeric
	const numA = isNumeric(a) ? Number(a) : null;
	const numB = isNumeric(b) ? Number(b) : null;

	// If `sortAsStrings` flag is enabled, treat numbers as strings for lexicographic comparison
	if (sortAsStrings) {
		// If both values are numbers, convert them to strings with equal length by padding them
		if (numA !== null && numB !== null) {
			const maxLength = Math.max(
				numA.toString().length,
				numB.toString().length,
			);
			a = numA.toString().padEnd(maxLength, "0");
			b = numB.toString().padEnd(maxLength, "0");
		} else if (numA !== null) {
			// If only the first value is numeric, pad it to maintain consistent length
			const maxLength = numA.toString().length;
			a = numA.toString().padEnd(maxLength, "0");
		} else if (numB !== null) {
			// If only the second value is numeric, pad it to maintain consistent length
			const maxLength = numB.toString().length;
			b = numB.toString().padEnd(maxLength, "0");
		}
	}

	// If `sortAsStrings` is false (default), perform numeric sorting if both values are numbers
	if (!sortAsStrings) {
		if (numA !== null && numB !== null) {
			// Both values are numbers, so perform numeric sorting
			return numA - numB;
		}

		// If one of the values is a number, it should come before a string
		if (numA !== null) return -1;
		if (numB !== null) return 1;
	}

	// If both values are strings, compare them lexicographically with numeric interpretation
	if (isString(a) && isString(b)) {
		return a.localeCompare(b, undefined, { numeric: true });
	}

	// If one of the values is a string, compare them as strings
	return String(a).localeCompare(String(b), undefined, { numeric: true });
};

export default sortByNumberOrString;
