import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const AliasCellContent: React.FC<AliasCellContent.Props> = ({ item }) => {
	const content = useMemo(() => item.alias ?? "", [item.alias]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace AliasCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default AliasCellContent;
