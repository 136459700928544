import React, { useMemo } from "react";

import {
	Language,
	ExecutorReport,
} from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (item: FleetCellContent.Props["item"]) => {
	if (!item || !item.fleet) return "";

	return item.fleet?.name || "";
};

const FleetCellContent: React.FC<FleetCellContent.Props> = ({ item }) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace FleetCellContent {
	interface Props {
		item: ExecutorReport.Model;
		language: Language;
	}
}

export default FleetCellContent;
