import * as ModelEvent from "@node-elion/syncron";
import { uniq, compact, isNumber, isBoolean } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import {
	createObjectLanguageNames,
	findValueByLanguageKey,
} from "../../assets/languages/langs";
import SubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import { IName } from "../../types/IName";
import { PaymentAccount } from "../../types/PaymentAccount";
import EntityStatus from "../../types/EntityStatus";
import { IPhone } from "../../types/IPhone";
import {
	Language,
	Reward,
	AgentGroup,
	TaxiService,
	Company,
	Map,
	Base,
} from "..";

import additionalFieldsToRequest from "./utils";

const logger = createLogger({ name: "Agent" });

export enum RewardCalculationMethod {
	ADD_TO_ORDER = "add_to_order",
	CUT_FROM_ORDER = "cut_from_order",
}

class Agent extends Base {
	public static fromResponse(data: any): Agent.Model {
		const defaultTaxiService =
			data.defaultTaxiService &&
			TaxiService.fromResponse(data.defaultTaxiService);

		const company = data.company && Company.fromResponse(data.company);
		const agentGroup =
			data.agentGroup && AgentGroup.fromResponse(data.agentGroup);

		const addresses = (data.addresses ?? []).map((address: any) => ({
			...address,
			pointFeature: {
				...(address.pointFeature || {}),
				number: address.pointFeature?.house,
			},
			pointCoordinates: {
				...(address?.pointCoordinates?.coordinates || {}),
			},
		}));

		const checkPaymentAccountIds = uniq<number>(
			data?.checkPaymentAccounts?.map(({ id }) => id),
		)?.[0];

		const payload: Agent.Model = {
			id: data.id,

			isArbitraryRewardAllowed: data.isArbitraryRewardAllowed,
			isRewardAllowed: data.isRewardAllowed,
			name: data.name,
			login: data.login,
			password: data.password,
			isAccessToAccount: data.isAccessToAccount,
			isAccessToApp: data.isAccessToApp,
			paymentAccounts: data.paymentAccounts ?? [],
			status: data.status,
			additionalFields: data.additionalFields ?? {},

			checkPaymentAccountIds,
			balanceId: checkPaymentAccountIds,

			code: data.code,

			company,
			companyId: data.company.id,

			counterpartyId: data.counterparty?.id,
			checkId: data.check?.id,
			defaultTaxiService,
			defaultTaxiServiceId: data.defaultTaxiService.id,
			latestTaxiServiceId: data.latestTaxiServiceId,
			agentToRewards: data.agentToRewards ?? [],
			phones: data.phones ?? [],

			addresses,

			agentGroup,
			agentGroupId: data.agentGroup?.id,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};

		return payload;
	}

	public static toRequest(model: Agent.New | Agent.Modified) {
		const payload: Record<string, any> = {
			isArbitraryRewardAllowed: model.isArbitraryRewardAllowed,
			isRewardAllowed: model.isRewardAllowed,
		};

		if (model.agentToRewards && model.agentToRewards.length) {
			payload.rewardIds = compact(
				uniq(model.agentToRewards.map((item) => item.reward?.id)),
			);
		}

		// const name =
		// 	model.name?.en ??
		// 	model.name?.uk ??
		// 	model.name?.ru ??
		// 	model.name?.az ??
		// 	model.name?.tr;

		const name = findValueByLanguageKey(model?.name);
		if (name) payload.name = createObjectLanguageNames(name);

		const output = additionalFieldsToRequest(model.additionalFields);
		if (output) {
			payload.additionalFields = output;
		}

		if (!("id" in model) || !model.id) {
			const { companyId } = model;
			if (companyId) payload.companyId = companyId;
			const { defaultTaxiServiceId: defaultBranchId } = model;
			if (defaultBranchId) payload.defaultTaxiServiceId = defaultBranchId;
		}

		const { latestTaxiServiceId } = model;
		if (latestTaxiServiceId) payload.latestTaxiServiceId;

		const { agentGroupId } = model;
		if (agentGroupId) payload.agentGroupId = agentGroupId;

		const { code } = model;
		if (code) {
			payload.code = {
				value: code.value,
				keyword: code.keyword,
			};
			if (code.id) payload.code.id = code.id;
		}

		const { phones } = model;
		if (phones) {
			payload.phones = phones.map((p, i) => {
				const phone: Agent.Phone = {
					isAgent: p.isAgent,
					group: i === 0 ? 0 : 1,
					number: p.number,
				};
				if (isNumber(p.id)) phone.id = p.id;
				return phone;
			});
		}

		const { addresses } = model;
		if (addresses) {
			payload.addresses = addresses.map(
				({ isAgent, pointCoordinates, pointFeature: obj }) => ({
					isAgent,
					pointCoordinates,
					pointFeature: {
						type: obj.type,
						name: obj.name,
						country: obj.country,
						countryCode: obj.countryCode,
						street: obj.street,
						streetType: obj.streetType,
						settlement: obj.settlement,
						settlementType: obj.settlementType,
						district: obj.district,
						region: obj.region,
						coordinates: obj.coordinates,
						house: obj.number,
					},
				}),
			);
		}

		if (!("id" in model)) {
			const { paymentTransactions } = model;
			const transactions: Record<string, any[]> = {};
			if (paymentTransactions.main.length)
				transactions.main = paymentTransactions.main;
			if (paymentTransactions.bonus.length)
				transactions.bonus = paymentTransactions.bonus;

			if (Object.keys(transactions).length)
				payload.paymentTransactions = transactions;
		}

		const { counterpartyId } = model;
		if (counterpartyId) payload.counterpartyId = counterpartyId;
		if (model.checkId) payload.checkId = model.checkId;
		if (model.balanceId) payload.checkPaymentAccountIds = [model.balanceId];

		logger.info("[Agent] toRequest", { model, payload });

		return payload;
	}

	public static async store(object: Agent.New, force = false) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.agent.create(
						this.toRequest(object),
						force,
					),
				{ silent: false, error: true },
			);

			logger.info("[Agent] store", { object, res, force });
			if (res?.error) return false;
			return true;
		} catch (err) {
			logger.error("[Agent] Error store:", err);
			return false;
		}
	}

	public static async update(object: Agent.Modified, force = false) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.agent.update(
						object.id,
						this.toRequest(object),
						force,
					),
				{ silent: false, error: true },
			);

			logger.info("[Agent] update", { object, res, force });
			return true;
		} catch (err: any) {
			logger.error("[Agent] Error update:", err);
			return false;
		}
	}

	public static async delete(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.agent.delete(id));
	}

	public static async subscribe(
		options: Agent.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Agent.Model>,
	): Promise<Subscription<Agent.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("[Agent] subscribe", { state });
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.agent.subscribe({
					params: options,
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "Agent.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Agent.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Agent.SubscribeOptions>;
	}

	private static optionsToRequest(options: Agent.SubscribeOptions) {
		const payload: Agent.SubscribeOptions = {
			...options,
			limit: options.limit,
			lang: options.lang,
			order: options.order,
			query: options.query,
			...(isBoolean(options.active) ? { active: options.active } : {}),
			...(isBoolean(options.default) ? { default: options.default } : {}),
		};

		logger.info("[Agent] subscribe option", { payload, options });
		return payload;
	}
}

declare namespace Agent {
	interface Model extends NonEditableProperties {
		name: IName;
		status: {
			level: EntityStatus;
			description: string;
		};
		isArbitraryRewardAllowed: boolean;
		isRewardAllowed: boolean;
		isAccessToAccount: boolean;
		isAccessToApp: boolean;
		login: string | null;
		password: string | null;
		phones: Phone[];
		addresses: Address[];
		paymentAccounts: PaymentAccount[];
		code: {
			value: string;
			keyword: string;
		} & Partial<NonEditableProperties>;
		counterpartyId: number;
		checkId: number;
		balanceId: number;
		checkPaymentAccountIds: number;
		company?: Company.Model;
		companyId: number;
		defaultTaxiService?: TaxiService.Model;
		defaultTaxiServiceId: number;
		latestTaxiServiceId: number;
		additionalFields: {
			contactPersonName?: string;
			contactPersonPhone?: string;
			contactPersonEmail?: string;
			executorNotes?: string;
			orderNotes?: string;
			notes?: string;
		};
		agentGroup: AgentGroup.Model;
		agentGroupId: number | undefined;
		agentToRewards: AgentToReward[];
	}

	interface AgentToReward extends NonEditableProperties {
		reward: Reward.Model;
	}

	type Phone = IPhone & { isAgent: boolean; id?: number | string };
	type Address = {
		pointCoordinates: { lat: number; lng: number };
		pointFeature: Map.Search.Object;
		isAgent: boolean;
	};

	type New = Omit<Model, NonEditablePropertyNames> & {
		paymentTransactions: {
			main: { amount: number; description?: string }[];
			bonus: { amount: number; description?: string }[];
		};
	};
	type Modified = Partial<Omit<New, "paymentTransactions">> & {
		readonly id: number;
	};
	type RewardOrder = Record<
		| "default"
		| "active"
		| "createdAt"
		| "percent"
		| "amount"
		| "maxAmountFromOrderInPercent"
		| "rewardCalculationMethod",
		"ASC" | "DESC"
	>;

	interface SubscribeOptions extends SubscribeOptionsBase<RewardOrder> {
		active?: boolean;
		default?: boolean;
		agentId?: number;
		lang?: Language;
	}
}

export default Agent;
