import { combineReducers } from "@reduxjs/toolkit";

import {
	globalStateRoleDataState,
	globalStateRoleDataReducer,
	globalStateRoleDataAction,
} from "./data";

export type StateRole = {
	data: globalStateRoleDataState;
};

export const role = combineReducers<StateRole>({
	data: globalStateRoleDataReducer,
});

export const globalStateRoleAction = {
	data: globalStateRoleDataAction,
};

export default role;
