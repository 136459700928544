/**
 * Checks if all words in the search query are present as substrings in the given text.
 *
 * @param {string} text - The main text to search within.
 * @param {string} query - The query string containing words to search for.
 * @returns {boolean} - Returns true if all words in the query are found in the text, otherwise false.
 */
const isTextMatchingAnySegmentFilterHelper = (
	text: string,
	query: string,
): boolean => {
	// Validate that both parameters are strings
	if (typeof text !== "string" || typeof query !== "string") {
		return false;
	}

	// Convert both strings to lowercase for case-insensitive comparison
	const lowerText = text.toLowerCase();
	const lowerQuery = query.toLowerCase();

	// Split the query string into an array of words, excluding empty entries
	const words = lowerQuery.split(" ").filter((word) => word);

	// Ensure each word from the query is found in the main text as a substring
	return words.every((word) => lowerText.includes(word));
};

export default isTextMatchingAnySegmentFilterHelper;
