import React, {
	useEffect,
	useState,
	useMemo,
	useCallback,
	memo,
	Suspense,
	useLayoutEffect,
} from "react";
import { Column, Key, Option, OrderTabs, Tabs, useInternal } from "uikit";
import { cloneDeep, isEqual, uniqBy } from "lodash";
import styled from "styled-components";
import { LatLngLiteral } from "leaflet";
import { useDebouncedCallback } from "use-debounce/lib";
import { useTranslation } from "react-i18next";

import { Order, ExecutorLocations } from "../../../../../../services";
import { CalculationErrorType } from "../../../../../../services/Order";
import createEmptyForm, {
	IOrderPoint,
	Schema,
	createOrderPointFromQuery,
	formatOrder,
	stringifyPoint,
	createOrderFormBy,
	prepareOrderByForm,
	formatOrderRoute,
	ThresholdPaymentStatus,
	formatTaximeterOrderRoute,
} from "../../../../../../redux/constants/OrdersPage/order";
import { Orders } from "../../../../../../redux/reducers/Orders";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import reveal from "../../../../../../redux/services/Map/getReveal";
import search from "../../../../../../redux/services/Map/search";
import calcRoute from "../../../../../../redux/services/Map/calcRoute";
import createEmptyTab from "../../../../../../redux/constants/OrdersPage/createEmptyTab";
import {
	openOrderCard,
	closeOrderCard,
	focusOrderCard,
} from "../../../../../../redux/services/Order/card";
import orderPage from "../../../../../../redux/reducers/OrdersPage";
import getOrderHistory, {
	History,
} from "../../../../../../redux/services/Order/getHistory";
import { getAndCallDown, callUp } from "../../../../../../utils/jsSip";
import createUIDMaker from "../../../../../../utils/createUIDMaker";
import { validateOrderForm } from "../../../../../../utils/validateOrderForm";
import {
	useExecutorLocationsSubHandler,
	useModelSubscribe,
} from "../../../../../../hooks";
import { useWidths } from "../../../../../../hooks/useTableSettings";
import OrderMap from "../../../../../../components/Map/Map";
import ModalController from "../../../../../../components/ModalController/Controller";
import PointModal from "../../../../../../components/PointModal";
import Modal from "../../../../../../components/Modal";
import tables from "../../../../../../constants/tables";
import { ColumnId } from "../../../../../../constants/tables/types";
import LegacyTableColumn from "../../../../../../types/TableColumn";
import {
	SuspenseLoader,
	StyledColumn,
} from "../../../../../../components/common";
import MapExecutorLocations from "../../../../../../components/MapExecutorLocations";
import KeyBind from "../../../../../../components/KeyBind";
import {
	CompanyBillMetadata,
	BaseCompanyBillMetadata,
} from "../../../../../../components/Orders";
import { OrderModalProvider } from "../../../context";
import { DateFns } from "../../../../../../utils/DateFns";
import { useArchiveOrders } from "../../../Archives/hooks";
import { Parkings, Sectors, PriceZones } from "../../../components";
import InfoModalHistory from "../../../components/InfoModal/InfoModalHistory";
import InfoModalCalls from "../../../Archives/tabs/Orders/components/ArchiveInfoModal/components/InfoModalCalls";

import { useGetSector } from "./hooks/useGetSector";
import { OrderCostRequest, useCalcOrderCost } from "./hooks/useCalcOrderCost";
import prepareValues from "./utils/prepareValues";
import { Tab } from "./types/Tab";
import OrderForm from "./OrderForm";
import FilesWidget from "./components/FilesWidget";
import MapController from "./components/MapController";
import { FooterMemo } from "./components/Footer";
import { ItemTabHeader } from "./components/ItemTabHeader";

const StyledWrap = styled.div`
	width: clamp(1302px, 80vw, 1402px);
	height: clamp(574px, 76vh, 768px);
	margin: auto;
	border-radius: 5px;
	background-color: #ffffff;
`;

const TabsWrap = styled(Tabs)`
	& > div:hover {
		color: #03a9f4;
	}
`;

const StyledWrapModal = styled(StyledColumn)`
	flex-wrap: nowrap;
	height: 100%;
	width: 100%;

	& > div:nth-child(1) {
		height: clamp(37px, 5.5vh, 47px);
		div .tabs {
			height: clamp(37px, 5.5vh, 47px);

			div.ui.attached.tabular.menu {
				padding: 5px 5px 0px 8px !important;
				height: clamp(32px, 4.3vh, 41px);

				div.tab {
					padding: 0px 14px 0px 14px !important;
					height: 100%;
				}
				div.addTabs {
					padding: 0px 0px 5px 14px !important;
					height: 100%;
					div {
						height: clamp(28px, 3.8vh, 32px);
						width: clamp(28px, 3.8vh, 32px);
						min-width: clamp(28px, 3.8vh, 32px);
					}
				}
			}
		}
	}
`;

const StyledWrapRight = styled(Column)`
	width: 100%;
	overflow: hidden;
	background: #ffffff;
`;

const StyledMapContainer = styled.div`
	position: relative;
	overflow: hidden;

	.map-tips-wrapper {
		position: absolute;
		padding: 10px 50px 5px 10px;
		z-index: 401;
		width: 100%;

		.map-tips-container {
			overflow: hidden;
			overflow-y: auto;
			max-height: calc(27.6px * 5);
			display: flex;
			flex-direction: column;
			border-radius: 3px;
			border: 1px solid #dee0e2;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);

			::-webkit-scrollbar {
				width: 5px;
			}

			.map-tip-item {
				background: rgba(255, 255, 255, 0.9);
				color: #647079;
				padding: 4px;
				cursor: pointer;

				&.active {
					background: #ebebeb;
				}

				&:hover {
					background: #ebebeb;
				}

				:not(:last-child) {
					border-bottom: 1px solid #dee0e2;
				}
			}
		}
	}
`;

type ITab = "map" | "events" | "files" | "notes" | "conversations";
const OPTIONS: Option<ITab>[] = [
	{
		key: "map",
		label: "mainPage.archives.orders.modal.tabs.map",
		value: "map",
	},
	{
		key: "events",
		label: "mainPage.archives.orders.modal.tabs.events",
		value: "events",
	},
	{
		key: "conversations",
		label: "mainPage.archives.orders.modal.tabs.conversations",
		value: "conversations",
	},
	// {
	// 	key: "files",
	// 	label: "mainPage.archives.orders.modal.tabs.files",
	// 	value: "files",
	// },
	// {
	// 	key: "notes",
	// 	label: "mainPage.archives.orders.modal.tabs.notes",
	// 	value: "notes",
	// },
];

interface BaseTip {
	visible: boolean;
	data: IOrderPoint[];
	activeIndex: number;
}

interface Props {
	close: (ids: number[]) => void;
	activePoint: number;
	setActivePoint: (index: number) => void;
	pointModal: { visible: boolean; isInsert: boolean; isEdit: boolean };
	setPointModal: (data: {
		visible: boolean;
		isInsert: boolean;
		isEdit: boolean;
	}) => void;
}

const BaseOrderModal = ({
	close,
	activePoint,
	setActivePoint,
	pointModal,
	setPointModal,
}: Props) => {
	const baseTip: BaseTip = {
		visible: false,
		data: [],
		activeIndex: -1,
	};

	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const { setActiveOrderId, setOrder } = useArchiveOrders();
	const { get: getSector } = useGetSector();
	const { get: getCalcOrderCost, getDiscountPlans } = useCalcOrderCost();
	const { getAllExecutorLocationsSubHandler } =
		useExecutorLocationsSubHandler();

	const { user } = useTypedSelector((state) => state.account);
	const modal = useTypedSelector((state) => state.ordersPageReducer.modal);
	const { mapCenter } = useTypedSelector((state) => state.ordersPageReducer);
	const btnOnMap = useTypedSelector(
		(state) => state.orders.mapFromAddModal.toggles.btnOnMap,
	);

	const mapLanguage = useTypedSelector((state) => state.session.language);
	const settings = useTypedSelector((state) => state.settings.order.general);
	const { inputRequestDelayMs } = useTypedSelector(
		(state) => state.settings.map,
	);
	const polygons = useTypedSelector((state) => state.mapReducer.polygons);
	const { rates } = useTypedSelector(
		(state) => state.preferencesReducer.rates,
	);
	const { models: classes } = useTypedSelector(
		(state) => state.preferencesReducer.carClasses.data,
	);

	const [segmentsMap, setSegmentsMap] = useState<
		Map<number, { hash: string; segments: any[] }>
	>(new Map());

	// const [formMap, setFormMap] = useState<Map<number, Schema>>(new Map());

	// const [segmentsTaximeterMap, setSegmentsTaximeterMap] = useState<
	// 	Map<number, { hash: string; segments: any[] }>
	// >(new Map());

	const [allowMapFocusing, setPermissionBounds] = useState(true);
	const [acceptTapOpenModalEvent, setTabAccept] = useState(true);
	const [allowMapCentralization, setAllowMapCentralization] = useState(false);
	const [recalculate, setRecalculate] = useState<boolean>(false);
	const [firstTabEvent, setTabEvent] = useState(false);
	const [cityTipsVisible, setCityTipsVisible] = useState(false);
	const [activeTabIndex, setActiveTabIndex] = useState(-1);
	const [tabs, setTabs] = useState<Tab[]>([]);
	const [points, setPoints] = useState<IOrderPoint[]>([]);
	const distance = 0;
	const [revealPoints, setRevealPoints] = useState<any>([]);
	const [mapRevealTipsState, setMapRevealTipsState] =
		useState<BaseTip>(baseTip);
	const [centroid, setCentroid] = useState<number[] | LatLngLiteral>(
		user?.defaultTaxiService?.settlementPoint,
	);
	const [allTips, setAllTips] = useState<IOrderPoint[]>([]);
	const [point, setPoint] = useState<{ [key: string]: any }>({
		value: "",
		address: { value: "" },
		city: user?.defaultTaxiService,
	});
	const [isLoadingBtnSubmit, setIsLoadingBtnSubmit] = useState(false);
	const [selectedRightTab, setSelectedRightTab] = useState<Key>("map");
	const [history, setHistory] = useState<History[]>([]);
	const [displayBadges, setDisplayBadges] =
		useInternal<Orders.MapFromAddModal.BtnOnMapToggles>(btnOnMap);
	const [companyBillMetadataModal, setCompanyBillMetadataModal] =
		useInternal<boolean>(false);

	const nextUID = useMemo(() => createUIDMaker(), []);
	const dateFns = useMemo(() => new DateFns(), []);

	const executorLocationsData = useMemo(() => {
		const options: ExecutorLocations.SubscribeOptions = {};

		return getAllExecutorLocationsSubHandler(options);
	}, [getAllExecutorLocationsSubHandler]);

	const executorLocationsDataModels = useMemo(() => {
		if (selectedRightTab !== "map") return [];

		return executorLocationsData;
	}, [executorLocationsData, selectedRightTab]);

	// Data from modal.data.cards
	useEffect(() => {
		if (!modal.open) return;
		if (modal.data.activeCardId) {
			console.log("[ OrderModal ]: modal.data.cards", {
				cards: modal.data.cards,
				activeCardId: modal.data.activeCardId,
			});

			const updateTabs = modal.data.cards?.map((card) =>
				createTab(card.order),
			);
			// TODO: need to rewrite it
			setTabs((prev) => {
				if (!prev.length) return updateTabs;

				const items = updateTabs.map((item) => {
					if (item.form.status !== "creating") return item;

					const exist = prev.find(
						(prevItem) => prevItem.id === item.id,
					);

					if (exist) return exist;
					return item;
				});

				return items;
			});

			const index = modal.data.cards.findIndex(
				(card) => card.id === modal.data.activeCardId,
			);

			setActiveTabIndex(index);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, modal, modal.data.cards, modal.data.activeCardId, dispatch]);

	const activeCard = useMemo(() => {
		if (modal.data.cards.length) {
			const card = modal.data.cards.find(
				(item) => item.order.id === modal.data.activeCardId,
			);

			return {
				...card,
				order: formatOrder(card?.order || {}),
			};
		}

		return null;
	}, [modal.data]);

	const saveRevealPoint = useCallback(
		(data) => {
			const revealPointsCopy = [...revealPoints];
			const revealPointIndex = revealPointsCopy.findIndex(
				(item) => item.index === data.index,
			);
			if (revealPointIndex !== -1) {
				revealPointsCopy[revealPointIndex] = data;
				setRevealPoints(revealPointsCopy);
			} else {
				setRevealPoints([...revealPointsCopy, data]);
			}

			return data;
		},
		[revealPoints],
	);

	const defaultFormValues = useCallback<() => Schema>(() => {
		const carClass = classes.find((item) => item.default);
		const emptyForm = createEmptyForm();

		if (user) {
			emptyForm.companyID = user.defaultTaxiService?.company?.id;
			emptyForm.taxiServiceId = user.defaultTaxiService?.id;

			const language = user.settings?.language || "en";

			if (carClass) {
				emptyForm.taxiClass = [
					{ name: carClass.name?.[language], value: carClass.id },
				];
			}
		}

		if (rates.length && carClass) {
			const carClassIds = emptyForm.taxiClass
				? emptyForm.taxiClass.map((item) => item.value)
				: [carClass.id];

			const defaultRate = rates.filter((rate) => {
				if (!rate?.active) return false;
				const cars = carClassIds
					.map((item) => rate.carClassIds.includes(item))
					.every((item) => item);

				const taxi = rate.taxiServiceIds.includes(
					emptyForm.taxiServiceId,
				);

				const company = rate.taxiServices.find((service) => {
					if (service.company?.id === emptyForm.companyID)
						return true;
					return false;
				});

				if (taxi && company && cars) return true;
				return false;
			});

			if (defaultRate.length) {
				const rate =
					defaultRate.at(0) ||
					rates.find((item) => item.root === true);
				const rateSettings = rate.additionalFields;
				const { autoCalculatePickupCost } = rateSettings.general;
				emptyForm.rateId = rate.id;
				emptyForm.rateIncludePickupCost = autoCalculatePickupCost;
			}
		}

		return {
			...emptyForm,
			createdAt: Date.now(),
		};
	}, [rates, classes, user]);

	const setCenter = useCallback(
		(newCenter) => {
			dispatch(orderPage.actions.setMapCenter(newCenter));
		},
		[dispatch],
	);

	const createTab = useCallback<(order?: any) => Tab>(
		(order = {}): Tab => {
			const schema = order?.id
				? createOrderFormBy(order, user?.settings?.language)
				: { status: undefined, rateId: 1 };
			const defaultValues = defaultFormValues();
			const rateId =
				schema.status === undefined || schema.status === "creating"
					? defaultValues.rateId
					: schema.rateId;

			const plans = getDiscountPlans(
				order?.cost?.settings?.customerDiscountPlans,
			);

			const form: Schema = {
				...defaultValues,
				...schema,
				additionalPlan: plans?.additionalPlan,
				mainPlan: plans?.mainPlan,
				rateId,
			};

			const routeTaximeter = formatTaximeterOrderRoute(order);

			const route = formatOrderRoute(order?.route) || null;
			const uid = nextUID();
			const data = createEmptyTab();

			const validator: any = Object.keys(form).reduce((acc, key) => {
				acc[key] = true;
				return acc;
			}, {});

			const taxiService = order?.taxiService || user?.defaultTaxiService;
			const userLanguage = user?.settings?.language || "en";
			const passenger = order?.passengers?.at(0) || [];
			if (!order?.id) setCenter(taxiService.settlementPoint);

			console.log("[OrderModal]: createOrderFormBy -> createTab:", {
				order,
				schema,
				form,
				passenger,
				userLanguage,
				uid,
				taxiService,
				route,
				routeTaximeter,
				plans,
			});

			// setFormMap((pros) => {
			// 	if (form?.id) {
			// 		console.log(
			// 			"[OrderModal]: createOrderFormBy -> setFormMap",
			// 		);
			// 		pros.set(form.id, form);
			// 	}
			// 	return pros;
			// });

			return {
				id: form.id,
				uid,
				data,
				form,
				validator,
				state: {
					customerId: passenger ? passenger?.customer?.id : null,
					defaultCity: {
						id: taxiService.id,
						value: taxiService.settlement[userLanguage],
						settlement: taxiService.settlement[userLanguage],
						coordinates: taxiService.settlementPoint,
					},
					popularAddresses: [],
					additionalNumbers: Math.max(
						1,
						form?.additionalPhones?.length || 0,
					),
					modals: {
						time: false,
						classes: false,
						services: false,
					},
					route,
					routeTaximeter: null,
				},
				setState(state) {
					setTabs((prev) => {
						// TODO: need to rewrite it
						const newTabs = [...prev];
						const index = newTabs.findIndex(
							(tab) => tab.uid === uid,
						);

						if (index !== -1) {
							newTabs[index] = {
								...newTabs[index],
								state:
									typeof state === "function"
										? state(newTabs[index].state)
										: state,
							};

							const coordinates =
								newTabs[index]?.form?.points?.at(
									-1,
								)?.coordinates;
							if (coordinates?.lat || coordinates?.lng) {
								setCenter(coordinates);
							}

							return newTabs;
						}

						return prev;
					});
				},
			};
		},

		[
			user?.settings?.language,
			user?.defaultTaxiService,
			defaultFormValues,
			getDiscountPlans,
			nextUID,
			setCenter,
		],
	);

	const orderFormTab: Tab = useMemo<Tab>(
		() => {
			const value = tabs[activeTabIndex] || createTab();

			return value;
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			// eslint-disable-next-line react-hooks/exhaustive-deps
			activeTabIndex,
			// eslint-disable-next-line react-hooks/exhaustive-deps
			tabs[activeTabIndex]?.form,
			// eslint-disable-next-line react-hooks/exhaustive-deps
			tabs[activeTabIndex]?.state,
			// eslint-disable-next-line react-hooks/exhaustive-deps
			tabs[activeTabIndex]?.validator,
		],
	);

	const isDisabled = useMemo(() => {
		const isOwn = orderFormTab?.form?.isOwn || false;

		const status = orderFormTab.form?.status === "closed";

		if (status) return true;
		if (!isOwn) {
			const hasExecutor = orderFormTab?.form?.executorSip;
			return !hasExecutor;
		}
		return Boolean(activeCard?.lockedBy);
	}, [
		orderFormTab.form?.isOwn,
		orderFormTab.form?.status,
		orderFormTab.form?.executorSip,
		activeCard?.lockedBy,
	]);

	const handleCloseActiveCard = useCallback(() => {
		setMapRevealTipsState(baseTip);
		if (activeCard.id) {
			close([activeCard.id]);
			segmentsMap.delete(activeCard.id);
			// segmentsTaximeterMap.delete(activeCard.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeCard, close]);

	const handleCloseModal = useCallback(() => {
		setMapRevealTipsState(baseTip);
		const cardIds = modal.data.cards.map((card) => card.id);
		cardIds.forEach((card) => {
			segmentsMap.delete(card.id);
			// segmentsTaximeterMap.delete(card.id);
		});
		close(cardIds);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [close, modal.data.cards]);

	const prepareFormData = useCallback(() => {
		const values = prepareValues(orderFormTab.form);
		const { id } = orderFormTab.form;
		const { customerId } = orderFormTab.state;

		const data = prepareOrderByForm(
			values,
			customerId || activeCard.order?.passengers?.[0]?.customer?.id,
		);

		return { id, params: data };
	}, [activeCard, orderFormTab]);

	const silentSubmit = useCallback(() => {
		if (!tabs.length) return;

		const createOrderOption = settings.requiredFields;
		const validator = validateOrderForm(orderFormTab.form, {
			phone: createOrderOption.phone,
			points: createOrderOption.points,
		});
		const valid = Object.values(validator).every(Boolean);

		if (valid) {
			const preparedData = prepareFormData();

			Order.update({ id: preparedData.id, ...preparedData.params }).catch(
				(error) => {
					console.error("[OrderModal]: update order error:", error);
				},
			);
		}
	}, [
		tabs.length,
		settings.requiredFields,
		orderFormTab.form,
		prepareFormData,
	]);

	// --- Auto update order ---
	const handleOrderUpdate = useCallback(async () => {
		if (activeCard?.lockedBy) return;
		if (!modal.data.activeCardId) return;

		const formOrder = orderFormTab.form;

		const { customerId } = orderFormTab.state;

		const payload: Order.Model.Modified = prepareOrderByForm(
			formOrder,
			customerId || activeCard.order?.passengers?.[0]?.customer?.id,
		);

		if (formOrder.id) {
			await Order.update(
				{ ...payload, id: formOrder.id },
				{ dispatcherModal: true },
			);
		}
	}, [
		activeCard?.lockedBy,
		activeCard.order?.passengers,
		modal.data.activeCardId,
		orderFormTab.form,
		orderFormTab.state,
	]);

	const debounceOrderUpdateField = useDebouncedCallback(
		(save = false) => {
			if (!settings.enableQuickDistribution) return;
			if (!settings.autosaveOrderSettings.active || !save) return;
			if (orderFormTab?.form.orderPointSave) return;
			handleOrderUpdate();
		},
		settings.autosaveOrderSettings.active
			? settings.autosaveOrderSettings?.delayAfterUpdateField || 0
			: 0,
	);

	const delayAfterRevealPoint = useMemo(() => {
		const delay = settings.autosaveOrderSettings?.delayAfterRevealPoint;
		if (delay) return delay + 1000;
		return 1000;
	}, [settings.autosaveOrderSettings?.delayAfterRevealPoint]);

	const debounceOrderUpdateRevealPoint = useDebouncedCallback(
		(save = false) => {
			if (!settings.enableQuickDistribution) return;
			if (!settings.autosaveOrderSettings.active || !save) return;
			if (orderFormTab?.form.orderSave) return;
			handleOrderUpdate();
		},
		delayAfterRevealPoint,
	);

	const delayAutosaveOrderSettings = useMemo(() => {
		if (settings.autosaveOrderSettings.active) {
			const delay =
				settings.autosaveOrderSettings.delayAfterSearchAddress;
			if (delay) return delay + 200;
			return 200;
		}
		return 0;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings.autosaveOrderSettings.delayAfterSearchAddress]);

	const debounceOrderUpdatePoint = useDebouncedCallback((save = false) => {
		if (!settings.enableQuickDistribution) return;
		if (!settings.autosaveOrderSettings.active || !save) return;
		if (orderFormTab?.form.orderSave) return;
		handleOrderUpdate();
	}, delayAutosaveOrderSettings);

	const onSavePoints = useCallback(() => {
		if (orderFormTab?.form?.status) {
			const options = ["closed", "creating"];
			const status = orderFormTab.form.status || "closed";
			setRecalculate(true);
			if (options.includes(status)) return null;
			debounceOrderUpdatePoint(true);
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		orderFormTab?.form?.status,
		orderFormTab?.form?.points,
		activeCard,
		modal,
	]);

	useMemo(() => {
		if (orderFormTab?.form.orderPointSave && orderFormTab?.form?.status) {
			const options = ["closed", "creating"];
			const status = orderFormTab?.form.status || "closed";
			if (options.includes(status)) return;
			debounceOrderUpdatePoint(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		orderFormTab?.form.orderPointSave,
		orderFormTab?.form.status,
		orderFormTab?.form.points,
	]);

	useMemo(() => {
		if (orderFormTab?.form.orderSave && orderFormTab?.form.status) {
			const options = ["closed", "creating"];
			const status = orderFormTab?.form.status || "closed";
			if (options.includes(status)) return;
			debounceOrderUpdateField(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderFormTab?.form.orderSave, orderFormTab?.form.status]);

	// --- end ---
	const handleSubmit = useCallback(() => {
		if (isLoadingBtnSubmit) return;

		setIsLoadingBtnSubmit(true);

		const createOrderOption = settings.requiredFields;
		const validator = validateOrderForm(orderFormTab.form, {
			phone: createOrderOption.phone,
			points: createOrderOption.points,
		});

		const valid = Object.values(validator).every(Boolean);

		if (valid) {
			const { id, params: updatableParams } = prepareFormData();

			const promise1 = new Promise<Order.Model | null>(
				async (resolve) => {
					const res = await Order.update(
						{ id, ...updatableParams },
						{ dispatcherModal: true },
					);
					if (!res) {
						console.error(
							"[OrderModal]: Order update  error:",
							res,
						);
					}
					resolve(res);
				},
			);

			const promise2 = new Promise<"close">((resolve) => {
				setTimeout(
					() => {
						resolve("close");
					},
					2_000,
					"close",
				);
			});

			Promise.race([promise1, promise2])
				.then((response: Order.Model | null | "close") => {
					if (typeof response === "string" && response === "close") {
						if (id) dispatch(closeOrderCard(id));
						return;
					}

					if (response && response?.id) {
						dispatch(
							orderPage.actions.setFocusableOrder({
								id: response.id,
								createdAt: Date.now(),
							}),
						);
						dispatch(
							orderPage.actions.setActiveOrder(
								formatOrder(response),
							),
						);

						dispatch(closeOrderCard(response.id));
						segmentsMap.delete(response.id);
						// segmentsTaximeterMap.delete(response.id);
					}
				})
				.finally(() => {
					setIsLoadingBtnSubmit(false);
				});
		} else {
			setTabs((prev) =>
				prev.map((tab, index) => {
					if (index !== activeTabIndex) return tab;
					return { ...tab, validator };
				}),
			);
			setIsLoadingBtnSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabs, activeTabIndex, prepareFormData, dispatch]);

	const handleClosePointModal = useCallback(() => {
		setPointModal({ visible: false, isInsert: false, isEdit: false });
		setTabAccept(false);
		setTabEvent(false);
		setMapRevealTipsState(baseTip);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setPointModal]);

	const handleAddTab = useCallback(() => {
		silentSubmit();
		setMapRevealTipsState(baseTip);
		dispatch(openOrderCard());
		dispatch(orderPage.actions.setBufferOpenNewTab(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, silentSubmit]);

	const handleChangeTab = useCallback(
		(index) => {
			if (index === activeTabIndex) return;
			const orderId = modal.data.cards?.[index]?.id;
			if (orderId) {
				setSegmentsMap((prev) => {
					const exist = prev.get(orderId);
					if (exist) {
						prev.set(orderId, {
							hash: "reset",
							segments: exist.segments,
						});
					}
					return prev;
				});

				// setSegmentsTaximeterMap((prev) => {
				// 	const exist = prev.get(orderId);
				// 	if (exist) {
				// 		prev.set(orderId, {
				// 			hash: "reset",
				// 			segments: exist.segments,
				// 		});
				// 	}
				// 	return prev;
				// });
			}
			silentSubmit();
			setActiveTabIndex(Number(index));
			dispatch(focusOrderCard(modal.data.cards[+index].id));
		},
		[dispatch, silentSubmit, activeTabIndex, modal.data],
	);

	const handleCloseTab = useCallback(
		(index) => {
			if (typeof index !== "number") return;
			if (modal.data.cards?.[index]?.id) {
				const orderId = modal.data.cards?.[index]?.id;
				dispatch(closeOrderCard(orderId));
				segmentsMap.delete(orderId);
				// segmentsTaximeterMap.delete(orderId);
			}
			setMapRevealTipsState(baseTip);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, modal.data.cards],
	);

	const handleCloseOrderAndTab = useCallback(
		(orderId: number) => {
			if (orderId) {
				setMapRevealTipsState(baseTip);
				close([orderId]);
				segmentsMap.delete(orderId);
				// segmentsTaximeterMap.delete(orderId);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[orderPage, closeOrderCard, dispatch, close, setMapRevealTipsState],
	);

	const handleChangePoint = useCallback(
		async (index, coordinates, response) => {
			if (activeCard?.lockedBy) return null;
			setActivePoint(index);

			if (!response?.objects?.length) return null;

			const pointOnMap = t(["point_on_map"]);

			const convertedObjects: any = uniqBy(
				response.objects.map((object) =>
					createOrderPointFromQuery(object, mapLanguage),
				),
				(item: any) => stringifyPoint(item, "full", pointOnMap),
			);

			const firstAddressIndex = convertedObjects.findIndex(
				(item) => item.type === "address",
			);
			const activeIndex =
				firstAddressIndex === -1 ? 0 : firstAddressIndex;

			const object = convertedObjects[activeIndex];
			const addSector = async () => {
				if (
					(object.sector === null && object?.coordinates) ||
					(object.sector === undefined && object?.coordinates)
				) {
					const sector = await getSector(object.coordinates);
					if (sector) return { id: sector?.id, name: sector?.name };
				}
				return null;
			};
			const sector = await addSector();
			const newOject = sector ? { ...object, sector } : object;
			const tipsObjects = convertedObjects?.map((item) => {
				if (isEqual(item.coordinates, newOject.coordinates))
					return newOject;
				return item;
			});

			saveRevealPoint({
				index,
				tips: tipsObjects,
				activeIndex,
			});
			setMapRevealTipsState({
				visible: true,
				data: tipsObjects,
				activeIndex,
			});

			setRecalculate(true);
			setTabs((tabs) => {
				const newTabs = [...tabs];
				const newPoints = [...newTabs[activeTabIndex].form.points];
				newPoints[index] = {
					...newOject,
					customStreet:
						`${newOject.streetType} ${newOject.street}`.trim(),
					customHouse: `${newOject.house}`,
					coordinates,
				};
				newTabs[activeTabIndex] = {
					...newTabs[activeTabIndex],
					form: {
						...newTabs[activeTabIndex].form,
						points: newPoints,
						orderPointSave: false,
						orderSave: false,
					},
				};

				return newTabs;
			});

			if (orderFormTab.form?.status) {
				const options = ["closed", "creating"];
				const status = orderFormTab?.form.status || "closed";
				if (options.includes(status)) return null;
				debounceOrderUpdateRevealPoint(true);
			}

			return null;
		},

		[
			activeCard?.lockedBy,
			setActivePoint,
			t,
			saveRevealPoint,
			orderFormTab.form.status,
			mapLanguage,
			getSector,
			activeTabIndex,
			debounceOrderUpdateRevealPoint,
		],
	);

	const pointsLength = useMemo(
		() => orderFormTab?.form?.points?.length || 0,
		[orderFormTab?.form?.points?.length],
	);

	const handleAddPoint = useCallback(() => {
		if (activeCard?.lockedBy) return;
		const pointOnMap = t(["point_on_map"]);

		// TODO: rewrite. is not correct
		reveal(mapCenter, {}).then(async (response) => {
			if (!response?.objects?.length) return null;

			const convertedObjects: any = uniqBy(
				response.objects.map((object) =>
					createOrderPointFromQuery(object, mapLanguage),
				),
				(item: any) => stringifyPoint(item, "full", pointOnMap),
			);

			const firstAddressIndex = convertedObjects.findIndex(
				(item) => item.type === "address",
			);
			const activeIndex =
				firstAddressIndex === -1 ? 0 : firstAddressIndex;

			const object = convertedObjects[activeIndex];

			const addSector = async () => {
				if (
					(object.sector === null && object?.coordinates) ||
					(object.sector === undefined && object?.coordinates)
				) {
					const sector = await getSector(object.coordinates);
					if (sector) return { id: sector?.id, name: sector?.name };
				}
				return null;
			};
			const sector = await addSector();
			const newOject = sector ? { ...object, sector } : object;

			const tipsObjects = convertedObjects?.map((item) => {
				if (isEqual(item.coordinates, newOject.coordinates)) {
					return newOject;
				}
				return item;
			});

			saveRevealPoint({
				index: points.length,
				tips: tipsObjects,
				activeIndex,
			});
			setMapRevealTipsState({
				visible: true,
				data: tipsObjects,
				activeIndex,
			});

			setRecalculate(true);

			const getTaximeterStatus = () => {
				if (pointsLength === 0 && settings.singlePointMetering) {
					return true;
				}

				if (pointsLength > 0 && settings.singlePointMetering) {
					return false;
				}

				if (settings.allOrdersMetering) {
					return true;
				}
				return null;
			};

			const isTaximeter = getTaximeterStatus();

			setTabs((tabs) =>
				tabs.map((tab, index) => {
					if (index !== activeTabIndex) return tab;

					return {
						...tab,
						form: {
							...tab.form,
							orderPointSave: false,
							orderSave: false,
							taximeter:
								isTaximeter !== null
									? isTaximeter
									: tab.form.taximeter,
							points: [
								...tab.form.points,
								{
									...newOject,
									customStreet:
										`${newOject.streetType} ${newOject.street}`.trim(),
									customHouse: `${newOject.house}`,
									coordinates: mapCenter,
								},
							],
						},
					};
				}),
			);
			setActivePoint(points.length);

			if (orderFormTab.form?.status) {
				const options = ["closed", "creating"];
				const status = orderFormTab.form.status || "closed";
				if (options.includes(status)) return null;
				debounceOrderUpdateRevealPoint(true);
			}

			return null;
		});
	}, [
		activeCard?.lockedBy,
		t,
		mapCenter,
		pointsLength,
		settings.singlePointMetering,
		settings.allOrdersMetering,
		saveRevealPoint,
		points.length,
		setActivePoint,
		orderFormTab.form.status,
		mapLanguage,
		getSector,
		activeTabIndex,
		debounceOrderUpdateRevealPoint,
	]);

	const selectRevealTip = useCallback(
		(object, index) => {
			setMapRevealTipsState({
				...mapRevealTipsState,
				activeIndex: index,
			});

			setRecalculate(true);
			setTabs((tabs) => {
				const newTabs = [...tabs];
				const newPoints = [...newTabs[activeTabIndex].form.points];

				newPoints[activePoint] = { ...object };
				newTabs[activeTabIndex] = {
					...newTabs[activeTabIndex],
					form: {
						...newTabs[activeTabIndex].form,
						points: newPoints,
						orderPointSave: false,
						orderSave: false,
					},
				};

				return newTabs;
			});

			if (orderFormTab.form?.status) {
				const options = ["closed", "creating"];
				const status = orderFormTab.form.status || "closed";
				if (options.includes(status)) return null;
				debounceOrderUpdateRevealPoint(true);
			}
			return null;
		},

		[
			mapRevealTipsState,
			orderFormTab.form?.status,
			activeTabIndex,
			activePoint,
			debounceOrderUpdateRevealPoint,
		],
	);

	useEffect(() => {
		const revealPointTips = revealPoints.find(
			(item) => +item.index === +activePoint,
		);

		if (revealPointTips) {
			setMapRevealTipsState({
				visible: true,
				data: revealPointTips.tips,
				activeIndex: revealPointTips.activeIndex,
			});
		} else {
			setMapRevealTipsState(baseTip);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [revealPoints, activePoint]);

	useEffect(() => {
		setCentroid(
			activeCard?.order?.taxiService?.settlementPoint ||
				user?.defaultTaxiService?.settlementPoint,
		);
	}, [activeCard, user?.defaultTaxiService, user]);

	// centroid map by point
	useEffect(() => {
		if (!orderFormTab?.state?.defaultCity?.coordinates) return;
		if (!isEqual(centroid, orderFormTab?.state?.defaultCity?.coordinates)) {
			setCentroid(
				orderFormTab?.state?.defaultCity?.coordinates ||
					user?.defaultTaxiService.settlementPoint,
			);
			setAllowMapCentralization(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderFormTab?.state?.defaultCity?.coordinates]);

	useEffect(() => {
		let cancel = false;
		const normalacyArr = [...points].filter((item) => {
			if (!item) return false;
			return true;
		});

		// TODO: Unnecessarily requests when opening an existing order
		if (
			normalacyArr.length > 1 &&
			!orderFormTab.form.processedByTaximeter
		) {
			const points = normalacyArr.map((item) => item?.coordinates);

			dispatch(
				calcRoute(points, (result) => {
					if (cancel) return;
					if (orderFormTab) {
						orderFormTab.setState((state) => ({
							...state,
							route: result,
						}));
					}
				}),
			);
		} else if (
			orderFormTab &&
			orderFormTab.state?.route !== null &&
			!orderFormTab.form.processedByTaximeter
		) {
			orderFormTab.setState((state) => ({
				...state,
				route: null,
			}));
		}

		if (normalacyArr.length >= 1) {
			// validate again if points changed
			setTabs((prev) =>
				prev.map((tab, index) => {
					if (index !== activeTabIndex) return tab;
					if (tab?.validator?.points) return tab;
					return {
						...tab,
						validator: {
							...tab.validator,
							points: true,
						},
					};
				}),
			);
		}

		return function cleanup() {
			cancel = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [points, activeTabIndex]);

	// -- end --

	useEffect(() => {
		if (acceptTapOpenModalEvent && firstTabEvent) {
			setPointModal({ visible: true, isInsert: false, isEdit: false });
		}
	}, [acceptTapOpenModalEvent, firstTabEvent, setPointModal]);

	// Retrieve a list of addresses on request
	const debounceRequestAddress = useDebouncedCallback(() => {
		const value = point.value.trim();

		if (value.length >= 3) {
			search(value, {
				country: "ua",
				// country: "md",
				searchType: [],
				near: {
					point: user?.defaultTaxiService?.settlementPoint || null,
					radius: 9000000,
				},
				lang: mapLanguage,
			}).then((response) => {
				const tips = [...response].sort(
					(x, y) => x.distanceMeters - y.distanceMeters,
				);

				if (tips.length >= 1) setAllTips(tips);
				setCityTipsVisible(true);
			});
		} else setAllTips([]);
	}, inputRequestDelayMs);

	/** Calculate order cost request */
	const debounceCalculateCost = useDebouncedCallback(async () => {
		if (recalculate) setRecalculate(false);
		if (isDisabled) return;

		const preparedData = prepareFormData();
		const form = cloneDeep(orderFormTab.form);

		const {
			source,
			orderType,
			counterparty,
			id,
			additionalCost,
			agent,
			paymentChangeType,
			discount,
			phone,
			code,
			paymentType,
			counterRideTotal,
		} = form;
		const { additionalFields } = preparedData.params;
		const { arbitraryCost } = additionalFields;

		if (form.processedByTaximeter) return;

		const orderCostRequest: OrderCostRequest = {
			source,
			paymentType,
			additionalFields: {
				rateSettings: additionalFields?.rateSettings,
				passengersCount: additionalFields?.passengersCount,
				suburbanDistanceOverride:
					additionalFields?.suburbanDistanceOverride,
				idleTimeMilliseconds: additionalFields?.idleTimeMilliseconds,
				waitingTimeMilliseconds:
					additionalFields?.waitingTimeMilliseconds,
				additionalCost: additionalCost || 0,
				taximeter: additionalFields.taximeter || false,
			},
			isPreliminary: preparedData.params.isPreliminary,
			orderDate: dateFns.formatTime(
				preparedData.params.orderDate,
				"yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
			),
			rateId: preparedData.params.rateId,
			taxiServiceId: preparedData.params.taxiServiceId,
			carClassIds: preparedData.params.carClassIds,
			serviceIds: preparedData.params.serviceIds,
			passengers: preparedData.params.passengers
				// .filter((item) => item.location && item.points.length)
				.map((passenger) => ({
					points: passenger.points.sort(
						(a, b) => a.position - b.position,
					),
					location: passenger.location,
					customerId: passenger.customer?.id,
					rideNumber:
						passenger?.customer?.counters?.total ||
						counterRideTotal ||
						1,
				})),
			form: JSON.stringify(form),
		};
		if (counterparty?.id) {
			orderCostRequest.counterpartyId = counterparty?.id;
		}
		if (counterparty?.checkCardId) {
			orderCostRequest.checkCardId = counterparty?.checkCardId;
		}
		if (id) orderCostRequest.id = id;

		if (discount && discount.active) {
			orderCostRequest.additionalFields.discount = discount;
		}

		if (agent) {
			if (agent.id) {
				orderCostRequest.agentId = agent.id;
			}
			if (agent.reward?.id) {
				orderCostRequest.agentRewardId = agent.reward?.id;
			}
		}

		if (paymentChangeType && phone && code && !counterparty?.checkCardId) {
			orderCostRequest.paymentChangeType = paymentChangeType;
		}

		console.log("[OrderModal]: Request order cost:", {
			preparedData,
			orderCostRequest,
			form: orderFormTab.form,
			processedByTaximeter: form.processedByTaximeter,
			arbitraryCost,
		});

		if (orderType === "wizard" || arbitraryCost) return;
		const costOrder = await getCalcOrderCost(orderCostRequest, {
			dispatcherModal: true,
		});
		if (costOrder) {
			const plan = getDiscountPlans(
				costOrder.settings?.customerDiscountPlans,
			);
			console.log("[ OrderModal ]: Updated order cost", {
				costOrder,
				plan,
			});

			setTabs((tabs) =>
				tabs.map((tab, index) => {
					if (index === activeTabIndex) {
						return {
							...tab,
							form: {
								...tab.form,
								price: costOrder.value,
								priceSettings: costOrder?.settings,
								discountCost: costOrder?.discount,
								distance: costOrder?.distances?.total,
								agentRewardAddToPrice: costOrder?.stages?.find(
									(item) => item?.stage === "agent_reward",
								)?.value,
								additionalPlan: plan.additionalPlan,
								mainPlan: plan.mainPlan,
								suburban: costOrder.distances?.suburban,
								companyBillMetadata:
									costOrder?.companyBillMetadata,
							},
						};
					}
					return tab;
				}),
			);
		}
	}, 500);

	// useEffect(() => {
	// 	const {
	// 		rateId,
	// 		taxiServiceId,
	// 		taxiClass = [],
	// 		services = [],
	// 		scheduledTime,
	// 		rateIncludePickupCost,
	// 		isIntercityRoadTrip,
	// 		includePickupMargin,
	// 		enableHourlyService,
	// 		passengersCount,
	// 		points = [],
	// 		suburbanDistanceOverride,
	// 		suburbanDistanceOverrideState,
	// 		suburban,
	// 		waitingTime,
	// 		idleTime,
	// 		paymentType,
	// 		mainPaymentAccount,
	// 		additionalCost,
	// 		counterparty,
	// 		agent,
	// 		discount,
	// 		processedByTaximeter,
	// 	} = orderFormTab?.form || {};

	// 	const value = `${Object.values({
	// 		processedByTaximeter: processedByTaximeter || "",
	// 		activeDiscount: discount?.active || "",
	// 		activeDiscountUsage: discount?.discountUsage?.active || "",
	// 		activeBonusUsage: discount?.bonusUsage?.active || "",
	// 		typeBonusUsage: discount?.bonusUsage?.type || "",
	// 		activeBonusUsageLimit: discount?.bonusUsage?.limit?.active || "",
	// 		maxAmountBonusUsageLimit:
	// 			discount?.bonusUsage?.limit?.maxAmount || "",
	// 		agentId: agent?.id || -1,
	// 		agentRewardId: JSON.stringify(agent?.reward || ""),
	// 		paymentType,
	// 		additionalCost,
	// 		checkCardId: counterparty?.checkCardId || -1,
	// 		waitingTime,
	// 		idleTime,
	// 		suburban,
	// 		suburbanDistanceOverride,
	// 		rateId,
	// 		taxiServiceId,
	// 		scheduledTime: scheduledTime || 0,
	// 		passengersCount,
	// 		suburbanDistanceOverrideState,
	// 		enableHourlyService,
	// 		includePickupMargin,
	// 		rateIncludePickupCost,
	// 		isIntercityRoadTrip,
	// 		taxiClassHash: taxiClass.map((item) => item?.value).join(""),
	// 		services: services.map((item) => item?.value).join(""),
	// 		mainPaymentAccount: JSON.stringify(mainPaymentAccount),
	// 		points:
	// 			points?.map((item) => JSON.stringify(item?.coordinates)) || "",
	// 	}).join("|")}`;

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [
	// 	orderFormTab?.form?.paymentType,
	// 	orderFormTab?.form?.rateId,
	// 	orderFormTab?.form?.taxiServiceId,
	// 	orderFormTab?.form?.taxiClass,
	// 	orderFormTab?.form?.services,
	// 	orderFormTab?.form?.scheduledTime,
	// 	orderFormTab?.form?.rateIncludePickupCost,
	// 	orderFormTab?.form?.includePickupMargin,
	// 	orderFormTab?.form?.enableHourlyService,
	// 	orderFormTab?.form?.suburbanDistanceOverride,
	// 	orderFormTab?.form?.suburbanDistanceOverrideState,
	// 	orderFormTab?.form?.suburban,
	// 	orderFormTab?.form?.waitingTime,
	// 	orderFormTab?.form?.idleTime,
	// 	orderFormTab?.form?.isIntercityRoadTrip,
	// 	orderFormTab?.form?.passengersCount,
	// 	orderFormTab?.form?.points,
	// 	orderFormTab?.form?.mainPaymentAccount,
	// 	orderFormTab?.form?.counterparty?.checkCardId,
	// 	orderFormTab?.form?.additionalCost,
	// 	orderFormTab?.form?.agent?.id,
	// 	orderFormTab?.form?.agent?.reward,
	// 	orderFormTab?.form?.discount?.active,
	// 	orderFormTab?.form?.discount?.discountUsage?.active,
	// 	orderFormTab?.form?.discount?.bonusUsage?.active,
	// 	orderFormTab?.form?.discount?.bonusUsage?.limit?.active,
	// 	orderFormTab?.form?.discount?.bonusUsage?.limit?.maxAmount,
	// 	orderFormTab?.form?.discount?.bonusUsage?.type,
	// 	orderFormTab?.form?.processedByTaximeter,
	// ]);

	const calculationCostTabFormHash = useMemo(() => {
		const {
			rateId,
			taxiServiceId,
			taxiClass = [],
			services = [],
			scheduledTime,
			rateIncludePickupCost,
			isIntercityRoadTrip,
			includePickupMargin,
			enableHourlyService,
			passengersCount,
			points = [],
			suburbanDistanceOverride,
			suburbanDistanceOverrideState,
			suburban,
			waitingTime,
			idleTime,
			paymentType,
			mainPaymentAccount,
			additionalCost,
			counterparty,
			agent,
			discount,
			processedByTaximeter,
		} = orderFormTab?.form || {};
		return `${Object.values({
			processedByTaximeter: processedByTaximeter || "",
			activeDiscount: discount?.active || "",
			activeDiscountUsage: discount?.discountUsage?.active || "",
			activeBonusUsage: discount?.bonusUsage?.active || "",
			typeBonusUsage: discount?.bonusUsage?.type || "",
			activeBonusUsageLimit: discount?.bonusUsage?.limit?.active || "",
			maxAmountBonusUsageLimit:
				discount?.bonusUsage?.limit?.maxAmount || "",
			agentId: agent?.id || -1,
			agentRewardId: JSON.stringify(agent?.reward || ""),
			paymentType,
			additionalCost,
			checkCardId: counterparty?.checkCardId || -1,
			waitingTime,
			idleTime,
			suburban,
			suburbanDistanceOverride,
			rateId,
			taxiServiceId,
			scheduledTime: scheduledTime || 0,
			passengersCount,
			suburbanDistanceOverrideState,
			enableHourlyService,
			includePickupMargin,
			rateIncludePickupCost,
			isIntercityRoadTrip,
			taxiClassHash: taxiClass.map((item) => item?.value).join(""),
			services: services.map((item) => item?.value).join(""),
			mainPaymentAccount: JSON.stringify(mainPaymentAccount),
			points:
				points?.map((item) => JSON.stringify(item?.coordinates)) || "",
		}).join("|")}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		orderFormTab?.form?.paymentType,
		orderFormTab?.form?.rateId,
		orderFormTab?.form?.taxiServiceId,
		orderFormTab?.form?.taxiClass,
		orderFormTab?.form?.services,
		orderFormTab?.form?.scheduledTime,
		orderFormTab?.form?.rateIncludePickupCost,
		orderFormTab?.form?.includePickupMargin,
		orderFormTab?.form?.enableHourlyService,
		orderFormTab?.form?.suburbanDistanceOverride,
		orderFormTab?.form?.suburbanDistanceOverrideState,
		orderFormTab?.form?.suburban,
		orderFormTab?.form?.waitingTime,
		orderFormTab?.form?.idleTime,
		orderFormTab?.form?.isIntercityRoadTrip,
		orderFormTab?.form?.passengersCount,
		orderFormTab?.form?.points,
		orderFormTab?.form?.mainPaymentAccount,
		orderFormTab?.form?.counterparty?.checkCardId,
		orderFormTab?.form?.additionalCost,
		orderFormTab?.form?.agent?.id,
		orderFormTab?.form?.agent?.reward,
		orderFormTab?.form?.discount?.active,
		orderFormTab?.form?.discount?.discountUsage?.active,
		orderFormTab?.form?.discount?.bonusUsage?.active,
		orderFormTab?.form?.discount?.bonusUsage?.limit?.active,
		orderFormTab?.form?.discount?.bonusUsage?.limit?.maxAmount,
		orderFormTab?.form?.discount?.bonusUsage?.type,
		orderFormTab?.form?.processedByTaximeter,
	]);

	useLayoutEffect(() => {
		if (orderFormTab?.form.processedByTaximeter) return;
		if (orderFormTab?.form?.status === "creating") {
			debounceCalculateCost();
		}

		const isSaveExistOrder =
			orderFormTab?.form?.status !== "creating" &&
			!settings.enableQuickDistribution &&
			!settings.autosaveOrderSettings.active;
		if (
			(isSaveExistOrder && orderFormTab.form.orderSave) ||
			(isSaveExistOrder && orderFormTab.form.orderPointSave) ||
			(isSaveExistOrder && recalculate)
		) {
			debounceCalculateCost();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calculationCostTabFormHash,
		orderFormTab?.form?.status,
		settings.enableQuickDistribution,
		settings.autosaveOrderSettings.active,
		recalculate,
	]);

	// ---- Calculate cost end ----

	// Update points if tab was changed
	useLayoutEffect(() => {
		const comparisonPoint = orderFormTab?.form?.points;

		if (Array.isArray(comparisonPoint)) {
			setPoints(comparisonPoint);
		}
	}, [orderFormTab?.form?.points]);

	const hashSegments = useMemo(() => {
		const orderSegments = orderFormTab?.state?.route?.segments;
		const id = orderFormTab?.form?.id;
		if (!id) return "";

		const existHas = segmentsMap.has(id);

		if (!existHas) {
			segmentsMap.set(id, { hash: "", segments: orderSegments || [] });
		}
		if (orderSegments) {
			segmentsMap.set(id, { hash: "", segments: orderSegments || [] });
		}

		const exist = segmentsMap.get(id);
		const hashData = exist?.segments?.map((item) => {
			const first = item?.at(0);
			const last = item?.at(-1);
			return JSON.stringify({ first, last });
		});

		setSegmentsMap((prev) => {
			prev.set(id, {
				hash: hashData?.flat().join("|") || "",
				segments: orderSegments || [],
			});
			return prev;
		});

		if (exist?.hash === "reset") return exist?.hash;
		return hashData?.flat().join("|") || "";
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderFormTab?.state?.route?.segments, orderFormTab?.form?.id]);

	// const hashTaximeterSegments = useMemo(() => {
	// 	const orderSegments = orderFormTab?.state?.routeTaximeter?.segments;
	// 	const id = orderFormTab?.form?.id;
	// 	if (!id) return "";

	// 	const existHas = segmentsTaximeterMap.has(id);

	// 	if (!existHas) {
	// 		segmentsTaximeterMap.set(id, {
	// 			hash: "",
	// 			segments: orderSegments || [],
	// 		});
	// 	}
	// 	if (orderSegments) {
	// 		segmentsTaximeterMap.set(id, {
	// 			hash: "",
	// 			segments: orderSegments || [],
	// 		});
	// 	}

	// 	const exist = segmentsTaximeterMap.get(id);
	// 	const hashData = exist?.segments?.map((item) => {
	// 		const first = item?.at(0);
	// 		const last = item?.at(-1);
	// 		return JSON.stringify({ first, last });
	// 	});

	// 	setSegmentsTaximeterMap((prev) => {
	// 		prev.set(id, {
	// 			hash: hashData?.flat().join("|") || "",
	// 			segments: orderSegments || [],
	// 		});
	// 		return prev;
	// 	});

	// 	if (exist?.hash === "reset") return exist?.hash;
	// 	return hashData?.flat().join("|") || "";
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [orderFormTab?.state?.routeTaximeter?.segments, orderFormTab?.form?.id]);

	const hashPoints = useMemo(() => {
		const orderPoints = orderFormTab?.form?.points;
		const normalacyArr = [...orderPoints].filter((item) => {
			if (!item) return false;
			return true;
		});

		const id = orderFormTab?.form?.id;

		const hashData = normalacyArr?.map((item) => {
			const dataHash = {
				...item?.coordinates,
				entrance: item?.entrance || "",
				name: item?.name || "",
				title: item?.title || "",
			};
			const payload = JSON.stringify(dataHash);
			return payload;
		});

		if (hashData?.length <= 1 && id) {
			segmentsMap.set(id, { hash: "", segments: [] });
		}

		return hashData?.flat().join("|") || "";
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderFormTab?.form?.points, orderFormTab?.form?.id]);

	const pointsOrder = useMemo(() => {
		const orderPoints = orderFormTab?.form?.points;
		// console.log("[Street] 24", { orderPoints });
		const normalacyArr = [...orderPoints]?.filter((item) => {
			if (!item) return false;
			return true;
		});
		// console.log("[Street] 25", { normalacyArr });
		return normalacyArr || [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hashPoints]);

	const route = useMemo(() => {
		if (!displayBadges.route) return [];
		return orderFormTab?.state?.route?.segments || [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hashSegments, displayBadges.route]);

	// const routeTaximeter = useMemo(() => {
	// 	if (!displayBadges.route) return [];
	// 	return orderFormTab?.state?.routeTaximeter?.segments || [];
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [hashTaximeterSegments, displayBadges.route]);

	const pointsToMap = useMemo(() => {
		if (!displayBadges.route) return [];
		return pointsOrder;
	}, [pointsOrder, displayBadges.route]);

	useLayoutEffect(() => {
		if (modal.data.activeCardId) {
			const exist = modal.data?.cards?.find(
				(card) => card.order?.id === modal.data.activeCardId,
			);
			setOrder(exist || null);
			setActiveOrderId(modal.data.activeCardId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modal.data, setOrder]);

	useEffect(() => {
		if (modal.data.activeCardId) {
			dispatch(
				getOrderHistory(modal.data.activeCardId, (data) => {
					setHistory(data);
				}),
			);
		}
	}, [dispatch, modal.data.activeCardId, setHistory]);

	useEffect(() => {
		if (tabs) {
			dispatch(orderPage.actions.setTabs(tabs));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabs]);

	// ----- companyBillMetadata start
	const companyBillMetadataHash = useMemo((): string => {
		const currency = orderFormTab.form?.currency?.name || "";
		const phoneType = orderFormTab.form?.phoneType || "";
		const companyBillMetadata = orderFormTab.form?.companyBillMetadata;
		const status = companyBillMetadata?.status || "";
		const orderId = companyBillMetadata?.orderId || "";
		const threshold = companyBillMetadata?.threshold || "";
		const missingAmount = companyBillMetadata?.missingAmount || "";
		const paymentAccountAmount =
			companyBillMetadata?.paymentAccountAmount || "";
		const paymentProcess = companyBillMetadata?.paymentProcess || "";
		const live = companyBillMetadata?.tabs?.live?.amount || "";
		const executable = companyBillMetadata?.tabs?.executable?.amount || "";
		const preliminary =
			companyBillMetadata?.tabs?.preliminary?.amount || "";
		const liveOrders = companyBillMetadata?.tabs?.live?.orders || "";
		const executableOrders =
			companyBillMetadata?.tabs?.executable?.orders || "";
		const preliminaryOrders =
			companyBillMetadata?.tabs?.preliminary?.orders || "";

		return Object.values({
			live,
			executable,
			preliminary,
			liveOrders,
			executableOrders,
			preliminaryOrders,
			currency,
			orderId,
			threshold,
			paymentAccountAmount,
			paymentProcess,
			status,
			missingAmount,
			phoneType,
		}).join("|");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		orderFormTab.form?.companyBillMetadata?.status,
		orderFormTab.form?.companyBillMetadata?.orderId,
		orderFormTab.form?.companyBillMetadata?.threshold,
		orderFormTab.form?.companyBillMetadata?.missingAmount,
		orderFormTab.form?.companyBillMetadata?.tabs?.live?.amount,
		orderFormTab.form?.companyBillMetadata?.tabs?.executable?.amount,
		orderFormTab.form?.companyBillMetadata?.tabs?.preliminary?.amount,
		orderFormTab.form?.companyBillMetadata?.tabs?.live?.orders,
		orderFormTab.form?.companyBillMetadata?.tabs?.executable?.orders,
		orderFormTab.form?.companyBillMetadata?.tabs?.preliminary?.orders,
		orderFormTab.form?.currency?.name,
		orderFormTab.form?.phoneType,
	]);

	const companyBillMetadata =
		useMemo((): BaseCompanyBillMetadata.Value | null => {
			const companyBillMetadata = orderFormTab.form?.companyBillMetadata;
			const status = orderFormTab.form?.companyBillMetadata?.status;
			const currency = orderFormTab.form?.currency;
			const phoneType = orderFormTab.form?.phoneType;
			if (
				companyBillMetadata &&
				status === ThresholdPaymentStatus.THRESHOLD_FAILED
			) {
				setCompanyBillMetadataModal(true);
				return {
					companyBillMetadata,
					currency,
					phoneType,
				};
			}
			setCompanyBillMetadataModal(false);
			return null;
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [companyBillMetadataHash]);

	const resolveOrderForm = useCallback(
		(data: Order.Model.OrderForm) => {
			const id = data?.orderId;
			if (id && data?.form) {
				const form =
					data?.form && typeof data?.form === "string"
						? (JSON.parse(data?.form || "") as Schema)
						: ({} as Schema);

				const defaultValues = defaultFormValues();

				console.log("[ OrderModal ] resolveOrderForm", {
					form,
					defaultValues,
					order: orderFormTab.form,
				});
				setTabs((tabs) =>
					tabs.map((tab) => {
						if (id === tab.id) {
							return {
								...tab,
								form: {
									...defaultValues,
									...form,
									clearPhone: !form.phone,
									clearCounterparty: !form.counterparty,
									clearAgent: !form.agent,
									paymentChangeType:
										!form.paymentChangeType &&
										tab.form?.counterparty &&
										!form?.counterparty &&
										!form.phone
											? "company_bill_to_cash"
											: undefined,
								},
							};
						}
						return tab;
					}),
				);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[orderFormTab.form, debounceCalculateCost],
	);

	const companyBillMetadataSave = useCallback(async () => {
		const companyBillMetadata = orderFormTab.form?.companyBillMetadata;

		if (!companyBillMetadata) setCompanyBillMetadataModal(false);

		try {
			const orderId = companyBillMetadata?.orderId;
			if (orderId) {
				const res = await Order.resolveError({
					orderId,
					type: CalculationErrorType.THRESHOLD_ERROR_YES,
				});

				const id = res?.orderId;

				if (id && res?.form) resolveOrderForm(res);
			}

			setCompanyBillMetadataModal(false);
		} catch (error) {
			console.error("[OrderModal] Error companyBillMetadataSave", error);
			setCompanyBillMetadataModal(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderFormTab.form?.companyBillMetadata]);

	const companyBillMetadataClose = useCallback(async () => {
		const companyBillMetadata = orderFormTab.form?.companyBillMetadata;

		if (!companyBillMetadata) setCompanyBillMetadataModal(false);

		const orderId = companyBillMetadata?.orderId;
		try {
			if (orderId) {
				const res = await Order.resolveError({
					orderId,
					type: CalculationErrorType.THRESHOLD_ERROR_NO,
				});

				const id = res?.orderId;
				if (id && res?.form) resolveOrderForm(res);
			}

			setCompanyBillMetadataModal(false);
		} catch (error) {
			console.error("[OrderModal] Error companyBillMetadataClose", error);
			setCompanyBillMetadataModal(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderFormTab.form?.companyBillMetadata, defaultFormValues]);
	// ----- companyBillMetadata end

	// TODO: Should be update by id
	const onOrderFormChange = useCallback(
		(form) => {
			setTabs((tabs) =>
				tabs.map((tab, index) => {
					if (index !== activeTabIndex) return tab;

					// if (tab?.form?.id) {
					// 	const exist = formMap.get(tab.form.id);

					// 	console.log(
					// 		"createOrderFormBy -> onOrderFormChange",
					// 		isEqual(exist, form),
					// 	);
					// }

					return { ...tab, form };
				}),
			);
		},
		[activeTabIndex],
	);
	const onOrderFormInsertPoint = useCallback(() => {
		setPointModal({ visible: true, isInsert: true, isEdit: false });
	}, [setPointModal]);

	const onOrderFormOpenPointModal = useCallback(() => {
		setPointModal({ visible: true, isInsert: false, isEdit: false });
	}, [setPointModal]);

	const onOrderFormEditPoint = useCallback(() => {
		setPointModal({ visible: true, isInsert: false, isEdit: true });
	}, [setPointModal]);

	// ---- Work with modal column widths
	const { widths, setWidths } = useWidths("orders.orderModal", "orderModal");

	const onColumnWidthChange = useCallback(
		(key: ColumnId<"orderModal">, width: number) => {
			if (widths) setWidths({ ...widths, [key]: width });
		},
		[widths, setWidths],
	);

	const onColumnResize = useCallback(
		(width, columnKey) => onColumnWidthChange(columnKey, width),
		[onColumnWidthChange],
	);

	const translatedDefaultColumns = useMemo<
		LegacyTableColumn<string>[]
	>(() => {
		const columns = tables.orderArchiveModal?.columns?.map((column) => ({
			label: t(column?.label),
			key: column?.id,
			resizable: true,
			width: widths[column?.id],
			accessor: column?.id,
			isChecked: true,
		}));

		return columns;
	}, [widths, t]);
	// ------

	/** Displaying calls in the order tab */
	const orderTabsData = useMemo(
		() =>
			tabs.map((tab) => {
				const newTab = cloneDeep(tab);

				const isOwn = newTab?.form?.isOwn;

				const phoneIsOwn = newTab.form?.phone;
				const phoneIsNotOwn =
					newTab.form?.transfer?.lastReceivedOrder?.phones?.sort(
						(a, b) => a.group - b.group,
					)?.[0]?.number;

				const hasExecutor = newTab.form?.transfer?.executor;

				const maskPhoneNumber = (
					phoneNumber?: string,
				): string | undefined => {
					if (
						!phoneNumber ||
						typeof phoneNumber !== "string" ||
						phoneNumber.length < 4
					) {
						return phoneNumber;
					}

					return `${phoneNumber.slice(0, -3)}***`;
				};

				const securePhoneForIsNotOwn = hasExecutor
					? phoneIsNotOwn
					: maskPhoneNumber(phoneIsNotOwn);

				const resultPhoneHeader = isOwn
					? phoneIsOwn
					: securePhoneForIsNotOwn;

				newTab.data.label = (
					<ItemTabHeader phone={resultPhoneHeader} />
				) as unknown as string;

				return newTab.data;
			}),
		[tabs],
	);
	// ------

	const orderModalSetAllowBounding = useCallback(() => {
		setPermissionBounds(false);
	}, []);

	const nextElementById = useCallback(
		(elemId: string): HTMLElement | null => {
			const elem = document.getElementById(elemId);
			if (elem) elem.focus();
			return elem;
		},
		[],
	);

	const options = useMemo(
		() => OPTIONS.map((o) => ({ ...o, label: t(o.label) })),
		[t],
	);

	const isTaximeter = useMemo(
		() => orderFormTab?.form?.processedByTaximeter,
		[orderFormTab?.form?.processedByTaximeter],
	);

	const rightTabs = useMemo(() => {
		try {
			switch (selectedRightTab) {
				// eslint-disable-next-line default-case-last
				default:
				case "map":
					return (
						<MapController
							point={point}
							disabled={isDisabled}
							activeCard={activeCard}
							setPoint={setPoint}
							setAllowMapCentralization={
								setAllowMapCentralization
							}
							handleAddPoint={handleAddPoint}
							debounceRequestAddress={debounceRequestAddress}
							selectRevealTip={selectRevealTip}
							mapRevealTipsState={mapRevealTipsState}
							cityTipsVisible={cityTipsVisible}
							allTips={allTips}
							allowMapFocusing={allowMapFocusing}
							setPermissionBounds={setPermissionBounds}
							displayBadges={displayBadges}
							setDisplayBadges={setDisplayBadges}
						>
							<OrderMap
								key={`${centroid}`}
								readOnly={isDisabled}
								geometry={point.geometry}
								searchType={point.type}
								points={pointsToMap}
								activePoint={activePoint}
								allowMapCentralization={allowMapCentralization}
								setAllowMapCentralization={
									setAllowMapCentralization
								}
								allowBound={displayBadges.allowBound}
								distance={distance}
								polygons={polygons}
								polygonsVisible={true}
								isSetBounding={allowMapFocusing}
								setAllowBounding={orderModalSetAllowBounding}
								setPoint={handleChangePoint}
								setCenter={setCenter}
								centroid={centroid}
								route={route}
								isTaximeter={isTaximeter}
								// routeTaximeter={routeTaximeter}
								cars={[]}
							>
								{displayBadges.priceZones && <PriceZones />}
								{displayBadges.sectors && <Sectors />}
								{displayBadges.parking && <Parkings />}
								{displayBadges.cars && (
									<MapExecutorLocations
										executorLocations={
											executorLocationsDataModels
										}
									/>
								)}
							</OrderMap>
						</MapController>
					);

				case "events":
					return <InfoModalHistory history={history} />;
				case "conversations":
					return <InfoModalCalls />;
				case "files":
					return <FilesWidget form={orderFormTab} validation={{}} />;
			}
		} catch {
			return <div></div>;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedRightTab,
		history,
		route,
		// routeTaximeter,
		activeCard,
		handleAddPoint,
		allowMapFocusing,
		mapRevealTipsState,
		cityTipsVisible,
		point,
		allTips,
		activePoint,
		allowMapCentralization,
		orderFormTab,
		activeTabIndex,
		polygons,
		handleChangePoint,
		centroid,
		distance,
		setActivePoint,
		selectRevealTip,
		debounceRequestAddress,
		dispatch,
		displayBadges,
		pointsToMap,
		isDisabled,
		executorLocationsDataModels,
	]);

	return (
		<Modal dimmed useFocusTrap={false}>
			<KeyBind
				keys={["Escape"]}
				onUse={() => {
					handleCloseActiveCard();
				}}
			/>
			<KeyBind settingId="callUpSoftphone" onUse={() => callUp(true)} />
			<KeyBind
				settingId="callDownSoftphone"
				onUse={() => getAndCallDown()}
			/>
			<OrderModalProvider tab={orderFormTab}>
				<StyledWrap>
					<StyledWrapModal>
						<OrderTabs.Tabs
							numberIcon={false}
							dragIcon={false}
							activeTab={activeTabIndex}
							slidersData={orderTabsData}
							onTabChange={handleChangeTab}
							onAddTabs={handleAddTab}
							onCloseTab={handleCloseTab}
						/>

						<ModalController />

						<OrderForm
							tab={orderFormTab}
							disabled={isDisabled}
							setTabAccept={() => setTabAccept(false)}
							onFormChange={onOrderFormChange}
							onOpenPointModal={onOrderFormOpenPointModal}
							onInsert={onOrderFormInsertPoint}
							onEditPoint={onOrderFormEditPoint}
							onChangeActivePoint={setActivePoint}
							tableData={pointsOrder}
							activePoint={activePoint}
							onRowClick={setActivePoint}
							onColumnResize={onColumnResize}
							tableSchema={translatedDefaultColumns}
							tableRowHeight={28}
							setPermissionBounds={setPermissionBounds}
							onSavePoints={onSavePoints}
							rightPart={
								<StyledWrapRight sizes="35px 1fr">
									<TabsWrap
										value={selectedRightTab}
										onChange={(value) => {
											setSelectedRightTab(value);
										}}
										options={options}
										variant="bookmarks"
									/>
									<StyledMapContainer>
										<Suspense fallback={<SuspenseLoader />}>
											{rightTabs}
										</Suspense>
									</StyledMapContainer>
								</StyledWrapRight>
							}
							footer={
								<FooterMemo
									locked={activeCard?.lockedBy}
									save={handleSubmit}
									cancel={handleCloseActiveCard}
									close={handleCloseModal}
									onCloseTab={handleCloseOrderAndTab}
									disabled={isDisabled}
									isLoadingBtnSubmit={isLoadingBtnSubmit}
								/>
							}
						/>
					</StyledWrapModal>
				</StyledWrap>

				{companyBillMetadataModal && (
					<CompanyBillMetadata
						value={companyBillMetadata}
						onClose={companyBillMetadataClose}
						onSave={companyBillMetadataSave}
					/>
				)}

				{pointModal.visible && (
					<PointModal
						taxiServiceId={orderFormTab.form.taxiServiceId}
						defaultCity={
							orderFormTab?.state?.defaultCity ||
							user?.defaultTaxiService
						}
						executorLocationsDataModels={
							executorLocationsData || []
						}
						customerId={orderFormTab?.state?.customerId}
						editPoint={pointModal.isEdit && points[activePoint]}
						isFirstPoint={points.length === 0}
						onClose={handleClosePointModal}
						onSubmit={(point) => {
							const activeTabData = cloneDeep(orderFormTab.form);

							if (pointModal.isEdit) {
								activeTabData.points[activePoint] = point;
								setActivePoint(activePoint);
							} else if (pointModal.isInsert) {
								const pointLength = activeTabData.points.length;
								let activeInsert = activePoint + 1;

								if (pointLength <= 1) {
									nextElementById(`2street`);
									if (pointLength === 1) activeInsert = 1;
									else activeInsert = 0;
								} else if (pointLength > 1) {
									nextElementById(`driver-node-id`);
									if (activeInsert > pointLength) {
										activeInsert = pointLength;
									}
								}

								if (activePoint < 0) activeInsert = 0;
								else if (activePoint === 1) {
									nextElementById(`driver-node-id`);
								}

								if (pointLength === activeInsert) {
									activeTabData.points.splice(
										activeInsert,
										0,
										point,
									);
									if (activeInsert === 0) setActivePoint(1);
									else setActivePoint(activeInsert);
								} else {
									activeTabData.points.splice(
										activeInsert,
										0,
										point,
									);

									setActivePoint(activeInsert);
								}
							} else {
								activeTabData.points.push(point);
								const activeAdd = activeTabData.points.length;
								setActivePoint(activeAdd);
								if (activeAdd === 1) nextElementById(`2street`);
								if (activeAdd > 1) {
									setActivePoint(activeAdd - 1);
									nextElementById(`driver-node-id`);
								}
							}

							setTabs((tabs) =>
								tabs.map((tab, index) => {
									if (index === activeTabIndex) {
										return { ...tab, form: activeTabData };
									}
									return tab;
								}),
							);

							setMapRevealTipsState({
								visible: false,
								data: [],
								activeIndex: -1,
							});

							setPermissionBounds(true);
							onSavePoints();
						}}
					/>
				)}
			</OrderModalProvider>
		</Modal>
	);
};

export default memo(BaseOrderModal);
