import createLogger from "../../utils/logger.util";
import { Base } from "..";

import { PaymentProviderAccessorType } from "./types";

const logger = createLogger({ name: "Payment" });

class Payment extends Base {
	public static async updateBalance(data: Payment.UpdateBalance) {
		try {
			logger.info("[Payment] update balance ", data);
			const res = await this.request(
				(prpc) => prpc.theirsModel.payment.updateBalance(data),
				{ silent: false, error: true },
			);
			if (res?.error) {
				logger.error("[Payment] update balance error", { res });
				return true;
			}
			return true;
		} catch (err) {
			logger.error("[Payment] update balance error", err);
			return false;
		}
	}
}

declare namespace Payment {
	interface UpdateBalance {
		amount: number;
		description: string;
		paymentAccountId: number;
		providerAccessor: {
			id: number;
			type: PaymentProviderAccessorType;
		};
	}
}

export default Payment;
