/* eslint-disable consistent-return */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	Dispatch,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column, MultiSelect, theme } from "uikit";
import styled from "styled-components";
import { assign, clone } from "lodash";

import Language from "../../../../../../../../../services/Language";
import { ExecutorFilter } from "../../../../../../../../../redux/reducers/OrdersPage/Filters";
import {
	ordersAction as orders,
	Orders,
} from "../../../../../../../../../redux/reducers/Orders";
import useCarBrandSubscription from "../../../../../../../../../hooks/useCarBrandSubscription";
import useCompanyAndTaxiServiceIdsFilter from "../../../../../../../../../hooks/useCompanyAndTaxiServiceIdsFilter";
import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../FieldsContainer";
import LabeledField from "../../../../../../../../LabeledField";
import {
	MultiSelectExecutorGroupWithModal,
	MultiSelectCarParkWithModal,
	StyledColumn,
	StyledRow,
} from "../../../../../../../../common";

const CheckboxText = styled("span")`
	font-size: 14px;
	color: ${theme.colors.text_placeholder};
	cursor: default;
	line-height: 20px;
`;

const Content: React.FC<Content.PropsBase> = ({
	language,
	onChangeRequestData,
	showCompany,
	setShowCompany,
	showTaxiService,
	setShowTaxiService,
	companyAndTaxiServiceIds,
	setCompanyAndTaxiServiceIds,
	executorsStatuses,
	setExecutorsStatuses,
	onlineStatus,
	setOnlineStatus,
	workingStatus,
	setWorkingStatus,
	isYourOrderStatus,
	setIsYourOrderStatus,
	error,
	onChangeError,
	fleetIds,
	setFleetIds,
	executorGroupIds,
	setExecutorGroupIds,
	setShowModalPropsForExecutorGroup,
	setShowModalPropsForCarPark,
}) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(
		companyAndTaxiServiceIds,
		setCompanyAndTaxiServiceIds,
	);
	const companyAndTaxiServiceValue = valueEditor.usePicker([
		"companyIds",
		"taxiServiceIds",
	]);

	const currentValueRef = useRef(companyAndTaxiServiceValue);

	currentValueRef.current = companyAndTaxiServiceValue;

	const accessOnChange = useCallback(
		(accessValue: Filter.Value) => {
			const assignValue: Partial<Filter.Value> = {
				...accessValue,
			};

			valueEditor.assign(assignValue);
		},
		[valueEditor],
	);

	const setField = useCallback(
		<Field extends keyof Filter.Value>(
			field: Field,
			fieldValue: Filter.Value[Field],
		) => {
			currentValueRef.current = assign(clone(currentValueRef.current), {
				[field]: fieldValue,
			});

			accessOnChange(currentValueRef.current);
		},
		[accessOnChange],
	);

	const onChangeTaxiServiceIds = useCallback(
		(taxiServiceIds: number[] | ["all"]) => {
			setField("taxiServiceIds", taxiServiceIds);
		},
		[setField],
	);

	const onChangeCompanyIds = useCallback(
		(companyIds: number[] | ["all"]) => {
			setField("companyIds", companyIds);
		},
		[setField],
	);

	const [
		possibleCompanies,
		possibleTaxiServices,
		companyIds,
		taxiServiceIds,
		setCompanyIds,
		setTaxiServiceIds,
	] = useCompanyAndTaxiServiceIdsFilter(
		companyAndTaxiServiceValue.companyIds,
		companyAndTaxiServiceValue.taxiServiceIds,
		onChangeCompanyIds,
		onChangeTaxiServiceIds,
	);

	companyIds;
	taxiServiceIds;

	const possibleCompanyOptions = useMemo(
		() =>
			possibleCompanies.map((company) => ({
				key: company.id,
				label: company.name?.[language] ?? "",
				value: company.id,
			})),
		[language, possibleCompanies],
	);

	const companyIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setCompanyIds(newCompanyIds as number[]);
			onChangeError((prev) => ({ ...prev, company: false }));
		},
		[onChangeError, setCompanyIds],
	);

	const possibleTaxiServiceOptions = useMemo(
		() =>
			possibleTaxiServices.map((taxiService) => ({
				key: taxiService.id,
				label: `${taxiService?.settlement[language]} (${taxiService.company?.name[language]}) `,
				value: taxiService.id,
			})),
		[language, possibleTaxiServices],
	);

	const taxiServiceIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setTaxiServiceIds(newCompanyIds as number[]);
			onChangeError((prev) => ({ ...prev, taxiService: false }));
		},
		[onChangeError, setTaxiServiceIds],
	);

	const onChageStatusesCheckbox = useCallback(
		(value: boolean, status: Content.ExecutorStatus) => {
			if (value && !executorsStatuses?.includes(status)) {
				setExecutorsStatuses([...executorsStatuses, status]);
			} else if (!value && executorsStatuses?.includes(status)) {
				setExecutorsStatuses([
					...executorsStatuses.filter((el) => el !== status),
				]);
			}
		},
		[executorsStatuses, setExecutorsStatuses],
	);

	const isAvailable = useMemo(
		() => executorsStatuses?.includes("available"),
		[executorsStatuses],
	);
	const isBusy = useMemo(
		() => executorsStatuses?.includes("busy"),
		[executorsStatuses],
	);
	const isDinner = useMemo(
		() => executorsStatuses?.includes("dinner"),
		[executorsStatuses],
	);
	const isHome = useMemo(
		() => executorsStatuses?.includes("home"),
		[executorsStatuses],
	);
	const isOnOrder = useMemo(
		() => executorsStatuses?.includes("on_order"),
		[executorsStatuses],
	);
	const isClosed = useMemo(
		() => executorsStatuses?.includes("closed"),
		[executorsStatuses],
	);

	const openCompany = useCallback(() => {
		setShowCompany(true);
	}, [setShowCompany]);

	const closeCompany = useCallback(() => {
		setShowCompany(false);
	}, [setShowCompany]);

	const openTaxiService = useCallback(() => {
		setShowTaxiService(true);
	}, [setShowTaxiService]);

	const closeTaxiService = useCallback(() => {
		setShowTaxiService(false);
	}, [setShowTaxiService]);

	const onFocusCompany = useCallback(() => {
		openCompany();
		closeTaxiService();
	}, [closeTaxiService, openCompany]);

	const onFocusTaxiService = useCallback(() => {
		openTaxiService();
		closeCompany();
	}, [closeCompany, openTaxiService]);

	const closeCompanyAndTaxiService = useCallback(() => {
		closeCompany();
		closeTaxiService();
	}, [closeCompany, closeTaxiService]);

	const onChageOnlineCheckbox = useCallback(
		(value: boolean, status: Content.OnlineStatus) => {
			if (value && !onlineStatus?.includes(status)) {
				setOnlineStatus([...onlineStatus, status]);
			} else if (!value && onlineStatus?.includes(status)) {
				setOnlineStatus([
					...onlineStatus.filter((el) => el !== status),
				]);
			}
		},
		[onlineStatus, setOnlineStatus],
	);

	const isOnline = useMemo(
		() => onlineStatus.includes("online"),
		[onlineStatus],
	);
	const isOffline = useMemo(
		() => onlineStatus.includes("offline"),
		[onlineStatus],
	);

	const onChangeOnline = useCallback(
		(value) => {
			onChageOnlineCheckbox(value, "online");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageOnlineCheckbox],
	);
	const onChangeOffline = useCallback(
		(value) => {
			onChageOnlineCheckbox(value, "offline");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageOnlineCheckbox],
	);

	const onChageWorkingStatusCheckbox = useCallback(
		(value: boolean, status: Content.WorkingStatus) => {
			if (value && !workingStatus?.includes(status)) {
				setWorkingStatus([...workingStatus, status]);
			} else if (!value && workingStatus?.includes(status)) {
				setWorkingStatus([
					...workingStatus.filter((el) => el !== status),
				]);
			}
		},
		[setWorkingStatus, workingStatus],
	);

	const isWorkingStatus = useMemo(
		() => workingStatus.includes("working"),
		[workingStatus],
	);
	const isNotWorkingStatus = useMemo(
		() => workingStatus.includes("notWorking"),
		[workingStatus],
	);

	const onChangeWorking = useCallback(
		(value) => {
			onChageWorkingStatusCheckbox(value, "working");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageWorkingStatusCheckbox],
	);
	const onChangeNotWorking = useCallback(
		(value) => {
			onChageWorkingStatusCheckbox(value, "notWorking");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageWorkingStatusCheckbox],
	);

	const onChangeAvailable = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "available");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageStatusesCheckbox],
	);
	const onChangeBusy = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "busy");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageStatusesCheckbox],
	);
	const onChangeDinner = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "dinner");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageStatusesCheckbox],
	);
	const onChangeHome = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "home");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageStatusesCheckbox],
	);
	const onChangeOnOrder = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "on_order");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageStatusesCheckbox],
	);
	const onChangeClosed = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "closed");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageStatusesCheckbox],
	);

	const onChangeIsYourOrderStatus = useCallback(
		(value) => {
			setIsYourOrderStatus(value);
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, setIsYourOrderStatus],
	);

	useEffect(() => {
		onChangeRequestData({
			statuses: executorsStatuses as string[],
			taxiServiceIds,
			companyIds,
			online: onlineStatus,
			workingStatus,
			isYourOrderStatus,
			fleetIds,
			executorGroupIds,
		});
	}, [
		companyIds,
		executorsStatuses,
		onChangeRequestData,
		taxiServiceIds,
		isYourOrderStatus,
		onlineStatus,
		workingStatus,
		fleetIds,
		executorGroupIds,
	]);

	useEffect(() => {
		if (possibleCompanyOptions.length === 1)
			setCompanyIds([possibleCompanyOptions[0].key]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [possibleCompanyOptions]);
	useEffect(() => {
		if (possibleTaxiServiceOptions.length === 1)
			setTaxiServiceIds([possibleTaxiServiceOptions[0].key]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [possibleTaxiServiceOptions]);

	return (
		<StyledRow gap="12px">
			<StyledColumn gap="12px" w="100%">
				<LabeledField
					label={
						t(
							"orderPageWidgets.executors.filter.popover.content.str0",
						) ?? ""
					}
				>
					<MultiSelect
						value={companyIds}
						disabled={possibleCompanyOptions.length === 1}
						all={possibleCompanyOptions.length > 1}
						// open={showCompany}
						// focused={showCompany}
						// onFocus={onFocusCompany}
						// onBlur={closeCompany}
						placeholder={
							t(
								"orderPageWidgets.executors.filter.popover.content.str1",
							) ?? ""
						}
						error={error.company}
						options={possibleCompanyOptions}
						onChange={companyIdsOnChange}
					/>
				</LabeledField>
				<LabeledField
					label={
						t(
							"orderPageWidgets.executors.filter.popover.content.str2",
						) ?? ""
					}
				>
					<MultiSelect
						value={taxiServiceIds}
						disabled={possibleTaxiServiceOptions.length <= 1}
						all={possibleTaxiServiceOptions.length > 1}
						// open={showTaxiService}
						// focused={showTaxiService}
						// onFocus={onFocusTaxiService}
						// onBlur={closeTaxiService}
						placeholder={
							t(
								"orderPageWidgets.executors.filter.popover.content.str3",
							) ?? ""
						}
						error={error.taxiService}
						options={possibleTaxiServiceOptions}
						onChange={taxiServiceIdsOnChange}
					/>
				</LabeledField>

				<LabeledField
					label={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str201",
						) ?? ""
					}
				>
					<MultiSelectExecutorGroupWithModal
						value={executorGroupIds || []}
						onChange={setExecutorGroupIds}
						// value={[]}
						// onChange={() => {}}
						titleText={
							t(
								"orderPageWidgets.chat.executorsFilterPanel.popover.str201",
							) ?? ""
						}
						modalSetting={{
							zIndex: 999,
							containerId: "root",
						}}
						setShowModalProps={setShowModalPropsForExecutorGroup}
					/>
				</LabeledField>

				<LabeledField
					label={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str202",
						) ?? ""
					}
				>
					<MultiSelectCarParkWithModal
						value={fleetIds || []}
						onChange={setFleetIds}
						// value={[]}
						// onChange={() => {}}
						titleText={
							t(
								"orderPageWidgets.chat.executorsFilterPanel.popover.str202",
							) ?? ""
						}
						modalSetting={{
							zIndex: 999,
							containerId: "root",
						}}
						setShowModalProps={setShowModalPropsForCarPark}
					/>
				</LabeledField>
			</StyledColumn>
			<StyledColumn onClick={closeCompanyAndTaxiService} w="100%">
				<FieldsContainer
					label={
						t(
							"orderPageWidgets.executors.filter.popover.content.str4",
						) ?? ""
					}
					labelColor={theme.colors.text_placeholder}
				>
					<StyledColumn gap="12px">
						<StyledRow gap="10px">
							<CheckBox
								onChange={onChangeOnline}
								value={isOnline}
							/>
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str100",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
						<StyledRow gap="10px">
							<CheckBox
								onChange={onChangeOffline}
								value={isOffline}
							/>
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str101",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
						<StyledRow gap="10px">
							<CheckBox
								onChange={onChangeAvailable}
								value={isAvailable}
							/>
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str102",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
						<StyledRow gap="10px">
							<CheckBox onChange={onChangeBusy} value={isBusy} />
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str103",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
						<StyledRow gap="10px">
							<CheckBox
								onChange={onChangeDinner}
								value={isDinner}
							/>
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str104",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
						<StyledRow gap="10px">
							<CheckBox onChange={onChangeHome} value={isHome} />
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str105",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
						<StyledRow gap="10px">
							<CheckBox
								onChange={onChangeOnOrder}
								value={isOnOrder}
							/>
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str106",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
						<StyledRow gap="10px">
							<CheckBox
								onChange={onChangeClosed}
								value={isClosed}
							/>
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str107",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
						<StyledRow gap="10px">
							<CheckBox
								onChange={onChangeIsYourOrderStatus}
								value={isYourOrderStatus}
							/>
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str108",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
					</StyledColumn>
				</FieldsContainer>
			</StyledColumn>
			<StyledColumn onClick={closeCompanyAndTaxiService} w={"100%"}>
				<FieldsContainer
					label={
						t(
							"orderPageWidgets.executors.filter.popover.content.str5",
						) ?? ""
					}
					labelColor={theme.colors.text_placeholder}
					maxedHeight
				>
					<StyledColumn gap="12px">
						<StyledRow gap="10px">
							<CheckBox
								onChange={onChangeWorking}
								value={isWorkingStatus}
							/>
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str109",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
						<StyledRow gap="10px">
							<CheckBox
								onChange={onChangeNotWorking}
								value={isNotWorkingStatus}
							/>
							<CheckboxText>
								{t(
									"orderPageWidgets.executors.filter.popover.content.str110",
								) ?? ""}
							</CheckboxText>
						</StyledRow>
					</StyledColumn>
				</FieldsContainer>
			</StyledColumn>
		</StyledRow>
	);
};

declare namespace Content {
	interface Value extends Pick<useCarBrandSubscription.Filter, "activity"> {}

	export interface PropsBase {
		language: Language;
		requestData: Orders.Executors.ExecuterFilter;
		onChangeRequestData: React.Dispatch<
			React.SetStateAction<Orders.Executors.ExecuterFilter>
		>;
		showCompany: boolean;
		setShowCompany: React.Dispatch<React.SetStateAction<boolean>>;
		showTaxiService: boolean;
		setShowTaxiService: React.Dispatch<React.SetStateAction<boolean>>;
		companyAndTaxiServiceIds: Filter.Value;
		setCompanyAndTaxiServiceIds: React.Dispatch<
			React.SetStateAction<Filter.Value>
		>;
		executorsStatuses: Content.ExecutorStatus[];
		setExecutorsStatuses: React.Dispatch<
			React.SetStateAction<Content.ExecutorStatus[]>
		>;
		onlineStatus: ("online" | "offline")[];
		setOnlineStatus: React.Dispatch<
			React.SetStateAction<("online" | "offline")[]>
		>;
		workingStatus: ("working" | "notWorking")[];
		setWorkingStatus: React.Dispatch<
			React.SetStateAction<("working" | "notWorking")[]>
		>;
		isYourOrderStatus: boolean;
		setIsYourOrderStatus: React.Dispatch<React.SetStateAction<boolean>>;
		error: {
			taxiService: boolean;
			company: boolean;
		};
		onChangeError: React.Dispatch<
			React.SetStateAction<{
				taxiService: boolean;
				company: boolean;
			}>
		>;

		fleetIds: number[];
		setFleetIds: Dispatch<number[]>;
		executorGroupIds: number[];
		setExecutorGroupIds: Dispatch<number[]>;

		setShowModalPropsForExecutorGroup: (value: boolean) => void;
		setShowModalPropsForCarPark: (value: boolean) => void;
	}

	type ExecutorStatus =
		| "available"
		| "busy"
		| "dinner"
		| "home"
		| "on_order"
		| "closed";

	type OnlineStatus = "online" | "offline";
	type WorkingStatus = "working" | "notWorking";
}

declare namespace Filter {
	interface Value {
		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];
	}
}

export default Content;
