import React, {
	PropsWithChildren,
	createContext,
	memo,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";

import { Executor, ExecutorGroup, ExecutorApp } from "../../../../../services";
import { useTypedSelector } from "../../../../../redux/store";
import { useModelSubscribe, useObjectEditor } from "../../../../../hooks";
import { ValueLanguage } from "../../../../../assets/languages/langs";
import { StyledRow } from "../../../../../components/common";
import {
	UseTableOptions,
	useTableOptions,
} from "../../../../../components/LightTable";

import defaultExecutorTeam from "./defaultValue";

export const ExecutorTeamsContext =
	createContext<ExecutorTeamsProvider.Context | null>(null);

export const useExecutorTeamsContext = (): ExecutorTeamsProvider.Context => {
	const store = useContext<ExecutorTeamsProvider.Context | null>(
		ExecutorTeamsContext,
	);
	if (!store) {
		throw new Error("Missing ExecutorTeamsContext.Provider in the tree");
	}
	return store;
};
/** Modal should be additionally wrapped with this provider and data should be routed
 * through `internalValue` and `setInternalValue` */
export const ExecutorTeamsProvider: React.FC<ExecutorTeamsProvider.Props> = ({
	children,
	id = "executor_times_provider_id",
	internalValue,
	setInternalValue,
}): JSX.Element => {
	const {
		lang: language,
		editor: editorTable,
		onChange: onChangeTable,
	} = useTableOptions();

	const executors = useTypedSelector(
		(state) => state.globalState.executors.data.subscription.basic,
	);

	const [value, setValue] =
		useState<ExecutorTeamsProvider.Value>(defaultExecutorTeam);

	const subscribeExecutorApp = useModelSubscribe(
		{
			isExecutorGroup: true,
			active: true,
		},
		ExecutorApp,
	);

	const executorsApps = useMemo<ExecutorApp.Model[]>(
		() => subscribeExecutorApp.models ?? [],
		[subscribeExecutorApp.models],
	);

	const getExecutorIds = useCallback<
		ExecutorTeamsProvider.Context["getExecutorIds"]
	>(
		(id: number) => {
			const ids = executors
				.filter((item) => {
					if (!id) return false;
					if (!item.group?.id) return false;
					return item.group.id === id;
				})
				.map((executor) => executor.id);
			return ids;
		},
		[executors],
	);

	const nextElementById = useCallback<
		ExecutorTeamsProvider.Context["nextElementById"]
	>((elemId: string) => {
		const elem = document.getElementById(elemId);
		if (elem) {
			elem.focus();
			if (elem?.nodeName === "INPUT") {
				const input = elem as HTMLInputElement;
				input?.select();
			}
		}
		return elem;
	}, []);

	const store = useCallback<ExecutorTeamsProvider.Context["store"]>(
		async (data) => {
			const res = await ExecutorGroup.store(data);
			return res;
		},
		[],
	);

	const update = useCallback<ExecutorTeamsProvider.Context["update"]>(
		async (data) => {
			const res = await ExecutorGroup.update(data);
			return res;
		},
		[],
	);

	const destroy = useCallback<ExecutorTeamsProvider.Context["destroy"]>(
		async (ids) => {
			const res = await ExecutorGroup.destroy(ids);
			return res;
		},
		[],
	);

	const valueEditor = useObjectEditor(
		internalValue ?? value,
		setInternalValue ?? setValue,
	);

	return (
		<ExecutorTeamsContext.Provider
			value={useMemo(
				() => ({
					value,
					setValue,

					executors,
					executorsApps,
					language,
					getExecutorIds,
					nextElementById,
					update,
					destroy,
					store,
					onChangeTable,
					editorTable,
				}),
				[
					value,

					executors,
					executorsApps,
					language,
					getExecutorIds,
					nextElementById,
					update,
					destroy,
					store,
					onChangeTable,
					editorTable,
				],
			)}
		>
			<StyledRow position="relative" w="100%" h="100%" id={id}>
				{children}
			</StyledRow>
		</ExecutorTeamsContext.Provider>
	);
};

declare namespace ExecutorTeamsProvider {
	type Value = Omit<ExecutorGroup.Model, "id"> & { id?: number };
	export interface Props extends PropsWithChildren {
		id?: string;
		internalValue?: Value;
		setInternalValue?: React.Dispatch<React.SetStateAction<Value>>;
	}

	interface Context {
		value: Value;
		setValue: React.Dispatch<React.SetStateAction<Value>>;
		update: (data: ExecutorGroup.Model.Modified) => Promise<any | null>;
		destroy: (ids: number[]) => Promise<any | null>;
		store: (data: ExecutorGroup.Model.New) => Promise<any | null>;
		nextElementById: (elemId: string) => HTMLElement | null;
		getExecutorIds: (id: number) => number[];
		language: ValueLanguage;
		executors: Executor.Model[];
		executorsApps: ExecutorApp.Model[];
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}

	namespace Context {}
}

export const ExecutorTeamsProviderMemo = memo(ExecutorTeamsProvider);
