import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Preferences } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	models: [],
};

const setModels: ReduxReducer<State, State["models"]> = (
	state,
	{ payload },
) => {
	state.models = payload;
};

const data = createSlice({
	name: Preferences.ConstantPreferencesSlice.SERVICES_DATA,
	initialState,
	reducers: {
		setModels,
	},
});

export const dataAction = data.actions;
export const dataReducer = data.reducer;
export type dataState = State;

export default data;
