import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { DATE, ACTIVE } = BASE_WIDTH_COLUMNS;

const accountsRole = makeTable({
	allIds: ["active", "name", "assigns", "description", "createdAt"],
	i18tPath: (id) => `mainPage.accounts.roles.columns.${id}`,
	defaultIds: ["active", "name", "assigns", "description", "createdAt"],
	initialSortMethod: {
		column: "assigns",
		type: "asc",
	},
	initialWidths: {
		active: ACTIVE,
		name: 200,
		assigns: 130,
		description: 390,
		createdAt: DATE,
	},
	initialResizable: {
		active: true,
		name: true,
		assigns: true,
		description: true,
		createdAt: true,
	},
	initialSortable: {
		active: true,
		name: true,
		assigns: true,
		description: true,
		createdAt: true,
	},
});

export default accountsRole;
