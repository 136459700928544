import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (
	item: CashlessCountCellContent.Props["item"],
) => {
	if (!isNumber(item?.ordersCounters?.cashless?.count)) return "";
	return item.ordersCounters.cashless.count;
};

const CashlessCountCellContent: React.FC<CashlessCountCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace CashlessCountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default CashlessCountCellContent;
