/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { Role } from "../../../../../services";
import { ReduxReducer } from "../../../../interface";
import { GlobalState } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	subscription: {
		basic: [],
	},
};

const setSubscriptionBasicData: ReduxReducer<State, Role.Model[]> = (
	state,
	action,
) => {
	state.subscription.basic = action.payload;
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const globalStateRoleData = createSlice({
	name: GlobalState.ConstantGlobalStateSlice.ROLE_DATA,
	initialState,
	reducers: {
		reset,
		setSubscriptionBasicData,
	},
});

export const globalStateRoleDataAction = globalStateRoleData.actions;
export const globalStateRoleDataReducer = globalStateRoleData.reducer;
export type globalStateRoleDataState = State;

export default globalStateRoleData;
