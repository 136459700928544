import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (item: OverallCountCellContent.Props["item"]) => {
	if (!isNumber(item?.ordersCounters?.overall?.count)) return "";
	return item.ordersCounters.overall.count;
};

const OverallCountCellContent: React.FC<OverallCountCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace OverallCountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default OverallCountCellContent;
