import { useMemo } from "react";
import { isString } from "lodash";

import CarBrand from "../services/CarBrand";
import TableSortMethod from "../types/SortMethod";

import useModelSubscribe from "./useModelSubscribe2";

declare namespace useCarBrandSubscription {
	type Display = "all" | "active" | "inactive";
	type Native = "all" | "native" | "custom";
	interface Filter {
		query: string;
		native: Native;
		activity: Display;
	}

	type SortMethod = TableSortMethod<keyof Pick<CarBrand.Model, "name">>;

	interface Props {
		offset: number;
		chunkSize: number;
		sort: SortMethod;
		filter: useCarBrandSubscription.Filter;
	}
}

function useCarBrandSubscription({
	offset,
	chunkSize,
	sort,
	filter,
}: useCarBrandSubscription.Props) {
	const subscribeOptions = useMemo<CarBrand.SubscribeOptions>(
		() => ({
			order:
				isString(sort.column) && isString(sort.type)
					? {
							[sort.column]: sort.type === "asc" ? "ASC" : "DESC",
					  }
					: undefined,

			offset,
			limit: chunkSize,

			query: filter.query,
			active:
				filter.activity === "all"
					? undefined
					: filter.activity === "active",
			native:
				filter.native === "all"
					? undefined
					: filter.native === "native",
		}),
		[
			sort.column,
			sort.type,
			offset,
			chunkSize,
			filter.query,
			filter.activity,
			filter.native,
		],
	);

	const subscription = useModelSubscribe(subscribeOptions, CarBrand, {
		debounce: 300,
	});

	const brands = useMemo(
		() => subscription.models.sort((a, b) => a.name.localeCompare(b.name)),
		[subscription.models],
	);
	const total = useMemo(
		() => (subscription.metadataState.total || 0) as number,
		[subscription.metadataState.total],
	);

	return {
		offset,
		subscribeOptions,
		subscription,

		brands,
		total,
	};
}

export default useCarBrandSubscription;
