import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (
	item: CashlessAmountCellContent.Props["item"],
) => {
	if (!isNumber(item?.ordersCounters?.cashless?.amount)) return "";
	return formatNumber(item?.ordersCounters?.cashless?.amount);
};

const CashlessAmountCellContent: React.FC<CashlessAmountCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace CashlessAmountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default CashlessAmountCellContent;
