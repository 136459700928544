import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
	StyledGrid,
	StyledRow,
	Colors,
} from "../../../../../../../../components/common";
import Style, {
	StyledGridItem,
} from "../../../../../../../../components/common/styles";
import { OrderFormProps } from "../../types/FormProps";
import { Primary, Gray } from "../styled";

import OutOfTown from "./components/OutOfTown";
import AdditionalCost from "./components/AdditionalCost";
import OrderHourly from "./components/OrderHourly";
import OrderPrice from "./components/Price";
import OrderDate from "./components/OrderDate";
import ArrivalTime from "./components/ArrivalTime";
import ExecutionTime from "./components/ExecutionTime";
import TimeWaitingAndIdle from "./components/TimeWaitingAndIdle";

const BaseSummary: React.FC<BaseSummary.Props> = ({
	tab,
	form,
	disabled,
	vertical,
}) => {
	const { t } = useTranslation();
	const styles = useMemo<Style.GridType>(() => {
		if (vertical) {
			return {
				areas: `'created'
						'arrival_time'
						'execution'
						'route_length'
						'additionalCost'
						'idleTime'
						'waitingTime'
						'hourly'
						'out_of_town'
						'price'
						`,
				columns: "repeat(1, 1fr)",
				rows: "repeat(9, 24px)",
			};
		}
		return {
			areas: `'created arrival_time arrival_time execution'
					'route_length out_of_town waitingTime idleTime'
					'price price additional_cost hourly'`,
			columns: "repeat(4, 1fr)",
			rows: "repeat(3, 20px)",
		};
	}, [vertical]);

	const nextElementById = useCallback(
		(elemId: string): HTMLElement | null => {
			const elem = document.getElementById(elemId);
			if (elem) {
				elem.focus();
				if (elem?.nodeName === "INPUT") {
					const input = elem as HTMLInputElement;
					input?.select();
				}
			}
			return elem;
		},
		[],
	);

	const distance = useMemo(() => {
		const value = tab.form?.distance || tab.state?.route?.distance || 0;
		return Number((value / 1000).toFixed(3));
	}, [tab.form?.distance, tab.state.route]);

	const setEditableParam = useCallback(
		(param: typeof tab.state.modals.editableParam) => {
			tab.setState((prev) => ({
				...prev,
				modals: {
					...prev.modals,
					editableParam: disabled ? null : param,
				},
			}));
			if (!param) {
				form.setValue("orderSave", true);
				form.setValue("orderPointSave", false);
				nextElementById("driver-node-id");
			}
		},
		[disabled, form, nextElementById, tab],
	);

	return (
		<StyledGrid
			{...styles}
			gap=".2rem 1rem"
			w="100%"
			p="6px 12px"
			bgC={Colors.BACKGROUND_GRAY_2}
			b={{ bottom: `1.5px solid ${Colors.BORDER_GRAY}` }}
		>
			<StyledGridItem area="created" alignSelf="center">
				<OrderDate form={form} tab={tab} disabled={disabled} />
			</StyledGridItem>

			<StyledGridItem area="arrival_time" alignSelf="center">
				<ArrivalTime form={form} tab={tab} disabled={disabled} />
			</StyledGridItem>

			<StyledGridItem area="execution" alignSelf="center">
				<ExecutionTime form={form} tab={tab} disabled={disabled} />
			</StyledGridItem>

			<StyledGridItem area="route_length" alignSelf="center">
				<StyledRow gap="6px">
					<Primary>
						{`${t([
							`orderPage.order_form.route_length`,
							"Distance",
						])}:`}
					</Primary>

					<Gray>
						{distance} {t(`units.km`) ?? "km"}
					</Gray>
				</StyledRow>
			</StyledGridItem>

			<StyledGridItem area="out_of_town" alignSelf="center">
				<OutOfTown
					disabled={disabled}
					form={form}
					tab={tab}
					edit={
						tab.state?.modals?.editableParam ===
						"suburbanDistanceOverride"
					}
					setEdit={(edit) =>
						setEditableParam(
							edit ? "suburbanDistanceOverride" : null,
						)
					}
				/>
			</StyledGridItem>

			<StyledGridItem area="price" alignSelf="center">
				<OrderPrice
					key="price"
					disabled={disabled}
					form={form}
					edit={tab.state.modals.editableParam === "price"}
					setEdit={(edit) => setEditableParam(edit ? "price" : null)}
					tab={tab}
				/>
			</StyledGridItem>

			<StyledGridItem area="idleTime" alignSelf="center">
				<TimeWaitingAndIdle
					id="idleTime"
					form={form}
					tab={tab}
					title={t([`orderPage.order_form.idle_time`, "Plain"])}
					disabled={disabled}
					edit={tab.state.modals.editableParam === "idleTime"}
					setEdit={(edit) =>
						setEditableParam(edit ? "idleTime" : null)
					}
				/>
			</StyledGridItem>

			<StyledGridItem area="waitingTime" alignSelf="center">
				<TimeWaitingAndIdle
					id="waitingTime"
					form={form}
					tab={tab}
					title={t([
						`orderPage.order_form.waiting_time`,
						"Expectation",
					])}
					disabled={disabled}
					edit={tab.state.modals.editableParam === "waitingTime"}
					setEdit={(edit) =>
						setEditableParam(edit ? "waitingTime" : null)
					}
				/>
			</StyledGridItem>

			<StyledGridItem area="additional_cost" alignSelf="center">
				<AdditionalCost
					disabled={disabled}
					form={form}
					tab={tab}
					edit={tab.state.modals.editableParam === "additionalCost"}
					setEdit={(edit) =>
						setEditableParam(edit ? "additionalCost" : null)
					}
				/>
			</StyledGridItem>

			<StyledGridItem area="hourly" alignSelf="center">
				<OrderHourly
					disabled={disabled}
					form={form}
					edit={tab.state?.modals?.editableParam === "hourly"}
					setEdit={(edit) => setEditableParam(edit ? "hourly" : null)}
					tab={tab}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

declare namespace BaseSummary {
	interface Props extends OrderFormProps {
		vertical?: boolean;
	}
}
export const Summary = memo(BaseSummary);
export default BaseSummary;
