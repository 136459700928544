import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Option, Select } from "uikit";

import Chart from "./Chart";

interface Props {
	value: Chart.IntervalLabel;
	onChange: (value: Chart.IntervalLabel) => void;
}

const OPTIONS: Option<Chart.IntervalLabel>[] = [
	{
		key: "hour",
		label: "mainPage.reports.charts.interval.hour",
		value: "hour",
	},
	{
		key: "day",
		label: "mainPage.reports.charts.interval.day",
		value: "day",
	},
	{
		key: "month",
		label: "mainPage.reports.charts.interval.month",
		value: "month",
	},
	{
		key: "year",
		label: "mainPage.reports.charts.interval.year",
		value: "year",
	},
];

const IntervalSelect: React.FC<Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const options = useMemo<Option<Chart.IntervalLabel>[]>(
		() =>
			OPTIONS.map((option) => ({
				...option,
				label: t(option.label) || option.label,
			})),
		[t],
	);

	return (
		<Select<Chart.IntervalLabel>
			value={value}
			onChange={(key) => {
				onChange(key as Chart.IntervalLabel);
			}}
			options={options}
		/>
	);
};

export default IntervalSelect;
