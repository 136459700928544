import React, {
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { MultiSelect, react } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedDispatch, useTypedSelector } from "../../../redux/store";
import getCompanies from "../../../redux/services/Company/getCompanies";
import { Language } from "../../../assets/languages/langs";
import useObjectEditor from "../../../hooks/useObjectEditor";
import { useTaxiServiceFilterAccess } from "../../../access";
import LabeledField from "../../LabeledField";

import InternalController from "./Controller";

const CompaniesBase = react.withController<
	CompaniesBase.PropsBase,
	CompaniesBase.Controller
>(({ value, onChange, controller, placeholder, label }) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const { subCompaniesOptions } = useTaxiServiceFilterAccess<any>();

	const { companies } = useTypedSelector((state) => state.ordersPageReducer);

	const [error, setError] = useState(false);

	const valueEditor = useObjectEditor(value, onChange);

	const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
	const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");

	const selectedCompanies = valueEditor.useGetter("companyIds");
	const setSelectedCompanies = valueEditor.useSetter("companyIds");

	const [initialCompanyIds] = useState(selectedCompanies);

	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);

	const companiesOptions = useMemo(
		() => subCompaniesOptions({ initialCompanyIds }),
		[subCompaniesOptions, initialCompanyIds],
	);

	const updateSelectedValues = useCallback(
		(newCompanies) => {
			setSelectedCompanies(newCompanies);
			const newTaxiServiceIds = taxiServiceIds.filter((serviceId) => {
				const serviceCompany = companies.items.find((company) =>
					company.taxiServices.some(
						(service) => service.id === serviceId,
					),
				);
				return (
					serviceCompany && newCompanies.includes(serviceCompany.id)
				);
			});
			setTaxiServiceIds(newTaxiServiceIds);
		},
		[
			setSelectedCompanies,
			taxiServiceIds,
			setTaxiServiceIds,
			companies.items,
		],
	);

	useEffect(() => {
		if (companiesOptions.length === 1)
			updateSelectedValues([companiesOptions[0].key]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companiesOptions]);

	controller.setContext({ value, setError });

	return (
		<>
			<LabeledField label={t(label) || ""}>
				<MultiSelect
					all={companiesOptions.length > 1}
					disabled={companiesOptions.length === 1}
					placeholder={t(placeholder) || ""}
					error={error}
					value={selectedCompanies}
					options={companiesOptions}
					onChange={updateSelectedValues}
				/>
			</LabeledField>
		</>
	);
}, InternalController);

declare namespace CompaniesBase {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: (x: Value) => void;
		label: string[] | string;
		placeholder: string[] | string;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Company {
		id: number;
		name: Language;
	}

	interface TaxiService {
		id: number;
		settlement: Language;
		company: Company;
	}

	interface Value {
		taxiServices: TaxiService[];
		taxiServiceIds: number[];
		companies: Company[];
		companyIds: number[];
	}
}
export const Companies = memo(CompaniesBase);
declare namespace Companies {
	interface PropsBase extends CompaniesBase.PropsBase {}
	interface Company extends CompaniesBase.Company {}
	interface TaxiService extends CompaniesBase.TaxiService {}
	interface Value extends CompaniesBase.Value {}

	type Ref = CompaniesBase.Ref;
	type Controller = CompaniesBase.Controller;
	type Props = PropsBase & RefAttributes<Ref>;
}

export default CompaniesBase;
