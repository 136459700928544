import * as ModelEvent from "@node-elion/syncron";
import { compact } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createRPCQuery from "../../utils/createRPCQuery.util";
import createLogger from "../../utils/logger.util";
import { NonEditableProperties } from "../../types/NonEditableProperties";
import SubcribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { DateRange } from "../../types/DataRange";
import { PaymentAccount } from "../../types/PaymentAccount";
import { SortingOrder } from "../../types/SortingOrder";
import { Base, Language } from "..";

import {
	OperationTransactionType,
	PayableEntityType,
	TransactionAction,
	TransactionMetadataAction,
	TransactionMetadataContext,
	TransactionMetadataEntityType,
	TransactionStatus,
} from "./types";

const logger = createLogger({ name: "Transaction" });

class Transaction extends Base {
	public static fromResponse(data: any): Transaction.Model {
		return {
			id: data?.id,

			amount: data?.amount,
			status: data?.status,
			targetAction: data?.targetAction,

			operation: data?.operation,
			metadata: data?.metadata,
			from: data?.from,
			to: data?.to,

			createdAt: data?.createdAt,
			updatedAt: data?.updatedAt,
			deletedAt: data?.deletedAt,

			paymentAccount: data.paymentAccount,
			targetPaymentAccount: data.targetPaymentAccount,
		};
	}

	public static async subscribe(
		options: Transaction.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Transaction.Model>,
	): Promise<Subscription<Transaction.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("transaction subscribe", { state, options });
				const models = compact(state.models);
				onUpdate({
					...state,
					models: models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				createRPCQuery(() =>
					prpc.theirsModel.payment.subscribeTransactions({
						params: options,
						ping: () => true,
						onEvent: (events) => {
							modelEventConstructor.onEvent(events);
						},
						onError: (error) => {
							logger.error("transaction Error subscribe", {
								error,
							});
							logger.error(error);
						},
					}),
				),
			{ name: "Transaction.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Transaction.SubscribeOptions) =>
				subscription.update(options),
		} as Subscription<Transaction.SubscribeOptions>;
	}
}

declare namespace Transaction {
	interface OperationTransaction
		extends Record<string, any>,
			NonEditableProperties {
		id: number;
		description: string;
		order?: {
			orderNumber: number;
		} | null;
		createdBy: {
			data: Record<string, any>;
			type: OperationTransactionType;
		};
		metadata?: {
			context: TransactionMetadataContext;
			comment: string;
			action?: TransactionMetadataAction;
			entity?: {
				type: TransactionMetadataEntityType;
				id: number;
				name: Name;
				period?: {
					from?: Date | number;
					to?: Date | number;
				};
			};
		};
	}

	type Name = Record<Language, string>;

	interface MetadataTransaction extends Record<string, any> {
		accountAmountBefore: number;
		accountAmountAfter: number;
		context: TransactionMetadataContext;
		comment: string;

		action?: TransactionMetadataAction;
		entity?: {
			type: TransactionMetadataEntityType;
			id: number;
			name: Name;
			period?: {
				from?: Date | number;
				to?: Date | number;
			};
		};
	}
	interface Person {
		name: string | null;
		fatherName: string | null;
		surname: string | null;
	}
	interface Company {
		name: Name;
	}

	interface CompanyTransaction extends Record<string, any> {
		name: Name;
	}
	interface CustomerTransaction extends Record<string, any> {
		company: Company;
		person: Person;
	}

	interface CounterpartyTransaction extends Record<string, any> {
		company: Company;
		name: string | null;
	}

	interface CounterpartyCheckTransaction extends Record<string, any> {
		company: Company;
		checkValue: string | null;
	}

	interface AgentTransaction extends Record<string, any> {
		company: Company;
		name: Name;
	}
	interface ExecutorTransaction extends Record<string, any> {
		callSign: string | null;
		taxiService: {
			settlement: Name;
			company: Company;
		};
		person: Person;
	}
	interface TaxiServiceTransaction extends Record<string, any> {
		settlement: Record<Language, string>;
		company: Company;
	}
	interface PaymentAccountRelation {
		amount: number | null;
		type: PaymentAccount["type"] | null;
		entityType: PayableEntityType;
		isProvider: boolean | null;
		company: CompanyTransaction | null;
		customer: CustomerTransaction | null;
		executor: ExecutorTransaction | null;
		taxiService: TaxiServiceTransaction | null;
		counterparty: CounterpartyTransaction | null;
		counterpartyCheck: CounterpartyCheckTransaction | null;
		agent: AgentTransaction | null;
	}
	interface Model extends NonEditableProperties {
		amount: number;
		id: number;
		status: TransactionStatus;
		targetAction: TransactionAction;
		metadata: MetadataTransaction;
		operation: OperationTransaction;
		to: PaymentAccountRelation | null;
		from: PaymentAccountRelation | null;
		/** @deprecated */
		paymentAccount: PaymentAccount;
		/** @deprecated */
		targetPaymentAccount: PaymentAccount;
	}

	interface GetTransactionsOrder {
		isProvider?: SortingOrder;
		createdAt?: SortingOrder;
		type?: SortingOrder;
	}
	interface SubscribeOptions
		extends SubcribeOptionsBase<GetTransactionsOrder> {
		dateRange?: DateRange;
		language?: Language | string;
		operationId?: number;
		checkCardId?: number;
		employeeId?: number;
		targetAction?: TransactionAction;
		paymentAccountIds?: number[];
	}
}

export default Transaction;
