import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Executor, Dispatcher } from "../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { ordersAction as orders } from "../../../../../../../../../../redux/reducers/Orders";
import {
	useExecutorsSubHandler,
	useModelSubscribeOld,
	useDispatcherSubHandler,
} from "../../../../../../../../../../hooks";

const ChatsSubscribe: React.FC = () => {
	const dispatch = useDispatch();
	const { getAllExecutorsSubHandler } = useExecutorsSubHandler();
	const { getSortAndLimitDispatcherSubHandler } = useDispatcherSubHandler();

	const { chats } = useTypedSelector((state) => state.orderPageFilters);

	const executorsData = useMemo(() => {
		const options: Executor.SubscribeOptions = {
			query: chats.filters.query,
		};

		if (chats.filters.order?.column && chats.filters.order?.type) {
			options.order = {
				[chats.filters.order.column]:
					chats.filters.order.type.toUpperCase(),
			};
		}

		return getAllExecutorsSubHandler(options);
	}, [
		chats.filters.order?.column,
		chats.filters.order?.type,
		chats.filters.query,
		getAllExecutorsSubHandler,
	]);

	const dispatcherSubscriptionOptions =
		useMemo<Dispatcher.SubscribeOptions>(() => {
			const result: Dispatcher.SubscribeOptions = {
				query: chats.filters.query,
			};

			return result;
		}, [chats.filters.query]);

	const dispatchersData = useModelSubscribeOld(
		dispatcherSubscriptionOptions,
		Dispatcher,
	);

	const modelDispatcherSub = useMemo(() => {
		const [column, type] = [
			chats.filters.order?.column,
			chats.filters.order?.type,
		];
		const options: Dispatcher.SubscribeOptions = {
			order:
				typeof column === "string" && typeof type === "string"
					? ({
							[column]: type,
					  } as Dispatcher.SubscribeOptions["order"])
					: ({} as Dispatcher.SubscribeOptions["order"]),
		};

		const cacheDispatcherSub = dispatchersData?.cache ?? [];

		return getSortAndLimitDispatcherSubHandler(options, cacheDispatcherSub);
	}, [
		chats.filters.order?.column,
		chats.filters.order?.type,
		dispatchersData?.cache,
		getSortAndLimitDispatcherSubHandler,
	]);

	useEffect(() => {
		dispatch(orders.chats.chat.setExecutorsInUserModalData(executorsData));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [executorsData]);

	useEffect(() => {
		dispatch(
			orders.chats.chat.setDispatchersInUserModalData(modelDispatcherSub),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelDispatcherSub]);

	return null;
};

export default ChatsSubscribe;
