import React, {
	Suspense,
	useMemo,
	lazy,
	useEffect,
	useState,
	useCallback,
} from "react";
import { useTranslation } from "react-i18next";

import { useTypedDispatch, useTypedSelector } from "../../../../redux/store";
import getCompanies from "../../../../redux/services/Company/getCompanies";
import useActiveTab from "../../../../hooks/useActiveTab";
import { SVGgeneral } from "../../../../utils/generalSprite";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import {
	StyledRow,
	SuspenseLoader,
	SideTab,
	StyledP,
} from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyObjects = lazy<React.FC<PreferencesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Objects" /* webpackChunkName: "preferences-page-objects" */
	);
	return { default: elem.default };
});

const LazyPriceZones = lazy<React.FC<PreferencesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/PriceZones" /* webpackChunkName: "preferences-page-price-zones" */
	);
	return { default: elem.default };
});

const LazyCarModelsAndBrands = lazy<React.FC<PreferencesPage.Props>>(
	async () => {
		const elem = await import(
			"./tabs/CarModelsAndBrands" /* webpackChunkName: "preferences-page-car-models-and-brands" */
		);
		return { default: elem.default };
	},
);

const LazyColors = lazy<React.FC<PreferencesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Colors" /* webpackChunkName: "preferences-page-colors" */
	);
	return { default: elem.default };
});

const LazyCarClass = lazy<React.FC<PreferencesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/CarClass" /* webpackChunkName: "preferences-page-car-class" */
	);
	return { default: elem.default };
});

const LazyServices = lazy<React.FC<PreferencesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Services" /* webpackChunkName: "preferences-page-services" */
	);
	return { default: elem.default };
});

const LazySectors = lazy<React.FC<PreferencesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Sectors" /* webpackChunkName: "preferences-page-sectors" */
	);
	return { default: elem.default };
});

const LazyParkings = lazy<React.FC<PreferencesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Parkings" /* webpackChunkName: "preferences-page-parkings" */
	);
	return { default: elem.default };
});

const PreferencesPage: React.FC = () => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [_activeKey, _setActiveKey] = useState<string>("");

	useEffect(() => {
		dispatch(getCompanies());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const textWithIcon = useCallback(
		(key: string, iconID: string, text: string) => (
			<StyledRow gap="10px">
				<StyledRow style={{ minWidth: "20px" }}>
					<SVGgeneral
						id={iconID}
						style={{
							width: "20px",
							height: "20px",
							fill: _activeKey === key ? "#03a9f4" : "#647079",
						}}
					/>
				</StyledRow>

				<StyledP
					font={{
						fw: 600,
						line: "18px",
					}}
					colors={_activeKey === key ? "#03a9f4" : "#647079"}
				>
					{text}
				</StyledP>
			</StyledRow>
		),
		[_activeKey],
	);

	const tabs: TabOptions.ArrayWithAnyLabel = useMemo(
		() =>
			[
				{
					key: TabKeys.SETTINGS_CAR_MODELS_AND_BRANDS,
					label: textWithIcon(
						TabKeys.SETTINGS_CAR_MODELS_AND_BRANDS,
						"settingsCarModelsAndBrands",
						t("pages.preferencesPages.navInfo.str200") ?? "",
					),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyCarModelsAndBrands />
							</Suspense>
						),
					},
					accessName:
						TabAccessNames[TabKeys.SETTINGS_CAR_MODELS_AND_BRANDS],
				},
				{
					key: TabKeys.CAR_CLASSES,
					label: textWithIcon(
						TabKeys.CAR_CLASSES,
						"carClass",
						t("pages.preferencesPages.navInfo.str201") ?? "",
					),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyCarClass />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.CAR_CLASSES],
				},
				{
					key: TabKeys.COLORS,
					label: textWithIcon(
						TabKeys.COLORS,
						"colors",
						t("pages.preferencesPages.navInfo.str203") ?? "",
					),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyColors />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.COLORS],
				},
				{
					key: TabKeys.OBJECTS,
					label: textWithIcon(
						TabKeys.OBJECTS,
						"object",
						t("pages.preferencesPages.navInfo.str204") ?? "",
					),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyObjects />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.OBJECTS],
				},
				{
					key: TabKeys.SECTORS,
					label: textWithIcon(
						TabKeys.SECTORS,
						"map",
						t("pages.preferencesPages.navInfo.str205") ?? "",
					),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazySectors />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.SECTORS],
				},

				{
					key: TabKeys.PARKINGS,
					label: textWithIcon(
						TabKeys.PARKINGS,
						"parking",
						t("pages.preferencesPages.navInfo.str206") ?? "",
					),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyParkings />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.PARKINGS],
				},
				{
					key: TabKeys.PRICE_ZONES,
					label: textWithIcon(
						TabKeys.PRICE_ZONES,
						"priceZone",
						t("pages.preferencesPages.navInfo.str207") ?? "",
					),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyPriceZones />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.PRICE_ZONES],
				},
				{
					key: TabKeys.SERVICES,
					label: textWithIcon(
						TabKeys.SERVICES,
						"services",
						t("pages.preferencesPages.navInfo.str208") ?? "",
					),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyServices />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.SERVICES],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[textWithIcon, t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	useEffect(() => {
		if (typeof activeKey === "string") {
			_setActiveKey(activeKey);
		}
	}, [activeKey, activeTab]);

	return (
		<Suspense
			fallback={
				<StyledRow position="absolute" top="50%" left="50%">
					<SuspenseLoader />
				</StyledRow>
			}
		>
			<BasicPageLayout
				navigation={
					<SideTab
						value={activeKey}
						onChange={setActiveKey}
						options={tabs}
						variant="vertical"
					/>
				}
				content={activeTab.value.render()}
			/>
		</Suspense>
	);
};

declare namespace PreferencesPage {
	interface Props {}
}

export default PreferencesPage;
