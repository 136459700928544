const set = {
	columnModal: {
		open: "ordersPage.columnModal.open",
		data: "ordersPage.columnModal.data",
	},
	modal: {
		open: "ordersPage.modal.open",
		data: "ordersPage.modal.data",
	},
	modals: "ordersPage.modals",
	setModalVisible: "ordersPage.setModalVisible",
	setOrders: "ordersPage.setOrders",
	setOrdersByTab: "ordersPage.setOrdersByTab",
	setFocusableOrder: "ordersPage.setFocusableOrder",
	setOrdersType: "ordersPage.setOrdersType",
	setTableOrderBy: "ordersPage.setTableOrderBy",
	setActive: "ordersPage.setActive",
	setRoute: "ordersPage.setRoute",
	setColumn: "ordersPage.column.remember",
	setActivePoint: "ordersPage.setActivePoint",
	setMapCenter: "ordersPage.setMapCenter",
	setSearchTypes: "ordersPage.setSearchTypes",
	setCompanies: "ordersPage.setCompanies",
	setFilteredOrdersID: "ordersPage.setFilteredOrdersID",
	setCarsPosition: "ordersPage.setCarsPosition",
};

const constants = Object.seal(set);
export default constants;

export const EMPTY_ORDER_TEMPLATE = {
	points: [],
	distance: 0,
	route: [],
};

export const ORDERS_TYPES = {
	PRELIMINARY: "preliminary",
	EXECUTABLE: "executable",
	ALL: "all",
	CLOSED: "closed",
	LIVE: "live",
} as const;

export type OrderType = (typeof ORDERS_TYPES)[keyof typeof ORDERS_TYPES];

export const VISIBLE_ORDERS_COUNT = 60;

export interface ModalInterface {
	label: string;
	type: string;
	position: string;
	groupId: string;
	visible: boolean;
	columnStart: number;
	rowStart: number;
	columnEnd: number;
	rowEnd: number;
}
