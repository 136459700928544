/* eslint-disable no-param-reassign */
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Language, Dispatcher } from "../../services";
import {
	DisplayFields,
	mergeAccessDefault,
	mergeAccessRolesUser,
	ACCESS_TO_THE_COMPONENT,
} from "../../access";

type TaxiService = {
	accountCode: string;
	createdAt: string;
	deletedAt: string | null;
	id: number;
	settlement: {
		[key: string]: string;
	};
	settlementPoint: {
		type: string;
		coordinates: [number, number];
	};
	updatedAt: string;
	v: number;
};

interface TaxiServiceToDispatcher {
	id: number;
	taxiService: TaxiService;
}

interface User extends Dispatcher.Model {
	dispatcherToTaxiServices?: TaxiServiceToDispatcher[];
}

interface State {
	user?: User;
	dispatchers?: User[];
	personalRole?: DisplayFields;
}

type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

const setUser: Reducer<State["user"]> = (state, { payload }) => {
	state.user = payload;

	const arrUserRoles =
		payload?.dispatcherToRoles
			?.map((item) => item?.role?.displayFields)
			?.filter((role): role is DisplayFields => role !== undefined) ?? [];

	let access = ACCESS_TO_THE_COMPONENT;

	if (arrUserRoles.length === 1) {
		access = mergeAccessDefault(ACCESS_TO_THE_COMPONENT, arrUserRoles[0]);
	} else if (arrUserRoles.length > 1) {
		access = mergeAccessDefault(
			ACCESS_TO_THE_COMPONENT,
			mergeAccessRolesUser(arrUserRoles),
		);
	}

	state.personalRole = access;
};
const setDispatchers: Reducer<State["dispatchers"]> = (state, { payload }) => {
	state.dispatchers = payload;
};

export const setLanguage: Reducer<Language> = (state, { payload }) => {
	if (state.user?.settings) {
		state.user.settings.language = payload;
	}
};

const initialState: State = {};

const account = createSlice({
	name: "account",
	initialState,
	reducers: {
		setUser,
		setDispatchers,
		setLanguage,
	},
});

export const accountAction = account.actions;
export const accountReducer = account.reducer;
export type accountState = State;

export default account;
