/* eslint-disable no-shadow */
import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import { ModelId } from "../../types/ModelId";
import GeneralSurcharges from "../../pages/Settings/pages/Tariffs/tabs/General/components/Modal/components/Content/tabs/GeneralSurcharges";
import Name from "../../pages/Settings/pages/Tariffs/tabs/General/components/Modal/components/Content/tabs/GeneralSurcharges/components/Name";
import Subscription from "../../types/Subscription";
import { TaxiServiceOld as TaxiService, Base, Language, Card } from "..";

const logger = createLogger({ name: "GeneralTariff" });

class GeneralTariff extends Base {
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;
		this._Card = new Card((prpc) => prpc.theirsModel.generalRate.card);
		return this._Card;
	}

	public static fromResponse(data: any): GeneralTariff.Model {
		const taxiServices = data.rateToTaxiServices.flatMap(
			(item) => item.taxiService,
		);

		return {
			id: data.id,

			name: data.name,
			active: data.active,
			additionalFields: data.additionalFields,
			taxiServices,
			taxiServiceIds: taxiServices.map(({ id }) => id),
			carClassIds: data.rateToCarClasses.map(
				({ carClass }) => carClass.id,
			),

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: GeneralTariff.New | GeneralTariff.Modified) {
		return {
			name: model.name,
			active: model.active,
			additionalFields: model.additionalFields,
			taxiServiceIds: model.taxiServiceIds,
			carClassIds: model.carClassIds,
		};
	}

	public static async getAll() {
		const res = await this.request((prpc) =>
			prpc.theirsModel.generalRate.getAll(),
		);
		return res?.items?.map(this.fromResponse);
	}

	public static async rearrange(id: ModelId, position: number) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.generalRate.rearrange(id, position),
			);
		} catch (error: any) {
			logger.error("Error in rearrange method:", error);
		}
	}

	public static async copy(id: ModelId) {
		try {
			await this.request((prpc) => prpc.theirsModel.generalRate.copy(id));
		} catch (err: any) {
			logger.error("Error in copy method:", err);
		}
	}

	public static async store(object: GeneralTariff.New) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.generalRate.create(this.toRequest(object)),
			);
		} catch (err: any) {
			return false;
		}
		return true;
	}

	public static async update(object: GeneralTariff.Modified) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.generalRate.update(
					object.id,
					this.toRequest(object),
				),
			);
		} catch (err: any) {
			return false;
		}
		return true;
	}

	public static async delete(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.generalRate.delete(id));
	}

	public static async subscribe(
		options: GeneralTariff.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<GeneralTariff.Model>,
	): Promise<Subscription<GeneralTariff.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("[GeneralTariff]", state);
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.generalRate.subscribe({
					params: options,
					ping: () => true,
					onEvent: async (events) => {
						await modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{
				name: "GeneralTariff",
				metadata: options,
			},
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: GeneralTariff.SubscribeOptions) =>
				subscription.update(options),
		};
	}
}

declare namespace GeneralTariff {
	interface Model extends NonEditableProperties {
		name: Name.Value;
		active: boolean;
		carClassIds: ModelId[];
		additionalFields: GeneralSurcharges.AdditionalFields;
		taxiServices: TaxiService.Model[];
		taxiServiceIds: number[];
	}

	type New = Omit<Model, NonEditablePropertyNames>;
	type Modified = Partial<New> &
		Partial<Omit<NonEditableProperties, "id">> & { readonly id: ModelId };

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<GeneralTariff.Model> {
		active?: boolean;
		language?: Language;
		taxiServiceIds?: number[];
	}
}

export default GeneralTariff;
