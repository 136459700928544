import { Order } from "../../../services";
import { DateRange, DateRangeLiteral } from "../../../types/DataRange";
import archiveReportsByOrders from "../../reducers/Archives/Reports/charts/byOrders";

interface Options {
	taxiServiceIds?: number[];
	dateRange: DateRange | DateRangeLiteral;
}

interface RequestParams {
	taxiServiceIds?: number[];
	dateRange: DateRange;
}

export default function getArchiveOrdersForChart(options: Options) {
	return async (dispatch) => {
		const requestParams: RequestParams = {
			taxiServiceIds: options?.taxiServiceIds || [],
			dateRange: {
				from: new Date(options.dateRange.from),
				to: new Date(options.dateRange.to),
			},
		};

		const activeTab = "overall";
		const data = await Order.getByTab(activeTab, requestParams);

		dispatch(archiveReportsByOrders.actions.setLoading(true));

		if (data) {
			dispatch(archiveReportsByOrders.actions.setLoading(false));
			dispatch(
				archiveReportsByOrders.actions.setDateRange(options.dateRange),
			);
			dispatch(
				archiveReportsByOrders.actions.determineInterval(
					options.dateRange,
				),
			);
			dispatch(archiveReportsByOrders.actions.setOrders(data.items));
		}
	};
}
