import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Dispatcher } from "../../services";
import { useTypedSelector } from "../../redux/store";
import { sortByNumberOrString } from "../../utils";
import { ColumnId } from "../../pages/MainPage/pages/Accounts/tabs/Users/components/Table/columns";

import { getFullName, getPhone } from ".";

const useDispatcherSubHandler = (): useDispatcherSubHandler.Props => {
	enum ColumnIdAdditional {
		Phone = "phone",
		Alias = "alias",
		FullName = "fullName",
	}

	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const getNameTaxiServiceMax = useCallback(
		(items: Dispatcher.Model, isCompany: boolean) => {
			const DEFAULT_TEXT = "Zero";

			if (
				!(
					Array.isArray(items.taxiServiceIds) &&
					items.taxiServiceIds?.length
				)
			)
				return DEFAULT_TEXT;

			if (taxiServices.length === items.taxiServiceIds.length)
				return isCompany
					? t(["all_company", "All companies"])
					: t(["all_taxi_service", "All branches"]);

			const arr =
				items.taxiServiceIds
					?.map((id) =>
						isCompany
							? taxiServices.find(
									(taxiService) => taxiService.id === id,
							  )?.company?.name?.[lang] ?? ""
							: taxiServices.find(
									(taxiService) => taxiService.id === id,
							  )?.settlement?.[lang] ?? "",
					)
					.sort((a, b) => a.localeCompare(b)) || [];

			const lastElement = arr.at(-1) ?? DEFAULT_TEXT;
			return lastElement;
		},
		[lang, t, taxiServices],
	);

	const getSortAndLimitDispatcherSubHandler = useCallback(
		(
			{ limit, order }: useDispatcherSubHandler.SmallSubscribeOptions,
			roleSubscribe: Dispatcher.Model[],
		): Dispatcher.Model[] => {
			if (!Array.isArray(roleSubscribe)) {
				return [];
			}

			let retval = [...(roleSubscribe || [])];

			const [isOrder, isLimit] = [
				typeof order === "object" &&
					order !== null &&
					!Array.isArray(order),
				typeof limit === "number",
			];

			if (isOrder) {
				const [[dataKey, sortType] = []] = Object.entries(order || {});
				const isSort =
					typeof sortType === "string" &&
					typeof dataKey === "string" &&
					["asc", "desc"].includes(sortType.toLowerCase());

				if (isSort) {
					const isAscending = sortType.toLowerCase() === "asc";
					const sortOrder = isAscending ? 1 : -1;

					if (
						dataKey === ColumnId.Name ||
						dataKey === ColumnIdAdditional.FullName
					) {
						retval = retval.sort((prev, next) => {
							const prevValue = getFullName(prev.person);
							const nextValue = getFullName(next.person);

							return (
								sortByNumberOrString(
									prevValue,
									nextValue,
									true,
								) * sortOrder
							);
						});
					}

					if (
						dataKey === ColumnId.CallSign ||
						dataKey === ColumnIdAdditional.Alias
					) {
						const sort = retval.sort((prev, next) => {
							const prevValue = prev.alias ?? -1;
							const nextValue = next.alias ?? -1;
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (
						dataKey === ColumnId.Phones ||
						dataKey === ColumnIdAdditional.Phone
					) {
						retval = retval.sort((prev, next) => {
							const prevValue = getPhone(prev.person);
							const nextValue = getPhone(next.person);

							return (
								sortByNumberOrString(
									prevValue,
									nextValue,
									true,
								) * sortOrder
							);
						});
					}

					if (dataKey === ColumnId.Role) {
						const sort = retval.sort((prev, next) => {
							const findTextFullRole = (
								model: Dispatcher.Model,
							) =>
								model.roles
									?.map((role) => role.name)
									?.join(", ") ?? "";

							const prevValue = `${findTextFullRole(prev)}` || "";
							const nextValue = `${findTextFullRole(next)}` || "";

							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}
					if (dataKey === ColumnId.Status) {
						const sort = retval.sort((prev, next) => {
							const prevValue = `${prev?.status}` || "";
							const nextValue = `${next?.status}` || "";
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}
					if (dataKey === ColumnId.CreatedAt) {
						const sort = retval.sort((prev, next) => {
							const prevValue = prev.createdAt
								? Date.parse(prev.createdAt) || 0
								: 0;
							const nextValue = next.createdAt
								? Date.parse(next.createdAt) || 0
								: 0;
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}
					if (
						dataKey === ColumnId.Company ||
						dataKey === ColumnId.TaxiService
					) {
						const isCompany = dataKey === ColumnId.Company;
						const sort = retval.sort((prev, next) => {
							const prevValue = getNameTaxiServiceMax(
								prev,
								isCompany,
							);
							const nextValue = getNameTaxiServiceMax(
								next,
								isCompany,
							);
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === ColumnId.BirthAt) {
						const sort = retval.sort((prev, next) => {
							let prevValue = 0;
							const prevBirthday = prev.person?.birthday;
							if (prevBirthday) {
								if (typeof prevBirthday === "string") {
									// Если это строка, парсим
									prevValue = Date.parse(prevBirthday) || 0;
								} else {
									// Иначе это объект Date, используем getTime()
									prevValue = prevBirthday.getTime();
								}
							}

							let nextValue = 0;
							const nextBirthday = next.person?.birthday;
							if (nextBirthday) {
								if (typeof nextBirthday === "string") {
									nextValue = Date.parse(nextBirthday) || 0;
								} else {
									nextValue = nextBirthday.getTime();
								}
							}

							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}
				}
			}

			if (isLimit) {
				retval = retval.slice(0, limit);
			}

			return retval;
		},
		[
			ColumnIdAdditional.Alias,
			ColumnIdAdditional.FullName,
			ColumnIdAdditional.Phone,
			getNameTaxiServiceMax,
		],
	);

	return useMemo(
		() => ({
			getSortAndLimitDispatcherSubHandler,
		}),
		[getSortAndLimitDispatcherSubHandler],
	);
};

declare namespace useDispatcherSubHandler {
	interface Props {
		getSortAndLimitDispatcherSubHandler: (
			data: SmallSubscribeOptions,
			roleSubscribe: Dispatcher.Model[],
		) => Dispatcher.Model[];
	}

	interface SmallSubscribeOptions {
		order?: Dispatcher.Order;
		limit?: number;
	}
}

export default useDispatcherSubHandler;
