import React, {
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { MultiSelect, react } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedDispatch, useTypedSelector } from "../../../redux/store";
import getCompanies from "../../../redux/services/Company/getCompanies";
import useObjectEditor from "../../../hooks/useObjectEditor";
import { useTaxiServiceFilterAccess } from "../../../access";
import LabeledField from "../../LabeledField";

import InternalController from "./Controller";

const CompaniesAndTaxiServicesBase = react.withController<
	CompaniesAndTaxiServicesBase.PropsBase,
	CompaniesAndTaxiServicesBase.Controller
>(
	/**
	 * @param {boolean} autoSelectAllTaxiServices
	 * A flag to enable or disable the auto-selection of all taxi services when companies are selected.
	 * If true, when a company is selected, all associated taxi services will be automatically selected.
	 * If false, only the previously selected taxi services will remain.
	 */
	({
		value,
		onChange,
		controller,

		labelCompany = "settings.tariffs.main.mainModal.tabs.main.company",
		placeholderCompany = "settings.tariffs.main.mainModal.tabs.main.chooseCompany",
		labelTaxiServices = "settings.tariffs.main.mainModal.tabs.main.branch",
		placeholderTaxiServices = "settings.tariffs.main.mainModal.tabs.main.chooseBranch",

		required = true,
		disabled = false,
		autoSelectAllTaxiServices = false,
	}) => {
		const { t } = useTranslation();
		const dispatch = useTypedDispatch();
		const { subCompaniesOptions, subTaxiServiceOptions } =
			useTaxiServiceFilterAccess<any>();
		const { companies } = useTypedSelector(
			(state) => state.ordersPageReducer,
		);

		const [errorCompanyIds, setCompanyIdsError] = useState(false);
		const [errorTaxiServiceIds, setTaxiServiceIdsError] = useState(false);

		const valueEditor = useObjectEditor(value, onChange);

		const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
		const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");

		const selectedCompanies = valueEditor.useGetter("companyIds");
		const setSelectedCompanies = valueEditor.useSetter("companyIds");

		const [initialCompanyIds] = useState(selectedCompanies);
		const [initialTaxiServiceIds] = useState(taxiServiceIds);

		const companiesOptions = useMemo(
			() => subCompaniesOptions({ initialCompanyIds }),
			[subCompaniesOptions, initialCompanyIds],
		);

		const taxiServiceOptions = useMemo(
			() =>
				subTaxiServiceOptions({
					initialTaxiServiceIds,
					initialCompanyIds,
					selectedCompanies,
				}),
			[
				subTaxiServiceOptions,
				initialTaxiServiceIds,
				initialCompanyIds,
				selectedCompanies,
			],
		);

		useEffect(() => {
			dispatch(getCompanies());
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const updateSelectedValues = useCallback(
			(newCompanies) => {
				setSelectedCompanies(newCompanies);

				// Auto-select all taxi services if the flag is true
				if (autoSelectAllTaxiServices) {
					const allTaxiServices = companies.items
						.filter((company) => newCompanies.includes(company.id))
						.flatMap(
							(company) =>
								company.taxiServices?.map(
									(service) => service.id,
								) || [],
						);

					setTaxiServiceIds(allTaxiServices);
				} else {
					// const newTaxiServiceIds = taxiServiceIds.filter(
					// 	(serviceId) => {
					// 		const serviceCompany = companies.items.find(
					// 			(company) =>
					// 				company.taxiServices.some(
					// 					(service) => service.id === serviceId,
					// 				),
					// 		);
					// 		return (
					// 			serviceCompany &&
					// 			newCompanies.includes(serviceCompany.id)
					// 		);
					// 	},
					// );
					// setTaxiServiceIds(newTaxiServiceIds);
				}

				if (newCompanies.length) setCompanyIdsError(false);
			},
			[
				setSelectedCompanies,
				setTaxiServiceIds,
				companies.items,
				autoSelectAllTaxiServices,
			],
		);

		const updateSelectedTaxiServiceValues = useCallback(
			(ids) => {
				if (ids?.length) setTaxiServiceIdsError(false);

				setTaxiServiceIds([...ids]);
			},
			[setTaxiServiceIds],
		);

		useEffect(() => {
			if (companiesOptions.length === 1)
				updateSelectedValues([companiesOptions[0].key]);

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [companiesOptions]);

		useEffect(() => {
			if (taxiServiceOptions?.length === 1)
				setTaxiServiceIds([taxiServiceOptions?.[0]?.key ?? -2]);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [taxiServiceOptions]);

		controller.setContext({
			value,
			setCompanyIdsError,
			setTaxiServiceIdsError,
			required,
		});

		return (
			<>
				<LabeledField label={t(labelCompany) || ""}>
					<MultiSelect
						all={companiesOptions.length > 1}
						disabled={disabled || companiesOptions.length === 1}
						placeholder={t(placeholderCompany) || ""}
						error={errorCompanyIds}
						value={selectedCompanies}
						options={companiesOptions}
						onChange={updateSelectedValues}
					/>
				</LabeledField>
				<LabeledField label={t(labelTaxiServices) || ""}>
					<MultiSelect
						all={taxiServiceOptions.length > 1}
						disabled={disabled || taxiServiceOptions.length <= 1}
						placeholder={t(placeholderTaxiServices) || ""}
						value={taxiServiceIds}
						error={errorTaxiServiceIds}
						options={taxiServiceOptions}
						onChange={updateSelectedTaxiServiceValues}
					/>
				</LabeledField>
			</>
		);
	},
	InternalController,
);

declare namespace CompaniesAndTaxiServicesBase {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface Value {
		taxiServiceIds: number[];
		companyIds: number[];
	}
	interface PropsBase {
		value: Value;
		onChange: (value: Value) => void;

		labelCompany?: string[] | string;
		placeholderCompany?: string[] | string;
		labelTaxiServices?: string[] | string;
		placeholderTaxiServices?: string[] | string;

		/** if required === "false" at that validate === `true` */
		required?: boolean;
		disabled?: boolean;
		autoSelectAllTaxiServices?: boolean;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export const CompaniesAndTaxiServices = memo(CompaniesAndTaxiServicesBase);

declare namespace CompaniesAndTaxiServices {
	interface PropsBase extends CompaniesAndTaxiServicesBase.PropsBase {}
	interface Value extends CompaniesAndTaxiServicesBase.Value {}

	type Ref = CompaniesAndTaxiServicesBase.Ref;
	type Controller = CompaniesAndTaxiServicesBase.Controller;
	type Props = PropsBase & RefAttributes<Ref>;
}

export default CompaniesAndTaxiServicesBase;
