import React, { useCallback, useMemo, Fragment } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { colorGroups } from "../../../../../../../../redux/reducers/settings/colors";
import { keybindGroups } from "../../../../../../../../redux/reducers/settings/keybinds";
import { useTypedSelector } from "../../../../../../../../redux/store";
import { StyledColumn, StyledP } from "../../../../../../../common";
import { ModalInfo } from "..";

const TableContainer = styled.div`
	overflow-x: auto;
`;

const StyledTable = styled.table`
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #ccc;
	border-radius: 4px;
	overflow: hidden;
`;

const TableHeader = styled.th`
	padding: 8px;
	text-align: center;
	background-color: #f4f4f4;
	border-bottom: 1px solid #ccc;

	&:not(:last-child) {
		border-right: 1px solid #ccc;
	}
`;

const TableRow = styled.tr<{ isEven: boolean }>`
	background-color: ${(props) => (props.isEven ? "#f9f9f9" : "#fff")};

	&:hover {
		background-color: #e6f7ff;
	}
`;

const TableCell = styled.td`
	padding: 8px;
	border-bottom: 1px solid #ccc;
	text-align: center;
	vertical-align: middle;

	&:not(:last-child) {
		border-right: 1px solid #ccc;
	}

	&:first-child {
		width: 30%;
	}

	&:nth-child(2) {
		text-align: left;
	}
`;

const ColorBox = styled.div<{ color?: string }>`
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: ${({ color }) => color || "transparent"};
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	margin: auto;
`;

const Modal: React.FC<Modal.Props> = ({
	title,
	onCancel,
	onSave,
	isLoading,
}) => {
	const { t } = useTranslation();

	const colorsState = useTypedSelector((state) => state.settings.colors);
	const keybindsState = useTypedSelector((state) => state.settings.keybinds);

	const isHotKey = title === "hotKey";

	const groups = isHotKey ? keybindGroups : colorGroups;
	const translateAddress = isHotKey
		? `settings.interface.keybinds.keys.`
		: "settings.interface.colors.options.";

	const handleSubmit = useCallback(() => {
		onSave();
	}, [onSave]);

	const textGroup = useMemo(
		() => ({
			hotKey: t("main_header.settings.hotKey") ?? "",
			colourDesignations:
				t("main_header.settings.colourDesignations") ?? "",
			keyboards: t("main_header.settings.keyboards") ?? "",
			description: t("main_header.settings.description") ?? "",
			color: t("main_header.settings.color") ?? "",
		}),
		[t],
	);

	const funcTitleGroup = (text?: (string | undefined)[]) => {
		const isTitleGroup: string | null =
			typeof text?.[0] === "string" ? text[0] : null;
		const isDescriptionGroup: string | null =
			typeof text?.[1] === "string" ? text[1] : null;

		if (!isTitleGroup) return null;

		const titleGroup: string = isTitleGroup ? t(isTitleGroup) : "";
		const descriptionGroup: string = isDescriptionGroup
			? ` / ${t(isDescriptionGroup)}`
			: "";

		return (
			<StyledP
				alignItems="center"
				justify="center"
				p="10px"
				br="4px"
				// b="1px solid #ccc"
				b={{
					top: "1px solid #ccc",
					right: "1px solid #ccc",
					left: "1px solid #ccc",
					bottom: "none",
				}}
				font={{ fw: "bold" }}
				textAlign="center"
			>
				{titleGroup}
				{descriptionGroup}
			</StyledP>
		);
	};

	const getHumanReadableKey = useCallback((key: string): string => {
		if (typeof key !== "string") return "";

		const humanReadableKeys: Record<string, string> = {
			AltLeft: "Alt",
			AltRight: "Alt",
			Control: "Ctrl",
			ControlLeft: "Ctrl",
			ControlRight: "Ctrl",
			ShiftLeft: "Shift",
			ShiftRight: "Shift",
			Digit0: "0",
			Digit1: "1",
			Digit2: "2",
			Digit3: "3",
			Digit4: "4",
			Digit5: "5",
			Digit6: "6",
			Digit7: "7",
			Digit8: "8",
			Digit9: "9",
			KeyA: "A",
			KeyB: "B",
			KeyC: "C",
			KeyD: "D",
			KeyE: "E",
			KeyF: "F",
			KeyG: "G",
			KeyH: "H",
			KeyI: "I",
			KeyJ: "J",
			KeyK: "K",
			KeyL: "L",
			KeyM: "M",
			KeyN: "N",
			KeyO: "O",
			KeyP: "P",
			KeyQ: "Q",
			KeyR: "R",
			KeyS: "S",
			KeyT: "T",
			KeyU: "U",
			KeyV: "V",
			KeyW: "W",
			KeyX: "X",
			KeyY: "Y",
			KeyZ: "Z",
			Numpad0: "Num 0",
			Numpad1: "Num 1",
			Numpad2: "Num 2",
			Numpad3: "Num 3",
			Numpad4: "Num 4",
			Numpad5: "Num 5",
			Numpad6: "Num 6",
			Numpad7: "Num 7",
			Numpad8: "Num 8",
			Numpad9: "Num 9",
			NumpadAdd: "+",
			NumpadSubtract: "-",
			NumpadMultiply: "*",
			NumpadDivide: "/",
			NumpadDecimal: ".",
			Slash: "/",
			Backslash: "\\",
			Comma: ",",
			Period: ".",
			Semicolon: ";",
			Quote: "'",
			BracketLeft: "[",
			BracketRight: "]",
			Minus: "-",
			Equal: "=",
			Backquote: "`",
		};

		return humanReadableKeys[key] || key;
	}, []);

	return (
		<ModalInfo
			onSubmit={handleSubmit}
			onClose={onCancel}
			loading={isLoading}
			title={textGroup?.[title] ?? ""}
		>
			<StyledColumn gap="20px" h="100%">
				{groups.map((group, index) => (
					<StyledColumn key={index}>
						{funcTitleGroup(group.title)}
						<StyledColumn
							gap="20px"
							b="1px solid #ccc"
							br="4px"
							overY="auto"
						>
							<TableContainer>
								<StyledTable>
									<thead>
										<tr>
											<TableHeader>
												{textGroup?.[
													isHotKey
														? "keyboards"
														: "color"
												] ?? ""}
											</TableHeader>
											<TableHeader>
												{textGroup.description ?? ""}
											</TableHeader>
										</tr>
									</thead>
									<tbody>
										{group.entryKeys.map((row, index) => {
											let valueCol: any = "";

											if (isHotKey) {
												const valueHotKey =
													keybindsState?.[row] ||
													null;

												valueCol = Array.isArray(
													valueHotKey,
												)
													? valueHotKey.map(
															(key, idx) => (
																<Fragment
																	key={idx}
																>
																	{getHumanReadableKey(
																		key,
																	)}
																	{idx <
																		valueHotKey.length -
																			1 &&
																		" + "}
																</Fragment>
															),
													  )
													: "";
											} else {
												const valueColor =
													colorsState?.[row] || null;

												valueCol =
													typeof valueColor ===
													"string" ? (
														<ColorBox
															color={valueColor}
														/>
													) : (
														""
													);
											}

											const descriptionText =
												t(
													`${translateAddress}${row}`,
												) ?? "";
											return (
												<TableRow
													key={index}
													isEven={index % 2 === 0}
												>
													<TableCell>
														{valueCol}
													</TableCell>
													<TableCell>
														{descriptionText}
													</TableCell>
												</TableRow>
											);
										})}
									</tbody>
								</StyledTable>
							</TableContainer>
						</StyledColumn>
					</StyledColumn>
				))}
			</StyledColumn>
		</ModalInfo>
	);
};

declare namespace Modal {
	interface Props {
		title: "hotKey" | "colourDesignations";
		onCancel: () => void;
		onSave: () => void;
		isLoading: boolean;
	}
}

export default Modal;
