import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import Language from "../../../../../../../../../../services/Language";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const Constant: Record<Language, string> = {
	ru: "Оренда",
	uk: "Аренда",
	en: "Rent",
	tr: "Rent",
	az: "Rent",
	ro: "Chirie",
};

export const cellProcessing = (item: RentCellContent.Props["item"]) => {
	if (!item || !item.executorRateColumns) return "";
	const exist = item.executorRateColumns.find(
		(data) => data.name?.en === Constant.en,
	);

	if (!exist) return "";
	if (!isNumber(exist?.amount)) return "";

	return formatNumber(exist?.amount);
};

const RentCellContent: React.FC<RentCellContent.Props> = ({ item }) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace RentCellContent {
	interface Props {
		item: ExecutorReport.Model;
		language: Language;
	}
}

export default RentCellContent;
