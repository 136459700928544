import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";

const MultiSelectOrderClassesWithModalBase: React.FC<
	MultiSelectOrderClassesWithModalBase.Props
> = ({
	value,
	onChange,
	disabled,
	titleText,
	allText,
	allActiveText,
	required,
	modalSetting,
	selectOptions = [],
	sort,
	...props
}) => {
	const { t } = useTranslation();

	const [error, setError] = useState("");

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) => {
				const retval = value.find((key) => key === item.key);

				return typeof retval === "number";
			}),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const Ids = newValue.map((item) => item.value);
			onChange(Ids);
		},
		[onChange],
	);

	const texts = useMemo(
		() => ({
			title: t(titleText),
			allText: t(allText),
			allActiveText: t(allActiveText),
		}),
		[allActiveText, allText, t, titleText],
	);

	return (
		<MultiSelectWithModal
			error={error}
			disabled={disabled}
			value={selected}
			title={texts.title}
			allText={texts.allText}
			allActiveText={texts.allActiveText}
			options={selectOptions}
			onChange={selectOnChange}
			required={required}
			pullUpItemInsideArray
			hiddenButton={false}
			showSelectAll
			modalSetting={modalSetting}
			sort={{
				show: true,
				active: true,
				...(sort || {}),
			}}
			{...props}
		/>
	);
};

export const MultiSelectOrderClassesWithModal = memo(
	MultiSelectOrderClassesWithModalBase,
);

declare namespace MultiSelectOrderClassesWithModalBase {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText: string | string[];
		allText: string | string[];
		allActiveText: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		selectOptions: MultiSelectWithModalBase.Value;
	}

	type Value = number[];
}

export default MultiSelectOrderClassesWithModalBase;
