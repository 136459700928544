import { consoleLogTransport, Logger, LoggerOptions } from "prpcow";

const isProduction = process.env.NODE_ENV === "production";
const LOG_LEVEL = isProduction ? "info" : "silly";

const isLocalhost = ["localhost", "127.0.0.1"].includes(
	window.location.hostname,
);

/**
 * Creates a logger instance with the specified options.
 * @param options - The options for the logger.
 * @returns A new logger instance with conditional logging based on URL.
 */
const createLogger = (options: LoggerOptions) => {
	const logger = new Logger({
		level: LOG_LEVEL,
		callback: consoleLogTransport,
		...options,
	});

	if (!isLocalhost) {
		logger.info = () => {};
	}

	return logger;
};

export default createLogger;
