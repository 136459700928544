import React, { useMemo } from "react";

import { Order, Language } from "../../../../../../../services";
import CellContentRoot from "../CellContentRoot";

const TaxiServiceCellContent: React.FC<TaxiServiceCellContent.Props> = ({
	item,

	language,
}) => {
	const content = useMemo(
		() => item.taxiService?.settlement?.[language],
		[item.taxiService?.settlement, language],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace TaxiServiceCellContent {
	interface Props {
		item: Order.Model;

		language: Language;
	}
}

export default TaxiServiceCellContent;
