import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (item: FeeCellContent.Props["item"]) => {
	if (!item) return "";
	if (!isNumber(item.id)) return "";
	return item.id;
};

const FeeCellContent: React.FC<FeeCellContent.Props> = ({ item }) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace FeeCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default FeeCellContent;
