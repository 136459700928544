import { DateFns, ConstantFormat } from "../DateFns";

type FileNameOptions = {
	title?: string;
	dateFrom?: string | number | Date | null;
	dateTo?: string | number | Date | null;
	format?: "pdf" | "xlsx";
};

/**
 * Generates a formatted filename based on provided parameters.
 * @param {FileNameOptions} options - The file name options.
 * @returns {string} The formatted file name.
 */
const generateFileNameFileHelper = ({
	title = "",
	dateFrom = new Date(),
	dateTo = new Date(),
	format,
}: FileNameOptions): string => {
	const dateFns = new DateFns();

	const isValidDateFrom = dateFrom && !(dateFrom instanceof Date);
	const isValidDateTo = dateTo && !(dateTo instanceof Date);

	const _dateFrom = isValidDateFrom ? dateFrom : new Date();
	const _dateTo = isValidDateTo ? dateTo : new Date();

	if (isValidDateFrom)
		console.error("Invalid dateFrom value: Expected a Date object.");

	if (isValidDateTo)
		console.error("Invalid dateTo value: Expected a Date object.");

	const formattedDateFrom = dateFns.formatTime(
		_dateFrom,
		ConstantFormat.MDY_UK,
	);

	const formattedDateTo = dateFns.formatTime(_dateTo, ConstantFormat.MDY_UK);

	let fileName = `${title.trim()} - ${formattedDateFrom} - ${formattedDateTo}`
		.replace(/\s+/g, " ")
		.trim();

	if (typeof format === "string") {
		fileName = `${fileName}.${format.toLowerCase()}`;
	}

	return fileName;
};

export default generateFileNameFileHelper;
