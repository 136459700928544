import React, {
	Dispatch,
	memo,
	useCallback,
	useMemo,
	useState,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Agent } from "../../../../services";
import { useTypedSelector } from "../../../../redux/store";
import { useModelSubscribe } from "../../../../hooks";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";
import { StyledP, StyledRow, StyledColumn } from "../..";

const EqualWidthColumn = styled(StyledColumn)`
	flex: 1;
`;

const MultiSelectAgentWithModal: React.FC<MultiSelectAgentWithModal.Props> = ({
	value = [],
	onChange,
	disabled,
	titleText,
	required,
	modalSetting,
	sort,
	subscribeOptions = {},
}) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);

	const options = useMemo(() => subscribeOptions, [subscribeOptions]);

	const agentSubscribe = useModelSubscribe(options, Agent);

	const [error, setError] = useState("");

	const texts = useMemo(
		() => ({
			title: t("agentChoise"),
			allText: t("all_agent"),
			allActiveText: t("all_active_agent"),
			cardNumber: t("cardNumber"),
		}),
		[t],
	);

	const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			agentSubscribe.models.map((item) => ({
				key: item.id,
				label: `${item.name?.[language] || "Unknown"} (${
					texts.cardNumber
				}: ${item.name || "Unknown"})`,
				value: item.id,
				name: `${item.name?.[language] || "Unknown"} (${
					texts.cardNumber
				}: ${item.code?.value || "Unknown"})`,
				data: item,
			})),
		[agentSubscribe.models, language, texts.cardNumber],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) =>
				value.find((data) => data === item.value),
			),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const ids = newValue.map((item) => item.value);
			onChange(ids);
		},
		[onChange],
	);

	useEffect(() => {
		if (disabled) {
			onChange([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disabled]);

	return (
		<StyledRow gap="7px" alignItems="center">
			<EqualWidthColumn gap="7px">
				{titleText && <StyledP>{t(titleText) ?? ""}</StyledP>}
				<MultiSelectWithModal
					value={selected}
					onChange={selectOnChange}
					options={selectOptions}
					error={error}
					disabled={disabled}
					title={texts.title}
					allText={texts.allText}
					allActiveText={texts.allActiveText}
					required={required}
					pullUpItemInsideArray
					hiddenButton={false}
					showSelectAll
					modalSetting={modalSetting}
					sort={{
						show: true,
						active: true,
						...(sort || {}),
					}}
				/>
			</EqualWidthColumn>
		</StyledRow>
	);
};

declare namespace MultiSelectAgentWithModal {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText?: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		companyIds?: number[];
		subscribeOptions?: Agent.SubscribeOptions;
		canUseCompanyIds?: boolean;
	}

	type Value = number[];
}

export default memo(MultiSelectAgentWithModal);
