import React, { useMemo } from "react";
import moment from "moment";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import { PaymentAccountType } from "../../../../../../../../../../types/PaymentAccount";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (
	item: DataChangeBalanceCellContent.Props["item"],
) => {
	const paymentAccount = () => {
		if (!item?.paymentAccounts) return null;
		return item.paymentAccounts.find(
			(a) => a.type === PaymentAccountType.MAIN,
		);
	};

	try {
		if (!paymentAccount() || !paymentAccount()?.amountUpdatedAt) {
			return null;
		}

		return moment(paymentAccount()?.amountUpdatedAt).format(
			"DD-MM-YYYY HH:mm:ss",
		);
	} catch {
		return "";
	}
};

const DataChangeBalanceCellContent: React.FC<
	DataChangeBalanceCellContent.Props
> = ({ item }) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace DataChangeBalanceCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default DataChangeBalanceCellContent;
