/* eslint-disable no-shadow */
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { uniq } from "lodash";

import SIPToDispatcherEvent from "../../services/SIPToDispatcher/events";
import { Dispatcher, Call, Executor } from "../../services";
import softphoneWidget from "../../redux/reducers/softphoneWidget";
import { useTypedSelector } from "../../redux/store";
import useModelSubscribe from "../../hooks/useModelSubscribe2";
import useModelSubscribeOld from "../../hooks/useModelSubscribe";
import usePRPC from "../../hooks/usePRPC";
import { useExecutorsSubHandler } from "../../hooks";
import RequireAuth from "../../middlewares/RequireAuth";
import { callUpNewCall } from "../../utils/jsSip";

import removeOldMissedCallsStorage from "./helpers";

const CallProvider = () => {
	const dispatch = useDispatch();
	const { getAllExecutorsSubHandler } = useExecutorsSubHandler();

	const { id: sipToDispatcherId } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const { latestQuery, isShowAllLatestCalls, transferSearchQuery } =
		useTypedSelector((state) => state.softphoneWidget);

	const userId = useTypedSelector((state) => state.account.user?.id);

	const missedCallsSubscribeOptions = useMemo(() => {
		const result: Call.SubscribeOptions = {
			limit: 100,
			notAnswered: true,
			query: latestQuery,
		};
		return result;
	}, [latestQuery]);

	const missedCallSubscribeData = useModelSubscribe(
		missedCallsSubscribeOptions,
		Call,
	);

	const subscribeCurrentSipCallsOptions = useMemo(() => {
		const result: Call.SubscribeOptions = {};
		result.limit = 100;
		result.sipToDispatcherId = isShowAllLatestCalls
			? undefined
			: sipToDispatcherId;
		result.dispatcherId = !sipToDispatcherId && userId ? userId : undefined;
		result.query = latestQuery?.length > 0 ? latestQuery : undefined;
		return result;
	}, [isShowAllLatestCalls, latestQuery, sipToDispatcherId, userId]);

	const currentSipCallsSubscribeData = useModelSubscribe(
		subscribeCurrentSipCallsOptions,
		Call,
	);

	const allCalls = useMemo(() => {
		const mergedArray = [
			...missedCallSubscribeData.models,
			...currentSipCallsSubscribeData.models,
		];

		const sortedArray = mergedArray.sort((a, b) => {
			const dateA = new Date(b.createdAt).getTime();
			const dateB = new Date(a.createdAt).getTime();
			return dateA - dateB;
		});

		return sortedArray;
	}, [currentSipCallsSubscribeData.models, missedCallSubscribeData.models]);

	useEffect(() => {
		removeOldMissedCallsStorage();

		const missedCallsToJSON = JSON.stringify(
			missedCallSubscribeData.models,
		);

		const uniqueMissedPhones = uniq(
			missedCallSubscribeData.models
				.filter((call) => call.type !== "IN_RECALLED")
				.map((call) => call.phone),
		);

		dispatch(
			softphoneWidget.actions.setNumberOfMissedCalls(
				uniqueMissedPhones.length,
			),
		);

		const missedCallsPhonesToJSON = JSON.stringify(uniqueMissedPhones);

		localStorage.setItem("missedCalls", missedCallsToJSON);
		localStorage.setItem("missedCallsPhones", missedCallsPhonesToJSON);
	}, [dispatch, missedCallSubscribeData.models]);

	useEffect(() => {
		dispatch(softphoneWidget.actions.setLatestCallsData(allCalls));
	}, [allCalls, dispatch]);

	enum SipToDispatcherEventType {
		NEW_CALL = "newCall",
	}

	const { id: dispatcherId } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const prpc = usePRPC();

	useEffect(() => {
		setImmediate(async () => {
			try {
				if (dispatcherId) {
					await SIPToDispatcherEvent.subscribe({
						sipToDispatcherId: dispatcherId,
						onEvent: (data: {
							sipUri: string;
							type: SipToDispatcherEventType;
						}) => {
							if (
								data.type === SipToDispatcherEventType.NEW_CALL
							) {
								callUpNewCall(data.sipUri);
							}
						},
						onError: (data) => {
							console.error(
								"sip to dispatcher event error ",
								data,
							);
						},
					});
				}
			} catch (error) {
				console.error(error);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatcherId, prpc]);

	const dispatcherSubscriptionOptions =
		useMemo<Dispatcher.SubscribeOptions>(() => {
			const result: Dispatcher.SubscribeOptions = {
				query: transferSearchQuery,
			};
			return result;
		}, [transferSearchQuery]);

	const dispatchersData = useModelSubscribeOld(
		dispatcherSubscriptionOptions,
		Dispatcher,
	);

	const executorsData = useMemo(() => {
		const options: Executor.SubscribeOptions = {
			query: transferSearchQuery,
		};

		return getAllExecutorsSubHandler(options);
	}, [getAllExecutorsSubHandler, transferSearchQuery]);

	useEffect(() => {
		dispatch(
			softphoneWidget.actions.setExecutorsInTransferList(executorsData),
		);
	}, [dispatch, executorsData]);
	useEffect(() => {
		dispatch(
			softphoneWidget.actions.setDispatchersInTransferList(
				dispatchersData?.cache || [],
			),
		);
	}, [dispatch, dispatchersData?.cache]);

	return <></>;
};

export default RequireAuth(CallProvider);
