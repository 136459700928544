/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer, Archive } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	activeOrderId: null,
	orders: [],
	order: null,
	activePoint: -1,
};

const setRouteToActiveOrder: ReduxReducer<State, Archive.Orders.Route> = (
	state,
	{ payload },
) => {
	if (state.order) {
		state.order.route = payload;
	}
};

const setPointsToActiveOrder: ReduxReducer<
	State,
	{
		passenger: number;
		points: (Pick<Archive.Orders.Point, "coordinates"> & {
			feature: Record<string, any>;
		})[];
	}
> = (state, { payload: { passenger = 0, points } }) => {
	if (state.order) {
		state.order.passengers[passenger].points = points;
	}
};

const setActivePoint: ReduxReducer<State, State["activePoint"]> = (
	state,
	{ payload },
) => {
	state.activePoint = payload;
};

const setActiveOrderId: ReduxReducer<State, State["activeOrderId"]> = (
	state,
	{ payload },
) => {
	state.activeOrderId = payload;
};

const setOrders: ReduxReducer<State, State["orders"]> = (
	state,
	{ payload },
) => {
	state.orders = payload;
};

const setOrder: ReduxReducer<State, State["order"]> = (state, { payload }) => {
	state.order = payload;
};

const addOrder: ReduxReducer<State, State["orders"][0]> = (
	state,
	{ payload },
) => {
	if (payload) state.orders.push(payload);
};

const updateOrder: ReduxReducer<State, State["orders"][0]> = (
	state,
	{ payload },
) => {
	if (payload)
		state.orders.map((order) =>
			order.id === payload.id ? payload : order,
		);
};
const removeOrder: ReduxReducer<State, { id: number }> = (
	state,
	{ payload },
) => {
	if (payload && payload.id) {
		const newOrder = [...state.orders].filter(
			(order) => order.id !== payload.id,
		);

		state.orders = newOrder;
	}
};

const resetOrders: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const archiveOrders = createSlice({
	name: Archive.ConstantOrderSlice.ORDERS_ORDERS,
	initialState,
	reducers: {
		resetOrders,
		setPointsToActiveOrder,
		setRouteToActiveOrder,
		setActiveOrderId,
		setActivePoint,
		setOrders,
		setOrder,
		addOrder,
		updateOrder,
		removeOrder,
	},
});

export const archiveOrdersAction = archiveOrders.actions;
export const archiveOrdersReducer = archiveOrders.reducer;
export type archiveOrdersState = State;

export default archiveOrders;
