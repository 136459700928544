import React, { useMemo } from "react";
import { isNumber } from "lodash";

import {
	ExecutorReport,
	Language,
} from "../../../../../../../../../../services";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const Constant: Record<Language, string> = {
	ru: "Абонентская плата",
	uk: "Абонентська плата",
	en: "Subscription fee",
	tr: "Subscription fee",
	az: "Subscription fee",
	ro: "Taxă de abonament",
};
export const cellProcessing = (item: FeeCellContent.Props["item"]) => {
	if (!item || !item.executorRateColumns) return "";
	const exist = item.executorRateColumns.find(
		(data) => data.name?.en === Constant.en,
	);

	if (!exist) return "";
	if (!isNumber(exist?.amount)) return "";

	return formatNumber(exist?.amount);
};

const FeeCellContent: React.FC<FeeCellContent.Props> = ({ item }) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace FeeCellContent {
	interface Props {
		item: ExecutorReport.Model;
		language: Language;
	}
}

export default FeeCellContent;
