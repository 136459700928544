import React, { memo, useCallback, useState } from "react";
import { Icon } from "uikit";
import styled from "styled-components";

import { Popup, StyledColumn } from "../../../../../common";

import { PopupContent, ModalInfoContent } from "./components";

const Root = styled(StyledColumn)`
	width: 48px;
	height: 100%;

	padding-inline: 12px;

	font-family: Lato;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;

	color: #bcc1c5;

	cursor: pointer;

	user-select: none;
`;

const popupOffset = { x: -140, y: 38 };

const Settings = memo(() => {
	const [showPopup, setShowPopup] = useState(false);
	const [showModalInfo, setShowModalInfo] = useState<
		"hotKey" | "colourDesignations" | null
	>(null);

	return (
		<Popup
			useHoverControl
			open={showPopup}
			offset={popupOffset}
			tracker={
				<Root justify="center" alignItems="center" h="100%">
					<Icon id="headerSettings" size={24} colors={["#bcc1c5"]} />
					{showModalInfo && (
						<ModalInfoContent
							title={showModalInfo}
							isLoading={false}
							onCancel={() => setShowModalInfo(null)}
							onSave={() => setShowModalInfo(null)}
						/>
					)}
				</Root>
			}
			onChangeOpen={setShowPopup}
		>
			<StyledColumn
				gap="0.5rem"
				justify="space-between"
				alignItems="center"
				bgC="#ffffff"
				p="1rem 1rem 1.75rem 1rem"
				b="1px solid #c5c6c7"
				br="5px"
				w={{ min: "100px" }}
			>
				<PopupContent onShowModalInfo={setShowModalInfo} />
			</StyledColumn>
		</Popup>
	);
});

export default Settings;
