import { ReactNode } from "react";

import { Language } from "../../../../../../../../../services";
import tPath from "../../../constants/tPath";

import active from "./active";
import name from "./name";
import assigns from "./assigns";
import description from "./description";
import createdAt from "./createdAt";

const translationTablePath = `${tPath}.columns`;

export enum ColumnId {
	Active = "active",
	Name = "name",
	Assigns = "assigns",
	Description = "description",
	CreatedAt = "createdAt",
}

export interface Column {
	render: (options: Column.Options) => ReactNode;
	label: string | string[];
}

export declare namespace Column {
	interface Options {
		width?: number;
		language: Language;
		onResize?: (width: number, columnId: ColumnId) => void;
	}
}

export const columns: Record<ColumnId, Column> = {
	[ColumnId.Active]: {
		render: active,
		label: [`${translationTablePath}.${ColumnId.Active}`, "Active"],
	},

	[ColumnId.Name]: {
		render: name,
		label: [`${translationTablePath}.${ColumnId.Name}`, "Name"],
	},

	[ColumnId.Assigns]: {
		render: assigns,
		label: [`${translationTablePath}.${ColumnId.Assigns}`, "Assigns"],
	},

	[ColumnId.Description]: {
		render: description,
		label: [
			`${translationTablePath}.${ColumnId.Description}`,
			"Description",
		],
	},

	[ColumnId.CreatedAt]: {
		render: createdAt,
		label: [`${translationTablePath}.${ColumnId.CreatedAt}`, "CreatedAt"],
	},
};

export const defaultColumnIds = [
	ColumnId.Active,
	ColumnId.Name,
	ColumnId.Assigns,
	ColumnId.Description,
	ColumnId.CreatedAt,
];

export const disabledColumnsIds = [];

export default columns;
