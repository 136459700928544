import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ByMulty } from "../../../../../../../../../../../../redux/reducers/OrdersPage/interface";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import {
	CompaniesAndTaxiServices,
	StyledColumn,
	StyledRow,
	MultiSelectServiceWithModal,
	MultiSelectCarClassWithModal,
} from "../../../../../../../../../../../../components/common";

const Root = styled(StyledColumn)`
	min-width: 450px;
	padding-inline: 24px;
`;

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const taxiServicesData = valueEditor.usePicker(
		["taxiServiceIds", "taxiServices", "companyIds"],
		"json",
	);

	const onChangeContent = valueEditor.useAssigner();

	const services = valueEditor.useGetter("services");
	const setServices = valueEditor.useSetter("services");

	const classes = valueEditor.useGetter("classes");
	const setClasses = valueEditor.useSetter("classes");

	return (
		<Root gap="16px">
			<StyledRow gap="20px" h="100%">
				<CompaniesAndTaxiServices
					value={taxiServicesData}
					onChange={onChangeContent}
					autoSelectAllTaxiServices={true}
				/>
			</StyledRow>
			<StyledColumn gap="16px">
				<MultiSelectCarClassWithModal
					value={classes ?? []}
					onChange={setClasses}
					titleText={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carClassIId.str0",
						) ?? ""
					}
					showSelectAll={false}
					showHeadTitle
					subscribeOptions={{}}
					sort={{ sortType: "asc" }}
				/>
				<MultiSelectServiceWithModal
					value={services ?? []}
					onChange={setServices}
					titleText={
						"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.services.str0"
					}
					showHeadTitle
					subscribeOptions={{}}
				/>
			</StyledColumn>
		</Root>
	);
};

declare namespace Content {
	interface Value extends ByMulty {}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
