import React, { useCallback, useState } from "react";
import { Button, Icon, Nullable, Row } from "uikit";
import styled from "styled-components";

import { Executor } from "../../../../../../../services";

import { ModalTable } from "../Table";

interface Props {
	tableData: {
		id: number;
		alias: string;
	}[];
	onRowSelect: (rowData) => void;
	tableRowClassName: (
		rowData: Executor.Model,
	) => "selected" | "not-active" | "";
	selectedExecutorId: Nullable<number>;
	onChangeSelectedExecutorId: React.Dispatch<
		React.SetStateAction<Nullable<number>>
	>;
	onChangeTableData: React.Dispatch<
		React.SetStateAction<
			{
				id: number;
				alias: string;
			}[]
		>
	>;
}

const TableItem = styled(Row)`
	cursor: default;
	width: 100%;
	height: 100%;
`;
const CallSign = styled(Row)`
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 32px;
`;

const ExecutorsTable: React.FC<Props> = ({
	tableData,
	onRowSelect,
	tableRowClassName,
	selectedExecutorId,
	onChangeSelectedExecutorId,
	onChangeTableData,
}) => {
	const [hoveredExecutorId, setHoveredExecutorId] = useState<number | null>(
		null,
	);

	const findExecutorIndex = useCallback(
		(executorId: number) =>
			tableData?.findIndex((executor) => executor.id === executorId),
		[tableData],
	);

	const mouseEnter = useCallback((executorId: number) => {
		setHoveredExecutorId(executorId);
	}, []);

	const mouseLeave = useCallback(() => {
		setHoveredExecutorId(null);
	}, []);

	const upExecutorToOnePosition = useCallback(
		(executorId: number) => {
			const executorIndex = findExecutorIndex(executorId);
			if (executorIndex !== 0) {
				const copyTableData = [...tableData];
				const prevEl = copyTableData[executorIndex - 1];
				copyTableData[executorIndex - 1] = copyTableData[executorIndex];
				copyTableData[executorIndex] = prevEl;

				onChangeTableData(copyTableData);
				setImmediate(() => mouseLeave());
			}
		},
		[findExecutorIndex, mouseLeave, onChangeTableData, tableData],
	);

	const downExecutorToOnePosition = useCallback(
		(executorId: number) => {
			const executorIndex = findExecutorIndex(executorId);
			if (executorIndex !== tableData.length - 1) {
				const copyTableData = [...tableData];
				const prevEl = copyTableData[executorIndex + 1];
				copyTableData[executorIndex + 1] = copyTableData[executorIndex];
				copyTableData[executorIndex] = prevEl;

				onChangeTableData(copyTableData);
				setImmediate(() => mouseLeave());
			}
		},
		[findExecutorIndex, mouseLeave, onChangeTableData, tableData],
	);

	const upExecutorToFirstPosition = useCallback(
		(executorId: number) => {
			const executorIndex = findExecutorIndex(executorId);
			if (executorIndex !== 0) {
				const copyTableData = [...tableData];
				const firstEl = copyTableData[executorIndex];
				const newTableData = copyTableData.filter(
					(executor) => executor.id !== firstEl.id,
				);
				newTableData.unshift(firstEl);

				onChangeTableData(newTableData);
				setImmediate(() => mouseLeave());
			}
		},
		[findExecutorIndex, mouseLeave, onChangeTableData, tableData],
	);

	const downExecutorToLastPosition = useCallback(
		(executorId: number) => {
			const executorIndex = findExecutorIndex(executorId);
			if (executorIndex !== tableData.length - 1) {
				const copyTableData = [...tableData];
				const lastEl = copyTableData[executorIndex];
				const newTableData = copyTableData.filter(
					(executor) => executor.id !== lastEl.id,
				);
				newTableData.push(lastEl);

				onChangeTableData(newTableData);
				setImmediate(() => mouseLeave());
			}
		},
		[findExecutorIndex, mouseLeave, onChangeTableData, tableData],
	);

	const removeExecutorFromTable = useCallback(
		(executorId: number) => {
			if (executorId) {
				onChangeTableData((prev) =>
					prev.filter((executor) => executor.id !== executorId),
				);
				if (selectedExecutorId === executorId) {
					onChangeSelectedExecutorId(null);
				}
			}
		},
		[onChangeSelectedExecutorId, onChangeTableData, selectedExecutorId],
	);

	const isButtonDisabled = useCallback(
		(executorId: number, position: "upDisable" | "downDisable") => {
			const executorIndex = findExecutorIndex(executorId);
			if (executorIndex === 0 && position === "upDisable") return true;
			if (
				executorIndex === tableData.length - 1 &&
				position === "downDisable"
			)
				return true;
			return false;
		},
		[findExecutorIndex, tableData?.length],
	);

	const isShowButtons = useCallback(
		(executorId: number) => {
			if (selectedExecutorId === executorId) return true;
			if (hoveredExecutorId === executorId) return true;
			return false;
		},
		[hoveredExecutorId, selectedExecutorId],
	);

	return (
		<ModalTable
			data={tableData}
			headerHeight={0}
			rowHeight={32}
			rowClassName={tableRowClassName}
		>
			<ModalTable.Column width={468}>
				<ModalTable.HeaderCell> </ModalTable.HeaderCell>
				<ModalTable.Cell
					align="left"
					verticalAlign="middle"
					dataKey="name"
					onMouseLeave={mouseLeave}
				>
					{(rowData) => (
						<TableItem
							align="center"
							justify="space-between"
							onMouseEnter={() => mouseEnter(rowData.id)}
							onMouseMove={() => mouseEnter(rowData.id)}
							sizes="1fr auto"
						>
							<CallSign
								maxedHeight
								align="center"
								onClick={() => onRowSelect(rowData)}
							>
								{rowData.alias}
							</CallSign>
							<Row
								style={{
									display: isShowButtons(rowData.id)
										? "block"
										: "none",
								}}
							>
								<Row gaps="5px*">
									<Button.Button
										variant="secondary"
										transparent
										icon={<Icon id="arrow-up" size={23} />}
										onClick={() =>
											upExecutorToOnePosition(rowData.id)
										}
										disabled={isButtonDisabled(
											rowData.id,
											"upDisable",
										)}
									/>
									<Button.Button
										variant="secondary"
										transparent
										icon={
											<Icon id="arrow-down" size={23} />
										}
										onClick={() =>
											downExecutorToOnePosition(
												rowData.id,
											)
										}
										disabled={isButtonDisabled(
											rowData.id,
											"downDisable",
										)}
									/>
									<Button.Button
										variant="secondary"
										transparent
										icon={<Icon id="arrow-top" size={23} />}
										onClick={() =>
											upExecutorToFirstPosition(
												rowData.id,
											)
										}
										disabled={isButtonDisabled(
											rowData.id,
											"upDisable",
										)}
									/>
									<Button.Button
										variant="secondary"
										transparent
										icon={
											<Icon id="arrow-bottom" size={23} />
										}
										onClick={() =>
											downExecutorToLastPosition(
												rowData.id,
											)
										}
										disabled={isButtonDisabled(
											rowData.id,
											"downDisable",
										)}
									/>
									<Button.Button
										variant="secondary"
										transparent
										icon={<Icon id="trash" size={23} />}
										onClick={() =>
											removeExecutorFromTable(rowData.id)
										}
									/>
								</Row>
							</Row>
						</TableItem>
					)}
				</ModalTable.Cell>
			</ModalTable.Column>
		</ModalTable>
	);
};

export default ExecutorsTable;
