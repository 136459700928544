import { useCallback, useMemo } from "react";

import { Role } from "../../services";
import { useTypedSelector } from "../../redux/store";

/**
 * This hook is a placeholder and is not yet used anywhere in the project.
 * The filtering logic needs to be implemented based on the required criteria.
 */
const useCarParkFilterAccess = (): useCarParkFilterAccess.Props => {
	const roleSubscribe = useTypedSelector(
		(state) => state.globalState.role.data.subscription.basic,
	);

	const subTaxiServiceFilterAccess = useCallback(() => {
		const retval = [];

		return retval;
	}, []);

	return useMemo(
		() => ({
			subTaxiServiceFilterAccess,
		}),
		[subTaxiServiceFilterAccess],
	);
};

declare namespace useCarParkFilterAccess {
	interface Props {
		subTaxiServiceFilterAccess: (
			data: SmallSubscribeOptions,
		) => Role.Model[];
	}

	interface SmallSubscribeOptions {
		// order?: Role.Order;
		// query?: string;
		// limit?: number;
		// assignableTo?: Role.AssignableTo;
		// taxiServiceIds?: number[];
	}
}

export default useCarParkFilterAccess;
