import React, { useMemo } from "react";

import { Language, Order } from "../../../../../../../services";
import { getOrderPassengerPoints } from "../../../../../../../redux/constants/OrdersPage/order";
import CellContentRoot from "../CellContentRoot";

const PickupSectorCellContent: React.FC<PickupSectorCellContent.Props> = ({
	item,

	language,
}) => {
	const points = useMemo(() => getOrderPassengerPoints(item), [item]);

	const content = useMemo(
		() => points[0]?.sector?.name?.[language] ?? "",
		[language, points],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace PickupSectorCellContent {
	interface Props {
		item: Order.Model;

		language: Language;
	}
}

export default PickupSectorCellContent;
