import { combineReducers } from "@reduxjs/toolkit";

import {
	globalStateExecutorLocationsDataState,
	globalStateExecutorLocationsDataReducer,
	globalStateExecutorLocationsDataAction,
} from "./data";

export type StateExecutorLocations = {
	data: globalStateExecutorLocationsDataState;
};

export const executorLocations = combineReducers<StateExecutorLocations>({
	data: globalStateExecutorLocationsDataReducer,
});

export const globalStateExecutorLocationsAction = {
	data: globalStateExecutorLocationsDataAction,
};

export default executorLocations;
