import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const PhonesCellContent: React.FC<PhonesCellContent.Props> = ({ item }) => {
	const content = useMemo(
		() =>
			item.person?.phones
				?.filter((phone) => !phone?.deletedAt)
				?.map((phone) => phone.number)
				?.join(", ") ?? "",
		[item.person?.phones],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace PhonesCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default PhonesCellContent;
