import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const TaxiServiceCellContent: React.FC<TaxiServiceCellContent.Props> = ({
	item,
}) => {
	const lang = useTypedSelector((state) => state.session.language);
	const content = useMemo(
		() => item.taxiService?.settlement?.[lang] ?? "",
		[item.taxiService?.settlement, lang],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace TaxiServiceCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default TaxiServiceCellContent;
