import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { TextBox } from "uikit";
import styled from "styled-components";

import { useObjectEditor } from "../../../../../../../../../../../../hooks";
import { LabeledBlock } from "../../../../../../../../../../../../components";
import { StyledColumn } from "../../../../../../../../../../../../components/common";

const Root = styled(StyledColumn)`
	min-width: 450px;
	padding-inline: 24px;
`;

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const phoneValue = valueEditor.useGetter("phone");
	const phoneOnChange = valueEditor.useSetter("phone");

	const fromValue = valueEditor.useGetter("from");
	const fromOnChange = valueEditor.useSetter("from");

	const toValue = valueEditor.useGetter("to");
	const toOnChange = valueEditor.useSetter("to");

	return (
		<Root gap="16px">
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str0",
					) ?? ""
				}
			>
				<TextBox.TextBox
					value={phoneValue}
					placeholder={`${
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str200",
						) ?? ""
					}...`}
					onChange={phoneOnChange}
				/>
			</LabeledBlock>
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str1",
					) ?? ""
				}
			>
				<TextBox.TextBox
					value={fromValue}
					placeholder={`${
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str200",
						) ?? ""
					}...`}
					onChange={fromOnChange}
				/>
			</LabeledBlock>
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str2",
					) ?? ""
				}
			>
				<TextBox.TextBox
					value={toValue}
					placeholder={`${
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str200",
						) ?? ""
					}...`}
					onChange={toOnChange}
				/>
			</LabeledBlock>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		phone: string;
		from: string;
		to: string;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
