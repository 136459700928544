import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ORDERS_TYPES } from "../../../../../../../../../redux/constants/OrdersPage";
import { useTypedSelector } from "../../../../../../../../../redux/store";
import { hasAccess } from "../../../../../../../../../access";
import TabLabel from "../components/TabLabel";
import { TabKeys, TabAccessNames } from "../constants/access";

function useTabOptions() {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const orders = useTypedSelector((state) => state.ordersPageReducer.orders);

	const tabOptions = useMemo(
		() =>
			[
				{
					key: ORDERS_TYPES.LIVE,
					value: ORDERS_TYPES.LIVE,
					label: (
						<TabLabel
							label={
								t(
									"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str0",
								) ?? ""
							}
							count={
								orders[ORDERS_TYPES.LIVE]?.pagination.count ?? 0
							}
						/>
					),
					accessName: TabAccessNames[TabKeys.LIVE],
				},
				{
					key: ORDERS_TYPES.PRELIMINARY,
					value: ORDERS_TYPES.PRELIMINARY,
					label: (
						<TabLabel
							label={
								t(
									"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str1",
								) ?? ""
							}
							count={
								orders[ORDERS_TYPES.PRELIMINARY]?.pagination
									.count ?? 0
							}
						/>
					),
					accessName: TabAccessNames[TabKeys.PRELIMINARY],
				},
				{
					key: ORDERS_TYPES.EXECUTABLE,
					value: ORDERS_TYPES.EXECUTABLE,
					label: (
						<TabLabel
							label={
								t(
									"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str2",
								) ?? ""
							}
							count={
								orders[ORDERS_TYPES.EXECUTABLE]?.pagination
									.count ?? 0
							}
						/>
					),
					accessName: TabAccessNames[TabKeys.EXECUTING],
				},
				{
					key: ORDERS_TYPES.ALL,
					value: ORDERS_TYPES.ALL,
					label: (
						<TabLabel
							label={
								t(
									"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str3",
								) ?? ""
							}
							count={
								orders[ORDERS_TYPES.ALL]?.pagination.count ?? 0
							}
						/>
					),
					accessName: TabAccessNames[TabKeys.ALL],
				},
				{
					key: ORDERS_TYPES.CLOSED,
					value: ORDERS_TYPES.CLOSED,
					label: (
						<TabLabel
							label={
								t(
									"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str4",
								) ?? ""
							}
							count={
								orders[ORDERS_TYPES.CLOSED]?.pagination.count ??
								0
							}
						/>
					),
					accessName: TabAccessNames[TabKeys.CLOSED],
				},
			].filter((tab) => hasAccess(tab.accessName, personalRole)),
		[orders, personalRole, t],
	);

	return tabOptions;
}

export default useTabOptions;
