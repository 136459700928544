import React, { useMemo } from "react";
import { uniq } from "lodash";

import { Dispatcher } from "../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import { CompaniesByIds } from "../../../../../../../../../../components/common";

const CompanyCellContent: React.FC<CompanyCellContent.Props> = ({ item }) => {
	const language = useTypedSelector((state) => state.session.language);

	const content = useMemo(
		() => uniq(item.taxiServiceIds || []),
		[item.taxiServiceIds],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<CompaniesByIds taxiServiceIds={content} lang={language} />
		</CellContentRoot>
	);
};

declare namespace CompanyCellContent {
	interface Props {
		item: Dispatcher.Model;
	}
}

export default CompanyCellContent;
