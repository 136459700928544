import { combineReducers } from "@reduxjs/toolkit";

import {
	globalStateExecutorGroupDataState,
	globalStateExecutorGroupDataReducer,
	globalStateExecutorGroupDataAction,
} from "./data";

export type StateExecutorGroup = {
	data: globalStateExecutorGroupDataState;
};

export const executorGroup = combineReducers<StateExecutorGroup>({
	data: globalStateExecutorGroupDataReducer,
});

export const globalStateExecutorGroupAction = {
	data: globalStateExecutorGroupDataAction,
};

export default executorGroup;
