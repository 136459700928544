/**
 * Collection of route constants for the project.
 * This file contains the paths used in the application routing.
 */

export type RoutePaths = (typeof ROUTES)[keyof typeof ROUTES];

const ROUTES = {
	MAIN: "/",
	LOGIN: "/login",
	ORDERS: "/orders",
	ACCOUNTING: "/accounting",
	ACCOUNTS: "/accounts",
	ARCHIVES: "/archives",
	REPORTS: "/reports",
	CUSTOMERS: "/customers",
	BLACKLIST: "/blacklist",
	PREFERENCES: "/preferences",
	SETTINGS: "/settings",

	// ? settings part
	SETTINGS_ORDERS: "/settings/orders",
	SETTINGS_TARIFFS: "/settings/tariffs",
	SETTINGS_FINANCES: "/settings/finances",
	SETTINGS_MESSAGES: "/settings/messages",
	SETTINGS_MOBILE_APPS: "/settings/mobile-apps",
	SETTINGS_PARAMETERS: "/settings/parameters",
	SETTINGS_MAP: "/settings/map",
	SETTINGS_ORDER_EXCHANGE: "/settings/order-exchange",
	SETTINGS_PAYMENT_SYSTEMS: "/settings/payment-systems",
	SETTINGS_INTERFACE: "/settings/interface",
	SETTINGS_VOIP: "/settings/voip",

	// ? reports part
	REPORTS_CHARTS: "/reports/charts",
	REPORTS_ACCOUNTING: "/reports/accounting",
	REPORTS_SALARY: "/reports/salary",
} as const;

const OPEN_ROUTES: RoutePaths[] = [ROUTES.LOGIN];
const ADMIN_ROUTES: RoutePaths[] = [];

// type TabKeysForRoute = {
// 	[key in RoutePaths]: { [key: string]: string };
// };

const TABS /* : TabKeysForRoute  */ = {
	[ROUTES.PREFERENCES]: {
		SETTINGS_CAR_MODELS_AND_BRANDS: "settingsCarModelsAndBrands",
		CAR_CLASSES: "carClasses",
		COLORS: "colors",
		OBJECTS: "objects",
		SECTORS: "sectors",
		PARKINGS: "parkings",
		PRICE_ZONES: "priceZones",
		SERVICES: "services",
	},
	[ROUTES.ARCHIVES]: {
		ORDERS: "orders",
		BANK_TRANSACTIONS: "bankTransactions",
		TRANSACTIONS: "transactions",
		RATINGS: "ratings",
		GPS_LOG: "gpsLog",
		MESSAGES: "messages",
		PUSH: "push",
		DRIVER_SHIFTS: "driverShifts",
		CHANGE_HISTORY: "changeHistory",
		CALLS_HISTORY: "callsHistory",
	},
	// TODO DP Complete the rest of the page tabs
	[ROUTES.MAIN]: {},
	[ROUTES.LOGIN]: {},
	[ROUTES.ORDERS]: {},
	[ROUTES.ACCOUNTING]: {},
	[ROUTES.ACCOUNTS]: {},
	[ROUTES.REPORTS]: {},
	[ROUTES.CUSTOMERS]: {},
	[ROUTES.BLACKLIST]: {},
	[ROUTES.SETTINGS]: {},
	[ROUTES.SETTINGS_ORDERS]: {},
	[ROUTES.SETTINGS_TARIFFS]: {},
	[ROUTES.SETTINGS_FINANCES]: {},
	[ROUTES.SETTINGS_MESSAGES]: {},
	[ROUTES.SETTINGS_MOBILE_APPS]: {},
	[ROUTES.SETTINGS_PARAMETERS]: {},
	[ROUTES.SETTINGS_MAP]: {},
	[ROUTES.SETTINGS_ORDER_EXCHANGE]: {},
	[ROUTES.SETTINGS_PAYMENT_SYSTEMS]: {},
	[ROUTES.SETTINGS_INTERFACE]: {},
	[ROUTES.SETTINGS_VOIP]: {},
	[ROUTES.REPORTS_CHARTS]: {},
	[ROUTES.REPORTS_ACCOUNTING]: {},
	[ROUTES.REPORTS_SALARY]: {},
} as const;

export { ROUTES, OPEN_ROUTES, ADMIN_ROUTES, TABS };
