/* eslint-disable no-shadow */
import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { NonEditableProperties } from "../../types/NonEditableProperties";
import OptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { IName } from "../../types/IName";
import Main from "../../pages/Settings/pages/Tariffs/tabs/Additional/components/Modal/components/Content/tabs/Main";
import { Base, Currency } from "..";

const logger = createLogger({ name: "AdditionalTariff" });

class AdditionalTariff extends Base {
	public static fromResponse(data: any): AdditionalTariff.Model {
		return {
			id: data.id,

			name: data.name,
			additionalFields: data.additionalFields,
			currency: Currency.fromResponse(data.currency),

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: AdditionalTariff.Model) {
		return {
			name: model.name,
			additionalFields: model.additionalFields,
			currencyId: model.currency.id,
		};
	}

	public static async getAll() {
		const res = await this.request((prpc) =>
			prpc.theirsModel.globalRateSettings.getAll(),
		);
		return res?.items?.map(this.fromResponse);
	}

	public static async update(object: AdditionalTariff.Model) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.globalRateSettings.update(
					object.id,
					this.toRequest(object),
				),
			);
		} catch (err: any) {
			logger.error("[AdditionalTariff] Error update:", err);
			return false;
		}
		return true;
	}

	public static async subscribe(
		options: AdditionalTariff.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<AdditionalTariff.Model>,
	): Promise<Subscription<AdditionalTariff.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.globalRateSettings.subscribe({
					params: options,
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "AdditionalTariff.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: AdditionalTariff.SubscribeOptions) =>
				subscription.update(options),
		} as Subscription<AdditionalTariff.SubscribeOptions>;
	}
}

declare namespace AdditionalTariff {
	interface Model extends NonEditableProperties {
		name: IName;
		additionalFields: Main.AdditionalFields;
		currency: Currency.Model;
	}

	interface SubscribeOptions extends OptionsBase<AdditionalTariff.Model> {}
}

export default AdditionalTariff;
