/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ExecutorLocations } from "../../../../../services";
import { ReduxReducer } from "../../../../interface";
import { GlobalState } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	subscription: {
		basic: [],
	},
};

const setSubscriptionBasicData: ReduxReducer<
	State,
	ExecutorLocations.Model[]
> = (state, action) => {
	state.subscription.basic = action.payload;
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const globalStateExecutorLocationsData = createSlice({
	name: GlobalState.ConstantGlobalStateSlice.EXECUTOR_LOCATIONS_DATA,
	initialState,
	reducers: {
		reset,
		setSubscriptionBasicData,
	},
});

export const globalStateExecutorLocationsDataAction =
	globalStateExecutorLocationsData.actions;
export const globalStateExecutorLocationsDataReducer =
	globalStateExecutorLocationsData.reducer;
export type globalStateExecutorLocationsDataState = State;

export default globalStateExecutorLocationsData;
