/**
 * Transforms an item into a select option format.
 *
 * This function is used to standardize the format of select options
 * by mapping an item's properties to the expected structure. Additionally,
 * it supports an optional `nameCompany` property to include more context
 * in the displayed label.
 *
 * @param {Object} item - The item to transform into a select option.
 * @param {number} item.id - The unique identifier of the item.
 * @param {string} item.label - The main display label of the item.
 * @param {string} [item.nameCompany] - (Optional) The name of the company associated with the item.
 *
 * @returns {selectOptionsWrap.SelectOption} The formatted select option, containing:
 * - `key`: A unique identifier used in selection components.
 * - `value`: The same unique identifier, used for selection logic.
 * - `label`: The primary label displayed in the UI.
 * - `labelFull`: A more detailed label that includes `nameCompany` if provided.
 */
const selectOptionsWrap = ({
	id,
	label,
	nameCompany,
}: {
	id: number;
	label: string;
	nameCompany?: string;
}): selectOptionsWrap.SelectOption => {
	// Construct the full label by appending the company name in parentheses if available.
	const labelFull = nameCompany ? `${label} (${nameCompany})` : label;

	return {
		key: id, // Unique key used in selection components
		value: id, // The value stored and used for selection
		label, // Main display label
		labelFull, // Extended label including company name if provided
	};
};

/**
 * Namespace defining the structure of a SelectOption.
 * This ensures consistency across different parts of the application
 * that work with select options.
 */
declare namespace selectOptionsWrap {
	/**
	 * Represents a structured option for select components.
	 *
	 * @property {number} key - The unique identifier of the option.
	 * @property {number} value - The value used for selection logic.
	 * @property {string} label - The primary display label.
	 * @property {string} labelFull - The detailed label including `nameCompany` if provided.
	 */
	type SelectOption = {
		key: number;
		value: number;
		label: string;
		labelFull: string;
	};
}

export default selectOptionsWrap;
