import React, {
	useState,
	useLayoutEffect,
	useEffect,
	useCallback,
	useMemo,
	Dispatch,
} from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Nullable } from "uikit";

import { SIP, SIPToDispatcher } from "../../../../../../../../services";
import softphoneWidget from "../../../../../../../../redux/reducers/softphoneWidget";
import sipToDispatcher from "../../../../../../../../redux/reducers/sipToDispatcher";
import { useTypedSelector } from "../../../../../../../../redux/store";
import { Divider } from "../../../../../../../common";
import { MultiSelectWithModalBase } from "../../../../../../../common/Modal";
import LabeledField from "../../../../../../../LabeledField";
import Loader from "../../../../../../../Loader";
import DeleteModal from "../../../../../../../DeleteModal";

import Sip from "./components/Sip";

const ControlButton = styled(Button.Button)`
	height: 32px;
	min-width: 32px;
	width: 200px;
	border: 1px solid;
	border-color: #dee0e2;
	border-radius: 5px;
`;

const StyledLabeledField = styled(LabeledField)`
	// margin: 0 0 0.75rem 0;
	width: 200px;
	color: rgba(0, 0, 0, 0.87);
	justify-items: center;
	text-align: center;
	font-weight: 700;
	font-size: 1.1rem;
	line-height: 1.3;
`;

const Message = styled.div`
	padding-top: 10px;
	cursor: default;
`;

const DividerCustomer = styled(Divider)`
	margin-top: 10px;
	margin-bottom: 10px;
`;

const PopupContent: React.FC<PopupContent.Props> = ({
	onClose,
	setShowModalExternal,
	userId,
	setSelectOptionsExternal,
	choiseSIPidExternal,
	setChoiseSIPidExternal,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { uaConfig } = useTypedSelector((state) => state.sipToDispatcher);
	const { callsData } = useTypedSelector((state) => state.softphoneWidget);

	const textTranslate = useMemo(
		() => ({
			internalSIP: t("main_header.sip.internal") ?? "",
			externalSIP: t("main_header.sip.external") ?? "",
			emptySIP: t("main_header.sip.none2") ?? "",
			lines: t("main_header.sip.lines") ?? "",
			internalLine: t("main_header.sip.internal_line") ?? "",
			externalLine: t("main_header.sip.external_line") ?? "",
			sipAlert: t("main_header.sip.alert") ?? "",
			noAvailable: t("main_header.sip.no_available") ?? "",
		}),
		[t],
	);

	const [isLoading, setIsLoading] = useState(true);
	const [isHardPhoneSipsListEmpty, setIsHardPhoneSipsListEmpty] =
		useState(false);
	const [isSoftPhoneSipsListEmpty, setIsSoftPhoneSipsListEmpty] =
		useState(false);
	const [softPhoneSipsData, setSoftPhoneSipsData] = useState<
		SIP.Model.Available[]
	>([]);
	const [hardPhoneSipsData, setHardPhoneSipsData] = useState<
		SIP.Model.Available[]
	>([]);

	const getSips = useCallback(async () => {
		const sips = await SIPToDispatcher.getAvailable({
			dispatcherId: userId,
			status: "ALL",
		});

		const filtredHardPhoneSips = sips.filter((sip) => !sip.isSoftPhone);
		if (!filtredHardPhoneSips.length) {
			setIsHardPhoneSipsListEmpty(true);
		}
		const filtresSoftPhoneSips = sips.filter((sip) => sip.isSoftPhone);
		if (!filtresSoftPhoneSips.length) {
			setIsSoftPhoneSipsListEmpty(true);
		}

		setHardPhoneSipsData(filtredHardPhoneSips);
		setSoftPhoneSipsData(filtresSoftPhoneSips);
		setIsLoading(false);
		return null;
	}, [userId]);

	const onSubmitSip = useCallback(
		async (sipId: number) => {
			const data = await SIPToDispatcher.initSession({
				sipToDispatcherId: sipId,
			});

			if (data.success) {
				const selectedHardPhoneSip = hardPhoneSipsData.find(
					(sip) => sip.sipToDispatcherId === sipId,
				);
				const selectedSoftPhoneSip = softPhoneSipsData.find(
					(sip) => sip.sipToDispatcherId === sipId,
				);

				const sipToDispatcherId =
					selectedHardPhoneSip?.sipId ||
					selectedSoftPhoneSip?.sipId ||
					sipId;

				if (selectedHardPhoneSip) {
					dispatch(
						sipToDispatcher.actions.setSip({
							id: sipToDispatcherId,
							sipValue: selectedHardPhoneSip.sipValue,
						}),
					);
					dispatch(
						sipToDispatcher.actions.setLineType(
							textTranslate.externalLine,
						),
					);
				}

				if (selectedSoftPhoneSip) {
					dispatch(
						sipToDispatcher.actions.setSip({
							id: sipToDispatcherId,
							sipValue: selectedSoftPhoneSip.sipValue,
						}),
					);
					dispatch(
						sipToDispatcher.actions.setLineType(
							textTranslate.internalLine,
						),
					);
				}

				sessionStorage.setItem(
					"sipToDispatcherId",
					JSON.stringify(sipToDispatcherId || sipId),
				);
				sessionStorage.setItem("dispatcherId", JSON.stringify(userId));
				sessionStorage.setItem(
					"sipData",
					JSON.stringify({
						dispatcherId: userId,
						sipToDispatcherId: sipToDispatcherId || sipId,
						sipValue:
							selectedHardPhoneSip?.sipValue ||
							selectedSoftPhoneSip?.sipValue,
						lineType: selectedSoftPhoneSip
							? textTranslate.internalLine
							: textTranslate.externalLine,
					}),
				);
			}
			onClose();
		},
		[
			onClose,
			hardPhoneSipsData,
			softPhoneSipsData,
			userId,
			textTranslate.internalLine,
			textTranslate.externalLine,
			dispatch,
		],
	);

	const checkSipForActivity = (lastCheckMs: Nullable<string>) => {
		const isActive = Number(lastCheckMs) < 1;
		return isActive;
	};

	const onSubmitSoftPhoneSip = useCallback(async () => {
		for (let i = 0; i < softPhoneSipsData.length; i++) {
			const sip = softPhoneSipsData[i];
			if (checkSipForActivity(sip.lastCheckMs)) {
				await onSubmitSip(sip.sipToDispatcherId);
				onClose();
				return null;
			}
		}
		setIsSoftPhoneSipsListEmpty(true);
		return null;
	}, [onClose, onSubmitSip, softPhoneSipsData]);

	const clearSipSession = useCallback(async () => {
		await SIPToDispatcher.initSession({
			sipToDispatcherId: -1,
		});
		uaConfig?.ua.stop();
		dispatch(softphoneWidget.actions.clearCalls());
		dispatch(softphoneWidget.actions.setSelectedCaller(null));
		dispatch(softphoneWidget.actions.setIsOpen(false));
		dispatch(sipToDispatcher.actions.clearSipToDispatcher());
		sessionStorage.removeItem("sipToDispatcherId");
		sessionStorage.removeItem("dispatcherId");
		sessionStorage.removeItem("sipData");
		onClose();
	}, [dispatch, onClose, uaConfig?.ua]);

	const [selectedLineType, setSelectedLineType] =
		useState<PopupContent.LineType>(null);

	const isEmptyList =
		(selectedLineType === "external" && isHardPhoneSipsListEmpty) ||
		(selectedLineType === "internal" && isSoftPhoneSipsListEmpty);

	const [showModal, setShowModal] = useState(false);

	const closeModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const openModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const variousSipsChanges = useCallback(
		(lineType: PopupContent.LineType) => {
			if (lineType === "internal") onSubmitSoftPhoneSip();
			if (lineType === "none") clearSipSession();
		},
		[clearSipSession, onSubmitSoftPhoneSip],
	);

	const onConfirm = useCallback(() => {
		variousSipsChanges(selectedLineType);
	}, [selectedLineType, variousSipsChanges]);

	useLayoutEffect(() => {
		if (isLoading) getSips();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedLineType === "internal") {
			if (callsData.length) {
				openModal();
				return;
			}
			onSubmitSoftPhoneSip();
		}
	}, [callsData.length, onSubmitSoftPhoneSip, openModal, selectedLineType]);

	useEffect(() => {
		if (selectedLineType === "none") {
			if (callsData.length) {
				openModal();
				return;
			}
			clearSipSession();
		}
	}, [
		callsData.length,
		clearSipSession,
		dispatch,
		onClose,
		openModal,
		selectedLineType,
		uaConfig?.ua,
	]);

	useEffect(() => {
		if (selectedLineType === "external" && !!hardPhoneSipsData.length) {
			const selectOptionsExternal: MultiSelectWithModalBase.Value =
				hardPhoneSipsData.map((sip) => ({
					key: sip.sipToDispatcherId,
					label: sip.sipValue,
					value: sip.sipToDispatcherId,
					name: sip.sipValue,
					data: sip,
					iconBeforeText: (
						<Sip key={sip?.sipToDispatcherId} data={sip} />
					),
				}));

			setSelectOptionsExternal(selectOptionsExternal);
		} else setSelectOptionsExternal([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hardPhoneSipsData, selectedLineType]);

	useEffect(() => {
		if (choiseSIPidExternal) {
			onSubmitSip(choiseSIPidExternal);
			setChoiseSIPidExternal(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [choiseSIPidExternal]);

	return (
		<>
			<StyledLabeledField label={textTranslate.lines} />
			{isLoading ? (
				<Loader active dimmed={false} />
			) : (
				<>
					<ControlButton
						onClick={() => {
							setSelectedLineType("external");
							setShowModalExternal(true);
						}}
						text={textTranslate.externalSIP}
						variant="white"
					/>

					<ControlButton
						onClick={() => {
							setSelectedLineType("internal");
							onSubmitSoftPhoneSip();
						}}
						text={textTranslate.internalSIP}
						variant="white"
					/>

					<DividerCustomer side="top" />

					<ControlButton
						onClick={() => {
							setSelectedLineType("none");
							clearSipSession();
						}}
						text={textTranslate.emptySIP}
						variant="white"
					/>
				</>
			)}
			{isEmptyList && <Message>{textTranslate.noAvailable}</Message>}
			{showModal && (
				<DeleteModal
					label={textTranslate.sipAlert}
					onConfirm={onConfirm}
					onCancel={closeModal}
				/>
			)}
		</>
	);
};

declare namespace PopupContent {
	interface Props {
		onClose: () => void;
		setShowModalExternal: Dispatch<boolean>;
		userId: number;
		setSelectOptionsExternal: (
			value: MultiSelectWithModalBase.Value,
		) => void;
		choiseSIPidExternal: number | null;
		setChoiseSIPidExternal: Dispatch<number | null>;
	}

	type LineType = "internal" | "external" | "none" | null;
}

export default PopupContent;
