import React, { useMemo } from "react";

import {
	Language,
	ExecutorReport,
} from "../../../../../../../../../../services";
import { ValueLanguage } from "../../../../../../../../../../assets/languages/langs";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (
	item: GroupCellContent.Props["item"],
	language: ValueLanguage,
) => {
	if (!item || !item.group) return "";

	return item.group?.name?.[language] || "";
};

const GroupCellContent: React.FC<GroupCellContent.Props> = ({
	item,
	language,
}) => {
	const content = useMemo(
		() => cellProcessing(item, language),
		[item, language],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace GroupCellContent {
	interface Props {
		item: ExecutorReport.Model;
		language: Language;
	}
}

export default GroupCellContent;
