import { useCallback, useMemo } from "react";

import { Service } from "../../services";
import { useTypedSelector } from "../../redux/store";
import { isTextMatchingAnySegmentFilterHelper } from "../../utils";

const useServiceSubHandler = (): useServiceSubHandler.Props => {
	const lang = useTypedSelector((state) => state.session.language);
	const serviceSubscribe = useTypedSelector(
		(state) => state.preferencesReducer.services.data.models,
	);

	const getAllServiceSubHandler = useCallback(
		({
			limit,
			query,
			taxiServiceIds,
		}: useServiceSubHandler.SmallSubscribeOptions): Service.Model[] => {
			if (!Array.isArray(serviceSubscribe)) {
				return [];
			}

			let retval = [...(serviceSubscribe || [])];

			const [isQuery, isLimit, isTaxiServiceIds] = [
				typeof query === "string" && query !== "",
				typeof limit === "number",
				Array.isArray(taxiServiceIds) && taxiServiceIds?.length,
			];

			if (isQuery) {
				retval = retval.filter(
					(item) =>
						typeof item.name?.[lang] === "string" &&
						typeof query === "string" &&
						isTextMatchingAnySegmentFilterHelper(
							item.name[lang],
							query,
						),
				);
			}

			if (isTaxiServiceIds) {
				retval = retval.filter((item) => {
					if (
						Array.isArray(item.taxiServices) &&
						Array.isArray(taxiServiceIds)
					) {
						return item.taxiServices.some((taxiService) =>
							taxiServiceIds.includes(taxiService.id),
						);
					}
					return false;
				});
			}

			if (isLimit) {
				retval = retval.slice(0, limit);
			}

			return retval;
		},
		[lang, serviceSubscribe],
	);

	return useMemo(
		() => ({
			getAllServiceSubHandler,
		}),
		[getAllServiceSubHandler],
	);
};

declare namespace useServiceSubHandler {
	interface Props {
		getAllServiceSubHandler: (
			data: SmallSubscribeOptions,
		) => Service.Model[];
	}

	interface SmallSubscribeOptions {
		query?: string;
		limit?: number;
		taxiServiceIds?: number[];
	}
}

export default useServiceSubHandler;
