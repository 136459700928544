import React, { useMemo } from "react";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const AdditionalPhoneCellContent: React.FC<
	AdditionalPhoneCellContent.Props
> = ({ item }) => {
	const content = useMemo(
		() =>
			[...(item.phones ?? [])].sort(
				(a, b) => (a.group ?? 0) - (b.group ?? 0),
			)?.[1]?.number ?? "",
		[item.phones],
	);
	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace AdditionalPhoneCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default AdditionalPhoneCellContent;
