import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import Language from "../../../../../../../../../../services/Language";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const Constant: Record<Language, string> = {
	ru: "Касса",
	uk: "Каса",
	en: "Cash",
	tr: "Cash",
	az: "Cash",
	ro: "Numerar",
};

export const cellProcessing = (item: OverallCashCellContent.Props["item"]) => {
	if (!item || !item.executorRateColumns) return "";
	const exist = item.executorRateColumns.find(
		(data) => data.name?.en === Constant.en,
	);

	if (!exist) return "";
	if (!isNumber(exist?.amount)) return "";

	return formatNumber(exist?.amount);
};

const OverallCashCellContent: React.FC<OverallCashCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace OverallCashCellContent {
	interface Props {
		item: ExecutorReport.Model;
		language: Language;
	}
}

export default OverallCashCellContent;
