import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import BaseOptions from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import { Base } from "..";

const logger = createLogger({ name: "AgentGroup" });

class AgentGroup extends Base {
	public static fromResponse(data: any): AgentGroup.Model {
		return {
			id: data.id,

			name: data.name,
			agentIds: data.agents?.map(({ id }) => id) ?? [],
			notes: data.notes,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: AgentGroup.New | AgentGroup.Modified) {
		const toRequest: Record<string, any> = {};

		const { name } = model;
		if (name) toRequest.name = name;

		const { agentIds } = model;
		if (agentIds) toRequest.agentIds = agentIds;

		const { notes } = model;
		if (notes) toRequest.notes = notes;

		return toRequest;
	}

	public static async store(object: AgentGroup.New) {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.agentGroup.create(this.toRequest(object)),
			);
			return !!res;
		} catch (err: any) {
			logger.error("[AgentGroup] Error store:", err);
			if (err.message.includes("force")) {
				return false;
			}
		}
		return true;
	}

	public static async update(object: AgentGroup.Modified) {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.agentGroup.update(
					object.id,
					this.toRequest(object),
				),
			);
			return !!res;
		} catch (err) {
			logger.error("[AgentGroup] Error update:", err);
			return false;
		}
	}

	public static async delete(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.agentGroup.delete(id));
	}

	public static async subscribe(
		options: AgentGroup.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<AgentGroup.Model>,
	): Promise<Subscription<AgentGroup.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("[AgentGroup] subscribe", state);
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.agentGroup.subscribe({
					params: options,
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "AgentGroup.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: AgentGroup.SubscribeOptions) =>
				subscription.update(options),
		} as Subscription<AgentGroup.SubscribeOptions>;
	}
}

declare namespace AgentGroup {
	interface Model extends NonEditableProperties {
		name: string;
		agentIds: number[];
		notes: string;
	}

	type New = Omit<Model, NonEditablePropertyNames>;
	type Modified = Partial<New> & { readonly id: number };

	interface SubscribeOptions extends BaseOptions<AgentGroup.Model> {}
}

export default AgentGroup;
