import React, {
	Dispatch,
	memo,
	useCallback,
	useMemo,
	useState,
	useEffect,
	useRef,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Counterparty } from "../../../../services";
import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";
import { StyledP, StyledRow, StyledColumn } from "../..";

const EqualWidthColumn = styled(StyledColumn)`
	flex: 1;
`;

const MultiSelectCounterparty: React.FC<MultiSelectCounterparty.Props> = ({
	value = [],
	onChange,
	companyIds = [],
	disabled,
	titleText,
	required,
	modalSetting,
	sort,
	subscribeOptions = {},
	canUseCompanyIds = true,
	canShowBillText = false,
	canShowCardText = false,
}) => {
	const { t } = useTranslation();

	const prevOnChange = useRef(onChange);
	useEffect(() => {
		prevOnChange.current = onChange;
	}, [onChange]);

	const counterpartySubscribe = useModelSubscribe(
		// eslint-disable-next-line react-hooks/exhaustive-deps
		useMemo(() => subscribeOptions, [JSON.stringify(subscribeOptions)]),
		Counterparty,
	);

	const [error, setError] = useState("");

	const texts = useMemo(
		() => ({
			title: t("contragentChoise"),
			allText: t("all_counterparty"),
			allActiveText: t("all_active_counterparty"),
			erpou: t(
				"mainPage.customers.counterparties.modal.tabs.main.mainData.edrpou",
			),
			cards: t("cards"),
			bill: t("bill"),
		}),
		[t],
	);

	const selectOptions = useMemo(() => {
		const DEFAULT_TEXT_EMPTY = "Unknown";

		return counterpartySubscribe.models.map((item) => {
			const { id, additionalFields, checks } = item;
			const name = additionalFields?.name ?? DEFAULT_TEXT_EMPTY;
			const erpou = additionalFields?.edrpou ?? DEFAULT_TEXT_EMPTY;
			const erpouFull = `${texts.erpou}: ${erpou}`;

			const bill = checks
				.map(
					(check) =>
						check.additionalFields?.checkValue ??
						DEFAULT_TEXT_EMPTY,
				)
				.join(", ");
			const billFull = canShowBillText ? `; ${texts.bill}: ${bill}` : "";

			const cards = checks
				.flatMap(
					(check) =>
						check.checkCards?.map(
							(card) => card.code?.value ?? DEFAULT_TEXT_EMPTY,
						) || [],
				)
				.join(", ");
			const cardsFull = canShowCardText
				? `; ${texts.cards}: ${cards}`
				: "";

			const textRowTable = `${name} (${erpouFull}${billFull}${cardsFull})`;

			return {
				key: id,
				label: textRowTable,
				value: id,
				name: textRowTable,
				data: item,
			};
		});
	}, [counterpartySubscribe.models, texts, canShowBillText, canShowCardText]);

	const selectedSet = useMemo(() => new Set(value), [value]);
	const selected = useMemo(
		() => selectOptions.filter((item) => selectedSet.has(item.value)),
		[selectOptions, selectedSet],
	);

	const selectOnChange = useCallback((newValue) => {
		setError("");
		prevOnChange.current(newValue.map((item) => item.value));
	}, []);

	const isDisabledThenCompanyLength = canUseCompanyIds && !companyIds.length;

	useEffect(() => {
		if (disabled || isDisabledThenCompanyLength) {
			prevOnChange.current([]);
		}
	}, [disabled, isDisabledThenCompanyLength]);

	return (
		<StyledRow gap="7px" alignItems="center">
			<EqualWidthColumn gap="7px">
				{titleText && <StyledP>{t(titleText) ?? ""}</StyledP>}
				<MultiSelectWithModal
					value={selected}
					onChange={selectOnChange}
					options={selectOptions}
					error={error}
					disabled={disabled || isDisabledThenCompanyLength}
					title={texts.title}
					allText={texts.allText}
					allActiveText={texts.allActiveText}
					required={required}
					pullUpItemInsideArray
					hiddenButton={false}
					showSelectAll
					modalSetting={modalSetting}
					sort={{ show: true, active: true, ...(sort || {}) }}
				/>
			</EqualWidthColumn>
		</StyledRow>
	);
};

declare namespace MultiSelectCounterparty {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText?: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		companyIds?: number[];
		subscribeOptions?: Counterparty.SubscribeOptions;
		canUseCompanyIds?: boolean;
		canShowBillText?: boolean;
		canShowCardText?: boolean;
	}

	type Value = number[];
}

export default memo(MultiSelectCounterparty);
