import React, { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import {
	ChatMessage as ChatMessageServices,
	Executor,
	Dispatcher,
} from "../../../../../services";

import { Root, ExecutorComponentAvatarGroup } from "./components";

const MarkedUserChatVersion: React.FC<MarkedUserChatVersion.Props> = ({
	data,
}) => {
	const executors = data?.chatMessageToAllowedExecutors;
	const dispatchers = data?.chatMessageToAllowedDispatchers;

	const convertDispatcher = useCallback(
		(user: {
			dispatcher?: MarkedUserChatVersion.DispatcherWithPerson;
		}) => ({
			id: user?.dispatcher?.person?.id ?? uuidv4(),
			name: user?.dispatcher?.person?.name ?? "",
		}),
		[],
	);

	const convertExecutor = useCallback(
		(user: { executor?: MarkedUserChatVersion.ExecutorWithPerson }) => ({
			...(user?.executor ?? {}),
			id: user?.executor?.person?.id ?? uuidv4(),
			name: user?.executor?.person?.name ?? "",
		}),
		[],
	);

	const userListData = useMemo(() => {
		const dispatchersData = (dispatchers ?? []).map((dispatcher) =>
			convertDispatcher(dispatcher),
		);

		const executorsData = (executors ?? []).map((executor) =>
			convertExecutor(executor),
		);

		return [...dispatchersData, ...executorsData].filter((user) => !!user);
	}, [convertDispatcher, convertExecutor, dispatchers, executors]);

	if (userListData.length === 0) return null;

	return (
		<Root
			width={userListData.length <= 4 ? userListData.length * 17.6 : 71}
		>
			<ExecutorComponentAvatarGroup arrayUsers={userListData} />
		</Root>
	);
};

declare namespace MarkedUserChatVersion {
	interface Props {
		data?: {
			chatMessageToAllowedDispatchers?: {
				dispatcher: DispatcherWithPerson;
			}[];
			chatMessageToAllowedExecutors?: {
				executor: ExecutorWithPerson;
			}[];
		};
	}

	interface ExecutorWithPerson extends Executor {
		person?: ChatMessageServices.OutputGrouped.Person;
	}

	interface DispatcherWithPerson extends Dispatcher {
		person?: ChatMessageServices.OutputGrouped.Person;
	}

	interface UserData {
		id: number | string;
		name: string;
	}
}

export default MarkedUserChatVersion;
