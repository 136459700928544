import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Executor } from "../../services";
import { useTypedSelector } from "../../redux/store";
import { OrderPageExecutorColumnId } from "../../redux/reducers/Orders/Executors/tableSettings";
import {
	sortByNumberOrString,
	isTextMatchingLeadingStarsFilterHelper,
} from "../../utils";
import { getExecutorStatuses } from "../../components/OrderPageWidgets/Executors/constants";
import { ColumnId as ColumnIdFromTabExecutors } from "../../pages/MainPage/pages/Accounts/tabs/Executors/components/Table/columns";

import { getFullName, getPhone } from ".";

const useExecutorsSubHandler = (): useExecutorsSubHandler.Props => {
	enum ColumnIdAdditional {
		CallSignExecutor = "callSign",
		Name = "name",
		FullName = "fullName",
		Phone = "phone",
		Phones = "phones",
		ExecutorGroup = "executorGroup",
		Roles = "roles",
		Team = "team",
	}

	const { t } = useTranslation();
	const executorsSubscribe = useTypedSelector(
		(state) => state.globalState.executors.data.subscription.basic,
	);
	const lang = useTypedSelector((state) => state.session.language);
	const executorRate = useTypedSelector(
		(state) => state.accounts.executors.data.executorRate,
	);

	const EXECUTOR_STATUSES = useMemo(() => getExecutorStatuses(t), [t]);

	const getStatusData = useCallback(
		(rowData) => {
			const isWorking = rowData?.isWorking ?? false;

			const onOwnOrder =
				rowData?.executorToOrders?.find(
					(order) => order.source === "executor" && !order.closedAt,
				) ?? false;

			const hasDebt = rowData?.debt ?? false;

			let status = rowData?.status;

			if (isWorking) {
				if (hasDebt) {
					if (onOwnOrder) {
						status = "ownOrderDebt";
					} else {
						status = "availableDebt";
					}
				} else if (onOwnOrder) {
					status = "ownOrder";
				}
			} else {
				status = "notWorking";
			}

			const statusData = EXECUTOR_STATUSES[status];

			return statusData;
		},
		[EXECUTOR_STATUSES],
	);

	const getStatusBackgroundColor = useCallback(
		(rowData) => {
			const online = rowData?.online ?? false;

			const statusData = getStatusData(rowData);

			return online ? statusData?.color : statusData?.offlineColor;
		},
		[getStatusData],
	);

	const getStatusFontColor = useCallback(
		(rowData) => {
			const statusData = getStatusData(rowData);

			return statusData?.fontColor;
		},
		[getStatusData],
	);

	const getStatusName = useCallback(
		(rowData) => {
			const isYourOrderStatus = rowData?.executorToOrders?.find(
				(order) => order.source === "executor" && !order.closedAt,
			);

			if (rowData.debt) {
				if (rowData.status === "available") {
					return EXECUTOR_STATUSES.availableDebt.label;
				}
				if (rowData.closedBy) return EXECUTOR_STATUSES.closed.label;

				if (isYourOrderStatus) {
					return EXECUTOR_STATUSES.ownOrderDebt.label;
				}

				return `${EXECUTOR_STATUSES[rowData.status].label} ${
					t("orderPageWidgets.executors.modelTable.str200") ?? ""
				}`;
			}

			if (isYourOrderStatus) {
				return EXECUTOR_STATUSES.ownOrder.label;
			}
			return EXECUTOR_STATUSES[rowData.status].label;
		},
		[t, EXECUTOR_STATUSES],
	);

	const getValueStatus = useCallback(
		(rowData) => {
			const name = getStatusName(rowData);
			const color = getStatusFontColor(rowData);
			const bg = getStatusBackgroundColor(rowData);
			return `${name}${color}${bg}`;
		},
		[getStatusBackgroundColor, getStatusFontColor, getStatusName],
	);

	const getAllExecutorsSubHandler = useCallback(
		({
			order,
			query,
			limit,
			taxiServiceIds,
			isOnline,
			workingStatus,
			statuses,
		}: useExecutorsSubHandler.SmallSubscribeOptions): Executor.Model[] => {
			if (!Array.isArray(executorsSubscribe)) {
				return [];
			}

			let retval = [...(executorsSubscribe || [])];

			const [
				isQuery,
				_isOnline,
				isWorkingStatus,
				isStatuses,
				isTaxiServiceIds,
				isOrder,
				isLimit,
			] = [
				typeof query === "string" && query !== "",
				typeof isOnline === "boolean",
				typeof workingStatus === "boolean",
				Array.isArray(statuses) && statuses.length > 0,
				Array.isArray(taxiServiceIds) && taxiServiceIds?.length,
				typeof order === "object" &&
					order !== null &&
					!Array.isArray(order),
				typeof limit === "number",
			];

			if (isQuery) {
				retval = retval.filter(
					(item) =>
						query &&
						isTextMatchingLeadingStarsFilterHelper(
							item.alias,
							query,
						),
				);
			}

			if (_isOnline) {
				retval = retval.filter((item) => item.online === isOnline);
			}

			if (isWorkingStatus) {
				retval = retval.filter(
					(item) => item.isWorking === workingStatus,
				);
			}

			if (isStatuses) {
				retval = retval.filter(
					(item) =>
						statuses &&
						item.status &&
						statuses.includes(item.status),
				);
			}

			if (isTaxiServiceIds) {
				retval = retval.filter(
					(item) =>
						item.taxiService?.id &&
						taxiServiceIds &&
						taxiServiceIds.includes(item.taxiService.id),
				);
			}

			if (isOrder) {
				const [[dataKey, sortType] = []] = Object.entries(order || {});
				const isSort =
					typeof sortType === "string" &&
					typeof dataKey === "string" &&
					["asc", "desc"].includes(sortType.toLowerCase());

				if (isSort) {
					const isAscending = sortType.toLowerCase() === "asc";
					const sortOrder = isAscending ? 1 : -1;

					if (
						dataKey === ColumnIdAdditional.ExecutorGroup ||
						dataKey === ColumnIdAdditional.Team
					) {
						retval = retval.sort((prev, next) => {
							const findGroupName = (model: Executor.Model) =>
								model.group?.name?.[lang] ?? "";

							const prevValue = `${findGroupName(prev)}` || "";
							const nextValue = `${findGroupName(next)}` || "";

							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});
					}

					if (
						dataKey === ColumnIdAdditional.Phone ||
						dataKey === ColumnIdAdditional.Phones
					) {
						retval = retval.sort((prev, next) => {
							const prevValue = getPhone(prev.person);
							const nextValue = getPhone(next.person);

							return (
								sortByNumberOrString(
									prevValue,
									nextValue,
									true,
								) * sortOrder
							);
						});
					}

					if (
						dataKey === ColumnIdAdditional.Name ||
						dataKey === ColumnIdAdditional.FullName
					) {
						retval = retval.sort((prev, next) => {
							const prevValue = getFullName(prev.person);
							const nextValue = getFullName(next.person);

							return (
								sortByNumberOrString(
									prevValue,
									nextValue,
									true,
								) * sortOrder
							);
						});
					}

					if (dataKey === OrderPageExecutorColumnId.ONLINE) {
						const sort = retval.sort((prev, next) => {
							const prevValue = `ONLINE${prev.online}`;
							const nextValue = `ONLINE${next.online}`;
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (
						dataKey === OrderPageExecutorColumnId.ALIAS ||
						dataKey ===
							ColumnIdAdditional.CallSignExecutor /* for executor */
					) {
						const sort = retval.sort((prev, next) => {
							const prevValue = prev.alias;
							const nextValue = next.alias;
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (
						dataKey ===
						OrderPageExecutorColumnId.CallSign /* for car */
					) {
						const sort = retval.sort((prev, next) => {
							const prevValue =
								prev.executorToCrews[0]?.crew?.car?.callSign ||
								"";
							const nextValue =
								next.executorToCrews[0]?.crew?.car?.callSign ||
								"";
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === OrderPageExecutorColumnId.EXECUTOR_GROUP) {
						const sort = retval.sort((prev, next) => {
							const prevValue = prev?.group?.name?.[lang] || "";
							const nextValue = next?.group?.name?.[lang] || "";
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === OrderPageExecutorColumnId.COMPANY) {
						const sort = retval.sort((prev, next) => {
							const prevValue =
								prev?.taxiService?.company?.name?.[lang] || "";
							const nextValue =
								next?.taxiService?.company?.name?.[lang] || "";
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === OrderPageExecutorColumnId.TAXI_SERVICE) {
						const sort = retval.sort((prev, next) => {
							const prevValue =
								prev?.taxiService?.settlement?.[lang] || "";
							const nextValue =
								next?.taxiService?.settlement?.[lang] || "";
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === OrderPageExecutorColumnId.STATUS) {
						const sort = retval.sort((prev, next) => {
							const prevValue = getValueStatus(prev) || "";
							const nextValue = getValueStatus(next) || "";
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === OrderPageExecutorColumnId.IS_WORKING) {
						const sort = retval.sort((prev, next) => {
							const prevValue = `${prev?.isWorking}` || "";
							const nextValue = `${next?.isWorking}` || "";
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === ColumnIdFromTabExecutors.RegisteredAt) {
						const sort = retval.sort((prev, next) => {
							const prevValue = prev.createdAt
								? Date.parse(prev.createdAt) || 0
								: 0;
							const nextValue = next.createdAt
								? Date.parse(next.createdAt) || 0
								: 0;
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === ColumnIdFromTabExecutors.ParkNumber) {
						const sort = retval.sort((prev, next) => {
							const findNumber = (model: Executor.Model) =>
								model.cars
									?.filter((c) => !!c.parkNumber)
									?.map((c) => c.parkNumber)
									?.join(", ") ?? "";

							const prevValue = `${findNumber(prev)}` || "";
							const nextValue = `${findNumber(next)}` || "";

							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === ColumnIdFromTabExecutors.Balance) {
						const sort = retval.sort((prev, next) => {
							const findMoney = (model: Executor.Model) =>
								model.paymentAccounts?.find(
									(a) => a.type === "main",
								)?.amount ?? 0;

							const prevValue = findMoney(prev);
							const nextValue = findMoney(next);

							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === ColumnIdFromTabExecutors.BonusBalance) {
						const sort = retval.sort((prev, next) => {
							const findMoney = (model: Executor.Model) =>
								model.paymentAccounts?.find(
									(a) => a.type === "bonus",
								)?.amount ?? 0;

							const prevValue = findMoney(prev);
							const nextValue = findMoney(next);

							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (
						dataKey === ColumnIdFromTabExecutors.Role ||
						dataKey === ColumnIdAdditional.Roles
					) {
						const sort = retval.sort((prev, next) => {
							const findTextFullRole = (model: Executor.Model) =>
								model.roles
									?.map((role) => role.name)
									?.join(", ") ?? "";

							const prevValue = `${findTextFullRole(prev)}` || "";
							const nextValue = `${findTextFullRole(next)}` || "";

							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (
						dataKey ===
						ColumnIdFromTabExecutors.ResponsibleDispatcher
					) {
						const sort = retval.sort((prev, next) => {
							const findTextFullName = (model: Executor.Model) =>
								[
									model.responsibleDispatcher?.person
										?.lastName ?? "",
									model.responsibleDispatcher?.person
										?.firstName ?? "",
									model.responsibleDispatcher?.person
										?.fatherName ?? "",
								]
									?.join(" ")
									?.trim() ?? "";

							const prevValue = `${findTextFullName(prev)}` || "";
							const nextValue = `${findTextFullName(next)}` || "";

							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === ColumnIdFromTabExecutors.ExecutorRateId) {
						const sort = retval.sort((prev, next) => {
							const findTextRate = (id: number | undefined) =>
								executorRate?.find((rate) => rate.id === id)
									?.name ?? "";

							const prevValue =
								`${findTextRate(prev?.priority)}` || "";
							const nextValue =
								`${findTextRate(next?.priority)}` || "";
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === ColumnIdFromTabExecutors.Priority) {
						const sort = retval.sort((prev, next) => {
							const prevValue = `${prev?.priority}` || "";
							const nextValue = `${next?.priority}` || "";
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}
				}
			}

			if (isLimit) {
				retval = retval.slice(0, limit);
			}

			return retval;
		},
		[
			ColumnIdAdditional.CallSignExecutor,
			ColumnIdAdditional.ExecutorGroup,
			ColumnIdAdditional.FullName,
			ColumnIdAdditional.Name,
			ColumnIdAdditional.Phone,
			ColumnIdAdditional.Phones,
			ColumnIdAdditional.Roles,
			ColumnIdAdditional.Team,
			executorRate,
			executorsSubscribe,
			getValueStatus,
			lang,
		],
	);

	return useMemo(
		() => ({
			getAllExecutorsSubHandler,
		}),
		[getAllExecutorsSubHandler],
	);
};

declare namespace useExecutorsSubHandler {
	interface Props {
		getAllExecutorsSubHandler: (
			data: SmallSubscribeOptions,
		) => Executor.Model[];
	}

	interface SmallSubscribeOptions {
		order?: Executor.Order;
		taxiServiceIds?: number[];
		query?: string;
		limit?: number;
		isOnline?: boolean;
		workingStatus?: boolean;
		statuses?: string[];
	}
}

export default useExecutorsSubHandler;
