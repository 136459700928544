/* eslint-disable import/order */
import { combineReducers } from "redux";

import initialReducer from "./initial.reducer";
import prpc from "./prpc";
import session from "./session";
import account from "./account";
import currentIP from "./currentIP";

// --- LOCAL OBJECTS PAGE ---
import localObjectsAndLocalGroups from "./map";
import company from "./company";
import taxiService from "./taxiService";
// ---

// --- ORDERS PAGE ---
import mapReducer from "./map.reducer";
import orderPage from "./orderPage";
import orderPageReducer from "./OrdersPage";
import modalReducer from "./modal.reducer";
import carModels from "./carModels";
import carBrands from "./carBrands";
import widgets from "./widgets";
import orderPageFilters from "./OrdersPage/Filters";
// ---

// --- SETTINGS ---
import {
	colors,
	fonts,
	order,
	preorders,
	clientApp,
	keybinds,
	messages,
	paymentSystems,
	mapSettings,
	settingState,
	executorAppGeneral,
	executorAppInterfaceOrders,
	executorAppInterfaceMap,
	executorAppInterfaceGeneral,
} from "./settings";
// ---

// --- PAGES ---
import preferencesReducer, { preferencesAction } from "./Preferences";
import sectors from "./sectors";
import parkings from "./parkings";
import priceZones from "./priceZones";
import dispatchers from "./dispatcher";
import executors from "./executors";
import services from "./services";
import roles from "./roles";
import permissions from "./permissions";
import workShifts from "./workShifts";
import schedules from "./schedules";
import carParks from "./carParks";
import carClasses from "./carClasses";
import carColors from "./carColors";
import carBodyTypes from "./carBodyTypes";
import _cars from "./_cars";
import clients from "./clients";
import language from "./language";
// ---
// --- ACCOUNTING --
import accountingReducers, { accountingAction } from "./Accounting";
// --- ACCOUNTS --
import accountsReducers, { accountsAction } from "./Accounts";

// --- Customers --
import customersReducers, { customersAction } from "./Customers";

// --- PAGES ---
import archiveReducers, { archiveAction } from "./Archives";
import archiveGPSLog, { archiveGPSLogAction } from "./Archives/GPSLog";
import archiveDriverShifts from "./Archives/DriverShifts";
import archiveReports from "./Archives/Reports";
import archiveCalls from "./Archives/Calls";

// --- Table Columns ---
import tableColumns from "./TableColumns";

// --- SETTINGS PAGE ---

// -- MESSAGE PAGE ---
import messageProviderSms from "./SettingsPage/Message/ProviderSms";
import applicationConfig from "./applicationConfig";
// ---
// ---

// --- SoftphoneWidget ---
import softphoneWidget from "./softphoneWidget";
// ---

// --- PAGES ORDERS ---
import ordersReducers, { ordersAction } from "./Orders";
import orderChatsFilters from "./Orders/Chat/filter";
import orderExecutorsFilters from "./Orders/Executors/filter";
import orderExecutorsTableSettings from "./Orders/Executors/tableSettings";
import orderParkingFilters from "./Orders/Parking/filter";
import orderParkingTableSettings from "./Orders/Parking/tableSettings";
import orderMapFilters from "./Orders/Map/filter";
import orderMapToggles from "./Orders/Map/toggles";
import orderMapFromAddModalToggles from "./Orders/MapFromAddModal/toggles";
import orderTabsFilters from "./Orders/Tabs/filter";

// --- PAGE REPORTS ---
import reportsReducer, { reportsAction } from "./Reports";

// --- General for all pages ---
import globalStateReducer, { globalStateAction } from "./GlobalState";

// import chat from "./Orders/Chat/_index";
// ---

// -- SipToDispatcher ---
import sipToDispatcher from "./sipToDispatcher";

import loading from "./loading";
import taxiServices from "./taxiServices";
// ---

// --- Additional Tariff Settings
import additionalTariff from "./additionalTariff";
import additionalTariffs from "./additionalTariffs";
// ---

// ---  Exports
export const archive = archiveAction;
export const orders = ordersAction;
export const report = reportsAction;
export const archiveGPS = archiveGPSLogAction;
export const accounting = accountingAction;
export const accounts = accountsAction;
export const customers = customersAction;
export const preferences = preferencesAction;
export const globalState = globalStateAction;

//---

const settings = combineReducers({
	fonts: fonts.reducer,
	keybinds: keybinds.reducer,
	colors: colors.reducer,

	order: order.reducer,
	preorders: preorders.reducer,
	map: mapSettings.reducer,

	executorAppGeneral: executorAppGeneral.reducer,
	executorAppInterfaceGeneral: executorAppInterfaceGeneral.reducer,
	executorAppInterfaceOrders: executorAppInterfaceOrders.reducer,
	executorAppInterfaceMap: executorAppInterfaceMap.reducer,

	clientApp: clientApp.reducer,
	messages: messages.reducer,

	paymentSystems: paymentSystems.reducer,
});

const orderPageLocalStorage = combineReducers({
	orderChatsFilters: orderChatsFilters.reducer,
	orderExecutorsFilters: orderExecutorsFilters.reducer,
	orderExecutorsTableSettings: orderExecutorsTableSettings.reducer,
	orderParkingFilters: orderParkingFilters.reducer,
	orderParkingTableSettings: orderParkingTableSettings.reducer,
	orderMapFilters: orderMapFilters.reducer,
	orderMapToggles: orderMapToggles.reducer,
	orderMapFromAddModalToggles: orderMapFromAddModalToggles.reducer,
	orderTabsFilters: orderTabsFilters.reducer,
});

const rootReducer = combineReducers({
	initialReducer,
	prpc: prpc.reducer,
	session: session.reducer,
	account: account.reducer,
	language: language.reducer,
	currentIP: currentIP.reducer,
	loading: loading.reducer,
	applicationConfig: applicationConfig.reducer,
	taxiServices: taxiServices.reducer,
	additionalTariffs: additionalTariffs.reducer,

	map: localObjectsAndLocalGroups,

	company: company.reducer,
	taxiService: taxiService.reducer,
	sectors: sectors.reducer,
	parkings: parkings.reducer,
	priceZones: priceZones.reducer,
	dispatchers: dispatchers.reducer,
	executors: executors.reducer,
	services: services.reducer,
	roles: roles.reducer,
	permissions: permissions.reducer,
	workShifts: workShifts.reducer,
	schedules: schedules.reducer,
	_cars: _cars.reducer,
	carParks: carParks.reducer,
	carClasses: carClasses.reducer,
	carColors: carColors.reducer,
	carBodyTypes: carBodyTypes.reducer,
	clients: clients.reducer,

	tableColumns: tableColumns.reducer,
	preferencesReducer,

	mapReducer,
	carModels: carModels.reducer,
	carBrands: carBrands.reducer,
	orderPage: orderPage.reducer,
	ordersPageReducer: orderPageReducer.reducer,
	orderPageFilters: orderPageFilters.reducer,
	modal: modalReducer.reducer,
	widgets: widgets.reducer,

	settings,
	settingState: settingState.reducer,
	archiveGPSLog: archiveGPSLog.reducer,
	archiveDriverShifts: archiveDriverShifts.reducer,
	archive: archiveReducers,
	archiveReports,
	archiveCalls,

	// accounting
	accounting: accountingReducers,

	// accounts
	accounts: accountsReducers,

	// customers
	customers: customersReducers,

	// settings page reducers
	messageProviderSms: messageProviderSms.reducer,

	// softphone widget
	softphoneWidget: softphoneWidget.reducer,

	// sipToDispatcher
	sipToDispatcher: sipToDispatcher.reducer,

	// orders
	orders: ordersReducers,
	orderPageLocalStorage,

	// general for all pages
	globalState: globalStateReducer,

	// reports page
	reports: reportsReducer,

	// additionalTariff
	additionalTariff: additionalTariff.reducer,
});

export default rootReducer;
