import React, { useMemo } from "react";

import { Role } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const NameCellContent: React.FC<NameCellContent.Props> = ({ item }) => {
	const content = useMemo(() => item.name ?? "", [item.name]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace NameCellContent {
	interface Props {
		item: Role.Model;
	}
}

export default NameCellContent;
