import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PaymentSystem, {
	NoticeDestinationApp,
	PaymentSystemFrontEndType,
} from "../../../../../services/PaymentSystem";
import { useTypedSelector } from "../../../../../redux/store";
import useModelSubscribe from "../../../../../hooks/useModelSubscribe2";
import mapByKey from "../../../../../utils/mapByKey";
import { SettingsTabProps } from "../../../../../components/Settings/tabProps";
import DeleteModal from "../../../../../components/DeleteModal";
import DefaultPageHeader from "../../../../../components/DefaultPageHeader";
import { useTaxiServiceFilterAccess } from "../../../../../access";
import translationPath from "../constants/translationPath";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../constants/access";

import Root from "./components/Root";
import Modal from "./components/Modal";
import Content from "./components/Content";

const Executor: React.FC<SettingsTabProps> = () => {
	const { t } = useTranslation();
	const { subTaxiServiceFilterAccess } =
		useTaxiServiceFilterAccess<PaymentSystem.Model>();

	const globalLang = useTypedSelector((state) => state.session.language);

	const [destinationAppFilter, setDestinationAppFilter] = useState([
		...Object.values(NoticeDestinationApp),
	]);

	const actualExecutors = useModelSubscribe(
		{
			types: [
				PaymentSystemFrontEndType.EXECUTOR,
				PaymentSystemFrontEndType.TEMPLATE_EXECUTOR,
			],
		},
		PaymentSystem,
	);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showButtonUpdate, setShowButtonUpdate] = useState<boolean>(false);
	const [showClarificationModal, setShowClarificationModal] = useState(false);
	const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
	const [errorStore, setErrorStore] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [loadingEditModal, setLoadingEditModal] = useState<boolean>(false);

	const [executors, setExecutors] = useState<PaymentSystem.Model[] | []>([]);
	const [defaultTemplate, setDefaultTemplate] = useState<PaymentSystem.Model>(
		PaymentSystem.fromResponse({}),
	);

	const modelsAfterAccess = useMemo(() => {
		const data = actualExecutors.models || [];
		const dataAfterAccess = subTaxiServiceFilterAccess({
			models: data,
			branchPath: "taxiServiceIds",
		});
		return dataAfterAccess;
	}, [actualExecutors.models, subTaxiServiceFilterAccess]);

	useEffect(() => {
		const defaultItem = modelsAfterAccess.find(
			(item) => item.type === PaymentSystemFrontEndType.TEMPLATE_EXECUTOR,
		);

		const models = modelsAfterAccess.filter(
			(item) => item.type === PaymentSystemFrontEndType.EXECUTOR,
		);

		if (defaultItem) setDefaultTemplate(defaultItem);
		setExecutors(models);
	}, [modelsAfterAccess]);

	const [editingItem, setEditingItem] = useState<PaymentSystem.Model>(
		PaymentSystem.fromResponse({}),
	);

	const [selected, setSelected] = useState<number[]>([]);

	const modelItemById = useMemo(() => mapByKey(executors, "id"), [executors]);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];
			setEditingItem(item);
			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const addHandler = useCallback(() => {
		setEditingItem(defaultTemplate);
		setShowModal(true);
	}, [defaultTemplate]);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(template: PaymentSystem.Modified) => edit(template.id),
		[edit],
	);

	const copyHandler = useCallback(() => {
		PaymentSystem.copy(selected[0]);
	}, [selected]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(() => {
		PaymentSystem.destroy(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setLoadingEditModal(false);
	}, []);

	const saveHandler = useCallback(
		async (template: PaymentSystem.Modified) => {
			setLoadingEditModal(true);
			if (template.type === PaymentSystemFrontEndType.TEMPLATE_EXECUTOR) {
				const res = await PaymentSystem.create({
					...template,
					type: PaymentSystemFrontEndType.EXECUTOR,
				});

				if (res) {
					setEditingItem(defaultTemplate);
					setShowModal(false);
				}

				setLoadingEditModal(false);
				// isAllOk = !(res?.error || res === null);
				// if (res.error) {
				// 	const { message } = res.data;
				// 	setErrorMessage(message);
				// 	setErrorStore(true);
				// 	setShowClarificationModal(true);
				// }

				return;
			}

			if (template.id && template.paymentProvider && template.name) {
				const res = await PaymentSystem.update({
					...template,
					type: PaymentSystemFrontEndType.EXECUTOR,
				});

				if (res) {
					setEditingItem(defaultTemplate);
					setShowModal(false);
				}

				// isAllOk = !(res?.error || res === null);
				// if (res.error) {
				// 	const { message } = res.data;
				// 	setErrorMessage(message);
				// 	setErrorStore(true);
				// 	setShowClarificationModal(true);
				// }
				setLoadingEditModal(false);
			}
		},
		[defaultTemplate],
	);

	const createdAt = editingItem?.createdAt
		? new Date(editingItem.createdAt).toLocaleDateString()
		: new Date().toLocaleDateString();

	const showClarificationModalLabel = useMemo(() => {
		if (errorUpdate) {
			if (errorMessage === "Cannot create another default rate") {
				const text = t([
					`${translationPath}.clarificationModal.store`,
					"This tariff exist!",
				]);
				setShowButtonUpdate(true);
				return text;
			}

			if (errorMessage !== "Cannot create another default rate") {
				const text = t([
					`${translationPath}.clarificationModal.update`,
					"Failed to update!",
				]);
				setShowButtonUpdate(true);
				return text;
			}
		}

		if (errorStore) {
			if (
				errorMessage ===
				"Rate conflicts with other rates. Use 'force' flag to override them"
			) {
				const text = t([
					`${translationPath}.clarificationModal.title`,
					"Are you sure you want to overwrite existing rate(-s)?",
				]);
				return text;
			}

			if (
				errorMessage !==
				"Rate conflicts with other rates. Use 'force' flag to override them"
			) {
				const text = t([
					`${translationPath}.clarificationModal.create`,
					"Failed to create",
				]);
				setShowButtonUpdate(true);
				return text;
			}
		}

		const text =
			t(`${translationPath}.clarificationModal.title`) ||
			"Are you sure you want to overwrite existing rate(-s)?";

		return text;
	}, [t, errorMessage, errorStore, errorUpdate]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={
					!!selected.length &&
					!selected.some((id) => modelItemById[id].isDefault)
				}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
				afterAdditionalButtons={[
					{
						icon: { id: "copy", size: 20 },
						onClick: copyHandler,
						disabled: !(selected.length === 1),
					},
				]}
				// filters={
				// 	// <Filters
				// 	// 	value={destinationAppFilter}
				// 	// 	onChange={setDestinationAppFilter}
				// 	// />
				// }
				accessName={ARR_ACCESS_PART_KEY[TabKeys.EXECUTOR]}
			/>
			<Content
				value={executors}
				lang={globalLang}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				saveHandler={saveHandler}
			/>
			{showModal && (
				<Modal
					lang={globalLang}
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
					headerTitle={
						editingItem?.id
							? `${t(`${translationPath}.modal.title.edit`)} "${
									editingItem.name.length > 20
										? `${editingItem.name.slice(0, 20)}...`
										: editingItem.name
							  }"`
							: t(`${translationPath}.modal.title.add`)
					}
					createdAt={createdAt}
					loading={loadingEditModal}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={
						selected.length === 1
							? t(`${translationPath}.deleteModal.title`) || ""
							: t(`${translationPath}.deleteModal.title2`) || ""
					}
					onCancel={() => {
						setShowDeleteModal(false);
						setSelected([]);
					}}
					onConfirm={deleteHandler}
				/>
			)}

			{showClarificationModal && (
				<DeleteModal
					label={showClarificationModalLabel}
					onCancel={() => {
						setShowClarificationModal(false);
						setShowButtonUpdate(false);
						setErrorMessage("");
						setErrorUpdate(false);
						setErrorStore(false);
					}}
					isConfirm={!showButtonUpdate}
					onConfirm={() => {
						setShowClarificationModal(false);
						setErrorMessage("");
						setErrorUpdate(false);
						setErrorStore(false);
					}}
				/>
			)}
		</Root>
	);
};

export default Executor;
