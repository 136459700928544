import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { Colors } from "..";

const SelectContainer = styled.div<{ width: string }>`
	position: relative;
	width: ${({ width }) => width};
`;

const SelectControl = styled.div<{ isOpen: boolean }>`
	height: 100%;
	max-height: 32px;
	padding: 6px 12px;
	background-color: #ffffff;
	border-right: 1px solid;
	border-bottom: ${({ isOpen }) => (isOpen ? "none" : "1px solid")};
	border-left: 1px solid;
	border-top: 1px solid;
	border-radius: ${({ isOpen }) => (isOpen ? "5px 5px 0 0" : "5px")};
	border-color: ${({ isOpen }) => (isOpen ? "#007bff" : "#dee0e2")};

	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&::after {
		content: "";
		border: solid black;

		border-width: 0 1px 1px 0;
		display: inline-block;
		padding: 2px;
		transform: ${({ isOpen }) =>
			isOpen ? "rotate(-135deg)" : "rotate(45deg)"};
		transition: transform 0.2s ease;
		margin-left: 8px;
	}
`;

const Dropdown = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	max-height: 500px;
	border-right: 1px solid #007bff;
	border-bottom: 1px solid #007bff;
	border-left: 1px solid #007bff;
	border-top: none;
	border-radius: 0 0 5px 5px;
	background-color: #fff;
	overflow-y: auto;
	z-index: 1000;
`;

const OptionItem = styled.div<{ selected: boolean }>`
	padding: 6px 10px;
	cursor: pointer;
	background-color: #fff;
	font-weight: normal;

	&:hover {
		background-color: ${Colors.BACKGROUND_GRAY_2};
	}

	${({ selected }) =>
		selected &&
		`
		background-color: #fff;
		font-weight: normal;
	`}
`;

// const Spinner = styled.div`
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// 	border: 4px solid #f3f3f3;
// 	border-top: 4px solid #3498db;
// 	border-radius: 50%;
// 	width: 30px;
// 	height: 30px;
// 	animation: spin 2s linear infinite;

// 	@keyframes spin {
// 		0% {
// 			transform: rotate(0deg);
// 		}
// 		100% {
// 			transform: rotate(360deg);
// 		}
// 	}
// `;

const Select: React.FC<Select.Props> = ({
	options,
	value,
	onSelect,
	w = "130px",
	isLoading = false,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => {
		setIsOpen((prev) => !prev);
	};

	const handleSelect = useCallback(
		(selectedValue: any) => {
			onSelect(selectedValue);
			setIsOpen(false);
		},
		[onSelect],
	);

	const selectedOption = options.find((option) => option.value === value);

	return (
		<SelectContainer width={w}>
			<SelectControl isOpen={isOpen} onClick={handleToggle}>
				{selectedOption?.label || "Select an option"}
			</SelectControl>
			{!isLoading && isOpen && (
				<Dropdown>
					{options.map((option) => (
						<OptionItem
							key={option.value}
							selected={option.value === value}
							onClick={() => handleSelect(option)}
						>
							{option.label}
						</OptionItem>
					))}
				</Dropdown>
			)}
			{/* {isLoading ? (
				<Spinner />
			) : (
				isOpen && (
					<Dropdown>
						{options.map((option) => (
							<OptionItem
								key={option.value}
								selected={option.value === value}
								onClick={() => handleSelect(option)}
							>
								{option.label}
							</OptionItem>
						))}
					</Dropdown>
				)
			)} */}
		</SelectContainer>
	);
};

declare namespace Select {
	interface Option {
		label: string;
		value: string;
	}

	interface Props {
		options: Option[];
		value: string;
		onSelect: (value: any) => void;
		w?: string;
		isLoading?: boolean;
	}
}

export default Select;
