import React from "react";

import Divider from "../../../Divider";

import {
	Root,
	DateTime,
	User,
	SIP2,
	TaxiService,
	Language,
	Settings,
	Logout,
	Logo,
} from "./components";

const Header: React.FC = () => (
	<Root align="center" gaps="32px" sizes="auto!*2 1fr auto!*">
		<Logo />
		<Divider orientation="vertical" size={34} color="#374752" />
		<DateTime />
		<Divider orientation="vertical" size="max" color="#374752" />
		<User />
		<Divider orientation="vertical" size="max" color="#374752" />
		<SIP2 />
		<Divider orientation="vertical" size="max" color="#374752" />
		<TaxiService />
		<Divider orientation="vertical" size="max" color="#374752" />
		<Language />
		<Divider orientation="vertical" size="max" color="#374752" />
		<Settings />
		<Divider orientation="vertical" size="max" color="#374752" />
		<Logout />
	</Root>
);

export default Header;
