import { ReactNode } from "react";

import { Language } from "../../../../../../../../../services";
import tPath from "../../../constants/tPath";

import name from "./name";
import callSign from "./callSign";
import phones from "./phones";
import role from "./role";
import status from "./status";
import createdAt from "./createdAt";
import company from "./company";
import taxiService from "./taxiService";
import birthAt from "./birthAt";

const translationTablePath = `${tPath}.columns`;

export enum ColumnId {
	Name = "name",
	CallSign = "callSign",
	Phones = "phones",
	Role = "role",
	Status = "status",
	CreatedAt = "createdAt",
	Company = "company",
	TaxiService = "taxiService",
	BirthAt = "birthAt",
}

export interface Column {
	render: (options: Column.Options) => ReactNode;
	label: string | string[];
}

export declare namespace Column {
	interface Options {
		width?: number;
		language: Language;
		onResize?: (width: number, columnId: ColumnId) => void;
	}
}

export const columns: Record<ColumnId, Column> = {
	[ColumnId.Name]: {
		render: name,
		label: [`${translationTablePath}.name`, "Full Name"],
	},
	[ColumnId.CallSign]: {
		render: callSign,
		label: [`${translationTablePath}.callSign`, "CallSign"],
	},
	[ColumnId.Phones]: {
		render: phones,
		label: [`${translationTablePath}.phones`, "Phones"],
	},
	[ColumnId.Role]: {
		render: role,
		label: [`${translationTablePath}.role`, "Role"],
	},
	[ColumnId.Status]: {
		render: status,
		label: [`${translationTablePath}.status`, "Status"],
	},
	[ColumnId.CreatedAt]: {
		render: createdAt,
		label: [`${translationTablePath}.createdAt`, "Registered At"],
	},
	[ColumnId.Company]: {
		render: company,
		label: [`${translationTablePath}.company`, "Company"],
	},
	[ColumnId.TaxiService]: {
		render: taxiService,
		label: [`${translationTablePath}.taxiService`, "Taxi Service"],
	},
	[ColumnId.BirthAt]: {
		render: birthAt,
		label: [`${translationTablePath}.birthAt`, "Birth Date"],
	},
};

export const defaultColumnIds = [
	ColumnId.Name,
	ColumnId.CallSign,
	ColumnId.Phones,
	ColumnId.Role,
	ColumnId.Status,
	ColumnId.CreatedAt,
	ColumnId.Company,
	ColumnId.TaxiService,
	ColumnId.BirthAt,
];

export const disabledColumnsIds = [];

export default columns;
