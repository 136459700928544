import { Action, combineReducers } from "redux";

// import service from "./services.reducer";
import carsModelsReducer from "./cars.reducer";
import colorReducer from "./colors.reducer";
import carTypes from "./carsTypes.reducer";
import rates from "./rates.reducer";
import { services, servicesAction, StateServices } from "./services";
import { carClasses, carClassesAction, StateCarClasses } from "./carClasses";

export type State = {
	carsModelsReducer: any;
	services: StateServices;
	carClasses: StateCarClasses;
	rates: any;
	colorReducer: any;
	carTypes: any;
};

export type Actions = {
	services: typeof servicesAction;
	carClasses: typeof carClassesAction;
	carsModelsReducer: typeof carsModelsReducer.actions;
	rates: any;
	colorReducer: any;
	carTypes: any;
};

export const preferencesAction: Actions = {
	services: servicesAction,
	carClasses: carClassesAction,
	carsModelsReducer: carsModelsReducer.actions,
	rates,
	colorReducer,
	carTypes,
};

export const preferencesReducer = combineReducers<State, Action<Actions>>({
	services,
	carClasses,
	carsModelsReducer: carsModelsReducer.reducer,
	rates,
	colorReducer,
	carTypes,
});

export default preferencesReducer;
