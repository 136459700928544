import React, { useCallback, useMemo, useState } from "react";
import { Button, Icon } from "uikit";

import { Language } from "../../../services";
import { useTypedSelector } from "../../../redux/store";
import { useTaxiServiceFilterAccess } from "../../../access";
import { Popup } from "..";

import Popover from "./components/Popover";

const ExecutorsFilterPanel: React.FC<ExecutorsFilterPanel.Props> = ({
	filter,
	setFilter,
	language,
	onSave,
	onAllExecutingIdsInBase,
}) => {
	const {
		accessCompanies,
		accessTaxiServices,
		accessTaxiServicesIds,
		accessCompaniesIds,
	} = useTaxiServiceFilterAccess<any>();
	// const { companies } = useTypedSelector((state) => state.ordersPageReducer);

	// const itemsCompanies = useMemo(() => companies?.items || [], [companies]);

	// const { models } = useTypedSelector((state) => state.taxiServices);
	// const itemsTaxiServices = useMemo(() => models || [], [models]);

	const executorsSubscribe = useTypedSelector(
		(state) => state.globalState.executors.data.subscription.basic,
	);

	const isFilterActive = useMemo(() => {
		const isCompanyIds =
			filter.companyIds?.[0] === "all" || accessCompaniesIds.length === 1
				? false
				: filter.companyIds.length !== accessTaxiServices.length;
		const isTaxiServiceIds =
			filter.taxiServiceIds?.[0] === "all" ||
			accessTaxiServicesIds.length === 1
				? false
				: filter.taxiServiceIds.length !== accessCompanies.length;

		const isExecutorsIds = onAllExecutingIdsInBase
			? filter.executorIds.length !== executorsSubscribe.length &&
			  filter.executorIds?.[0] !== "all" &&
			  executorsSubscribe.length
			: filter.executorIds.length;

		const isExecutorGroupIds = !!filter.executorGroupIds.length;

		const isFleetIds = !!filter.fleetIds.length;

		if (isCompanyIds) return true;
		if (isTaxiServiceIds) return true;
		if (isExecutorsIds) return true;
		if (isExecutorGroupIds) return true;
		if (isFleetIds) return true;

		return false;
	}, [
		filter.companyIds,
		filter.taxiServiceIds,
		filter.executorIds,
		filter.executorGroupIds.length,
		filter.fleetIds.length,
		accessCompaniesIds.length,
		accessTaxiServices.length,
		accessTaxiServicesIds.length,
		accessCompanies.length,
		onAllExecutingIdsInBase,
		executorsSubscribe,
	]);

	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);

	const popoverOpen = useCallback(() => {
		setOpenAdditionalFilters(true);
	}, []);

	const popoverClose = useCallback(() => {
		setOpenAdditionalFilters(false);
	}, []);

	return (
		<>
			<Popup
				open={openAdditionalFilters}
				offset={{ x: -470, y: 40 }}
				styles={{ shadow: "rgba(0,0,0,0.2) 0px 6px 24px 0px" }}
				tracker={
					<Button.Button
						style={
							isFilterActive
								? { backgroundColor: "rgb(109,190,255)" }
								: {}
						}
						variant={isFilterActive ? "primary" : "secondary"}
						icon={<Icon id="filters" size={20} />}
						onClick={popoverOpen}
					/>
				}
			>
				<Popover
					language={language}
					filter={filter}
					setFilter={setFilter}
					onClose={popoverClose}
					onSave={onSave}
					onAllExecutingIdsInBase={onAllExecutingIdsInBase}
				/>
			</Popup>
		</>
	);
};

declare namespace ExecutorsFilterPanel {
	type Value = Popover.Value;
	interface Props {
		filter: Value;
		setFilter: React.Dispatch<React.SetStateAction<Value>>;
		language: Language;
		onSave: (data: Value) => void;
		onAllExecutingIdsInBase?: boolean;
	}
}

export default ExecutorsFilterPanel;
