import { Action, combineReducers } from "@reduxjs/toolkit";

import {
	executors,
	globalStateExecutorsAction,
	StateExecutors,
} from "./Executors";
import {
	executorLocations,
	globalStateExecutorLocationsAction,
	StateExecutorLocations,
} from "./ExecutorLocations";
import {
	executorGroup,
	globalStateExecutorGroupAction,
	StateExecutorGroup,
} from "./ExecutorGroup";
import {
	executorRate,
	globalStateExecutorRateAction,
	StateExecutorRate,
} from "./ExecutorRate";
import { role, globalStateRoleAction, StateRole } from "./Role";

import { GlobalState } from "./interface";

export { GlobalState };

export type State = {
	executors: StateExecutors;
	executorLocations: StateExecutorLocations;
	executorGroup: StateExecutorGroup;
	executorRate: StateExecutorRate;
	role: StateRole;
};

export type Actions = {
	executors: typeof globalStateExecutorsAction;
	executorLocations: typeof globalStateExecutorLocationsAction;
	executorGroup: typeof globalStateExecutorGroupAction;
	executorRate: typeof globalStateExecutorRateAction;
	role: typeof globalStateRoleAction;
};

export const globalStateReducer = combineReducers<State, Action<Actions>>({
	executors,
	executorLocations,
	executorGroup,
	executorRate,
	role,
});

export const globalStateAction: Actions = {
	executors: globalStateExecutorsAction,
	executorLocations: globalStateExecutorLocationsAction,
	executorGroup: globalStateExecutorGroupAction,
	executorRate: globalStateExecutorRateAction,
	role: globalStateRoleAction,
};

export default globalStateReducer;
