import { combineReducers } from "@reduxjs/toolkit";

import {
	globalStateExecutorsDataState,
	globalStateExecutorsDataReducer,
	globalStateExecutorsDataAction,
} from "./data";

export type StateExecutors = {
	data: globalStateExecutorsDataState;
};

export const executors = combineReducers<StateExecutors>({
	data: globalStateExecutorsDataReducer,
});

export const globalStateExecutorsAction = {
	data: globalStateExecutorsDataAction,
};

export default executors;
