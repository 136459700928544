/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { useMemo, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { react, Select as OriginalSelect, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";
import styled from "styled-components";

import Map from "../../../../../../../../redux/services/Map";
import { getTaxiServiceLabel } from "../../../../../../../../utils";
import { useTaxiServiceFilterAccess } from "../../../../../../../../access";

import InternalController from "./Controller";

const StyledRow = styled.div`
	display: flex;
	gap: 16px;
	padding-bottom: 4px;
	padding-left: 20px;
`;

const Select = styled(OriginalSelect)<{ disabled?: boolean }>`
	width: 250px;
	background-color: ${(props) => (props.disabled ? "#f5f5f5" : "white")};
	pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
	opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

const TaxiServiceForm = react.withController<
	TaxiServiceForm.InputifiedPropsBase,
	InternalController
>(
	react.inputify<TaxiServiceForm.InternalPropsBase, TaxiServiceForm.Value>(
		({ value, language, controller, onChange }) => {
			const { t } = useTranslation();
			const { accessCompanies, accessTaxiServices } =
				useTaxiServiceFilterAccess<any>();

			const rootRef = useRef<HTMLDivElement | null>(null);

			const [companyIdFiledError, setCompanyIdFieldError] =
				useState(false);
			const [taxiServiceIdFiledError, setTaxiServiceIdFieldError] =
				useState(false);

			const taxiService = useMemo(
				() =>
					accessTaxiServices?.find(
						(taxiService) => taxiService.id === value,
					),
				[accessTaxiServices, value],
			);

			const companyIdRef = useRef<number | undefined>(undefined);

			const [companyId, setCompanyId] = useInternal(
				taxiService?.company?.id ?? companyIdRef.current,
			);

			companyIdRef.current = companyId;

			const companyOptions = useMemo(
				() =>
					accessCompanies?.map((company) => ({
						key: company.id,
						label: company.name?.[language] ?? "",
						value: company.id,
					})) ?? [],
				[accessCompanies, language],
			);

			const taxiServiceOptions = useMemo(
				() =>
					companyId
						? accessTaxiServices
								?.filter(
									(taxiService) =>
										taxiService.company?.id === companyId,
								)
								.map((taxiService) => ({
									key: taxiService.id,
									label: getTaxiServiceLabel(
										taxiService,
										language,
										true,
									),
									value: taxiService.id,
								}))
						: accessTaxiServices?.map((taxiService) => ({
								key: taxiService.id,
								label: getTaxiServiceLabel(
									taxiService,
									language,
									true,
								),
								value: taxiService.id,
						  })),
				[accessTaxiServices, companyId, language],
			);

			useEffect(() => {
				if (companyOptions.length === 1)
					setCompanyId(companyOptions[0].key);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [companyOptions]);
			useEffect(() => {
				if (taxiServiceOptions.length === 1)
					onChange(taxiServiceOptions[0].key);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [taxiServiceOptions]);

			controller.setContext({
				rootRef,

				value,
				companyId,
				taxiServices: accessTaxiServices,

				setCompanyIdFieldError,
				setTaxiServiceIdFieldError,
			});

			return (
				<StyledRow ref={rootRef}>
					<Select
						placeholder={
							t(
								"settings.paymentSystems.modal.company.chooseCompany",
							) ?? ""
						}
						disabled={companyOptions.length === 1}
						options={companyOptions}
						value={companyId}
						error={companyIdFiledError}
						onSelect={(option) => {
							setCompanyIdFieldError(false);
							setCompanyId(option.value as number);
							onChange(undefined);
						}}
					/>
					<Select
						placeholder={
							!companyId
								? t("selectCompanyFirst") ?? ""
								: t(
										"settings.paymentSystems.modal.branch.chooseBranch",
								  ) ?? ""
						}
						disabled={!companyId || taxiServiceOptions.length === 1}
						options={taxiServiceOptions}
						value={value}
						error={taxiServiceIdFiledError}
						onSelect={(option) => {
							setCompanyIdFieldError(false);
							setTaxiServiceIdFieldError(false);
							onChange(option.value as number);
						}}
					/>
				</StyledRow>
			);
		},
	),
	InternalController,
);

declare namespace TaxiServiceForm {
	type Value = number | undefined;

	interface PropsBase {
		language: Map.Language;
	}

	interface InternalPropsBase extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = InputifiedPropsBase;

	type Controller = InternalController;
}

export default TaxiServiceForm;
