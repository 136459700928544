import React, { useMemo } from "react";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (item: LastNameCellContent.Props["item"]) =>
	item?.person?.lastName || "";

const LastNameCellContent: React.FC<LastNameCellContent.Props> = ({ item }) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace LastNameCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default LastNameCellContent;
