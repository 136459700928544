import { combineReducers } from "@reduxjs/toolkit";

import {
	globalStateExecutorRateDataState,
	globalStateExecutorRateDataReducer,
	globalStateExecutorRateDataAction,
} from "./data";

export type StateExecutorRate = {
	data: globalStateExecutorRateDataState;
};

export const executorRate = combineReducers<StateExecutorRate>({
	data: globalStateExecutorRateDataReducer,
});

export const globalStateExecutorRateAction = {
	data: globalStateExecutorRateDataAction,
};

export default executorRate;
