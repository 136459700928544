import { useCallback, useMemo } from "react";
import { get, isArray } from "lodash";

import { TaxiService, Company } from "../../services";
import { useTypedSelector } from "../../redux/store";
import { Companies } from "../../redux/reducers/OrdersPage/interface";
import { selectOptionsWrap } from "../../utils";

const useTaxiServiceFilterAccess = <
	T,
>(): useTaxiServiceFilterAccess.Props<T> => {
	const _taxiServicesFromCurrentUser =
		useTypedSelector(
			(state) => state.account.user?.dispatcherToTaxiServices,
		) || [];

	const lang = useTypedSelector((state) => state.session.language);
	const taxiServices = useTypedSelector((state) => state.taxiServices.models);
	const { companies } = useTypedSelector((state) => state.ordersPageReducer);

	const taxiServicesFromCurrentUser = useMemo(
		() => _taxiServicesFromCurrentUser,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[_taxiServicesFromCurrentUser.length],
	);

	// const companies: Company[] = useMemo(
	// 	() =>
	// 		Array.from(
	// 			new Map(
	// 				taxiServices
	// 					.map((taxiService) => {
	// 						const { company } = taxiService;
	// 						if (!company || company.id === -1) return null;
	// 						return [company.id, company];
	// 					})
	// 					.filter(Boolean) as [number, Company][],
	// 			).values(),
	// 		),
	// 	[taxiServices],
	// );

	const idsSetTaxiServices = useMemo(
		() =>
			new Set(
				taxiServicesFromCurrentUser.map(
					(service) => service.taxiService.id || -1,
				),
			),
		[taxiServicesFromCurrentUser],
	);

	const accessTaxiServices: TaxiService.Model[] = useMemo(
		() =>
			taxiServices.filter((taxiService) =>
				idsSetTaxiServices.has(taxiService.id || -2),
			),
		[idsSetTaxiServices, taxiServices],
	);

	const accessCompanies: Company.Model[] = useMemo(() => {
		const retval: Company.Model[] = Array.from(
			new Map(
				accessTaxiServices
					.map((taxiService) => {
						const company = taxiService.company as Company.Model;
						if (!company || company.id === -1) return null;
						return [company.id, company];
					})
					.filter(Boolean) as [number, Company.Model][],
			).values(),
		);
		return retval;
	}, [accessTaxiServices]);

	const accessTaxiServicesIds: number[] = useMemo(
		() => accessTaxiServices.map((taxiServices) => taxiServices.id),
		[accessTaxiServices],
	);

	const accessCompaniesIds: number[] = useMemo(
		() => accessCompanies.map((taxiServices) => taxiServices.id),
		[accessCompanies],
	);

	const accessCompaniesTypeSecond: Companies = useMemo(() => {
		if (!companies?.items) return companies;

		const companiesFilterAccess: Companies = {
			...companies,
			items: (
				companies.items?.filter((item) =>
					accessCompaniesIds.includes(item.id || -1),
				) || []
			).map((item) => ({
				...item,
				taxiServices:
					item.taxiServices?.filter(
						(taxiService: TaxiService.Model) =>
							accessTaxiServicesIds.includes(
								taxiService.id || -1,
							),
					) || [],
			})),
		};
		const retval: Companies = companiesFilterAccess;

		return retval;
	}, [accessCompaniesIds, accessTaxiServicesIds, companies]);

	const idsSetCompanies = useMemo(
		() => new Set(accessCompaniesIds.map((ids) => ids || -1)),
		[accessCompaniesIds],
	);

	const subTaxiServiceFilterAccess = useCallback(
		({
			models,
			branchPath,
			branchPath2,
			isCheckCompanyids,
		}: useTaxiServiceFilterAccess.Params<T>): T[] => {
			if (!Array.isArray(models) || typeof branchPath !== "string") {
				return [];
			}

			const arrTypeAccessIds = isCheckCompanyids
				? idsSetCompanies
				: idsSetTaxiServices;

			const accessModels = models.filter((model) => {
				const branchId = get(model, branchPath, -2);

				if (isArray(branchId)) {
					if (branchPath2) {
						return branchId.some((item) => {
							const nestedId = get(item, branchPath2, -2);
							return (
								typeof nestedId === "number" &&
								arrTypeAccessIds.has(nestedId)
							);
						});
					}

					return (
						branchId.every((id) => typeof id === "number") &&
						branchId.some((id) => arrTypeAccessIds.has(id))
					);
				}

				return arrTypeAccessIds.has(branchId as number);
			});

			return accessModels;
		},
		[idsSetCompanies, idsSetTaxiServices],
	);

	/**
	 * Generates a list of select options from companies and access companies.
	 *
	 * @param {useTaxiServiceFilterAccess.PropsSubArrCompanies} params - The parameters for filtering.
	 * @returns {selectOptionsWrap.SelectOption[]} - The formatted list of select options.
	 */
	const subCompaniesOptions = useCallback(
		({
			initialCompanyIds,
			initialTaxiServiceIds,
		}: useTaxiServiceFilterAccess.PropsSubCompaniesOptions): selectOptionsWrap.SelectOption[] => {
			const hasOptions = !!(initialCompanyIds || initialTaxiServiceIds);
			let companiesList: Companies["items"] = hasOptions
				? companies?.items || []
				: [];

			if (Array.isArray(companiesList) && hasOptions) {
				if (
					Array.isArray(initialCompanyIds) &&
					initialCompanyIds.every((id) => typeof id === "number")
				) {
					companiesList = companiesList.filter(
						(company: Company.Model) =>
							initialCompanyIds.includes(company.id),
					);
				}

				if (
					Array.isArray(initialTaxiServiceIds) &&
					initialTaxiServiceIds.every((id) => typeof id === "number")
				) {
					companiesList = companiesList.filter((company) =>
						company.taxiServices?.some(
							(taxiService: TaxiService.Model) =>
								initialTaxiServiceIds.includes(
									taxiService.id ?? -1,
								),
						),
					);
				}
			}

			const companiesListOptions: selectOptionsWrap.SelectOption[] =
				companiesList?.map((company) =>
					selectOptionsWrap({
						id: company.id,
						label: company.name?.[lang] ?? "",
					}),
				) || [];

			const accessCompaniesListOptions = Array.isArray(
				accessCompaniesTypeSecond?.items,
			)
				? accessCompaniesTypeSecond.items?.map((company) =>
						selectOptionsWrap({
							id: company.id,
							label: company.name?.[lang] ?? "",
						}),
				  )
				: [];

			// Combine lists and ensure unique keys
			return Array.from(
				new Map(
					[
						...accessCompaniesListOptions,
						...companiesListOptions,
					].map((item) => [item.key, item]),
				).values(),
			);
		},
		[companies?.items, accessCompaniesTypeSecond?.items, lang],
	);

	// todo

	const subTaxiServiceOptions = useCallback(
		({
			initialCompanyIds,
			initialTaxiServiceIds,
			selectedCompanies,
		}: useTaxiServiceFilterAccess.PropsSubTaxiServiceOptions): selectOptionsWrap.SelectOption[] => {
			const hasOptions = !!(initialCompanyIds || initialTaxiServiceIds);
			let companiesList: Companies["items"] = hasOptions
				? companies?.items || []
				: [];
			let accessCompaniesList: Companies["items"] =
				accessCompaniesTypeSecond?.items || [];
			let lengthCompaniSelect = 0;

			if (Array.isArray(companiesList) && hasOptions) {
				if (
					Array.isArray(initialCompanyIds) &&
					initialCompanyIds.every((id) => typeof id === "number")
				) {
					companiesList = companiesList.filter(
						(company: Company.Model) =>
							initialCompanyIds.includes(company.id),
					);
				}

				if (
					Array.isArray(selectedCompanies) &&
					selectedCompanies.every((id) => typeof id === "number")
				) {
					companiesList = companiesList.filter(
						(company: Company.Model) =>
							selectedCompanies.includes(company.id),
					);
				}

				if (
					Array.isArray(initialTaxiServiceIds) &&
					initialTaxiServiceIds.every((id) => typeof id === "number")
				) {
					companiesList = companiesList
						.filter((company) =>
							company.taxiServices?.some(
								(taxiService: TaxiService.Model) =>
									initialTaxiServiceIds.includes(
										taxiService.id ?? -1,
									),
							),
						)
						.map((company) => ({
							...company,
							taxiServices: company.taxiServices?.filter(
								(taxiService) =>
									initialTaxiServiceIds.includes(
										taxiService.id,
									),
							),
						}));
				}
			}

			const companiesListOptions: selectOptionsWrap.SelectOption[] =
				companiesList
					?.map(
						(company, _, arr) =>
							company?.taxiServices?.map((service) => {
								if (lengthCompaniSelect < arr.length) {
									lengthCompaniSelect = arr.length;
								}
								return selectOptionsWrap({
									id: service.id,
									label: service?.settlement?.[lang],
									nameCompany: company.name?.[lang] ?? "",
								});
							}) || [],
					)
					?.flat() || [];

			if (Array.isArray(accessCompaniesList)) {
				if (
					Array.isArray(selectedCompanies) &&
					selectedCompanies.every((id) => typeof id === "number")
				) {
					accessCompaniesList = accessCompaniesList.filter(
						(company: Company.Model) =>
							selectedCompanies.includes(company.id),
					);
				}
			}

			const accessCompaniesListOptions =
				accessCompaniesList
					?.map(
						(company, _, arr) =>
							company?.taxiServices?.map((service) => {
								if (lengthCompaniSelect < arr.length) {
									lengthCompaniSelect = arr.length;
								}

								return selectOptionsWrap({
									id: service.id,
									label: service?.settlement?.[lang],
									nameCompany: company.name?.[lang] ?? "",
								});
							}) || [],
					)
					?.flat() || [];

			// Combine lists and ensure unique keys
			return Array.from(
				new Map(
					[
						...accessCompaniesListOptions,
						...companiesListOptions,
					].map((item) => [
						item.key,
						lengthCompaniSelect > 1
							? { ...item, label: item.labelFull }
							: item,
					]),
				).values(),
			);
		},
		[companies, accessCompaniesTypeSecond, lang],
	);

	return useMemo(
		() => ({
			subTaxiServiceFilterAccess,
			accessTaxiServices,
			accessCompanies,
			accessTaxiServicesIds,
			accessCompaniesIds,
			accessCompaniesTypeSecond,
			subCompaniesOptions,
			subTaxiServiceOptions,
		}),
		[
			accessCompanies,
			accessCompaniesIds,
			accessCompaniesTypeSecond,
			accessTaxiServices,
			accessTaxiServicesIds,
			subTaxiServiceFilterAccess,
			subCompaniesOptions,
			subTaxiServiceOptions,
		],
	);
};

declare namespace useTaxiServiceFilterAccess {
	export interface Params<T> {
		models: T[];
		branchPath: string;
		branchPath2?: string;
		isCheckCompanyids?: boolean;
	}

	export interface PropsSubCompaniesOptions {
		initialCompanyIds?: number[];
		initialTaxiServiceIds?: number[];
	}

	export interface PropsSubTaxiServiceOptions
		extends PropsSubCompaniesOptions {
		selectedCompanies?: number[];
	}

	export interface Props<T> {
		subTaxiServiceFilterAccess: (params: Params<T>) => T[];
		accessTaxiServices: TaxiService.Model[];
		accessCompanies: Company.Model[];
		accessTaxiServicesIds: number[];
		accessCompaniesIds: number[];
		accessCompaniesTypeSecond: Companies;
		subCompaniesOptions: (
			params: PropsSubCompaniesOptions,
		) => selectOptionsWrap.SelectOption[];
		subTaxiServiceOptions: (
			params: PropsSubTaxiServiceOptions,
		) => selectOptionsWrap.SelectOption[];
	}
}

export default useTaxiServiceFilterAccess;
