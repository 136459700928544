import { useMemo, useCallback } from "react";

import { Order } from "../services";
import { OrderExecutingStage } from "../types";
import { CloseReason } from "../pages/MainPage/pages/Orders/components/CloseOrderModal/components/Content";

export default function useOrderStatistics(data: Order.Model[]) {
	const filterOrdersByCloseReason = useCallback(
		(closeReason: CloseReason): Order.Model[] =>
			data.filter((item) => {
				const lastReceivedOrder =
					item?.additionalFields?.transfer?.lastReceivedOrder;

				const statusDyn = () => {
					const toValue = item.closedOrderComments?.[0];

					if (toValue?.status === "hide_by_transfer") {
						const fromTransferValue =
							lastReceivedOrder?.closedOrderComments?.[0];
						return fromTransferValue;
					}

					return toValue;
				};

				const isStatus = statusDyn()?.status === closeReason;
				return isStatus;
			}),
		[data],
	);

	return useMemo(
		() => ({
			overOrdersArr: data,
			completedOrdersArr: data.filter(
				(item) => item.executingStage === OrderExecutingStage.EXECUTED,
			),
			unCompletedOrdersArr: data.filter(
				(item) => item.executingStage === OrderExecutingStage.FAILED,
			),
			unclosedOrdersArr: data.filter(
				(item) =>
					item.executingStage !== OrderExecutingStage.EXECUTED &&
					item.executingStage !== OrderExecutingStage.FAILED,
			),
			dispatcherErrorArr: filterOrdersByCloseReason(
				CloseReason.DispatcherMistake,
			),
			clientRefusalArr: filterOrdersByCloseReason(
				CloseReason.ClientCanceled,
			),
			driverRefusalArr: filterOrdersByCloseReason(
				CloseReason.ExecutorCanceled,
			),
			noCarAvailableArr: filterOrdersByCloseReason(CloseReason.NoCar),
			calculationErrorArr: filterOrdersByCloseReason(
				CloseReason.Miscalculate,
			),
			completedByTransferArr: data.filter((item) => {
				const {
					isOwn,
					additionalFields,
					executorToOrder,
					taxiService,
				} = item;
				const { transfer } = additionalFields;

				const executor =
					executorToOrder && executorToOrder?.length
						? executorToOrder?.[0]?.executor
						: undefined;

				if (
					isOwn &&
					executor?.taxiService?.id !== taxiService?.id &&
					transfer?.lastReceivedOrder?.executorToOrder?.length
				)
					return true; // pink

				return false;
			}),
			completedFromTransferArr: data.filter((item) => {
				const {
					status,
					isOwn,
					additionalFields,
					executorToOrder,
					globalId,
				} = item;
				const { transfer } = additionalFields;

				if (!isOwn) {
					if (!executorToOrder?.length) {
						if (
							status === "closed" &&
							transfer?.executor !== null &&
							globalId
						) {
							return true; // green
						}
					}

					if (executorToOrder?.length) {
						return true; // green
					}
				}

				return false;
			}),
			completedByOwnDriverArr: data.filter((item) => {
				const isExecuted =
					item.executingStage === OrderExecutingStage.EXECUTED;
				const { isOwn, additionalFields } = item;

				const lastReceivedOrder =
					additionalFields?.transfer?.lastReceivedOrder;

				const executorFromTransfer = () => {
					if (
						lastReceivedOrder?.executorToOrder &&
						lastReceivedOrder?.executorToOrder?.length
					) {
						return lastReceivedOrder?.executorToOrder?.[0]
							?.executor;
					}
					return lastReceivedOrder?.offer?.executor;
				};

				const executor = () => {
					if (
						item?.executorToOrder &&
						item?.executorToOrder?.length
					) {
						return item?.executorToOrder?.[0]?.executor;
					}
					return item.offer?.executor;
				};

				const callSignExecutor = () => {
					if (isOwn && executorFromTransfer()) return "";
					return executor()?.callSign || "";
				};

				const callSign = callSignExecutor();

				if (
					isExecuted &&
					typeof callSign === "string" &&
					callSign.trim() !== ""
				)
					return true;

				return false;
			}),
			ownCompletedOrdersArr: data.filter((item) => {
				const isExecuted =
					item.executingStage === OrderExecutingStage.EXECUTED;
				let isGreen = false;
				let isPink = false;
				const {
					status,
					isOwn,
					additionalFields,
					executorToOrder,
					globalId,
					taxiService,
				} = item;

				const executor =
					executorToOrder && executorToOrder?.length
						? executorToOrder?.[0]?.executor
						: undefined;
				const { transfer } = additionalFields;

				if (!isOwn) {
					if (!executorToOrder?.length) {
						if (
							status === "closed" &&
							transfer?.executor !== null &&
							globalId
						) {
							isGreen = true; // green
						}
					}

					if (executorToOrder?.length) {
						isGreen = true; // green
					}
				}

				if (
					isOwn &&
					executor?.taxiService?.id !== taxiService?.id &&
					transfer?.lastReceivedOrder?.executorToOrder?.length
				)
					isPink = true; // pink

				const isWhite = !isGreen && !isPink && isExecuted;
				const isPinkAndExecuted = isPink && isExecuted;

				if (isWhite || isPinkAndExecuted) return true;
				return false;
			}),
			firstOrdersClientArr: data.filter(
				(item) => (item.passengers?.at(0)?.rideNumber || 1) === 1,
			),
			firstOrdersDoneClientArr: data.filter(
				(item) =>
					(item.passengers?.at(0)?.rideNumber || 1) === 1 &&
					item.executingStage === OrderExecutingStage.EXECUTED,
			),
		}),
		[data, filterOrdersByCloseReason],
	);
}
