/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, Column, Icon, Nullable, Row, theme, useInternal } from "uikit";
import { assign, clone, isEqual } from "lodash";

import Executor from "../../../../../services/Executor";
import Parking from "../../../../../services/Parking2";
import Language from "../../../../../services/Language";
import TaxiService from "../../../../../services/TaxiService";
import { normalizeExecutorObject } from "../../utils";
import useObjectEditor from "../../../../../hooks/useObjectEditor";
import useKeyBind from "../../../../../hooks/useKeyBind";
import useCompanyAndTaxiServiceIdsFilter from "../../../../../hooks/useCompanyAndTaxiServiceIdsFilter";
import { useTypedSelector } from "../../../../../redux/store";
import DeleteModal from "../../../../DeleteModal";
import LightInputModal from "../../../../LightInputModal";

import {
	Root,
	ExecutorSearchField,
	ParkingSearchField,
	AddressSearchField,
	FieldsContainer,
	CompanyAndTaxiService,
	ExecutorsTable,
} from "./components";

const ParkingsModal: React.FC<ParkingsModal.Props> = ({
	data,
	isEditModal,
	addressSearchFieldQuery,
	addressSearchFieldError,
	selectedParkingsTableId,
	onClose,
	onSave,
	onChangeSelectedParkingsTableId,
	onChangeAddressSearchFieldQuery,
	onChangeAddressSearchFieldError,
	onChangeIsEditModal,
	language,
	onSubmitAddress,
	taxiServiceData,
	filter,
	searchedCompanyAndTaxiServiceIds,
}) => {
	const { t } = useTranslation();

	const [tableData, setTableData] = useState<{ id: number; alias: string }[]>(
		data?.executorsQueue?.map((queue) =>
			normalizeExecutorObject(queue.executor),
		),
	);

	const isDataWasChanged = useMemo(
		() =>
			!isEqual(
				tableData,
				data?.executorsQueue?.map(
					(queue) => normalizeExecutorObject(queue.executor) || [],
				),
			),
		[data?.executorsQueue, tableData],
	);

	const [searchFieldData, setSearchFieldData] = useState<
		Nullable<{
			id: number;
			alias: string;
			taxiServiceId: number;
			companyId: number;
		}>
	>(null);

	const [executorsSearchFieldLabel, setExecutorsSearchFieldLabel] =
		useState<string>("");
	const [executorsSearchFieldQuery, setExecutorsSearchFieldQuery] =
		useState<string>("");
	const [executorsSearchFieldError, setExecutorsSearchFieldError] =
		useState<boolean>(false);

	const [parkingsSearchFieldQuery, setParkingsSearchFieldQuery] =
		useState<string>("");
	const [parkingsSearchFieldError, setParkingsSearchFieldError] =
		useState<boolean>(false);

	const [selectedExecutorId, setSelectedExecutorId] =
		useState<Nullable<number>>(null);

	useEffect(() => {
		setTableData(
			data?.executorsQueue?.map(
				(queue) => normalizeExecutorObject(queue.executor) || [],
			),
		);
		setParkingsSearchFieldQuery(data?.name?.[language] || "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedParkingsTableId, isEditModal, language]);

	// eslint-disable-next-line consistent-return
	const addExecutorToTable = useCallback(() => {
		if (
			tableData?.some(
				(executor) => executor.alias === searchFieldData?.alias,
			)
		) {
			return setExecutorsSearchFieldError(true);
		}
		if (!tableData?.map((executor) => executor.id)) {
			return setParkingsSearchFieldError(true);
		}
		if (searchFieldData) {
			setTableData((prev) => [...prev, searchFieldData]);
			setSearchFieldData(null);
			setExecutorsSearchFieldQuery("");
			setExecutorsSearchFieldLabel("");
			setTabIndex(0);
		}
	}, [searchFieldData, tableData]);

	const removeExecutorFromTable = useCallback(() => {
		if (selectedExecutorId) {
			setTableData((prev) =>
				prev.filter((executor) => executor.id !== selectedExecutorId),
			);
			setSelectedExecutorId(null);
		}
	}, [selectedExecutorId]);

	const [showClearExecutorsModal, setShowClearExecutorsModal] =
		useState(false);

	const openClearExecutorsModal = useCallback(() => {
		setShowClearExecutorsModal(true);
	}, []);

	const closeClearExecutorsModal = useCallback(() => {
		setShowClearExecutorsModal(false);
	}, []);

	const clearAllExecutorsFromTable = useCallback(() => {
		setTableData([]);
		setSelectedExecutorId(null);
		closeClearExecutorsModal();
	}, [closeClearExecutorsModal]);

	const onRowSelect = useCallback(
		(rowData) => {
			if (rowData?.id === selectedExecutorId) {
				setSelectedExecutorId(null);
			} else {
				setSelectedExecutorId(rowData.id);
			}
		},
		[selectedExecutorId],
	);

	const tableRowClassName = useCallback(
		(rowData: Executor.Model) => {
			if (rowData?.id === selectedExecutorId) return "selected";

			if (!rowData?.active) return "not-active";

			return "";
		},
		[selectedExecutorId],
	);

	const submitChanges = useCallback(() => {
		const executorsIds = tableData?.map((executor) => executor.id);

		if (!executorsIds) return setParkingsSearchFieldError(true);
		onSave(data?.id, executorsIds, isDataWasChanged);
		return null;
	}, [data?.id, isDataWasChanged, onSave, tableData]);

	const selectedExecutorIndex = useMemo(
		() =>
			tableData?.findIndex(
				(executor) => executor.id === selectedExecutorId,
			),
		[selectedExecutorId, tableData],
	);

	const upExecutorToOnePosition = useCallback(() => {
		if (selectedExecutorIndex !== 0) {
			const copyTableData = [...tableData];
			const prevEl = copyTableData[selectedExecutorIndex - 1];
			copyTableData[selectedExecutorIndex - 1] =
				copyTableData[selectedExecutorIndex];
			copyTableData[selectedExecutorIndex] = prevEl;

			setTableData(copyTableData);
		}
	}, [selectedExecutorIndex, tableData]);

	const downExecutorToOnePosition = useCallback(() => {
		if (selectedExecutorIndex !== tableData.length - 1) {
			const copyTableData = [...tableData];
			const prevEl = copyTableData[selectedExecutorIndex + 1];
			copyTableData[selectedExecutorIndex + 1] =
				copyTableData[selectedExecutorIndex];
			copyTableData[selectedExecutorIndex] = prevEl;

			setTableData(copyTableData);
		}
	}, [selectedExecutorIndex, tableData]);

	const upExecutorToFirstPosition = useCallback(() => {
		if (selectedExecutorIndex !== 0) {
			const copyTableData = [...tableData];
			const firstEl = copyTableData[selectedExecutorIndex];
			const newTableData = copyTableData.filter(
				(executor) => executor.id !== firstEl.id,
			);
			newTableData.unshift(firstEl);

			setTableData(newTableData);
		}
	}, [selectedExecutorIndex, tableData]);

	const downExecutorToLastPosition = useCallback(() => {
		if (selectedExecutorIndex !== tableData.length - 1) {
			const copyTableData = [...tableData];
			const lastEl = copyTableData[selectedExecutorIndex];
			const newTableData = copyTableData.filter(
				(executor) => executor.id !== lastEl.id,
			);
			newTableData.push(lastEl);

			setTableData(newTableData);
		}
	}, [selectedExecutorIndex, tableData]);

	const [tabIndex, setTabIndex] = useState<number>(0);

	useKeyBind(["Tab"], (e) => {
		e.stopPropagation();
		e.preventDefault();
		if (!isEditModal) {
			if (tabIndex === 4) {
				setTabIndex(0);
			} else if (tabIndex === 1 && settlementQuery) {
				setTabIndex((prev) => prev + 2);
			} else {
				setTabIndex((prev) => prev + 1);
			}
		} else {
			setTabIndex(-1);
			setTabIndex(0);
		}
	});

	useKeyBind(["Shift", "Tab"], (e) => {
		e.stopPropagation();
		e.preventDefault();
		if (!isEditModal) {
			if (tabIndex === 0) {
				setTabIndex(4);
			} else if (tabIndex === 3 && settlementQuery) {
				setTabIndex((prev) => prev - 2);
			} else {
				setTabIndex((prev) => prev - 1);
			}
		} else {
			setTabIndex(-1);
			setTabIndex(0);
		}
	});

	const closeModal = useCallback(() => {
		onClose();
		setTabIndex(0);
	}, [onClose]);

	useKeyBind(["Enter"], (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (searchFieldData) {
			return addExecutorToTable();
		}
		if (!selectedParkingsTableId) return setParkingsSearchFieldError(true);
		submitChanges();
		return null;
	});

	useKeyBind(["Escape"], (e) => {
		e.preventDefault();
		e.stopPropagation();
		closeModal();
	});

	const [companiesAndTaxiServicesIds, setCompanyAndTaxiServiceIds] =
		useState<ParkingsModal.Filter>({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});

	const valueEditor = useObjectEditor(
		companiesAndTaxiServicesIds,
		setCompanyAndTaxiServiceIds,
	);

	const companyAndTaxiServiceValue = valueEditor.usePicker([
		"companyIds",
		"taxiServiceIds",
	]);
	const currentValueRef = useRef(companyAndTaxiServiceValue);

	currentValueRef.current = companyAndTaxiServiceValue;

	const accessOnChange = useCallback(
		(accessValue: ParkingsModal.Filter) => {
			const assignValue: Partial<ParkingsModal.Filter> = {
				...accessValue,
			};

			valueEditor.assign(assignValue);
		},
		[valueEditor],
	);

	const setField = useCallback(
		<Field extends keyof ParkingsModal.Filter>(
			field: Field,
			fieldValue: ParkingsModal.Filter[Field],
		) => {
			currentValueRef.current = assign(clone(currentValueRef.current), {
				[field]: fieldValue,
			});

			accessOnChange(currentValueRef.current);
		},
		[accessOnChange],
	);

	const onChangeTaxiServiceIds = useCallback(
		(taxiServiceIds: number[] | ["all"]) => {
			// setTaxiServiceIdsError(false);

			setField("taxiServiceIds", taxiServiceIds);
		},
		[setField],
	);

	const onChangeCompanyIds = useCallback(
		(companyIds: number[] | ["all"]) => {
			// setCompanyIdsError(false);

			setField("companyIds", companyIds);
		},
		[setField],
	);

	const companyAndTaxiServiceData = useMemo(() => {
		const companyAndTaxiService = taxiServiceData?.find(
			(taxiService) => taxiService.id === data?.taxiServiceId,
		);

		return {
			companyIds: searchFieldData?.companyId
				? [searchFieldData?.companyId]
				: companyAndTaxiService?.company?.id
				? [companyAndTaxiService?.company?.id]
				: companyAndTaxiServiceValue.companyIds,
			taxiServiceIds: searchFieldData?.taxiServiceId
				? [searchFieldData?.taxiServiceId]
				: companyAndTaxiService?.id
				? [companyAndTaxiService?.id]
				: companyAndTaxiServiceValue.taxiServiceIds,
		};
	}, [
		companyAndTaxiServiceValue.companyIds,
		companyAndTaxiServiceValue.taxiServiceIds,
		data?.taxiServiceId,
		searchFieldData?.companyId,
		searchFieldData?.taxiServiceId,
		taxiServiceData,
	]);

	const [
		possibleCompanies,
		possibleTaxiServices,
		companyIds,
		taxiServiceIds,
		setCompanyIds,
		setTaxiServiceIds,
	] = useCompanyAndTaxiServiceIdsFilter(
		companyAndTaxiServiceData.companyIds,
		companyAndTaxiServiceData.taxiServiceIds,
		onChangeCompanyIds,
		onChangeTaxiServiceIds,
	);

	const companyAndTaxiServiceIds = useMemo(() => {
		const taxiService = taxiServiceData?.find(
			(taxiService) => taxiService.id === data?.taxiServiceId,
		);
		const companyId =
			searchedCompanyAndTaxiServiceIds?.companyId ||
			taxiService?.company?.id ||
			1;

		const taxiServiceId =
			searchedCompanyAndTaxiServiceIds?.taxiServiceId ||
			taxiService?.id ||
			1;

		return {
			companyId,
			taxiServiceId,
		};
	}, [
		data?.taxiServiceId,
		searchedCompanyAndTaxiServiceIds?.companyId,
		searchedCompanyAndTaxiServiceIds?.taxiServiceId,
		taxiServiceData,
	]);

	const [companyId, setCompanyId] = useState(
		companyAndTaxiServiceIds.companyId,
	);
	const [taxiServiceId, setTaxiServiceId] = useState(
		companyAndTaxiServiceIds.taxiServiceId,
	);

	useEffect(() => {
		setCompanyId(companyAndTaxiServiceIds.companyId);
		setTaxiServiceId(companyAndTaxiServiceIds.taxiServiceId);
	}, [
		companyAndTaxiServiceIds.companyId,
		companyAndTaxiServiceIds.taxiServiceId,
	]);

	const { mapLanguage } = useTypedSelector((state) => state.settings.map);

	const { defaultCity } = useTypedSelector((state) => state.mapReducer);

	const currentTaxiService = useMemo(() => {
		const foundedTaxiService = taxiServiceData?.find(
			(taxiService) => taxiService.id === taxiServiceId,
		);
		return {
			data: foundedTaxiService,
			settlement: foundedTaxiService?.settlement[mapLanguage],
		};
	}, [mapLanguage, taxiServiceData, taxiServiceId]);

	const [settlementQuery, setSettlementQuery] = useInternal(
		currentTaxiService.settlement,
	);

	return (
		<>
			<LightInputModal
				title={t("orderPageWidgets.parking.parkingsModal.str0") ?? ""}
				onClose={closeModal}
				onSubmit={submitChanges}
				canSubmit={isDataWasChanged}
				style={{
					width: 500,
					boxShadow: "0px 3px 14px rgba(0,0,0,0.2)",
				}}
			>
				<div
					style={{
						borderRadius: "10px",
					}}
				>
					<Root align="start" gaps="15px*">
						<Column gaps="10px*" itemRef="columnWithInputs">
							<CompanyAndTaxiService
								filter={filter}
								language={language}
								possibleCompanies={possibleCompanies}
								possibleTaxiServices={possibleTaxiServices}
								taxiServiceIds={taxiServiceIds}
								parkingData={data}
								searchFieldData={searchFieldData}
								defaultCity={defaultCity}
								taxiServiceData={taxiServiceData}
								companyId={companyId}
								setCompanyId={setCompanyId}
								taxiServiceId={taxiServiceId}
								setTaxiServiceId={setTaxiServiceId}
								onChangeTabIndex={setTabIndex}
							/>
							<ExecutorSearchField
								query={executorsSearchFieldQuery}
								label={executorsSearchFieldLabel}
								error={executorsSearchFieldError}
								onChangeError={setExecutorsSearchFieldError}
								onChangeQuery={setExecutorsSearchFieldQuery}
								onChangeLabel={setExecutorsSearchFieldLabel}
								onChangeSearchFieldData={setSearchFieldData}
								tabIndex={tabIndex}
								isEditModal={isEditModal}
								parkingData={data}
								taxiServiceData={taxiServiceData}
								onChangeTabIndex={setTabIndex}
								taxiServiceId={taxiServiceId}
							/>
							<ParkingSearchField
								query={parkingsSearchFieldQuery}
								error={parkingsSearchFieldError}
								onChangeError={setParkingsSearchFieldError}
								onChangeQuery={setParkingsSearchFieldQuery}
								onChangeAddressQuery={
									onChangeAddressSearchFieldQuery
								}
								onChangeAddressError={
									onChangeAddressSearchFieldError
								}
								onChangeSelectedParkingId={
									onChangeSelectedParkingsTableId
								}
								onChangeIsEditModal={onChangeIsEditModal}
								tabIndex={tabIndex}
								isEditModal={isEditModal}
								taxiServiceData={taxiServiceData}
								selectedExecutor={searchFieldData}
								taxiServiceId={taxiServiceId}
								onChangeTabIndex={setTabIndex}
							/>
							{!isEditModal && (
								<AddressSearchField
									query={addressSearchFieldQuery}
									error={addressSearchFieldError}
									onChangeAddressSearchFieldError={
										onChangeAddressSearchFieldError
									}
									isEditModal={isEditModal}
									onSubmitAddress={onSubmitAddress}
									tabIndex={tabIndex}
									onChangeTabIndex={setTabIndex}
									settlementQuery={settlementQuery}
									setSettlementQuery={setSettlementQuery}
									companyId={companyId}
									taxiServiceId={taxiServiceId}
								/>
							)}
						</Column>
						<Row gaps="10px*">
							<Row gaps="5px*">
								<Button.Button
									onClick={addExecutorToTable}
									icon={
										<Icon
											id="plus"
											size={16}
											colors={[theme.colors.white]}
										/>
									}
								/>
								<Button.Button
									variant="secondary"
									onClick={removeExecutorFromTable}
									disabled={!selectedExecutorId}
									style={{
										backgroundColor:
											theme.colors.background_color,
									}}
									icon={
										<Icon
											id="minus"
											size={16}
											colors={
												selectedExecutorId
													? [theme.colors.accent]
													: undefined
											}
										/>
									}
								/>
							</Row>
							<Button.Button
								variant="secondary"
								onClick={openClearExecutorsModal}
								disabled={!tableData?.length}
								icon={<Icon id="trash" size={16} />}
							/>
							<Row gaps="5px*">
								<Button.Button
									variant="secondary"
									icon={<Icon id="arrow-up" size={23} />}
									onClick={upExecutorToOnePosition}
									disabled={
										!selectedExecutorId ||
										selectedExecutorIndex === 0
									}
								/>
								<Button.Button
									variant="secondary"
									icon={<Icon id="arrow-down" size={23} />}
									onClick={downExecutorToOnePosition}
									disabled={
										!selectedExecutorId ||
										selectedExecutorIndex ===
											tableData.length - 1
									}
								/>
								<Button.Button
									variant="secondary"
									icon={<Icon id="arrow-top" size={23} />}
									onClick={upExecutorToFirstPosition}
									disabled={
										!selectedExecutorId ||
										selectedExecutorIndex === 0
									}
								/>
								<Button.Button
									variant="secondary"
									icon={<Icon id="arrow-bottom" size={23} />}
									onClick={downExecutorToLastPosition}
									disabled={
										!selectedExecutorId ||
										selectedExecutorIndex ===
											tableData.length - 1
									}
								/>
							</Row>
						</Row>
						<Column maxedHeight maxedWidth>
							<FieldsContainer
								label={
									t(
										"orderPageWidgets.parking.parkingsModal.str1",
									) ?? ""
								}
								labelColor={theme.colors.text_placeholder}
							>
								<ExecutorsTable
									tableData={tableData}
									tableRowClassName={tableRowClassName}
									onRowSelect={onRowSelect}
									selectedExecutorId={selectedExecutorId}
									onChangeSelectedExecutorId={
										setSelectedExecutorId
									}
									onChangeTableData={setTableData}
								/>
							</FieldsContainer>
						</Column>
					</Root>
				</div>
			</LightInputModal>
			{showClearExecutorsModal && (
				<DeleteModal
					label={
						t("orderPageWidgets.parking.parkingsModal.str200") ?? ""
					}
					onCancel={closeClearExecutorsModal}
					onConfirm={clearAllExecutorsFromTable}
				/>
			)}
		</>
	);
};

declare namespace ParkingsModal {
	interface Filter {
		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];
	}

	interface Props {
		data: Parking.Model;
		isEditModal: boolean;
		allReservedIds: string[];
		addressSearchFieldQuery: {
			settlement: string;
			address: string;
			street: string;
		};
		addressSearchFieldError: boolean;
		selectedParkingsTableId: number;
		onClose: () => void;
		onSave: (
			parkingId: number,
			executorIds: number[],
			isDataWasChanged: any,
		) => Promise<void>;
		onChangeSelectedParkingsTableId: React.Dispatch<
			React.SetStateAction<number>
		>;
		onChangeAddressSearchFieldQuery: React.Dispatch<
			React.SetStateAction<{
				settlement: string;
				address: string;
				street: string;
			}>
		>;
		onChangeAddressSearchFieldError: React.Dispatch<
			React.SetStateAction<boolean>
		>;
		onChangeIsEditModal: React.Dispatch<React.SetStateAction<boolean>>;
		language: Language;
		onSubmitAddress: (coordinatesData: any) => Promise<void>;
		taxiServiceData: TaxiService.Model[] | undefined;
		filter;
		searchedCompanyAndTaxiServiceIds: {
			companyId: number;
			taxiServiceId: number;
		} | null;
	}
}

export default ParkingsModal;
