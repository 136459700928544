import { useCallback, useMemo } from "react";

import { Role } from "../../services";
import { useTypedSelector } from "../../redux/store";
import { sortByNumberOrString } from "../../utils";
import { ColumnId } from "../../pages/MainPage/pages/Accounts/tabs/Roles/components/Table/columns";

const useRoleSubHandler = (): useRoleSubHandler.Props => {
	const roleSubscribe = useTypedSelector(
		(state) => state.globalState.role.data.subscription.basic,
	);

	const getAllRoleSubHandler = useCallback(
		({
			limit,
			assignableTo,
			taxiServiceIds,
			order,
			query,
		}: useRoleSubHandler.SmallSubscribeOptions): Role.Model[] => {
			if (!Array.isArray(roleSubscribe)) {
				return [];
			}

			let retval = [...(roleSubscribe || [])];

			const [
				isQuery,
				isAssignableTo,

				isTaxiServiceIds,
				isOrder,
				isLimit,
			] = [
				typeof query === "string" && query !== "",
				assignableTo === "dispatcher" || assignableTo === "executor",

				Array.isArray(taxiServiceIds) && taxiServiceIds?.length,
				typeof order === "object" &&
					order !== null &&
					!Array.isArray(order),
				typeof limit === "number",
			];

			if (isQuery) {
				retval = retval.filter(
					(item) =>
						typeof item.name === "string" &&
						typeof query === "string" &&
						item.name.toLowerCase().startsWith(query.toLowerCase()),
				);
			}

			if (isAssignableTo) {
				retval = retval.filter(
					(item) =>
						typeof item.assignableTo === "string" &&
						typeof assignableTo === "string" &&
						item.assignableTo === assignableTo,
				);
			}

			if (isTaxiServiceIds) {
				retval = retval.filter((item) => {
					if (
						Array.isArray(item.taxiServiceIds) &&
						Array.isArray(taxiServiceIds)
					) {
						return item.taxiServiceIds.some((id) =>
							taxiServiceIds.includes(id),
						);
					}
					return false;
				});
			}

			if (isOrder) {
				const [[dataKey, sortType] = []] = Object.entries(order || {});
				const isSort =
					typeof sortType === "string" &&
					typeof dataKey === "string" &&
					["asc", "desc"].includes(sortType.toLowerCase());

				if (isSort) {
					const isAscending = sortType.toLowerCase() === "asc";
					const sortOrder = isAscending ? 1 : -1;

					if (dataKey === ColumnId.Active) {
						const sort = retval.sort((prev, next) => {
							const prevValue = prev.active ? 1 : 0;
							const nextValue = next.active ? 1 : 0;
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === ColumnId.Name) {
						retval.sort((prev, next) => {
							const prevValue = prev.name
								? prev.name.toLowerCase()
								: "";
							const nextValue = next.name
								? next.name.toLowerCase()
								: "";
							return (
								sortByNumberOrString(
									prevValue,
									nextValue,
									true,
								) * sortOrder
							);
						});
					}

					if (dataKey === ColumnId.Assigns) {
						const sort = retval.sort((prev, next) => {
							const prevValue = prev.assignableTo || "";
							const nextValue = next.assignableTo || "";

							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}

					if (dataKey === ColumnId.CreatedAt) {
						const sort = retval.sort((prev, next) => {
							const prevValue = prev.createdAt
								? Date.parse(prev.createdAt) || 0
								: 0;
							const nextValue = next.createdAt
								? Date.parse(next.createdAt) || 0
								: 0;
							return (
								sortByNumberOrString(prevValue, nextValue) *
								sortOrder
							);
						});

						retval = sort;
					}
				}
			}

			if (isLimit) {
				retval = retval.slice(0, limit);
			}

			return retval;
		},
		[roleSubscribe],
	);

	return useMemo(
		() => ({
			getAllRoleSubHandler,
		}),
		[getAllRoleSubHandler],
	);
};

declare namespace useRoleSubHandler {
	interface Props {
		getAllRoleSubHandler: (data: SmallSubscribeOptions) => Role.Model[];
	}

	interface SmallSubscribeOptions {
		order?: Role.Order;
		query?: string;
		limit?: number;
		assignableTo?: Role.AssignableTo;
		taxiServiceIds?: number[];
	}
}

export default useRoleSubHandler;
