import React, { useCallback, useEffect, useRef, useMemo } from "react";
import { Overlayer, theme } from "uikit";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
	PointElement,
	LineElement,
	ChartOptions,
	ChartData,
	ChartTypeRegistry,
} from "chart.js";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ChartProps } from "react-chartjs-2/dist/types";

ChartJS.register(
	PointElement,
	LineElement,
	LinearScale,
	BarElement,
	ArcElement,
	CategoryScale,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels,
);

const Chart: React.FC<Chart.Props> = ({ data, colors, type = "linear" }) => {
	const parentRef = useRef<HTMLDivElement | null>(null);
	const chartRef = useRef<ChartJS<any> | null>(null);

	function hsl(hue: number, type: "border" | "background") {
		return type === "border"
			? `hsl(${hue}, 100%, 50%)`
			: `hsl(${hue}, 100%, 75%)`;
	}

	const options: ChartProps<any>["options"] = useMemo(
		() => ({
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				y: {
					min: 0,
					max:
						Math.max(...data.sets.flatMap((set) => set.data)) *
						1.05, // * Imaginary indentation in the chart above the very top
				},
			},
			plugins: {
				legend: {
					position: "top" as const,
					labels: {
						boxWidth: 12,
						boxHeight: 12,
						font: {
							family: "Lato",
							size: 14,
						},
					},
				},
				datalabels: {
					anchor: "end",
					align: "end",
					// offset: 32,
					color: theme.colors.primary,
				},
			},
		}),
		[data.sets],
	);

	useEffect(() => {
		if (!parentRef.current) return undefined;

		const observer = new ResizeObserver((entries) => {
			const { width, height } = entries[0].contentRect;
			chartRef.current?.resize(width, height);
		});

		observer.observe(parentRef.current);

		return () => {
			observer.disconnect();
		};
	}, [parentRef]);

	const drawChart = useCallback(() => {
		switch (type) {
			case "circular":
				return (
					<Doughnut
						ref={chartRef}
						// width={width}
						// height={height}
						options={{
							...options,
							scales: {
								...options.scales,
								y: { display: false },
							},
						}}
						data={{
							labels: data.sets.map((set) => set.label),
							datasets: [
								{
									data: data.sets.map((set) =>
										set.data.reduce((a, b) => a + b, 0),
									),
									borderColor: colors.slice(
										0,
										data.sets.length,
									),
									backgroundColor: colors.slice(
										0,
										data.sets.length,
									),
									// borderColor: data.sets.map(
									// 	(set, i, array) =>
									// 		hsl(
									// 			(i * 360) / array.length,
									// 			"border",
									// 		),
									// ),
									// backgroundColor: data.sets.map(
									// 	(set, i, array) =>
									// 		hsl(
									// 			(i * 360) / array.length,
									// 			"background",
									// 		),
									// ),
								},
							].slice(0, 1),
						}}
					/>
				);

			case "columnar":
				return (
					<Bar
						ref={chartRef}
						// width={width}
						// height={height}
						options={options}
						data={{
							labels: data.timePeriods,
							datasets: data.sets.map((set, i, array) => {
								const hue = (i * 360) / array.length;

								return {
									...set,
									// borderColor: hsl(hue, "border"),
									// backgroundColor: hsl(hue, "background"),
									borderColor: colors[i % colors.length],
									backgroundColor: colors[i % colors.length],
								};
							}),
						}}
					/>
				);

			case "linear":
			default:
				return (
					<Line
						ref={chartRef}
						// width={width}
						// height={height}
						options={options}
						data={{
							labels: data.timePeriods,
							datasets: data.sets.map((set, i, array) => {
								const hue = (i * 360) / array.length;

								return {
									...set,
									// borderColor: hsl(hue, "border"),
									// backgroundColor: hsl(hue, "background"),
									borderColor: colors[i % colors.length],
									backgroundColor: colors[i % colors.length],
								};
							}),
						}}
					/>
				);
		}
	}, [colors, data.sets, data.timePeriods, options, type]);

	return (
		<Overlayer
			style={{
				width: "100%",
				height: "100%",
			}}
			zIndex={100}
			overlay={drawChart()}
		>
			<div
				ref={parentRef}
				style={{
					width: "100%",
					height: "100%",
				}}
			/>
		</Overlayer>
	);
};

declare namespace Chart {
	interface Props {
		data: OrderChartData;
		colors: string[];
		type?: ChartType;
	}

	interface OrderChartDataSet {
		label: string;
		data: number[];
	}

	interface OrderChartData {
		timePeriods: (number | string)[];
		sets: OrderChartDataSet[];
	}

	interface Interval {
		label: IntervalLabel;
		value: number;
		minRange: number;
		format: Intl.DateTimeFormatOptions;
	}

	type ChartType = "linear" | "columnar" | "circular";

	type IntervalLabel = "minute" | "hour" | "day" | "month" | "year";
}

export default Chart;
