import React, { memo, useMemo, Dispatch } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "uikit";

import { ROUTES } from "../../../../../../../../constants/routes";

const ControlButton = styled(Button.Button)`
	height: 32px;
	min-width: 32px;
	width: 200px;
	border: 1px solid;
	border-color: #dee0e2;
	border-radius: 5px;
`;

const PopupContent: React.FC<LanguageSwitch.Props> = ({ onShowModalInfo }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const textBtnGroup = useMemo(
		() => ({
			mainMenu: t("main_header.settings.mainMenu") ?? "",
			hotKey: t("main_header.settings.hotKey") ?? "",
			colourDesignations:
				t("main_header.settings.colourDesignations") ?? "",
		}),
		[t],
	);

	return (
		<>
			<ControlButton
				onClick={() => history.push(ROUTES.MAIN)}
				text={textBtnGroup.mainMenu}
				variant="white"
			/>

			<ControlButton
				onClick={() => onShowModalInfo("hotKey")}
				text={textBtnGroup.hotKey}
				variant="white"
			/>

			<ControlButton
				onClick={() => onShowModalInfo("colourDesignations")}
				text={textBtnGroup.colourDesignations}
				variant="white"
			/>
		</>
	);
};

declare namespace LanguageSwitch {
	interface Props {
		onShowModalInfo: Dispatch<"hotKey" | "colourDesignations">;
	}
}
export const LanguageSwitchMemo = memo(PopupContent);
export default PopupContent;
