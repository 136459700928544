/* eslint-disable no-param-reassign */

import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrderSettings } from "../../../services";

type State = OrderSettings.Model["settings"];

type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

const set: Reducer<State> = (_, { payload }) => payload;

const initialState: State = null as unknown as OrderSettings.Model["settings"];

const order = createSlice({
	name: "order",
	initialState,
	reducers: {
		set,
	},
});

export default order;
