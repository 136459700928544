import { generateAccessName, AccessKey } from "../../../../../access";
import { ROUTES, TABS } from "../../../../../constants/routes";

/**
 * Base keys of the access sections on this page.
 */
const ACCESS_SECTION: AccessKey[] = [AccessKey.ARCHIVES];

/**
 * Represents the set of keys for each tab on the current page.
 * These keys are used to uniquely identify and manage the tabs on this specific route.
 */
const ACTIVE_ROUTE = ROUTES.ARCHIVES;
const TabKeys = TABS[ACTIVE_ROUTE];
type TabKeysValuesType = (typeof TabKeys)[keyof typeof TabKeys];

/**
 * Constructs an array of access addresses by combining base access addresses
 * with the tab-specific access keys. This builds the full set of addresses
 * needed to manage access from the page level to the specific tab.
 *
 * @param key - Tab-specific access keys.
 * @returns Array of access addresses including base and tab-specific addresses.
 */
const generateAccessSection = (key: AccessKey): AccessKey[] => [
	...ACCESS_SECTION,
	key,
];

/**
 * Maps each tab on the page to its corresponding array of access addresses.
 * This container is used for managing access control not only for the tabs themselves,
 * but also for internal elements such as buttons within these tabs.
 * Each entry provides a set of access addresses specific to the tab.
 */
const ARR_ACCESS_PART_KEY: Record<TabKeysValuesType, AccessKey[]> = {
	[TabKeys.ORDERS]: generateAccessSection(AccessKey.ORDERS),
	[TabKeys.BANK_TRANSACTIONS]: generateAccessSection(
		AccessKey.BANK_TRANSACTIONS,
	),
	[TabKeys.TRANSACTIONS]: generateAccessSection(AccessKey.TRANSACTIONS),
	[TabKeys.RATINGS]: generateAccessSection(AccessKey.RATINGS),
	[TabKeys.GPS_LOG]: generateAccessSection(AccessKey.GPS_LOG),
	[TabKeys.MESSAGES]: generateAccessSection(AccessKey.MESSAGES),
	[TabKeys.PUSH]: generateAccessSection(AccessKey.PUSH),
	[TabKeys.DRIVER_SHIFTS]: generateAccessSection(AccessKey.DRIVER_SHIFTS),
	[TabKeys.CHANGE_HISTORY]: generateAccessSection(AccessKey.CHANGE_HISTORY),
	[TabKeys.CALLS_HISTORY]: generateAccessSection(AccessKey.CALL_HISTORY),
};

/**
 * Type representing the access names for each tab.
 */
type TabAccessNamesType = {
	[key in TabKeysValuesType]: string;
};

/**
 * Generates access names from arrays of access keys for each tab.
 * This mapping creates a unique access name for each tab, which is used
 * to determine whether the tab is accessible or not. Each access name represents
 * the combined access keys for the tab.
 *
 * @returns TabAccessNamesType - An object mapping each tab key to its unique access name
 */
const TabAccessNames: TabAccessNamesType = Object.fromEntries(
	Object.entries(ARR_ACCESS_PART_KEY).map(([key, section]) => [
		key,
		generateAccessName(...section),
	]),
) as TabAccessNamesType;

const mainPath = `mainPage.${ACTIVE_ROUTE.replace("/", "")}`;

export { TabKeys, TabAccessNames, ARR_ACCESS_PART_KEY, mainPath };
