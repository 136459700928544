import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const PriorityCellContent: React.FC<PriorityCellContent.Props> = ({ item }) => {
	const content = useMemo(() => item.priority ?? "", [item.priority]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace PriorityCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default PriorityCellContent;
