import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Tabs, theme } from "uikit";
import { useDispatch } from "react-redux";
import { isNumber, uniqueId } from "lodash";

import { Order } from "../../../../../../../../services";
import { useKeyBindSettings } from "../../../../../../../../hooks/useKeyBindSettings";
import { ByMulty } from "../../../../../../../../redux/reducers/OrdersPage/interface";
import { useTypedSelector } from "../../../../../../../../redux/store";
import { ordersAction as orders } from "../../../../../../../../redux/reducers/Orders";
import { focusOrderCard } from "../../../../../../../../redux/services/Order/card";
import orderPage from "../../../../../../../../redux/reducers/OrdersPage";
import {
	getOrderPassengerPoints,
	stringifyPoint,
} from "../../../../../../../../redux/constants/OrdersPage/order";
import { ORDERS_TYPES } from "../../../../../../../../redux/constants/OrdersPage";
import { BaseKeybindEntries } from "../../../../../../../../redux/reducers/settings/keybinds";
import { isTextMatchingAnySegmentFilterHelper } from "../../../../../../../../utils";
import { Divider } from "../../../../../../../../components";
import {
	StyledRow,
	StyledGrid,
} from "../../../../../../../../components/common";
import { hasAccess } from "../../../../../../../../access";

import useTabOptions from "./hooks/useTabOptions";
import {
	FilterOrdersModal,
	FilterOrdersByExecutorModal,
	FilterOrdersByCarModal,
	FilterOrdersByMultyModal,
	Button,
} from "./components";
import {
	TabKeys,
	TabAccessNames,
	AccessKeysOrderTabs,
} from "./constants/access";

const FirstRow: React.FC<FirstRow.Props> = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const tabOptions = useTabOptions();

	const ordersType = useTypedSelector(
		(state) => state.ordersPageReducer.ordersType,
	);
	const filters = useTypedSelector(
		(state) => state.ordersPageReducer.filters[ordersType],
	);
	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const filtersBtns = useTypedSelector(
		(state) => state.ordersPageReducer.filtersBtns,
	);
	const modelOrdersForActiveTab = useTypedSelector(
		(state) => state.ordersPageReducer.orders[ordersType].items,
	);

	const isClosedTab = useMemo(
		() => ordersType === ORDERS_TYPES.CLOSED,
		[ordersType],
	);

	const [
		isFilterOrdersByExecutorModalOpen,
		setIsFilterOrdersByExecutorModalOpen,
	] = useState(false);
	const [isFilterOrdersByCarModalOpen, setIsFilterOrdersByCarModalOpen] =
		useState(false);
	const [isFilterOrdersModalOpen, setIsFilterOrdersModalOpen] =
		useState(false);
	const [isFilterOrdersByMultyModal, setIsFilterOrdersByMultyModal] =
		useState(false);
	const isShowMainOrderPage = AccessKeysOrderTabs.filter((tab) =>
		hasAccess(tab, personalRole),
	).length;

	const tabsOnChangeSelectedOption = useCallback(
		(option: Tabs.Option<any>) => {
			dispatch(orderPage.actions.setOrdersType(option.value));
			dispatch(orders.tabs.filter.setOrdersType(option.value));
		},
		[dispatch],
	);

	useEffect(() => {
		if (
			isShowMainOrderPage > 0 &&
			isShowMainOrderPage < AccessKeysOrderTabs.length &&
			tabOptions?.length > 0
		) {
			for (let i = 0; i < AccessKeysOrderTabs.length; i++) {
				const tab = AccessKeysOrderTabs[i];
				if (hasAccess(tab, personalRole)) {
					tabsOnChangeSelectedOption(tabOptions[0]);
					break;
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const switchToTab = useCallback(
		(tabIndex: number) => (event: KeyboardEvent) => {
			tabsOnChangeSelectedOption(tabOptions[tabIndex]);

			event.stopPropagation();
			event.preventDefault();
		},
		[tabOptions, tabsOnChangeSelectedOption],
	);

	const accessGroup = useMemo(
		() => [
			hasAccess(TabAccessNames[TabKeys.LIVE], personalRole),
			hasAccess(TabAccessNames[TabKeys.PRELIMINARY], personalRole),
			hasAccess(TabAccessNames[TabKeys.EXECUTING], personalRole),
			hasAccess(TabAccessNames[TabKeys.ALL], personalRole),
			hasAccess(TabAccessNames[TabKeys.CLOSED], personalRole),
		],
		[personalRole],
	);

	const AccessBtnGroup = useMemo(() => {
		const retval = {
			searchCar: hasAccess(
				TabAccessNames[TabKeys.SEARCH_CAR],
				personalRole,
			),
			searchExecutor: hasAccess(
				TabAccessNames[TabKeys.SEARCH_EXECUTOR],
				personalRole,
			),
			searchOrder: hasAccess(
				TabAccessNames[TabKeys.SEARCH_ORDER],
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	const useTabKeyBind = (
		tabName?: keyof BaseKeybindEntries<"meta"> | undefined | null,
		numberTab?: number,
	) => {
		useKeyBindSettings(tabName, (event: KeyboardEvent) => {
			if (isNumber(numberTab) && accessGroup?.[numberTab]) {
				const accessibleTabs = accessGroup
					.slice(0, numberTab)
					.filter((access) => access === true).length;
				switchToTab(accessibleTabs)(event);
			}
		});
	};

	useTabKeyBind("liveTab", 0);
	useTabKeyBind("preliminaryTab", 1);
	useTabKeyBind("executingTab", 2);
	useTabKeyBind("allTab", 3);
	useTabKeyBind("closedTab", 4);

	const showOnlyMyOrdersOnClick = useCallback(() => {
		dispatch(
			orderPage.actions.setFiltersBtns({
				data: {
					...filtersBtns,
					showOnlyMyOrders: !filtersBtns.showOnlyMyOrders,
				},
			}),
		);
	}, [dispatch, filtersBtns]);

	const showOrdersWithoutExecutorsOnClick = useCallback(() => {
		dispatch(
			orderPage.actions.setFiltersBtns({
				data: {
					...filtersBtns,
					showOrdersWithoutExecutors:
						!filtersBtns.showOrdersWithoutExecutors,
				},
			}),
		);
	}, [dispatch, filtersBtns]);

	const openFilterOrdersByCarModal = useCallback(() => {
		setIsFilterOrdersByCarModalOpen(true);
	}, []);

	const closeFilterOrdersByCarModal = useCallback(() => {
		setIsFilterOrdersByCarModalOpen(false);
	}, []);

	const filterOrdersByCarModalOnReset = useCallback(() => {
		closeFilterOrdersByCarModal();

		dispatch(
			orderPage.actions.setFiltersByCar({
				type: ordersType,
				data: {
					number: undefined,
					type: "garage",
				},
			}),
		);
	}, [closeFilterOrdersByCarModal, dispatch, ordersType]);

	const selectOrder = useCallback(
		(original: Order.Model | null) => {
			if (original) {
				dispatch(orderPage.actions.setFocusableOrder(null));

				dispatch(
					orderPage.actions.setActiveOrder({
						...original,
						hash: uniqueId("order"),
					}),
				);

				if (original.points.length === 1) {
					dispatch(orderPage.actions.setActivePoint(0));
				}
			}
		},
		[dispatch],
	);

	const filterOrdersByCarModalOnSubmit = useCallback(
		(modalValue: FilterOrdersByCarModal.Value) => {
			const findOrderIdInEditModalOrder = (
				orders: Order.Model[],
				aliasCarFind: string,
				typeCarFind: "state" | "garage",
			): Order.Model | null | undefined => {
				for (const order of orders) {
					if (typeCarFind === "garage") {
						const callSignCarsFromOrder =
							order.carToOrder?.map((c) => ({
								id: order.id ?? 0,
								callSign: c.car?.callSign ?? "",
							})) || [];

						const modelFind = callSignCarsFromOrder.find(
							(exe) => exe.callSign === `${aliasCarFind}`,
						);
						if (modelFind) {
							const retval =
								typeof modelFind.id === "number"
									? orders.find(
											(order) =>
												modelFind.id === order.id,
									  )
									: null;

							return retval;
						}
					}

					if (typeCarFind === "state") {
						const callSignCarsFromOrder =
							order.carToOrder?.map((c) => ({
								id: order.id ?? 0,
								callSign:
									c.car?.additionalFields
										?.registrationNumber ?? "",
							})) || [];

						const modelFind = callSignCarsFromOrder.find(
							(exe) => exe.callSign === `${aliasCarFind}`,
						);
						if (modelFind) {
							const retval =
								typeof modelFind.id === "number"
									? orders.find(
											(order) =>
												modelFind.id === order.id,
									  )
									: null;

							return retval;
						}
					}
				}

				return null;
			};

			closeFilterOrdersByCarModal();

			const numberFind = modalValue?.number
				? modalValue.number
				: undefined;
			const typeFind = modalValue.type ? modalValue.type : "garage";

			dispatch(
				orderPage.actions.setFiltersByCar({
					type: ordersType,
					data: {
						number: modalValue.number
							? modalValue.number
							: undefined,
						type: modalValue.type ? modalValue.type : "garage",
					},
				}),
			);

			if (numberFind) {
				const findModel = findOrderIdInEditModalOrder(
					modelOrdersForActiveTab,
					numberFind,
					typeFind,
				);

				if (findModel) {
					selectOrder(findModel);

					dispatch(focusOrderCard(findModel.id));
				}
			}
		},
		[
			closeFilterOrdersByCarModal,
			dispatch,
			modelOrdersForActiveTab,
			ordersType,
			selectOrder,
		],
	);

	const openFilterOrdersByExecutorModal = useCallback(() => {
		setIsFilterOrdersByExecutorModalOpen(true);
	}, []);

	const closeFilterOrdersByExecutorModal = useCallback(() => {
		setIsFilterOrdersByExecutorModalOpen(false);
	}, []);

	const filterOrdersByExecutorModalOnReset = useCallback(() => {
		closeFilterOrdersByExecutorModal();

		dispatch(
			orderPage.actions.setFiltersByExecutor({
				type: ordersType,
				data: {
					number: undefined,
				},
			}),
		);
	}, [closeFilterOrdersByExecutorModal, dispatch, ordersType]);

	const filterOrdersByExecutorModalOnSubmit = useCallback(
		(modalValue: FilterOrdersByExecutorModal.Value) => {
			const findOrderIdInEditModalOrder = (
				orders: Order.Model[],
				alias: number,
			): Order.Model | null | undefined => {
				for (const order of orders) {
					const arrAliasExecutorsFromModel =
						order.executorToOrder?.map((exe) => ({
							id: order.id ?? 0,
							callSign: exe.executor?.callSign ?? 0,
						})) || [];

					const modelFind = arrAliasExecutorsFromModel.find(
						(exe) => exe.callSign === `${alias}`,
					);

					if (modelFind) {
						const retval =
							typeof modelFind.id === "number"
								? orders.find(
										(order) => modelFind.id === order.id,
								  )
								: null;

						return retval;
					}
				}

				return null;
			};

			closeFilterOrdersByExecutorModal();

			const numberFind = modalValue?.number
				? modalValue.number
				: undefined;

			dispatch(
				orderPage.actions.setFiltersByExecutor({
					type: ordersType,
					data: {
						number: numberFind,
					},
				}),
			);

			if (numberFind) {
				const findModel = findOrderIdInEditModalOrder(
					modelOrdersForActiveTab,
					numberFind,
				);

				if (findModel) {
					selectOrder(findModel);

					dispatch(focusOrderCard(findModel.id));
				}
			}
		},
		[
			closeFilterOrdersByExecutorModal,
			dispatch,
			modelOrdersForActiveTab,
			ordersType,
			selectOrder,
		],
	);

	const openFilterOrdersModal = useCallback(() => {
		setIsFilterOrdersModalOpen(true);
	}, []);

	const closeFilterOrdersModal = useCallback(() => {
		setIsFilterOrdersModalOpen(false);
	}, []);

	const filterOrdersModalOnReset = useCallback(() => {
		closeFilterOrdersModal();

		dispatch(
			orderPage.actions.setFiltersByOrder({
				type: ordersType,
				data: {
					phone: undefined,
					from: undefined,
					to: undefined,
				},
			}),
		);
	}, [closeFilterOrdersModal, dispatch, ordersType]);

	const filterOrdersModalOnSubmit = useCallback(
		(modalValue: FilterOrdersModal.Value) => {
			const findOrderIdInEditModalOrder = (
				models: Order.Model[],
				phone,
				from,
				to,
			): Order.Model | null | undefined => {
				const modelsFiltered = models.filter((model) => {
					let retval = true;

					const phoneOrderFind: string | null = phone ?? null;
					const fromOrderFind: string | null = from ?? null;
					const toOrderFind: string | null = to ?? null;

					const pointOnMap = t(["point_on_map"]);

					if (phoneOrderFind && retval) {
						const phonesFromOrder = model.phones.map(
							(phone) => phone.number ?? "",
						);

						retval = phonesFromOrder.some((phone) =>
							phone.includes(phoneOrderFind),
						);
					}

					if (fromOrderFind && retval) {
						const points = getOrderPassengerPoints(model);

						const pickupFromOrder = points?.[0]
							? stringifyPoint(points[0], "address", pointOnMap)
							: "";

						retval = isTextMatchingAnySegmentFilterHelper(
							pickupFromOrder,
							fromOrderFind,
						);
					}

					if (toOrderFind && retval) {
						const points = getOrderPassengerPoints(model);

						const destinationFromOrder = points?.at(-1)
							? stringifyPoint(
									points.at(-1),
									"address",
									pointOnMap,
							  )
							: "";

						retval = isTextMatchingAnySegmentFilterHelper(
							destinationFromOrder,
							toOrderFind,
						);
					}

					return retval;
				});

				return modelsFiltered.length ? modelsFiltered[0] : null;
			};

			closeFilterOrdersModal();

			const phoneFind = modalValue?.phone ? modalValue.phone : undefined;
			const fromFind = modalValue?.from ? modalValue.from : undefined;
			const toFind = modalValue?.to ? modalValue.to : undefined;

			dispatch(
				orderPage.actions.setFiltersByOrder({
					type: ordersType,
					data: {
						phone: phoneFind,
						from: fromFind,
						to: toFind,
					},
				}),
			);

			if (phoneFind || fromFind || toFind) {
				const findModel = findOrderIdInEditModalOrder(
					modelOrdersForActiveTab,
					phoneFind,
					fromFind,
					toFind,
				);

				if (findModel) {
					selectOrder(findModel);

					dispatch(focusOrderCard(findModel.id));
				}
			}
		},
		[
			closeFilterOrdersModal,
			dispatch,
			modelOrdersForActiveTab,
			ordersType,
			selectOrder,
			t,
		],
	);

	const openFilterOrdersByMultyModal = useCallback(() => {
		setIsFilterOrdersByMultyModal(true);
	}, []);

	const closeFilterOrdersByMultyModal = useCallback(() => {
		setIsFilterOrdersByMultyModal(false);
	}, []);

	const filterOrdersModalByMultyOnReset = useCallback(() => {
		closeFilterOrdersByMultyModal();

		dispatch(
			orderPage.actions.setFiltersByMulty({
				type: ordersType,
				data: undefined,
			}),
		);
	}, [closeFilterOrdersByMultyModal, dispatch, ordersType]);

	const filterOrdersModalByMultyOnSubmit = useCallback(
		(modalValue: FilterOrdersByMultyModal.Value) => {
			const findOrderIdInEditModalOrder = (
				models: Order.Model[],
				byMulty: ByMulty,
			): Order.Model | null | undefined => {
				const modelsFiltered = models.filter((model) => {
					let retval = true;

					const getValidArray = (arr?: number[]): number[] | null =>
						Array.isArray(arr) && arr.length ? arr : null;

					const [
						companyIdsOrderFind,
						taxiServiceIdsOrderFind,
						servicesOrderFind,
						classesOrderFind,
					] = [
						getValidArray(byMulty?.companyIds),
						getValidArray(byMulty?.taxiServiceIds),
						getValidArray(byMulty?.services),
						getValidArray(byMulty?.classes),
					];

					if (companyIdsOrderFind && retval) {
						const companyFromOrder =
							model.taxiService?.company?.id ?? -1;
						retval = companyIdsOrderFind.includes(companyFromOrder);
					}

					if (taxiServiceIdsOrderFind && retval) {
						const taxiServiceFromOrder =
							model.taxiService?.id ?? -1;

						retval =
							taxiServiceIdsOrderFind.includes(
								taxiServiceFromOrder,
							);
					}

					if (classesOrderFind && retval) {
						const carClassessFromOrder =
							model.orderToCarClasses?.map(
								(item) => item?.carClass?.id || -1,
							) || [];

						retval = carClassessFromOrder.some((num) =>
							classesOrderFind.includes(num),
						);
					}

					if (servicesOrderFind && retval) {
						const servicesFromOrder =
							model.orderToServices?.map(
								(item) => item?.service?.id || -1,
							) || [];

						retval = servicesFromOrder.some((num) =>
							servicesOrderFind.includes(num),
						);
					}

					return retval;
				});

				return modelsFiltered.length ? modelsFiltered[0] : null;
			};

			closeFilterOrdersByMultyModal();

			dispatch(
				orderPage.actions.setFiltersByMulty({
					type: ordersType,
					data: {
						companyIds: modalValue?.companyIds ?? [],
						taxiServices: modalValue?.taxiServices ?? [],
						taxiServiceIds: modalValue?.taxiServiceIds ?? [],
						services: modalValue?.services ?? [],
						classes: modalValue?.classes ?? [],
					},
				}),
			);

			if (
				modalValue !== undefined &&
				(modalValue.companyIds !== undefined ||
					modalValue.taxiServices !== undefined ||
					modalValue.taxiServiceIds !== undefined ||
					modalValue.services !== undefined ||
					modalValue.classes !== undefined)
			) {
				const findModel = findOrderIdInEditModalOrder(
					modelOrdersForActiveTab,
					modalValue,
				);

				if (findModel) {
					selectOrder(findModel);

					dispatch(focusOrderCard(findModel.id));
				}
			}
		},
		[
			closeFilterOrdersByMultyModal,
			dispatch,
			modelOrdersForActiveTab,
			ordersType,
			selectOrder,
		],
	);

	return (
		<>
			<StyledGrid
				alignItems="center"
				areas=""
				columns="1fr auto"
				gap="10px"
				bg={theme.colors.button_secondary}
				p={{ right: "8px" }}
			>
				<Tabs
					variant="bookmarks"
					value={ordersType}
					options={tabOptions}
					onChangeSelectedOption={tabsOnChangeSelectedOption}
				/>
				<StyledRow alignItems="center" gap="8px">
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.str0",
							) ?? ""
						}
						active={filtersBtns.showOnlyMyOrders}
						tabIndex={-1}
						onClick={showOnlyMyOrdersOnClick}
						disabled={isClosedTab}
					>
						<Icon id="orders-show-my-orders" size={20} />
					</Button>
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.str1",
							) ?? ""
						}
						active={filtersBtns.showOrdersWithoutExecutors}
						tabIndex={-1}
						onClick={showOrdersWithoutExecutorsOnClick}
						disabled={isClosedTab}
					>
						<Icon
							id="orders-show-not-distributed-orders"
							size={20}
						/>
					</Button>
					{AccessBtnGroup.searchCar && (
						<Button
							title={
								t(
									"pages.mainPage.pages.orders.orderHeader.firstRow.str2",
								) ?? ""
							}
							settingsKeyId="searchCar"
							active={filters.byCar?.number !== undefined}
							tabIndex={-1}
							onClick={openFilterOrdersByCarModal}
							disabled={isClosedTab}
						>
							<Icon id="orders-find-car" size={20} />
						</Button>
					)}

					{AccessBtnGroup.searchExecutor && (
						<Button
							title={
								t(
									"pages.mainPage.pages.orders.orderHeader.firstRow.str3",
								) ?? ""
							}
							settingsKeyId="searchExecutor"
							active={filters.byExecutor?.number !== undefined}
							tabIndex={-1}
							onClick={openFilterOrdersByExecutorModal}
							disabled={isClosedTab}
						>
							<Icon id="orders-find-executor" size={20} />
						</Button>
					)}
					{AccessBtnGroup.searchOrder && (
						<Button
							title={
								t(
									"pages.mainPage.pages.orders.orderHeader.firstRow.str4",
								) ?? ""
							}
							settingsKeyId="searchOrder"
							active={
								filters.byOrder !== undefined &&
								(filters.byOrder.from !== undefined ||
									filters.byOrder.phone !== undefined ||
									filters.byOrder.to !== undefined)
							}
							tabIndex={-1}
							onClick={openFilterOrdersModal}
							disabled={isClosedTab}
						>
							<Icon id="orders-find-order" size={20} />
						</Button>
					)}
					<Divider
						orientation="vertical"
						size={16}
						color="rgba(0, 0, 0, 0.12);"
					/>
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.filterMulty",
							) ?? ""
						}
						active={
							filters.byMulty !== undefined &&
							(filters.byMulty.companyIds !== undefined ||
								filters.byMulty.taxiServices !== undefined ||
								filters.byMulty.taxiServiceIds !== undefined ||
								filters.byMulty.services !== undefined ||
								filters.byMulty.classes !== undefined)
						}
						tabIndex={-1}
						onClick={openFilterOrdersByMultyModal}
						disabled={isClosedTab}
					>
						<Icon id="filter-building" size={20} />
					</Button>
				</StyledRow>
			</StyledGrid>
			{isFilterOrdersByCarModalOpen && (
				<FilterOrdersByCarModal
					value={filters.byCar}
					onClose={closeFilterOrdersByCarModal}
					onReset={filterOrdersByCarModalOnReset}
					onSubmit={filterOrdersByCarModalOnSubmit}
				/>
			)}
			{isFilterOrdersByExecutorModalOpen && (
				<FilterOrdersByExecutorModal
					value={filters.byExecutor}
					onClose={closeFilterOrdersByExecutorModal}
					onReset={filterOrdersByExecutorModalOnReset}
					onSubmit={filterOrdersByExecutorModalOnSubmit}
				/>
			)}
			{isFilterOrdersModalOpen && (
				<FilterOrdersModal
					value={filters.byOrder}
					onClose={closeFilterOrdersModal}
					onReset={filterOrdersModalOnReset}
					onSubmit={filterOrdersModalOnSubmit}
				/>
			)}
			{isFilterOrdersByMultyModal && (
				<FilterOrdersByMultyModal
					value={filters.byMulty}
					onClose={closeFilterOrdersByMultyModal}
					onReset={filterOrdersModalByMultyOnReset}
					onSubmit={filterOrdersModalByMultyOnSubmit}
				/>
			)}
		</>
	);
};

declare namespace FirstRow {
	interface Props {}
}

export default FirstRow;
