enum OrderExecutingStage {
	ASSIGNED = "assigned",
	ACCEPTED = "accepted",
	DELAYED = "delayed",
	ARRIVED = "arrived",
	WAITING_CLIENT = "waiting_client",
	WITH_CLIENTS = "with_clients",
	EXECUTED = "executed",
	FAILED = "failed",
}

export default OrderExecutingStage;
