import React, { CSSProperties, PropsWithChildren, memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Row } from "uikit";

import { StyledRow, SuspenseLoader } from "../../../common";

import Root from "./components/Root";

const Footer = memo<Footer.Props>(
	({
		cancelText,
		submitText,
		justify,
		children,
		canSave,
		onCancel,
		onSave,
		loading = false,
	}) => {
		const { t } = useTranslation();
		return (
			<Root
				gaps="24px"
				align="center"
				justify={justify ?? (children ? "space-between" : "right")}
			>
				{children ? (
					<div style={{ width: "100%" }}>{children}</div>
				) : (
					<></>
				)}
				<Row gaps="16px">
					<Button.Button
						style={{ padding: "9px 25px", height: "36px" }}
						variant="secondary"
						text={cancelText ?? t("editModal.footer.str200") ?? ""}
						onClick={onCancel}
					/>
					{!loading && (
						<Button.Button
							style={{ padding: "9px 25px", height: "36px" }}
							disabled={!canSave}
							text={
								submitText ?? t("editModal.footer.str201") ?? ""
							}
							onClick={onSave}
						/>
					)}
					{loading && (
						<StyledRow
							h="36px"
							w="120px"
							bgC="#03A9F4"
							br="5px"
							alignItems="center"
							justify="center"
							cursor="not-allowed"
						>
							<SuspenseLoader loader="6" />
						</StyledRow>
					)}
				</Row>
			</Root>
		);
	},
);

declare namespace Footer {
	interface Props extends PropsWithChildren {
		justify?: CSSProperties["justifyContent"];
		cancelText?: string;
		submitText?: string;
		canSave: boolean;
		onCancel: () => void;
		onSave: () => void;
		loading?: boolean;
	}
}

export default Footer;
