/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, Option, Row, TextSelect, useInternal } from "uikit";
import { useDebouncedCallback } from "use-debounce";
import { useTypedSelector } from "../../../../../../../redux/store";
import search from "../../../../../../../redux/services/Map/search";
import SearchType from "../../../../../../../types/SearchType";
import settlementToString from "./functions/settlementToString";
import streetToString from "./functions/streetToString";

const fromResponseSettlement = (object, index) => {
	const settlement = {
		key: index,
		label: settlementToString(object),
		value: {
			countryCode: object?.countryCode,
			country: object?.country,
			settlementType: object?.settlementType,
			settlement: object?.settlement,
			district: object?.district,
			region: object?.region,
			coordinates: object?.coordinates,
		},
	};
	return settlement;
};

const fromResponseStreet = (object, index) => {
	const street = {
		key: index,
		label: streetToString(object),
		value: {
			streetType: object.streetType,
			street: object.street,
			streetHouses: object.streetHouses.map(
				(streetHouse) => streetHouse.number,
			),
			coordinates: object.coordinates,
		},
	};
	return street;
};

const fromResponseHouse = (houseNumber, index) => {
	const house = {
		key: index,
		label: houseNumber,
		value: houseNumber,
	};
	return house;
};

const AddressSearchField = ({
	query,
	error,
	isEditModal,
	onSubmitAddress,
	tabIndex,
	onChangeAddressSearchFieldError,
	onChangeTabIndex,
	settlementQuery,
	setSettlementQuery,
	companyId,
	taxiServiceId,
}) => {
	const { t } = useTranslation();
	useEffect(() => {
		if (query.settlement) {
			setStreetQuery(query.address);
			setHouseQuery(query.street);
			setSettlementQuery(query.settlement);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	const [streetQuery, setStreetQuery] = useInternal("");

	const {
		mapLanguage,
		inputRequestDelayMs,
		searchRadiusMeters,
		minSearchQueryLength,
	} = useTypedSelector((state) => state.settings.map);

	const defaultSearchTypes = useTypedSelector(
		(state) => state.ordersPageReducer.searchTypes,
	);

	const [settlementTips, setSettlementTips] = useState<Option<object>[] | []>(
		[],
	);

	const [streetTips, setStreetTips] = useState([]);

	const [houseTips, setHouseTips] = useState<Option<string>[] | []>([]);

	const [searchTypes] = useState<SearchType[]>(
		defaultSearchTypes as SearchType[],
	);

	const { companies } = useTypedSelector((state) => state.ordersPageReducer);

	const foundedTaxiService = companies
		? companies.items
				.find((company) => company.id === companyId)
				?.taxiServices.find(
					(taxiService) => taxiService.id === taxiServiceId,
				)
		: null;

	const [selectedSettlement, setSelectedSettlement] = useState({
		settlement: "",
		coordinates: "",
	});
	const [selectedStreet, setSelectedStreet] = useState({
		street: "",
		coordinates: "",
	});
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [selectedHouse, setSelectedHouse] = useState();

	const [responseStreetError, setResponseStreetError] = useState(false);
	const [responseSettlementError, setResponseSettlementError] =
		useState(false);

	const debounceRequestSettlement = useDebouncedCallback((value) => {
		const canRequest = value.trim();

		if (canRequest) {
			search(value, {
				country: "ua",
				searchType: "settlement",
				lang: mapLanguage,
			}).then((tips) => {
				if (tips.length) {
					const updatedTips = tips.map((tip, index) =>
						fromResponseSettlement(tip, index),
					);
					setSettlementTips(updatedTips);
					setResponseSettlementError(false);
				} else {
					setResponseSettlementError(true);
					setSettlementTips([]);
				}
			});
		} else {
			setSettlementTips([]);
		}
	}, inputRequestDelayMs);

	const debounceRequestStreet = useDebouncedCallback((value) => {
		const canRequest = value && value.trim();
		const nearCoord =
			selectedSettlement?.coordinates ||
			foundedTaxiService?.settlementPoint;

		if (canRequest) {
			search(value, {
				country: "ua",
				searchType:
					(searchTypes.length > 1 ? searchTypes : searchTypes[0]) ||
					"street",
				near: {
					point: nearCoord,
					radius: searchRadiusMeters,
				},
				lang: mapLanguage,
			}).then((tips) => {
				if (tips.length) {
					const updatedTips = tips.map((tip, index) =>
						fromResponseStreet(tip, index),
					);
					setStreetTips(updatedTips);
					setResponseStreetError(false);
				} else {
					setStreetTips([]);
					setResponseStreetError(true);
				}
			});
		} else {
			setStreetTips([]);
		}
	}, inputRequestDelayMs);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const requestHouseCoordinates = async (house) => {
		const canRequest = house?.trim();

		if (canRequest) {
			return search(`${selectedStreet?.street}, ${house}`, {
				country: "ua",
				searchType: "address",
				near: {
					point:
						selectedStreet?.coordinates ||
						foundedTaxiService?.settlementPoint,
					radius: searchRadiusMeters,
				},
				lang: mapLanguage,
			}).then((result) => {
				const finallAddress = result.find(
					(resultHouse) => resultHouse.number === house,
				);
				onSubmitAddress({ coordinates: finallAddress.coordinates });
			});
		}
		return null;
	};

	const [houseQuery, setHouseQuery] = useInternal("");

	const filtredHouseTips = useMemo(
		() => houseTips?.filter((house) => house?.value.includes(houseQuery)),
		[houseQuery, houseTips],
	);

	const [focusOnSettlement, setFocusOnSettlement] = useState<boolean>(false);
	const [focusOnAddress, setFocusOnAddress] = useState<boolean>(false);
	const [focusOnStreet, setFocusOnStreet] = useState<boolean>(false);

	const onBlurSettlement = useCallback(() => {
		setFocusOnSettlement(false);
	}, []);

	const onBlurAddress = useCallback(() => {
		setFocusOnAddress(false);
	}, []);

	const onBlurStreet = useCallback(() => {
		setFocusOnStreet(false);
	}, []);

	const onBlurAll = useCallback(() => {
		onBlurSettlement();
		onBlurAddress();
		onBlurStreet();
	}, [onBlurAddress, onBlurSettlement, onBlurStreet]);

	const onFocusSettlement = useCallback(() => {
		onBlurAddress();
		onBlurStreet();
		setFocusOnSettlement(true);
		onChangeTabIndex(2);
	}, [onBlurAddress, onBlurStreet, onChangeTabIndex]);

	const onFocusAddress = useCallback(() => {
		onBlurSettlement();
		onBlurStreet();
		setFocusOnAddress(true);
		onChangeTabIndex(3);
	}, [onBlurSettlement, onBlurStreet, onChangeTabIndex]);

	const onFocusStreet = useCallback(() => {
		onBlurSettlement();
		onBlurAddress();
		setFocusOnStreet(true);
		onChangeTabIndex(4);
	}, [onBlurAddress, onBlurSettlement, onChangeTabIndex]);

	const onChangeSettlement = useCallback(
		(value) => {
			setSettlementQuery(value);
			if (value.length >= minSearchQueryLength) {
				debounceRequestSettlement(value);
			}
			onChangeAddressSearchFieldError(false);
		},
		[
			debounceRequestSettlement,
			minSearchQueryLength,
			onChangeAddressSearchFieldError,
			setSettlementQuery,
		],
	);

	const onChangeAddress = useCallback(
		(value) => {
			setStreetQuery(value);
			if (value.length >= minSearchQueryLength) {
				debounceRequestStreet(value);
			}
			onChangeAddressSearchFieldError(false);
		},
		[
			debounceRequestStreet,
			minSearchQueryLength,
			onChangeAddressSearchFieldError,
			setStreetQuery,
		],
	);

	const onChangeStreet = useCallback(
		(value) => {
			setHouseQuery(value);
			onChangeAddressSearchFieldError(false);
		},
		[onChangeAddressSearchFieldError, setHouseQuery],
	);

	useEffect(() => {
		switch (tabIndex) {
			case 2: {
				onFocusSettlement();
				break;
			}
			case 3: {
				onFocusAddress();
				break;
			}
			case 4: {
				onFocusStreet();
				break;
			}
			default: {
				onBlurAll();
				break;
			}
		}
	}, [
		onBlurAddress,
		onBlurAll,
		onBlurSettlement,
		onBlurStreet,
		onFocusAddress,
		onFocusSettlement,
		onFocusStreet,
		tabIndex,
	]);

	const onSelectSettlement = useCallback(
		(option) => {
			setSelectedSettlement(option.value);
			setStreetTips([]);
			setHouseTips([]);
			setStreetQuery("");
			setHouseQuery("");
			onChangeTabIndex(3);
		},
		[onChangeTabIndex, setHouseQuery, setStreetQuery],
	);

	const onSelectStreet = useCallback(
		(option) => {
			setSelectedStreet(option.value);
			const houses =
				option.value.streetHouses?.map((house, index) =>
					fromResponseHouse(house, index),
				) || [];
			setHouseTips(houses);
			setHouseQuery("");
			onChangeTabIndex(4);
		},
		[onChangeTabIndex, setHouseQuery],
	);
	const onSelectHouse = useCallback(
		async (option) => {
			setSelectedHouse(option.value);
			await requestHouseCoordinates(option.value);
		},
		[requestHouseCoordinates],
	);

	const settlementError = useMemo(
		() => error || responseSettlementError,
		[error, responseSettlementError],
	);

	const streetError = useMemo(
		() => error || responseStreetError,
		[error, responseStreetError],
	);

	const houseError = useMemo(
		() => error || !!(!filtredHouseTips?.length && houseQuery),
		[error, filtredHouseTips?.length, houseQuery],
	);

	useEffect(() => {
		setStreetQuery("");
		setHouseQuery("");
		setHouseTips([]);
		setStreetTips([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServiceId]);

	return (
		<Column gaps="10px">
			<Row gaps="37px" maxedWidth align="center" justify="space-between">
				<span>
					{t(
						"orderPageWidgets.parking.parkingsModal.addressSearchField.str0",
					) ?? ""}
					:{" "}
				</span>
				<Row gaps="10px*" maxedWidth>
					<TextSelect
						value={settlementQuery}
						onChange={onChangeSettlement}
						onSelect={onSelectSettlement}
						onBlur={onBlurSettlement}
						onFocus={onFocusSettlement}
						error={settlementError}
						placeholder={
							t(
								"orderPageWidgets.parking.parkingsModal.addressSearchField.str0",
							) ?? ""
						}
						style={{ width: 390 }}
						options={settlementTips}
						focused={focusOnSettlement}
						disabled={isEditModal}
					/>
				</Row>
			</Row>
			<Row
				gaps="34px 10px"
				maxedWidth
				align="center"
				justify="space-between"
			>
				<span>
					{t(
						"orderPageWidgets.parking.parkingsModal.addressSearchField.str1",
					) ?? ""}
					:{" "}
				</span>
				<TextSelect
					value={streetQuery}
					onChange={onChangeAddress}
					onSelect={onSelectStreet}
					onBlur={onBlurAddress}
					onFocus={onFocusAddress}
					error={streetError}
					placeholder={
						t(
							"orderPageWidgets.parking.parkingsModal.addressSearchField.str1",
						) ?? ""
					}
					options={streetTips}
					focused={focusOnAddress}
					disabled={isEditModal}
					style={{ width: 270 }}
				/>
				<TextSelect
					value={houseQuery}
					onChange={onChangeStreet}
					onSelect={onSelectHouse}
					onFocus={onFocusStreet}
					onBlur={onBlurStreet}
					error={houseError}
					placeholder={
						t(
							"orderPageWidgets.parking.parkingsModal.addressSearchField.str2",
						) ?? ""
					}
					options={filtredHouseTips}
					focused={focusOnStreet}
					disabled={isEditModal}
					style={{ width: 110 }}
				/>
			</Row>
		</Column>
	);
};
export default AddressSearchField;
