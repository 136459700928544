import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Row, Button } from "uikit";
import { StyledRow, SuspenseLoader } from "../../../common";
import Root from "./components/Root";

const Footer = memo<Footer.Props>(({ onCancel, onSave, isSave }) => {
	const { t } = useTranslation();
	return (
		<Root align="center" justify="right">
			<Row gaps="16px">
				<Button.Button
					variant="secondary"
					text={t("inputModal.footer.str0") ?? ""}
					onClick={onCancel}
					style={{ width: "120px" }}
				/>
				{!isSave && (
					<Button.Button
						text={t("inputModal.footer.str1") ?? ""}
						onClick={onSave}
						style={{ width: "120px" }}
					/>
				)}
				{isSave && (
					<StyledRow
						h="34px"
						w="120px"
						bgC="#03A9F4"
						br="5px"
						alignItems="center"
						justify="center"
					>
						<SuspenseLoader loader="6" />
					</StyledRow>
				)}
			</Row>
		</Root>
	);
});

declare namespace Footer {
	interface Props {
		onCancel: () => void;
		onSave: () => void;
		isSave?: boolean;
	}
}

export default Footer;
