import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RadioList2, TextBox } from "uikit";
import styled from "styled-components";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledBlock from "../../../../../../../../../../../../components/LabeledBlock";
import {
	StyledColumn,
	StyledRow,
} from "../../../../../../../../../../../../components/common";

const Root = styled(StyledColumn)`
	min-width: 450px;
	padding-inline: 24px;
`;

const RadioButtonLabel = styled.div`
	white-space: nowrap;
`;

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const typeOptions: RadioList2.Option<"garage" | "state">[] = useMemo(
		() => [
			{
				key: "garage",
				value: "garage",
				content:
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.content.str200",
					) ?? "",
			},
			{
				key: "state",
				value: "state",
				content:
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.content.str201",
					) ?? "",
			},
		],
		[t],
	);

	const valueEditor = useObjectEditor(value, onChange);

	const typeValue = valueEditor.useGetter("type");
	const typeOnChange = valueEditor.useSetter("type");

	const numberValue = valueEditor.useGetter("number");
	const numberOnChange = valueEditor.useSetter("number");

	return (
		<Root gap="16px">
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.content.str0",
					) ?? ""
				}
			>
				<StyledColumn gap="16px">
					<RadioList2
						value={typeValue}
						options={typeOptions}
						onChange={typeOnChange}
					>
						{(radios) => (
							<StyledRow alignItems="center" gap="32px">
								{radios.map((radio, index) => (
									<RadioButtonLabel key={index}>
										{radio}
									</RadioButtonLabel>
								))}
							</StyledRow>
						)}
					</RadioList2>
					<TextBox.TextBox
						value={numberValue}
						autoFocus
						placeholder={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.content.str1",
							) ?? ""
						}
						onChange={numberOnChange}
					/>
				</StyledColumn>
			</LabeledBlock>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		type: "state" | "garage";
		number?: string;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
