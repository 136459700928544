import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Executor, Dispatcher } from "../../../../services";
import softphoneWidget, {
	CallType,
	TransferType,
} from "../../../../redux/reducers/softphoneWidget";
import { useTypedSelector } from "../../../../redux/store";
import {
	transferSelectedPhone,
	transferToAnyNumber,
} from "../../../../utils/jsSip";

import HeaderDraggable from "./components/HeaderDraggable";
import { DispatcherPhone, ExecutorPhone } from "./types";
import SipList from "./components/SipList";
import Root from "./components/Root";
import Search from "./components/Search";
import NavBar from "./components/NavBar";
import FooterButtons from "./components/FooterButtons";
import PhoneList from "./components/PhoneList";
import { convertDataToFullname } from "./helper";

interface Props {
	type: TransferType;
	onClose: () => void;
}

const Transfer: React.FC<Props> = ({ type, onClose }) => {
	const dispatch = useDispatch();

	const {
		transferData,
		transferTab,
		transferNumberQuery,
		selectedTransfer,
		isShowTransferSelector,
		selectedCall,
	} = useTypedSelector((state) => state.softphoneWidget);

	const { sipValue: userSipValue } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const dispatcherId = useTypedSelector((state) => state.account.user?.id);

	const onCall = useCallback(
		async (callType: CallType, sipValue?: string) => {
			if (type === "blind") {
				if (transferNumberQuery) {
					await transferToAnyNumber("blind", callType);
					onClose();
					return null;
				}

				await transferSelectedPhone(
					selectedTransfer,
					"blind",
					callType,
					sipValue,
				);
				onClose();
			}
			if (type === "normal") {
				if (transferNumberQuery) {
					await transferToAnyNumber("normal", callType);
					onClose();
					return null;
				}
				await transferSelectedPhone(
					selectedTransfer,
					"normal",
					callType,
					sipValue,
				);
				onClose();
			}
			onClose();
			return null;
		},
		[onClose, selectedTransfer, transferNumberQuery, type],
	);

	const onSelectPhone = useCallback(
		(phone: DispatcherPhone | ExecutorPhone) => {
			if (selectedTransfer) {
				if (
					phone.id === selectedTransfer.id &&
					phone.phone === selectedTransfer.phone
				) {
					dispatch(softphoneWidget.actions.setSelectedTransfer(null));
				} else {
					dispatch(
						softphoneWidget.actions.setSelectedTransfer(phone),
					);
				}
			} else {
				dispatch(softphoneWidget.actions.setSelectedTransfer(phone));
			}
		},
		[dispatch, selectedTransfer],
	);

	const convertDispatcher = useCallback(
		(dispatcher: Dispatcher.Model): DispatcherPhone => ({
			id: dispatcher.id,
			alias: dispatcher?.alias || "",
			dispatchersAvailableSips: dispatcher?.dispatcherToSIPs
				? dispatcher.dispatcherToSIPs
						.map((sip) => sip?.sip?.value)
						.filter((sip) => sip !== userSipValue)
				: [],
			name: convertDataToFullname(dispatcher),
			phone: dispatcher?.additionalFields?.phones?.length
				? `+${dispatcher.additionalFields?.phones[0]}`
				: "",
			type: "dispatcher",
		}),
		[userSipValue],
	);
	const convertExecutor = useCallback(
		(executor: Executor.Model): ExecutorPhone => ({
			id: executor.id,
			alias: executor.alias,
			name: convertDataToFullname(executor),
			phone: executor?.person?.phones?.length
				? `+${executor.person?.phones[0]?.number}`
				: "",
			type: "executor",
		}),
		[],
	);

	const phoneListData = useMemo(() => {
		const dispatchersData = transferData.dispatchers
			.map((dispatcher) => convertDispatcher(dispatcher))
			.filter((dispatcher) => dispatcher.id !== dispatcherId);

		const executorsData = transferData.executors.map((executor) =>
			convertExecutor(executor),
		);

		return {
			dispatchers: dispatchersData,
			executors: executorsData,
		};
	}, [
		convertDispatcher,
		convertExecutor,
		dispatcherId,
		transferData.dispatchers,
		transferData.executors,
	]);

	useEffect(() => {
		if (!selectedCall) {
			dispatch(softphoneWidget.actions.closeTransferModal());
		}
	}, [dispatch, selectedCall]);

	const { t } = useTranslation();
	return (
		<Root sizes="auto*3 1fr auto">
			<HeaderDraggable
				draggable={true}
				title={
					type === "blind"
						? t("softphoneWidget.transfer.str200") ?? ""
						: t("softphoneWidget.transfer.str201") ?? ""
				}
				onClose={onClose}
			/>
			<Search />
			<NavBar navigation={transferTab} transferTab={transferTab} />
			{transferTab === "dispatchers" && (
				<PhoneList
					data={phoneListData.dispatchers}
					onSelectPhone={onSelectPhone}
					selectedPhone={selectedTransfer}
				/>
			)}
			{transferTab === "executors" && (
				<PhoneList
					data={phoneListData.executors}
					onSelectPhone={onSelectPhone}
					selectedPhone={selectedTransfer}
				/>
			)}
			{transferTab === "anyNumber" && (
				<PhoneList
					data={[]}
					onSelectPhone={onSelectPhone}
					selectedPhone={selectedTransfer}
				/>
			)}
			<FooterButtons
				selectedPhone={selectedTransfer}
				type={type}
				onCall={onCall}
			/>
			{isShowTransferSelector && <SipList onCall={onCall} />}
		</Root>
	);
};

export default Transfer;
