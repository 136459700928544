import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

export const cellProcessing = (
	item: MarkUpOnAgencyOrderCellContent.Props["item"],
) => {
	if (!isNumber(item?.ordersCounters?.bonus?.amount)) return "";
	return formatNumber(0);
};

const MarkUpOnAgencyOrderCellContent: React.FC<
	MarkUpOnAgencyOrderCellContent.Props
> = ({ item }) => {
	const content = useMemo(() => cellProcessing(item), [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace MarkUpOnAgencyOrderCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default MarkUpOnAgencyOrderCellContent;
