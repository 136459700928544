import React, {
	memo,
	PropsWithChildren,
	useMemo,
	useState,
	ReactNode,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import { StyledRow, StyledSpan, Style } from "../styles";
import { Colors } from "../interface";

const CheckBoxWithTextBase: React.FC<CheckBoxWithTextBase.Props> = ({
	value,
	onChange,
	disabled = false,
	title,
	labelPosition,
	children,
	textAfterTitle = "",
	notActive = false,
	textStyle,
	iconBeforeText,
	...props
}) => {
	const { t } = useTranslation();
	const [data, setData] = useState<CheckBoxWithTextBase.Value>(false);

	const text = useMemo(() => t(title), [t, title]);
	const text2 = useMemo(() => t(textAfterTitle), [t, textAfterTitle]);

	const colorText = useMemo(() => {
		if (notActive) return Colors.PLACEHOLDER;
		if (disabled) return "#647079";
		if (value || data) return "#21333f";
		return "#647079";
	}, [value, disabled, data, notActive]);

	return (
		<StyledRow alignItems="center" gap="10px" {...props}>
			<CheckBox
				value={onChange ? value : data}
				onChange={onChange || setData}
				disabled={disabled}
				labelPosition={labelPosition}
			/>
			<StyledRow alignItems="center" gap="5px">
				{iconBeforeText && iconBeforeText}
				<StyledSpan colors={colorText} {...textStyle}>
					{`${text} ${text2}`.trim()}
				</StyledSpan>
			</StyledRow>
			{children}
		</StyledRow>
	);
};

export const CheckBoxWithText = memo(CheckBoxWithTextBase);

declare namespace CheckBoxWithTextBase {
	type Value = boolean;

	interface Props extends Style.Row, PropsWithChildren {
		value?: Value;
		onChange?: (value: Value) => void;
		title: string | string[];
		textAfterTitle?: string | string[];
		disabled?: boolean;
		notActive?: boolean;
		labelPosition?: CheckBox.Props["labelPosition"];
		textStyle?: Style.TextType;
		iconBeforeText?: ReactNode;
	}
}

export default CheckBoxWithTextBase;
