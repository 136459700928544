import { ReactNode } from "react";

import { Language } from "../../../../../../../../../services";
import tPath from "../../../constants/tPath";

import fullName from "./fullName";
import alias from "./alias";
import parkNumber from "./parkNumber";
import phones from "./phones";
import balance from "./balance";
import bonusBalance from "./bonusBalance";
import role from "./role";
import company from "./company";
import taxiService from "./taxiService";
import registeredAt from "./registeredAt";
import responsibleDispatcher from "./responsibleDispatcher";
import executorRateId from "./executorRateId";
import priority from "./priority";

const translationTablePath = `${tPath}.columns`;

export enum ColumnId {
	FullName = "fullName",
	Alias = "alias",
	ParkNumber = "parkNumber",
	Phones = "phones",
	Balance = "balance",
	BonusBalance = "bonusBalance",
	Role = "role",
	Company = "company",
	TaxiService = "taxiService",
	RegisteredAt = "registeredAt",
	ResponsibleDispatcher = "responsibleDispatcher",
	ExecutorRateId = "executorRateId",
	Priority = "priority",
}

export interface Column {
	render: (options: Column.Options) => ReactNode;
	label: string | string[];
}

export declare namespace Column {
	interface Options {
		width?: number;
		language: Language;
		onResize?: (width: number, columnId: ColumnId) => void;
	}
}

export const columns: Record<ColumnId, Column> = {
	[ColumnId.FullName]: {
		render: fullName,
		label: [`${translationTablePath}.fullName`, "Full Name"],
	},
	[ColumnId.Alias]: {
		render: alias,
		label: [`${translationTablePath}.alias`, "Alias"],
	},
	[ColumnId.ParkNumber]: {
		render: parkNumber,
		label: [`${translationTablePath}.parkNumber`, "Park Number"],
	},
	[ColumnId.Phones]: {
		render: phones,
		label: [`${translationTablePath}.phones`, "Phones"],
	},
	[ColumnId.Balance]: {
		render: balance,
		label: [`${translationTablePath}.balance`, "Balance"],
	},
	[ColumnId.BonusBalance]: {
		render: bonusBalance,
		label: [`${translationTablePath}.bonusBalance`, "Bonus Balance"],
	},
	[ColumnId.Role]: {
		render: role,
		label: [`${translationTablePath}.role`, "Role"],
	},
	[ColumnId.Company]: {
		render: company,
		label: [`${translationTablePath}.company`, "Company"],
	},
	[ColumnId.TaxiService]: {
		render: taxiService,
		label: [`${translationTablePath}.taxiService`, "Taxi Service"],
	},
	[ColumnId.RegisteredAt]: {
		render: registeredAt,
		label: [`${translationTablePath}.registeredAt`, "Registered At"],
	},
	[ColumnId.ResponsibleDispatcher]: {
		render: responsibleDispatcher,
		label: [
			`${translationTablePath}.responsibleDispatcher`,
			"Responsible Dispatcher",
		],
	},
	[ColumnId.ExecutorRateId]: {
		render: executorRateId,
		label: [`${translationTablePath}.executorRateId`, "ExecutorRateId"],
	},
	[ColumnId.Priority]: {
		render: priority,
		label: [`${translationTablePath}.priority`, "Priority"],
	},
};

export const defaultColumnIds = [
	ColumnId.FullName,
	ColumnId.Alias,
	ColumnId.ParkNumber,
	ColumnId.Phones,
	ColumnId.Balance,
	ColumnId.BonusBalance,
	ColumnId.Role,
	ColumnId.Company,
	ColumnId.TaxiService,
	ColumnId.RegisteredAt,
	ColumnId.ResponsibleDispatcher,
	ColumnId.ExecutorRateId,
	ColumnId.Priority,
];

export const disabledColumnsIds = [];

export default columns;
