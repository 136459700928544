import React, { useMemo } from "react";

import { Dispatcher } from "../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import { TaxiServicesByIds } from "../../../../../../../../../../components/common";

const TaxiServiceCellContent: React.FC<TaxiServiceCellContent.Props> = ({
	item,
}) => {
	const language = useTypedSelector((state) => state.session.language);

	const content = useMemo(
		() => item.taxiServiceIds || [],
		[item.taxiServiceIds],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<TaxiServicesByIds
				taxiServiceIds={content}
				lang={language}
				addNameCompany
			/>
		</CellContentRoot>
	);
};

declare namespace TaxiServiceCellContent {
	interface Props {
		item: Dispatcher.Model;
	}
}

export default TaxiServiceCellContent;
