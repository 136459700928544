import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	useMemo,
} from "react";
import { TimeInput } from "uikit";
import { isArray } from "lodash";

import { ChatMessage } from "../../../services";
import { useTypedSelector } from "../../../redux/store";
import { useModelSubscribe, useKeyBind } from "../../../hooks";
import { KEYBOARD_CODES } from "../../../constants/business";
import { useTaxiServiceFilterAccess } from "../../../access";
import { useFilteredExecutors } from "../hook";

import { useChatActions } from "./hooks";
import { Navigation } from "./types";
import {
	MODAL_TYPES,
	NAVIGATION_TYPES,
	SCHEDULE_ACTUAL_TYPES,
} from "./constants/business";
import {
	Root,
	NavBar,
	ChatList,
	SendBar,
	MessageTypeModal,
	ScheduleModal,
	UsersModal,
	ClockModal,
	AnswerShow,
} from "./components";

const Chat: React.FC = () => {
	const { subTaxiServiceFilterAccess } =
		useTaxiServiceFilterAccess<ChatMessage.Model>();

	const {
		// booferSelectedMessageType,
		selectedUsers,
		modalType,
		isShowClockModal,
		//
		scheduleTime,
		scheduleDate,
		scheduleModalActualType,
		scheduleHours,
		scheduleMinutes,
		//
		answerParentID,

		userModalData,
	} = useTypedSelector((state) => state.orders.chats.chat);
	const { filterExecutorsRead } = useTypedSelector(
		(state) => state.orders.chats.filter,
	);
	const executors = useTypedSelector(
		(state) => state.globalState.executors.data.subscription.basic,
	);

	const {
		selectDefaultMessage,
		onCleanAllUsers,
		onSubmitSchedule,
		onCleanAllSchedule,
		closeModal,
		onCleanAllAnswerParent,
		onSaveLastReadFirstLoading,
		onSendMessage,
		onSaveLastReadForSendMessageAndNullLust,
		onOpenClockModal,
		onCloseClockModal,
	} = useChatActions();

	const filteredExecutors = useFilteredExecutors(
		userModalData.executors,
		filterExecutorsRead.criteria,
		{
			companyIds: true,
			taxiServiceIds: true,
			executorIds: true,
			executorGroupIds: true,
			fleetIds: true,
		},
	);

	const showedExecutorIds = useMemo(() => {
		if (!filterExecutorsRead.isEnabled) {
			return undefined;
		}
		return filteredExecutors?.map((executor) => executor.id);
	}, [filterExecutorsRead.isEnabled, filteredExecutors]);

	const inputRef = useRef<any>(null);

	const user = useTypedSelector((state) => state.account.user);
	const lang = useTypedSelector((state) => state.session.language);

	const userId = user?.id;

	const [isInputFocused, setIsInputFocused] = useState(true);
	const [navigation, setNavigation] = useState<Navigation>("executors");
	const [isLoadingList, setIsLoadingList] = useState(true);
	const [isLoadingSendButton, setIsLoadingSendButton] = useState(false);

	const modelSubscriptionOptions =
		useMemo<ChatMessage.SubscribeOptions>(() => {
			const isTurnOnFiltrationExecutorMessage =
				isArray(showedExecutorIds);

			const result: ChatMessage.SubscribeOptions = {
				order: { createdAt: "ASC" },
				lang,
				...(isTurnOnFiltrationExecutorMessage
					? { showedExecutorIds }
					: {}),
			};

			return result;
		}, [showedExecutorIds, lang]);

	const chatMessageSubscribe = useModelSubscribe(
		modelSubscriptionOptions,
		ChatMessage,
	);

	const listServices = useMemo(() => {
		const models = chatMessageSubscribe.models || [];

		// const dataAfterAccess = subTaxiServiceFilterAccess({
		// 	models,
		// 	branchPath: "taxiService.id",
		// });

		const idsExecutorsAccess = new Set(
			executors.map((executor) => executor.id),
		);

		const modelsAccess = models.filter((model) =>
			idsExecutorsAccess.has(model.executor?.id ?? -1),
		);

		return modelsAccess;
	}, [chatMessageSubscribe.models, executors]);

	const onFocus = useCallback(() => {
		setTimeout(() => {
			inputRef?.current?.focus();
		}, 30);
		setIsInputFocused(true);
	}, []);

	const onBlur = useCallback(() => {
		setTimeout(() => {
			inputRef?.current?.blur();
		}, 30);
		setIsInputFocused(false);
	}, []);

	const clearInputs = useCallback(() => {
		if (inputRef?.current) {
			inputRef.current.value = "";
		}
	}, []);

	const isNotEmptyContent = (text: string | undefined): boolean =>
		typeof text === "string" && text.trim() !== "";

	const onClickSendMessage = useCallback(async () => {
		const content = inputRef?.current?.value;

		if (!userId || !content || !isNotEmptyContent(content)) {
			return;
		}

		const getTriggerDate = () => {
			if (!scheduleDate && !scheduleTime) return undefined;

			if (scheduleModalActualType === SCHEDULE_ACTUAL_TYPES.TIME) {
				const triggerDate = new Date();
				triggerDate.setHours(triggerDate.getHours() + scheduleHours);
				triggerDate.setMinutes(
					triggerDate.getMinutes() + scheduleMinutes,
				);

				return triggerDate;
			}

			if (
				scheduleModalActualType === SCHEDULE_ACTUAL_TYPES.DATE &&
				scheduleDate
			) {
				const date = new Date(scheduleDate);

				if (scheduleTime) {
					const time = new Date(scheduleTime);
					date.setHours(time.getHours());
					date.setMinutes(time.getMinutes());
					date.setSeconds(time.getSeconds());
					date.setMilliseconds(time.getMilliseconds());
				}

				return date;
			}

			return undefined;
		};

		const getUsersIds = (users: Chat.UserReceiver[]) =>
			users.length > 0
				? users.map((item: Chat.UserReceiver) => item.id)
				: [];

		const megaCleanAfterNewMessage = () => {
			clearInputs();
			selectDefaultMessage();
			onCleanAllUsers();
			onCleanAllSchedule();
			onCleanAllAnswerParent();
		};

		const _expirationDate = getTriggerDate() || undefined;

		const chatUpdateData: ChatMessage.Model.New = {
			content,
			...(_expirationDate ? { expirationDate: _expirationDate } : {}),
			// type: booferSelectedMessageType,
			...(answerParentID ? { parentId: answerParentID } : {}),
			allowedDispatcherIds: getUsersIds(selectedUsers.dispatchers),
			allowedExecutorIds: getUsersIds(selectedUsers.executors),
		};

		try {
			setIsLoadingSendButton(true);
			const res = await ChatMessage.store(chatUpdateData);
			if (res.error) {
				console.error("[Chat]: Error updating chat", res);
			} else {
				if (res.id > 0) onSaveLastReadForSendMessageAndNullLust(res.id);
				megaCleanAfterNewMessage();
				onSendMessage();
			}
		} catch (error) {
			console.error("[Chat]: Error updating chat", error);
		} finally {
			setIsLoadingSendButton(false);
		}
	}, [
		userId,
		selectedUsers,
		selectDefaultMessage,
		scheduleTime,
		scheduleDate,
		scheduleModalActualType,
		scheduleHours,
		scheduleMinutes,
		clearInputs,
		onCleanAllUsers,
		onCleanAllSchedule,
		onCleanAllAnswerParent,
		answerParentID,
		// booferSelectedMessageType,
		onSendMessage,
		onSaveLastReadForSendMessageAndNullLust,
	]);

	const onChangeNavigation = useCallback((tab: Navigation) => {
		setNavigation(tab);
	}, []);

	const onSubmitClockTime = useCallback(
		(time: TimeInput.Value) => {
			setScheduleTimeBoofer(time);
			onCloseClockModal();
		},
		[onCloseClockModal],
	);

	const getMidnightTime = useCallback(() => {
		const dateUTC = new Date("1970-01-01T23:59:59Z");

		const timezoneOffset = dateUTC.getTimezoneOffset();

		return new Date(dateUTC.getTime() + timezoneOffset * 60000);
	}, []);

	const [scheduleTimeBoofer, setScheduleTimeBoofer] =
		useState<TimeInput.Value>(scheduleTime || getMidnightTime());

	useEffect(() => {
		if (scheduleTime) {
			setScheduleTimeBoofer(scheduleTime);
		}
	}, [scheduleTime]);

	useEffect(() => {
		if (modalType === "none") {
			onFocus();
		}
	}, [modalType, onFocus]);

	useEffect(() => {
		setIsLoadingList(chatMessageSubscribe.loading);
	}, [chatMessageSubscribe.loading]);

	useKeyBind([KEYBOARD_CODES.ARROW_RIGHT], () => {
		if (!isInputFocused) {
			if (navigation === NAVIGATION_TYPES.EXECUTORS)
				setNavigation(NAVIGATION_TYPES.DISPATCHERS);
			if (navigation === NAVIGATION_TYPES.DISPATCHERS)
				setNavigation(NAVIGATION_TYPES.EXCHANGE);
		}
	});

	useKeyBind([KEYBOARD_CODES.ARROW_LEFT], () => {
		if (!isInputFocused) {
			if (navigation === NAVIGATION_TYPES.EXCHANGE)
				setNavigation(NAVIGATION_TYPES.DISPATCHERS);
			if (navigation === NAVIGATION_TYPES.DISPATCHERS)
				setNavigation(NAVIGATION_TYPES.EXECUTORS);
		}
	});

	useEffect(() => {
		const fetchLastRead = async () => {
			try {
				const response = await ChatMessage.getLastRead();

				if (response && response.chatMessageId) {
					const { chatMessageId } = response;
					if (chatMessageId > 0)
						onSaveLastReadFirstLoading(chatMessageId);
				}
			} catch (error) {
				console.error("[chat]: Error fetching last read:", error);
			}
		};

		fetchLastRead();
	}, [onSaveLastReadFirstLoading]);

	useEffect(() => {
		if (answerParentID && inputRef.current) {
			inputRef.current.focus();
		}
	}, [answerParentID, inputRef]);

	return (
		<>
			<Root gaps="auto 1fr auto" maxedHeight>
				<NavBar
					navigation={navigation}
					onChangeNavigation={onChangeNavigation}
				/>

				<ChatList
					listServices={listServices}
					isLoadingList={isLoadingList}
					userId={userId}
				/>

				{answerParentID && listServices && (
					<AnswerShow
						data={{
							messageParent: listServices?.find(
								(item) => item.id === answerParentID,
							),
						}}
					/>
				)}
				<SendBar
					inputRef={inputRef}
					onBlur={onBlur}
					onFocus={onFocus}
					onClickSendMessage={onClickSendMessage}
					clearInputs={clearInputs}
					isLoadingSendButton={isLoadingSendButton}
				/>

				{isShowClockModal && (
					<ClockModal
						onSubmit={onSubmitClockTime}
						onClose={onCloseClockModal}
					/>
				)}

				{modalType === MODAL_TYPES.USERS && (
					<UsersModal onClose={closeModal} />
				)}
				{modalType === MODAL_TYPES.SCHEDULE && (
					<ScheduleModal
						onClose={closeModal}
						onOpenClock={onOpenClockModal}
						onSubmit={onSubmitSchedule}
						scheduleTimeBoofer={scheduleTimeBoofer}
						onChangeScheduleTimeBoofer={setScheduleTimeBoofer}
					/>
				)}
				{modalType === MODAL_TYPES.FLAGS && (
					<MessageTypeModal onClose={closeModal} />
				)}
			</Root>
		</>
	);
};

declare namespace Chat {
	interface UserReceiver {
		id: number;
	}
}

export default Chat;
