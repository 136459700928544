import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledBlock from "../../../../../../../../../../../../components/LabeledBlock";
import {
	StyledColumn,
	Input,
} from "../../../../../../../../../../../../components/common";

const Root = styled(StyledColumn)`
	min-width: 450px;
	padding-inline: 24px;
`;

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	// const dispatch = useDispatch();
	const valueEditor = useObjectEditor(value, onChange);

	const numberValue = valueEditor.useGetter("number");
	const numberOnChange = valueEditor.useSetter("number");

	const setNumberValue: React.ChangeEventHandler<HTMLInputElement> = (
		event,
	) => {
		const { value } = event.target;
		const parsedValue = value ? Number(value) : undefined;
		numberOnChange(parsedValue);
	};

	return (
		<Root gap="16px">
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByExecutorModal.content.str2",
					) ?? ""
				}
			>
				<StyledColumn gap="16px">
					<Input
						value={numberValue ?? ""}
						type="number"
						placeholder={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByExecutorModal.content.str1",
							) ?? ""
						}
						onChange={setNumberValue}
					/>
				</StyledColumn>
			</LabeledBlock>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		number?: number;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
