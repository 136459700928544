import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const FullNameCellContent: React.FC<FullNameCellContent.Props> = ({ item }) => {
	const content = useMemo(
		() =>
			`${item.person?.lastName ?? ""} ${item.person?.firstName ?? ""} ${
				item.person?.fatherName ?? ""
			}`.trim() ?? "",
		[
			item.person?.fatherName,
			item.person?.firstName,
			item.person?.lastName,
		],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace FullNameCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default FullNameCellContent;
