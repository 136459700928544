import React, { useMemo } from "react";

import { Dispatcher } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const RoleCellContent: React.FC<RoleCellContent.Props> = ({ item }) => {
	const content = useMemo(
		() => item.roles?.map((role) => role.name || "").join(", ") ?? "",
		[item?.roles],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace RoleCellContent {
	interface Props {
		item: Dispatcher.Model;
	}
}

export default RoleCellContent;
