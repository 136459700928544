/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextSelect } from "uikit";

import { Executor } from "../../../../../../../services";
import { useExecutorsSubHandler } from "../../../../../../../hooks";
import { isTextMatchingLeadingStarsFilterHelper } from "../../../../../../../utils";
import LabeledField from "../../../../../../LabeledField";
import { getExecutorFullName } from "../../utils";

const SearchExecutor: React.FC<SearchExecutor.Props> = ({
	alias,
	handleSelect,
	selectedId,
	isEdit,
	isAliasWasSelected,
	setAlias,
	selected,
}) => {
	const { t } = useTranslation();
	const { getAllExecutorsSubHandler } = useExecutorsSubHandler();

	const [firstOpenModal, setFirstOpenModal] = useState<boolean>(true);
	const [isFoundExecutor, setIsFoundExecutor] = useState<boolean>(true);
	const [isCheck, setIsCheck] = useState<boolean>(false);
	const [haveEditFormAndBadCheck, setHaveEditFormAndBadCheck] =
		useState<boolean>(false);

	const searchAliasModelItems = useMemo(() => {
		const modelSearchAlias: Executor.SubscribeOptions = {
			query: alias,
		};

		return getAllExecutorsSubHandler(modelSearchAlias);
	}, [alias, getAllExecutorsSubHandler]);

	const executorOptions = useMemo(() => {
		const options = searchAliasModelItems?.map((tip) => {
			const labelString = getExecutorFullName(tip);
			return {
				key: tip.id,
				label: labelString,
				value: tip,
			};
		});

		const filteredOptions = options.filter((item) =>
			isTextMatchingLeadingStarsFilterHelper(item.value.alias, alias),
		);

		return filteredOptions;
	}, [alias, searchAliasModelItems]);

	useEffect(() => {
		if (firstOpenModal && !searchAliasModelItems.length) {
			setIsFoundExecutor(true);
		}
		if (searchAliasModelItems.length) setFirstOpenModal(false);
	}, [searchAliasModelItems]);

	const handleAliasChange = useCallback(() => {
		if (
			typeof selected?.alias === "string" &&
			typeof alias === "string" &&
			(!Array.isArray(executorOptions) || executorOptions?.length < 1)
		) {
			if (
				alias.length >= selected.alias.length &&
				alias.startsWith(selected.alias) &&
				Number.isNaN(Number(alias.slice(selected.alias.length)[0]))
			) {
				setHaveEditFormAndBadCheck(false);
			} else {
				setHaveEditFormAndBadCheck(true);
			}
		}

		if (Array.isArray(executorOptions) && executorOptions.length >= 1) {
			setHaveEditFormAndBadCheck(false);
		}
	}, [alias, executorOptions, selected?.alias]);

	useEffect(() => {
		handleAliasChange();
	}, [
		handleAliasChange,
		selected?.alias,
		alias,
		executorOptions,
		selectedId,
	]);

	useEffect(() => {
		if (selectedId) {
			setIsFoundExecutor(true);
			return;
		}

		const value = alias.trim();
		if (value.length >= 1) {
			if (firstOpenModal || searchAliasModelItems.length >= 1) {
				setIsFoundExecutor(true);
			} else {
				setIsFoundExecutor(false);
			}
		} else {
			setIsFoundExecutor(false);
		}
	}, [alias, searchAliasModelItems, selectedId]);

	useEffect(() => {
		if (!isCheck && executorOptions?.length) {
			const exist = executorOptions.find(
				(item) => item?.key === selectedId,
			);
			if (exist) {
				handleSelect(exist);
				setAlias(exist.label);
			}
			setIsCheck(true);
		}
	}, [isCheck, executorOptions]);

	return (
		<LabeledField
			label={
				t(
					"orderPageWidgets.executors.executorModal.searchExecutor.str0",
				) ?? ""
			}
		>
			<TextSelect
				placeholder={
					t(
						"orderPageWidgets.executors.executorModal.searchExecutor.str0",
					) ?? ""
				}
				style={{
					minHeight: "32px",
					flex: "1 0 0",
				}}
				value={alias}
				onChange={(value) => {
					setAlias(value);
				}}
				error={
					!isAliasWasSelected ||
					(!isFoundExecutor && !selectedId) ||
					haveEditFormAndBadCheck
				}
				onSelect={(option) => {
					handleSelect(option);
					setIsFoundExecutor(true);
				}}
				focused
				closeOnSelect
				options={alias ? executorOptions : []}
			/>
		</LabeledField>
	);
};

declare namespace SearchExecutor {
	interface Props {
		alias: string;
		isEdit: boolean;
		isAliasWasSelected: boolean;
		selectedId: number | null;
		setAlias: React.Dispatch<React.SetStateAction<string>>;
		handleSelect: (option) => void;
		selected: Executor.Model | null | undefined;
	}

	type Option = {
		key: number;
		label: string;
		value: {
			id: number;
			alias: string;
		};
	};
}

export default SearchExecutor;
