import React, { useMemo } from "react";

import { Order, Language } from "../../../../../../../services";
import CellContentRoot from "../CellContentRoot";

const CompanyCellContent: React.FC<CompanyCellContent.Props> = ({
	item,
	language,
}) => {
	const content = useMemo(
		() => item.taxiService?.company?.name?.[language],
		[item.taxiService?.company?.name, language],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CompanyCellContent {
	interface Props {
		item: Order.Model;

		language: Language;
	}
}

export default CompanyCellContent;
