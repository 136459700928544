import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const ResponsibleDispatcherCellContent: React.FC<
	ResponsibleDispatcherCellContent.Props
> = ({ item }) => {
	const content = useMemo(
		() =>
			[
				item.responsibleDispatcher?.person?.lastName ?? "",
				item.responsibleDispatcher?.person?.firstName ?? "",
				item.responsibleDispatcher?.person?.fatherName ?? "",
			]
				?.join(" ")
				?.trim() ?? "",
		[
			item.responsibleDispatcher?.person?.fatherName,
			item.responsibleDispatcher?.person?.firstName,
			item.responsibleDispatcher?.person?.lastName,
		],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace ResponsibleDispatcherCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default ResponsibleDispatcherCellContent;
