import React, { Dispatch, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInternal } from "uikit";
import { cloneDeep, defaults } from "lodash";

import LightInputModal from "../../../../../../../../../../components/LightInputModal";

import Footer from "./components/Footer";
import Content from "./components/Content";

const FilterOrdersModal: React.FC<FilterOrdersModal.Props> = ({
	onClose,
	onReset,
	onSubmit,

	...props
}) => {
	const { t } = useTranslation();
	const outerValue = useMemo(
		() =>
			defaults(cloneDeep(props.value ?? {}), {
				phone: "",
				from: "",
				to: "",
			}),
		[props.value],
	);

	const [value, setValue] = useInternal<Content.Value>(outerValue);

	const internalOnSubmit = useCallback(() => {
		onSubmit?.(value);
	}, [onSubmit, value]);

	return (
		<LightInputModal
			{...props}
			draggable={false}
			useDefaultFooter={false}
			title={
				t(
					"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.str0",
				) ?? ""
			}
			onClose={onClose}
			onSubmit={internalOnSubmit}
		>
			<Content value={value} onChange={setValue} />
			<Footer
				onCancel={onClose}
				onReset={onReset}
				onSubmit={internalOnSubmit}
			/>
		</LightInputModal>
	);
};

declare namespace FilterOrdersModal {
	type Value = Content.Value;

	interface Props
		extends Omit<
			LightInputModal.Props,
			"draggable" | "title" | "onSubmit"
		> {
		value?: Partial<Value>;

		onReset?: () => void;
		onSubmit?: Dispatch<Value>;
	}
}

export default FilterOrdersModal;
