/* eslint-disable no-param-reassign */
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { Order } from "../../../../../services";
import Chart from "../../../../../pages/Reports/pages/Charts/tabs/Orders/components/Chart";
import { DateRange, DateRangeLiteral } from "../../../../../types/DataRange";
import binarySearch from "../../../../../utils/binarySearch";
import OrderStatus from "../../../../../types/OrderStatus";
import CompaniesAndTaxiServicesFilter from "../../../../../components/CompaniesAndTaxiServicesFilter";

const sortedIntervals: Chart.Interval[] = [
	{
		label: "hour",
		value: 3600e3,
		minRange: 3600e3 * 2,
		format: {
			hour: "2-digit",
			minute: "2-digit",
		},
	},
	{
		label: "day",
		value: 3600e3 * 24,
		minRange: 3600e3 * 24 * 2,
		format: {
			dateStyle: "short",
		},
	},
	{
		label: "month",
		value: 3600e3 * 24 * 30,
		minRange: 3600e3 * 24 * 30 * 2,
		format: {
			month: "short",
		},
	},
	{
		label: "year",
		value: 3600e3 * 24 * 365,
		minRange: 3600e3 * 24 * 365 * 2,
		format: {
			year: "numeric",
		},
	},
];
sortedIntervals.sort((a, b) => a.value - b.value);
export const defaultInterval = sortedIntervals[0];

// export interface PartialOrder {
// 	createdAt: number;
// 	status: OrderStatus;
// }

interface ChartState {
	loading: boolean;
	dateFrom: number;
	dateTo: number;

	filter: {
		dateRangeLiteral: DateRangeLiteral;
		interval: Chart.Interval;
		companies: CompaniesAndTaxiServicesFilter.Props["companies"];
		taxiServices: CompaniesAndTaxiServicesFilter.Props["taxiServices"];
	};

	orders: Order.Model[];
}

const initialState: ChartState = {
	loading: false,
	dateFrom: moment().startOf("day").unix() * 1000,
	dateTo: moment().endOf("day").unix() * 1000,

	filter: {
		dateRangeLiteral: {
			from: moment().startOf("day").unix() * 1000,
			to: moment().endOf("day").unix() * 1000,
		},
		interval: defaultInterval,
		companies: ["all"],
		taxiServices: ["all"],
	},

	orders: [],
};

export function determineInterval(dateRange: DateRangeLiteral) {
	const diff = dateRange.to - dateRange.from + 1001; // +1.001 sec to compensate `23:59:59`

	const intervalKey = binarySearch(
		sortedIntervals.map((i) => i.minRange),
		diff,
	);
	const interval = sortedIntervals[intervalKey];

	return interval;
}

export function generateTimePeriods(
	dateRange: DateRangeLiteral,
	interval: Chart.Interval,
) {
	const start = moment(dateRange.from).startOf(interval.label);
	const end = moment(dateRange.to).endOf(interval.label);

	const diff = moment(end).diff(start);
	const periodCount = Math.round(diff / interval.value);

	return Array(periodCount)
		.fill(null)
		.map((_, i) => start.unix() * 1000 + i * interval.value);
}

type Reducer<P> = CaseReducer<ChartState, PayloadAction<P>>;

const setLoading: Reducer<ChartState["loading"]> = (state, { payload }) => {
	state.loading = payload;
};

const setDateFrom: Reducer<ChartState["dateFrom"]> = (state, { payload }) => {
	state.dateFrom = payload;
};

const setDateTo: Reducer<ChartState["dateTo"]> = (state, { payload }) => {
	state.dateTo = payload;
};

const setDateRange: Reducer<DateRange | DateRangeLiteral> = (
	state,
	{ payload },
) => {
	state.filter.dateRangeLiteral = {
		from: Number(payload.from),
		to: Number(payload.to),
	};

	state.filter.interval = determineInterval(state.filter.dateRangeLiteral);
};

const setInterval: Reducer<Chart.IntervalLabel> = (state, { payload }) => {
	const interval = sortedIntervals.find((i) => i.label === payload);

	if (!interval) throw Error(`Interval \`${payload}\` is not defined`);
	state.filter.interval = interval;
};

const setCompanyFilter: Reducer<ChartState["filter"]["companies"]> = (
	state,
	{ payload },
) => {
	state.filter.companies = payload;
};

const setTaxiServiceFilter: Reducer<ChartState["filter"]["taxiServices"]> = (
	state,
	{ payload },
) => {
	state.filter.taxiServices = payload;
};

const determineIntervalReducer: Reducer<
	DateRange | DateRangeLiteral | undefined
> = (state, { payload }) => {
	const dateRange = payload || state.filter.dateRangeLiteral;
	const dateRangeLiteral = {
		from: Number(dateRange.from),
		to: Number(dateRange.to),
	};

	state.filter.interval = determineInterval(dateRangeLiteral);
};

const setOrders: Reducer<ChartState["orders"]> = (state, { payload }) => {
	state.orders = payload;
};

const archiveReportsByOrders = createSlice({
	name: "reports",
	initialState,
	reducers: {
		setLoading,
		setDateFrom,
		setDateTo,

		setDateRange,
		setInterval,
		setCompanyFilter,
		setTaxiServiceFilter,

		determineInterval: determineIntervalReducer,
		setOrders,
	},
});

export default archiveReportsByOrders;
