import React, { useMemo } from "react";
import { isString } from "lodash";

import { Role } from "../../../../../../../../../../services";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const DescriptionCellContent: React.FC<DescriptionCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() => (isString(item.description) ? item.description : " "),
		[item.description],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content.split("\n").map((line, index) => (
				<React.Fragment key={index}>
					{line}
					<br />
				</React.Fragment>
			))}
		</CellContentRoot>
	);
};

declare namespace DescriptionCellContent {
	interface Props {
		item: Role.Model;
	}
}

export default DescriptionCellContent;
