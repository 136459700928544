import React, { useMemo } from "react";

import { Executor as ExecutorService } from "../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const CompanyCellContent: React.FC<CompanyCellContent.Props> = ({ item }) => {
	const lang = useTypedSelector((state) => state.session.language);
	const content = useMemo(
		() => item.taxiService?.company?.name?.[lang] ?? "",
		[item.taxiService?.company?.name, lang],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace CompanyCellContent {
	interface Props {
		item: ExecutorService.Model;
	}
}

export default CompanyCellContent;
