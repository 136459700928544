import React, {
	PropsWithChildren,
	memo,
	useCallback,
	CSSProperties,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { theme, Button } from "uikit";

import Modal from "../../../../../../../../components/Modal";
import {
	StyledRow,
	StyledColumn,
	SuspenseLoader,
} from "../../../../../../../../components/common";

const Root = styled(StyledColumn)`
	overflow: hidden;
	flex: 1;
	border-radius: 5px;
	background-color: ${theme.colors.white};
	overflow: hidden;
	min-width: 80vw;
	min-height: 75vh;
	max-width: 90vw;
	max-height: 90vh;
	margin: 0 auto;
`;

const FooterRoot = styled(StyledRow)`
	position: relative;
	padding: 0 20px 20px 0;
	box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
	gap: 24px;
	align: center;
	justify-content: flex-end;
`;

const GroupButtons = styled(StyledRow)`
	gap: 16px;
`;

const StyledButton = styled(Button.Button)`
	padding: 9px 25px;
	height: 36px;
	border: none;
	outline: none;
	box-shadow: none;

	&:focus {
		outline: none;
		box-shadow: none;
	}
`;

const EditModal: React.FC<EditModal.Props> = memo(
	({
		children,
		cancelText,
		submitText,
		canSave = true,
		dimmed = true,
		onCancel,
		onSave,
		loading,
	}) => {
		const { t } = useTranslation();

		const footerOnCancel = useCallback(() => onCancel?.(), [onCancel]);
		const footerOnSave = useCallback(() => onSave?.(), [onSave]);

		return (
			<Modal dimmed={dimmed}>
				<Root>
					{children}
					<FooterRoot>
						<GroupButtons>
							<StyledButton
								variant="secondary"
								text={
									cancelText ??
									t("editModal.footer.str200") ??
									""
								}
								onClick={footerOnCancel}
							/>

							{loading ? (
								<StyledRow
									h="36px"
									w="120px"
									bgC="#03A9F4"
									br="5px"
									alignItems="center"
									justify="center"
								>
									<SuspenseLoader loader="6" />
								</StyledRow>
							) : (
								<StyledButton
									disabled={!canSave}
									text={
										submitText ??
										t("editModal.footer.str201") ??
										""
									}
									onClick={footerOnSave}
								/>
							)}
						</GroupButtons>
					</FooterRoot>
				</Root>
			</Modal>
		);
	},
);

declare namespace EditModal {
	interface Props extends PropsWithChildren, Modal.Props {
		footerJustify?: CSSProperties["justifyContent"];
		dimmed?: boolean;
		canSave?: boolean;
		cancelText?: string;
		submitText?: string;
		onCancel: () => void;
		onSave: () => void;
		loading: boolean;
	}
}

export default EditModal;
