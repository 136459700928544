import React, { useMemo } from "react";
import { theme } from "uikit";
import styled from "styled-components";

import { SIP } from "../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import Circle from "../../../../../../../../../Circle";
import { StyledRow } from "../../../../../../../../../common";

import { checkSipForActivity } from "./helper";

const Root = styled(StyledRow)`
	cursor: pointer;
`;

const Sip: React.FC<Props> = ({ data }) => {
	const { sipValue } = useTypedSelector((state) => state.sipToDispatcher);

	const circleColor = useMemo(() => {
		if (data.sipValue === sipValue) return "#8000FF";
		return checkSipForActivity(data.lastCheckMs)
			? "rgb(76, 175, 80)"
			: theme.colors.negative;
	}, [data.lastCheckMs, data.sipValue, sipValue]);

	return (
		<Root alignItems="center">
			<Circle color={circleColor} />
		</Root>
	);
};

interface Props {
	data: SIP.Model.Available;
}

export default Sip;
