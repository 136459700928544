import { DatePicker } from "uikit";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { UserOptions } from "jspdf-autotable";

import { generateFileNameFileHelper, createLogger } from "../../utils";
import { DateRange } from "../../types/DataRange";
import IResponseWithItems from "../../types/IResponse";
import { PaymentAccount } from "../../types/PaymentAccount";
import { ValueLanguage } from "../../assets/languages/langs";
import { cellProcessing as additionalFeeCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/AdditionalFeeCellContent";
import { cellProcessing as balanceCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/BalanceCellContent";
import { cellProcessing as bonusAmountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/BonusAmountCellContent";
import { cellProcessing as callSignCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CallSignCellContent";
import { cellProcessing as cardCountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CardCountCellContent";
import { cellProcessing as cashAmountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CashAmountCellContent";
import { cellProcessing as cashCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CashCellContent";
import { cellProcessing as cashCountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CashCountCellContent";
import { cellProcessing as cashlessAmountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CashlessAmountCellContent";
import { cellProcessing as cashlessCountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CashlessCountCellContent";
import { cellProcessing as cellContentProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CellContent";
import { cellProcessing as commissionCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CommissionCellContent";
import { cellProcessing as compensationByBonusOrdersCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/CompensationByBonusOrdersCellContent";
import { cellProcessing as dataChangeBalanceCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/DataChangeBalanceCellContent";
import { cellProcessing as dueAmountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/DueAmountCellContent";
import { cellProcessing as fatherNameCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/FatherNameCellContent";
import { cellProcessing as feeCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/FeeCellContent";
import { cellProcessing as firstNameCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/FirstNameCellContent";
import { cellProcessing as fleetCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/FleetCellContent";
import { cellProcessing as groupCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/GroupCellContent";
import { cellProcessing as idCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/IdCellContent";
import { cellProcessing as invoiceAmountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/InvoiceAmountCellContent";
import { cellProcessing as invoiceCountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/InvoiceCountCellContent";
import { cellProcessing as lastNameCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/LastNameCellContent";
import { cellProcessing as markUpOnAgencyOrderCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/MarkUpOnAgencyOrderCellContent";
import { cellProcessing as ordersAmountToCardExecutorCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/OrdersAmountToCardExecutorCellContent";
import { cellProcessing as ordersAmountToCardServiceCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/OrdersAmountToCardServiceCellContent";
import { cellProcessing as ordersAmountToTerminalServiceCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/OrdersAmountToTerminalServiceCellContent";
import { cellProcessing as ordersCountToCardExecutorCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/OrdersCountToCardExecutorCellContent";
import { cellProcessing as ordersCountToTerminalServiceCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/OrdersCountToTerminalServiceCellContent";
import { cellProcessing as overallAmountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/OverallAmountCellContent";
import { cellProcessing as overallCountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/OverallCountCellContent";
import { cellProcessing as paymentFromExecutorCashCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/PaymentFromExecutorCashCellContent";
import { cellProcessing as paymentFromExecutorCashPeriodCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/PaymentFromExecutorCashPeriodCellContent";
import { cellProcessing as paymentFromExecutorToAccountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/PaymentFromExecutorToAccountCellContent";
import { cellProcessing as paymentFromExecutorToAccountPeriodCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/PaymentFromExecutorToAccountPeriodCellContent";
import { cellProcessing as penaltyAmountCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/PenaltyAmountCellContent";
import { cellProcessing as rentCellProcessing } from "../../pages/MainPage/pages/Accounting/tabs/ExecutorsGeneral/components/Content/components/RentCellContent";
import { Person, Base, Language, TaxiService, ExecutorRate } from "..";

const logger = createLogger({ name: "ExecutorReport" });

class ExecutorReport extends Base {
	static fromResponse(data: any): ExecutorReport.Model {
		return {
			id: data.id,
			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,

			callSign: data.callSign,
			person: data.person ? Person.fromResponse(data.person) : undefined,
			taxiService: TaxiService.fromResponse(data.taxiService),
			fleet: data.fleet,
			group: data.group,

			ordersCounters: data.ordersCounters,
			executorRateColumns: data.executorRateColumns,
			paymentAccounts: data.paymentAccounts || [],

			totalCommission: data.totalCommission,
			totalSubscription: data.totalSubscription,

			manualReplenishmentAmount: data.manualReplenishmentAmount,
			manualReplenishmentDate: data.manualReplenishmentDate,
			manualReplenishmentPeriod: data.manualReplenishmentPeriod,
			cardReplenishmentAmount: data.cardReplenishmentAmount,
			cardReplenishmentDate: data.cardReplenishmentDate,

			discountCompensation: data.discountCompensation,
			dueAmount: data.dueAmount,
		};
	}

	public static async getExecutorReport(
		params: ExecutorReport.SearchOptions,
	): Promise<ExecutorReport.GetExecutorReportResponse | null> {
		try {
			logger.info("[ExecutorReport] getExecutorReport params", {
				params,
			});
			if (!params.dateRange) return null;

			const response = await this.request((prpc) =>
				prpc.theirsModel.report.getExecutorsReport(params),
			);

			logger.info("[ExecutorReport] getExecutorReport response", {
				response,
			});
			if (!response) return null;

			return {
				...response,
				items: response?.items?.map(this.fromResponse),
			};
		} catch (error) {
			logger.error("[ExecutorReport] Error", error);

			return null;
		}
	}

	public static async getClassifier(): Promise<IResponseWithItems<ExecutorReport.ExecutorRateColumn> | null> {
		try {
			const response = await this.request((prpc) =>
				prpc.theirsModel.executorRate.classifier.getAll(),
			);

			logger.info("[ExecutorReport] getClassifier", { response });

			if (!response) return null;

			return response;
		} catch (error) {
			logger.error("[ExecutorReport] Error", error);

			return null;
		}
	}

	public static exportExcel = async (
		_data: ExecutorReport.Model[],
		options: {
			dateFrom: DatePicker.Value;
			dateTo: DatePicker.Value;
			headerTitle: string;
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			ColumnId: Record<string, string>;
			columnIds: string[];
			translationsColumnIds: string[];
			lang: ValueLanguage;
		},
	) => {
		if (!_data?.length) return;

		const {
			dateFrom,
			dateTo,
			headerTitle,
			tableColumn = [],
			columnStyles,
			ColumnId,
			columnIds,
			translationsColumnIds,
			lang,
		} = options;

		const namefile = generateFileNameFileHelper({
			title: headerTitle,
			dateFrom,
			dateTo,
		});

		const workbook = new ExcelJS.Workbook();

		const createReport = async (items: ExecutorReport.Model[]) => {
			const worksheet = workbook.addWorksheet(namefile);

			columnIds.forEach((colIndex, index) => {
				const cellWidth: number = +(
					columnStyles?.[colIndex]?.cellWidth ?? 80
				);

				if (typeof cellWidth === "number" && cellWidth > 0) {
					worksheet.getColumn(index + 1).width =
						Math.round(cellWidth);
				} else {
					logger.warn(
						`2 Width for column ${colIndex} is undefined or invalid: cellWidth`,
						cellWidth,
					);
				}
			});

			const titleRow = worksheet.addRow(
				columnIds.map(
					(textEN, index) =>
						(textEN = translationsColumnIds?.[index] ?? textEN),
				),
			);
			titleRow.font = {
				name: "Calibri",
				size: 11,
			};
			titleRow.alignment = {
				horizontal: "center",
				vertical: "middle",
				wrapText: true,
			};

			const maxHeight = Math.min(
				3,
				Math.max(
					...tableColumn.map((cell) => {
						if (typeof cell === "string") {
							return Math.ceil(cell.length / 10);
						}
						return 1;
					}),
				),
			);

			titleRow.height = maxHeight * 13;

			items.forEach((item) => {
				const row: (string | number | undefined | Date | null)[] = [];

				columnIds.forEach((columnId) => {
					let value: Date | string | number | null = null;

					switch (columnId) {
						case ColumnId.CallSign: {
							value = callSignCellProcessing(item) ?? null;
							break;
						}
						case ColumnId.Id:
							value = idCellProcessing(item) ?? null;
							break;
						case ColumnId.Group:
							value = groupCellProcessing(item, lang) ?? null;
							break;
						case ColumnId.Fleet:
							value = fleetCellProcessing(item) ?? null;
							break;
						case ColumnId.DueAmount:
							value = dueAmountCellProcessing(item) ?? null;
							break;
						case ColumnId.InvoiceAmount:
							value = invoiceAmountCellProcessing(item) ?? null;
							break;
						case ColumnId.InvoiceCount:
							value = invoiceCountCellProcessing(item) ?? null;
							break;
						case ColumnId.LastName:
							value = lastNameCellProcessing(item) ?? null;
							break;
						case ColumnId.FirstName:
							value = firstNameCellProcessing(item) ?? null;
							break;
						case ColumnId.FatherName:
							value = fatherNameCellProcessing(item) ?? null;
							break;
						case ColumnId.OverallAmount:
							value = overallAmountCellProcessing(item) ?? null;
							break;
						case ColumnId.OverallCount:
							value = overallCountCellProcessing(item) ?? null;
							break;
						case ColumnId.Commission:
							value = commissionCellProcessing(item) ?? null;
							break;
						case ColumnId.Cash:
							value = cashCellProcessing(item) ?? null;
							break;
						case ColumnId.CashAmount:
							value = cashAmountCellProcessing(item) ?? null;
							break;
						case ColumnId.CashCount:
							value = cashCountCellProcessing(item) ?? null;
							break;
						case ColumnId.CashCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.CashCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.CashlessAmount:
							value = cashlessAmountCellProcessing(item) ?? null;
							break;
						case ColumnId.CashlessCount:
							value = cashlessCountCellProcessing(item) ?? null;
							break;
						case ColumnId.CashlessCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.CashlessCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersCount:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmount:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OnlineOrdersCount:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OnlineOrdersAmount:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OnlineOrdersAmountCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OnlineOrdersAmountCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.BonusOrdersCount:
							value = cardCountCellProcessing(item) ?? null;
							break; // ! Double value
						case ColumnId.BonusOrdersAmount:
							value = bonusAmountCellProcessing(item) ?? null;
							break;
						case ColumnId.BonusOrdersAmountCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.BonusOrdersAmountCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersCountToCardService:
							value = cardCountCellProcessing(item) ?? null;
							break; // ! Double value
						case ColumnId.OrdersAmountToCardService:
							value =
								ordersAmountToCardServiceCellProcessing(item) ??
								null;
							break;
						case ColumnId.OrdersAmountToCardServiceCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountToCardServiceCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountToTerminalExecutorCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersCountToCardExecutor:
							value =
								ordersCountToCardExecutorCellProcessing(item) ??
								null;
							break;
						case ColumnId.OrdersAmountToCardExecutor:
							value =
								ordersAmountToCardExecutorCellProcessing(
									item,
								) ?? null;
							break;
						case ColumnId.OrdersAmountToTerminalExecutor:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountToTerminalExecutorCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountToCardExecutorCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountToCardExecutorCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.PenaltyCount:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.PenaltyAmount:
							value = penaltyAmountCellProcessing() ?? null;
							break; // ? Empty value
						case ColumnId.Rent:
							value = rentCellProcessing(item) ?? null;
							break;
						case ColumnId.Fee:
							value = feeCellProcessing(item) ?? null;
							break;
						case ColumnId.AdditionalFee:
							value = additionalFeeCellProcessing(item) ?? null;
							break;
						case ColumnId.TotalAmountPayable:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.Balance:
							value =
								balanceCellProcessing.cellProcessingSecondType(
									balanceCellProcessing(item),
								) ?? null;
							break;
						case ColumnId.PaymentFromExecutorCash:
							value =
								paymentFromExecutorCashCellProcessing(item) ??
								null;
							break;
						case ColumnId.PaymentFromExecutorCashPeriod:
							value =
								paymentFromExecutorCashPeriodCellProcessing(
									item,
								) ?? null;
							break;
						case ColumnId.PaymentFromExecutorToAccount:
							value =
								paymentFromExecutorToAccountCellProcessing(
									item,
								) ?? null;
							break;
						case ColumnId.PaymentFromExecutorToAccountPeriod:
							value =
								paymentFromExecutorToAccountPeriodCellProcessing(
									item,
								) ?? null;
							break;
						case ColumnId.Residue:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.DataChangeBalance:
							value =
								dataChangeBalanceCellProcessing(item) ?? null;
							break;
						case ColumnId.MarkUpOnAgencyOrder:
							value =
								markUpOnAgencyOrderCellProcessing(item) ?? null;
							break;
						case ColumnId.CompensationByBonusOrders:
							value =
								compensationByBonusOrdersCellProcessing(item) ??
								null;
							break;
						case ColumnId.IncomeExecutor:
							value = cellContentProcessing();
							break; // ? Empty value
						//
						case ColumnId.OrdersCountToTerminalService:
							value =
								ordersCountToTerminalServiceCellProcessing(
									item,
								) ?? null;
							break;
						case ColumnId.OrdersAmountToTerminalService:
							value =
								ordersAmountToTerminalServiceCellProcessing(
									item,
								) ?? null;
							break;
						case ColumnId.OrdersAmountToTerminalServiceCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountToTerminalServiceCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersCountWithLocal:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountWithLocal:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountWithLocalCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountWithLocalCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersCountWithGlobal:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountWithGlobal:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountWithGlobalCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountWithGlobalCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersCountWithOutSide:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountWithOutSide:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountWithOutSideCommissionGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersAmountWithOutSideCashDeskGross:
							value = cellContentProcessing();
							break; // ? Empty value
						case ColumnId.OrdersCountToTerminalExecutor:
							value = cellContentProcessing();
							break; // ? Empty value
						default:
							value = null;
							break;
					}

					row.push(value);
				});

				const newRow = worksheet.addRow(row);
				newRow.alignment = {
					horizontal: "center",
					vertical: "middle",
					wrapText: true,
				};
				newRow.font = { name: "Arial", size: 10 };

				newRow.eachCell((cell) => {
					if (
						typeof cell.value === "string" &&
						/[.,]\d/.test(cell.value)
					) {
						const num = parseFloat(cell.value.replace(",", "."));
						if (!Number.isNaN(num)) {
							cell.value = num;
							cell.numFmt = "0.00";
						}
					}
				});

				const maxHeight = Math.min(
					3,
					Math.max(
						...row.map((cell) => {
							if (typeof cell === "string") {
								return Math.ceil(cell.length / 10);
							}
							return 1;
						}),
					),
				);

				newRow.height = maxHeight * 13;

				newRow.alignment = {
					horizontal: "center",
					vertical: "middle",
					wrapText: true,
				};
			});

			worksheet.addRow([]);
			worksheet.addRow([]);

			const buffer = await workbook.xlsx.writeBuffer();

			const blob = new Blob([buffer], {
				type: "application/octet-stream",
			});
			saveAs(blob, nameFileExcelOnly);
		};

		const nameFileExcelOnly = `${namefile}.xlsx`;

		createReport(_data);
	};
}

enum ExecutorRateColumnsType {
	Commission = "commission",
	Subscription = "subscription",
}

declare namespace ExecutorReport {
	interface Model {
		id: number;
		createdAt?: string;
		updatedAt?: string;
		deletedAt?: string | null;

		fleet: ExecutorReport.Model.Fleet | null;
		group: ExecutorReport.Model.Group | null;
		taxiService: TaxiService.Model;

		person?: Person.Model;
		callSign: string;
		ordersCounters: ExecutorReport.Model.OrdersCounters;
		paymentAccounts?: ExecutorReport.Model.PaymentAccountReport[];
		executorRateColumns: ExecutorRateColumn[];

		/** Collects all manual (cash) replenishment (by dispatcher) to the executor main balance */
		manualReplenishmentAmount: number;
		/** Latest manual replenishment date */
		manualReplenishmentDate: Date;
		/** Manual replenishment amount at the end of period */
		manualReplenishmentPeriod: number;

		/** Collects all card replenishment from executor */
		cardReplenishmentAmount: number;
		/** Latest executor's card top up date */
		cardReplenishmentDate: Date;

		/** The total commission is the sum of the all types of the commission (cash, commission, etc) */
		totalCommission: number;
		/** The total subscription is the sum of the all types of the subscription (rent, subscription, additional subscription, etc) */
		totalSubscription: number;
		/** Compensation of the all discounted orders to executor */
		discountCompensation: number;
		/** The amount that the executor must paid to the company */
		dueAmount: number;

		// this is added inside the table and is only needed for the front end!
		rate?: Pick<ExecutorRate.Model, "amountOfMoneyToBlock" | "active">;
	}

	interface ExecutorRateColumn extends Record<string, any> {
		id: number;
		amount: number;
		type: ExecutorRateColumnsType;
		name: Record<Language, string>;
	}

	interface Counters {
		orders: ExecutorReport.Model.OrdersCounters;
		executorRateColumns: ExecutorRateColumn[];
		totalCommission?: number;
		totalSubscription?: number;
		discountCompensation: number;
		dueAmount: number;
		callSign: string;
	}

	interface GetExecutorReportResponse extends IResponseWithItems<Model> {
		counters: Counters;
	}

	interface SearchOptions {
		callSigns?: string[];
		fleetIds?: number[];
		executorIds?: number[];
		executorGroupIds?: number[];
		taxiServiceIds?: number[];
		dateRange: DateRange;
	}

	namespace Model {
		interface PaymentAccountReport extends PaymentAccount {
			amountUpdatedAt: number;
			amountAtEndOfPeriod: number;
		}
		interface Fleet {
			id: number;
			active: boolean;
			name: string;
		}
		interface Group {
			id: number;
			active: boolean;
			executorIds: number[];
			name: Record<Language, string>;
			configuration: Record<string, any>;
		}
		interface AmountCount {
			count: number;
			amount: number;
		}

		interface Penalties extends AmountCount {}

		interface Cash extends AmountCount {
			type: "cash";
		}
		interface CashLess extends AmountCount {
			type: "cashless";
		}
		interface Card extends AmountCount {
			type: "card";
		}
		interface CashLess extends AmountCount {
			type: "cashless";
		}
		interface Overall extends AmountCount {}
		interface Bonus extends AmountCount {}

		interface OrdersCounters {
			cash: Cash;
			cashless: CashLess;
			overall: Overall;
			bonus: Bonus;
			/** card === cardToTaxiService */
			card: Card;
			cardToTaxiService: AmountCount;
			cardToExecutor: AmountCount;
			terminal: AmountCount;
			invoice: AmountCount;
		}
	}
}

export default ExecutorReport;
